import styled from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 89px);
  margin-top: 3.375rem;
  @media (max-width: 743px) {
    justify-content: ${({ isOnline }) => (isOnline ? "flex-end" : "center")};
  }
`;
