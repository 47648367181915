import styled from "styled-components";

export const Overlay = styled.div`
  position: fixed;
  z-index: 10;
  background-color: #707070;
  opacity: 0.8;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const Container = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  z-index: 11;
  background-color: #fff;
  width: 1051px;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.24);
  border-radius: 8px;
  padding: 32px 30px;
  margin-bottom: 200px;
`;

export const ObservationBox = styled.div`
  text-align: center;
  background: #c5d7fb;
  border-radius: 8px;
  padding: 16px;
  font-size: 1rem;
  color: #0a3a99;
  margin: 8px 0 8px 0;
`;

export const H1 = styled.h1`
  font-weight: 700;
  font-size: ${({ size }) => (size ? size : "1.5rem")};
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : "12px")};
  color: #292929;
  text-align: ${({ align }) => (align ? align : "")};
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  @media (max-width: 1365px) {
    margin-bottom: 0;
  }
  span {
    font-size: 16px;
  }

  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

export const Divider = styled.div`
  margin: ${({ margin }) => margin};
  font-size: 1rem;
  color: #a3a3a3;
  hr {
    margin: 15px 0 32px;
  }
`;
export const Text = styled.p`
  color: #292929;
  line-height: 24px;
  font-weight: ${({ fontWeight }) => fontWeight || "400"};
`;
export const ButtonWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  button {
    height: 48px;
  }

  button:nth-child(1) {
    min-width: 0;
    margin-right: 16px;
    width: ${(props) => (props.width ? "48px" : "")};
  }

  @media (max-width: 420px) {
    margin-top: 20px;
  }
`;

export const TextsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  div {
    p:nth-child(1) {
      margin-bottom: 16px;
    }
  }

  /* p {
    margin-top: 16px;
  } */
`;

export const TextsRow = styled.div`
  display: flex;
  flex-direction: row;
  p {
    margin-right: 16px;
  }
`;

export const TableWrapper = styled.div`
  overflow-x: auto;

  ::-webkit-scrollbar {
    height: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const Table = styled.table`
  margin: ${(props) => (props.margin ? props.margin : "32px 0")};
  width: 100%;
  border-spacing: 0px;
  border-collapse: collapse;

  .table-line {
    box-shadow: 0px 1px #e0e0e0;
  }

  th {
    width: ${(props) => props.width};
    text-align: left;
    padding: 20px 24px;
    font-weight: 700;
  }

  td {
    width: ${(props) => props.width};
    padding: 20px 24px;
    font-weight: 400;
  }
`;

export const ContainerModal = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  overflow-y: hidden;
  max-height: 600px;
  max-width: 800px;

  @media (max-width: 1365px) {
    max-height: 450px;
    max-width: 624px;
  }
`;

export const BoxTitle = styled.div`
  display: flex;
  background-color: #fff;
  width: auto;
  max-height: 89px;
  margin-top: 17px;
  padding: 24px;
  border-radius: 8px 0 0 0;
  z-index: 10;

  .tmp-button-icon {
    margin: ${(props) => (props.margin ? props.margin : "24px 24px 0 0;")};
  }

  @media (max-width: 1365px) {
    max-width: 564px;
  }
`;

export const ModalContent = styled.div`
  margin: 8px 0 0px 24px;
  padding-right: 24px;
  position: relative;
  overflow-y: auto;
  max-height: calc(600px - 77px - 114px);

  @media (max-height: 700px) {
    max-height: 300px;
  }
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 8px;
  }
`;
