import { isAfter, isBefore } from "date-fns";

const calcFirstChecker = (firstNineDigits) => {
  let sum = 0;

  for (let i = 0; i < 9; ++i) {
    sum += Number(firstNineDigits.charAt(i)) * (10 - i);
  }

  const lastSumChecker = sum % 11;
  return lastSumChecker < 2 ? 0 : 11 - lastSumChecker;
};

const calcSecondChecker = (cpfWithChecker1) => {
  let sum = 0;

  for (let i = 0; i < 10; ++i) {
    sum += Number(cpfWithChecker1.charAt(i)) * (11 - i);
  }

  const lastSumChecker2 = sum % 11;
  return lastSumChecker2 < 2 ? 0 : 11 - lastSumChecker2;
};

const allDigitsAreEqual = (digits) => {
  for (let i = 0; i < 10; i++) {
    if (digits === Array.from({ length: digits.length + 1 }).join(String(i))) {
      return true;
    }
  }

  return false;
};

export default {
  cpf(value) {
    if (typeof value !== "string") {
      return false;
    }

    const cleanCPF = String(value).replace(/[\s.-]/g, "");
    const firstNineDigits = cleanCPF.slice(0, 9);
    const checker = cleanCPF.slice(9, 11);

    if (cleanCPF.length !== 11 || allDigitsAreEqual(cleanCPF)) {
      return false;
    }

    const checker1 = calcFirstChecker(firstNineDigits);
    const checker2 = calcSecondChecker(`${firstNineDigits}${checker1}`);

    return checker === `${checker1}${checker2}`;
  },
};

export const validationFormatDate = (valueInput) => {
  if (typeof valueInput !== "string") {
    return true;
  }

  if (!valueInput) {
    return true;
  } else {
    try {
      let day = valueInput.split("/")[0];
      let month = valueInput.split("/")[1];
      let year = valueInput.split("/")[2];
      let dateFormatEn = `${month}/${day}/${year}`;

      if (year?.length < 4) return true;

      const inputDate = new Date(dateFormatEn);

      const currentDate = new Date();
      const minDate = new Date("01/01/1900");

      if (!isBefore(inputDate, currentDate) || !isAfter(inputDate, minDate)) {
        return true;
      }
    } catch (error) {
      return true;
    }

    return false;
  }
};

export const validationEmail = (valueInput) => {
  const emailRegex =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let formatEmail = emailRegex.test(valueInput);
  return formatEmail;
};

export const ensureArray = (value) => {
  if (Array.isArray(value)) {
    // Se o input for um array, retornar o próprio array
    const novoArray = value.map((objeto) => {
      if (typeof objeto.quantidade === "number") {
        return {
          ...objeto,
          quantidade: objeto.quantidade.toString(),
        };
      }
      return objeto;
    });
    return novoArray;
  } else if (
    value === null ||
    typeof value === "undefined" ||
    typeof value === "object"
  ) {
    // Se o input for null ou undefined, retornar um array vazio
    return [];
  } else {
    // Se o input não for um array, objeto, null ou undefined, retornar um array vazio
    return [];
  }
};
