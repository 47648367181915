export const opmeType = {
  material: "",
  quantidade: "",
  fabricante1: "",
  fabricante2: "",
  fabricante3: "",
  naoIndicarFabricante: false,
  naoIndicarFabricanteMotivo: "",
  naoIndicarFabricanteJustificativa: "",
  naoIndicarFabricanteMotivoData: "",
  naoIndicarFabricanteMotivoTime: "",
};

export const genericType = {
  material: "",
  quantidade: "",
};

export const objectProcedure = {
  procedimento: "",
  lateralidades: [],
  exige_lado: "",
  lateralidade: "",
  ieLado: "",
  cdProcedimento: "",
};

export const otherTeams = {
  nomeCirurgiao: "",
  crmCirurgiao: "",
};

export const times = [
  "06:00 am",
  "07:00 am",
  "08:00 am",
  "09:00 am",
  "10:00 am",
  "11:00 am",
  "12:00 am",
  "13:00 pm",
  "14:00 pm",
  "15:00 pm",
  "16:00 pm",
  "17:00 pm",
  "18:00 pm",
  "19:00 pm",
  "20:00 pm",
  "21:00 pm",
  "22:00 pm",
];

export const timesBsb = [
  "00:00 am",
  "01:00 am",
  "02:00 am",
  "03:00 am",
  "04:00 am",
  "05:00 am",
  "06:00 am",
  "07:00 am",
  "08:00 am",
  "09:00 am",
  "10:00 am",
  "11:00 am",
  "12:00 am",
  "13:00 pm",
  "14:00 pm",
  "15:00 pm",
  "16:00 pm",
  "17:00 pm",
  "18:00 pm",
  "19:00 pm",
  "20:00 pm",
  "21:00 pm",
  "22:00 pm",
  "23:00 pm",
];

export const timesItaimWorkingDays = [
  "07:00 am",
  "08:00 am",
  "09:00 am",
  "10:00 am",
  "11:00 am",
  "12:00 am",
  "13:00 pm",
  "14:00 pm",
  "15:00 pm",
  "16:00 pm",
  "17:00 pm",
  "18:00 pm",
  "19:00 pm",
];

export const timesItaimHospitalDia = [
  "05:00 am",
  "06:00 am",
  "07:00 am",
  "08:00 am",
  "09:00 am",
  "10:00 am",
  "11:00 am",
  "12:00 am",
  "13:00 pm",
  "14:00 pm",
  "15:00 pm",
  "16:00 pm",
  "17:00 pm",
  "18:00 pm",
];

export const timesItaimSaturdays = [
  "07:00 am",
  "08:00 am",
  "09:00 am",
  "10:00 am",
  "11:00 am",
  "12:00 am",
  "13:00 pm",
  "14:00 pm",
  "15:00 pm",
  "16:00 pm",
  "17:00 pm",
  "18:00 pm",
];

export const getItaimHours = (data, regimeHospitalar) => {
  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const dayOfWeekNumber = new Date(data).getDay();
  const weekDay = weekdays[dayOfWeekNumber];

  if (weekDay !== "Sunday") {
    if (regimeHospitalar === "Hospital Dia") {
      return timesItaimHospitalDia;
    } else {
      if (weekDay === "Saturday") {
        return timesItaimSaturdays;
      } else return timesItaimWorkingDays;
    }
  } else if (weekDay === "Sunday") return [];
};
