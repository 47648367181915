import styled, { css } from "styled-components";
import { TmpListItem } from "@tempo/tempo-design-system-core/dist";

export const Container = styled.div`
  width: 100%;
  text-align: center;
  box-sizing: border-box;

  @media only screen and (max-width: 744px) {
    padding: 0 12.23%;
  }

  @media only screen and (min-width: 745px) {
    padding: 0 13.54%;
  }
`;

export const StyledLoading = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #f5f5f5;
  z-index: 4;
  opacity: 80%;
`;

export const MenuOverFlow = styled.div`
  display: flex;
  align-items: center;
  button {
    padding: 3px;
  }

  > div > ul {
    @media (max-width: 768px) {
      padding-top: 90px;
    }
  }
`;

export const Label = styled.li`
  @media (max-width: 744px) {
    padding: 16px 42px;
  }
  @media (max-width: 414px) {
    padding: 16px 16px;
  }
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 63px;
  position: relative;
  cursor: pointer;

  :after {
    position: absolute;
    content: "";
    border-bottom: 1px solid #d2d7da;
    width: calc(100% - 66px);
    transform: translateX(-50%);
    bottom: 1px;
    left: 50%;
  }

  &:hover {
    background-color: rgba(197, 215, 251, 0.32);
    color: #0a3a99;

    .label,
    .text {
      color: #0a3a99;
    }
  }

  .boxLabel {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 16px;
    text-align: left;

    /* white-space: nowrap; */
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .label {
    margin-bottom: 4px;
    font-family: "Inter";
    font-weight: 500;
    font-size: 1rem;
    color: #2c6ef2;

    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 414px) {
      font-size: 0.87rem;
    }
  }

  .text {
    font-family: "Inter";
    font-weight: 400;
    font-size: 0.875rem;
    color: #666666;
    overflow: hidden;

    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 414px) {
      font-size: 0.75rem;
    }
  }
`;

export const Content = styled.div``;

export const Subtitle = styled.p`
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 133%;
  margin: 88px 0px 16px 0px;
  color: #292929;

  @media (max-width: 743px) {
    font-size: 1.25rem;
  }
`;

export const Paragraph = styled.p`
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  color: #292929;
  margin-bottom: 32px;

  @media (max-width: 743px) {
    font-size: 0.875rem;
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #e0e0e0;
`;

export const CardWrap = styled.div`
  margin: 16px 0px;

  div {
    padding: 0px;
    min-height: min-content;
    padding-top: ${(props) => props.paddingTop};
    padding-bottom: ${(props) => props.paddingBottom};
    border-color: #2c6ef2;
  }
`;

export const ListItem = styled(TmpListItem)`
  @media (max-width: 744px) {
    padding: 0 42px;
  }
  @media (max-width: 414px) {
    padding: 16px;
  }

  padding: 0 63px;
  box-sizing: border-box;
  border-radius: 16px;
  width: 100%;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.4;
    `}
  .label {
    padding-bottom: 10px;
    font-family: "Inter";
    font-weight: 500;
    font-size: 40px;
    line-height: 100%;
    color: #2c6ef2;
  }

  span {
    font-family: "Inter";
    font-weight: 400;
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 414px) {
      font-size: 1rem;
    }

    font-size: 1.25rem;
  }
`;

export const StyledAlert = styled.div`
  > div > div:last-child {
    text-align: left;
  }

  > div {
    top: 0;
    transform: translate(-50%, -110%);
    width: 312px;
    min-width: 312px;
    z-index: 100;
    margin: 0 auto;

    ${(props) =>
      props.isOpened &&
      css`
        top: 0;
        transform: translate(-50%, 90%);
      `}

    h3 {
      font-size: 16px;
    }
  }
`;

export const WrapperActionModal = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1rem;
`;

export const SVGWrapper = styled.div`
  > svg {
    width: 24px;
    height: 24px;

    @media (max-width: 414px) {
      width: 16px;
      height: 16px;
    }
  }
`;
