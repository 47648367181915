import React from "react";

import * as Styled from "./style";

const Tooltip = ({
  position = "top",
  message,
  id = "tooltip_id",
  messageComponent,
  children,
  disabled
}) => {
  return (
    <Styled.Wrapper role="tooltip" aria-describedby={id} position={position} disabled={disabled}>
      {messageComponent && <>{messageComponent}</>}

      {message && (
        <Styled.Tooltip id={id} className="tmp-tooltip__message">
          {message}
        </Styled.Tooltip>
      )}

      <div className="tmp-tooltip-text">{children}</div>
    </Styled.Wrapper>
  );
};

export default Tooltip;
