import React, { useEffect, useState } from "react";

import * as S from "./style";
import Loading from "../../../../../components/Loading";

const Autocomplete = ({
  list,
  propLabel,
  onClick,
  isCurrentInput,
  isLoading,
  initialOpenValue = false,
  sugestionTitle,
  emptyMessage,
  ...props
}) => {
  const [isOpenSugestion, setIsOpenSugestion] = useState(initialOpenValue);

  useEffect(() => {
    setIsOpenSugestion(true);
  }, [list]);

  const handleClick = (value) => {
    setIsOpenSugestion(false);
    onClick(value);
  };

  const handleReturn = (item) => {
    return item[propLabel];
  };

  return isLoading ? (
    <S.SugestionBox {...props}>
      <Loading />
    </S.SugestionBox>
  ) : isOpenSugestion && isCurrentInput ? (
    <S.SugestionBox {...props}>
      {sugestionTitle && <S.SugestionTitle>{sugestionTitle}</S.SugestionTitle>}
      {list.length > 0 ? (
        list.map((item, index) => (
          <S.SugestionItem
            showTopBorder={!!sugestionTitle}
            key={index}
            onClick={() => handleClick(item)}
          >
            {handleReturn(item)}
          </S.SugestionItem>
        ))
      ) : !isLoading && emptyMessage ? (
        <p>{emptyMessage}</p>
      ) : null}
    </S.SugestionBox>
  ) : null;
};
export default Autocomplete;
