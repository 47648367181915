import styled from "styled-components";

export const Container = styled.header`
  display: flex;
  position: fixed;
  width: 100%;
  height: 88px;
  z-index: 40;

  > div {
    width: 100%;
    padding: 0px 16px 0px 40px;

    @media (max-width: 743px) {
      padding: 0px 0px 0px 24px;
    }
  }

  div {
    div:nth-child(2) {
      padding-left: 0px;
      display: flex;
      justify-content: flex-end;

      .tmp-icon {
        color: #292929;
        @media (max-width: 743px) {
          padding-left: 0px;
        }
      }

      > div > div > button > div > span {
        font-family: "Inter";
        font-weight: 500;
        font-size: 1rem;
        line-height: 100%;
        color: #292929;
        padding-bottom: 0px;

        @media (max-width: 743px) {
          width: 0px;
          visibility: hidden;
        }
      }
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 306px;
  max-width: 100vw;

  > div {
    min-width: 250px;
  }

  @media (max-width: 743px) {
    width: 88px;
    margin-right: 25px;
  }

  > div {
    button {
      align-items: center;
    }
  }
`;

export const HeaderContent = styled.div`
  @media (max-width: 766px) {
    display: none;
  }
  display: flex;
  position: absolute;
  height: 89px;
  justify-content: center;
  align-items: center;
  width: 100%;
  top: 0;
  //styleName: Desktop/Heading/Small (16);
  font-family: Inter;
  font-size: 1rem;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: -0.01em;
  color: #666666;

  > p {
    margin-left: 10px;
  }
`;
