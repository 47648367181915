import styled from "styled-components";

export const Container = styled.footer`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 58px;
  align-items: center;
  background-color: #fff;
`;

export const Text = styled.p`
  font-size: ${({ size }) => size};
  font-weight: ${({ weight }) => weight};
  color: ${({ color }) => color || "#292929"};
  margin: ${({ margin }) => margin};
`;
