import styled from "styled-components";

export const ContainerLogout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (max-width: 767px) {
    height: 100%;
  }
`;

export const ImgLogout = styled.img`
  width: 269px;
  height: 225px;
  margin: 8px 0;
`;

export const TitleLogout = styled.h2`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 133%;
  letter-spacing: -0.24px;
  margin-bottom: 8px;

  @media (max-width: 767px) {
    width: 200px;
  }
`;

export const TextLogout = styled.p`
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;
export const WrapperBtn = styled.div`
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: -24px;
  height: 96px;
  margin-top: 50px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-right: 24px;
    padding: 0 24px;
    height: 48px;

    @media (max-width: 767px) {
      width: 100%;
      margin-right: 0;
    }
  }

  @media (max-width: 767px) {
    justify-content: center;
    padding-right: 0;
    width: calc(100% - 48px);
    padding: 0 24px;
  }

  @media (max-width: 767px) {
    background: transparent;
    margin: auto;
    margin-bottom: 48px;
    margin-top: 97px;
    align-items: flex-end;
  }
`;
