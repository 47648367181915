import styled from "styled-components";

export const BoxModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => alignItems ?? "center"};
  text-align: ${({ textAlign }) => textAlign ?? "center"};
  margin-bottom: 32px;
  margin-top: 24px;
  padding: 0 24px;
  max-width: 624px;
  color: #292929;
`;

export const BtnSendWrap = styled.div`
  width: 100%;
  height: 96px;
  display: flex;
  align-items: ${(props) => props.alingItems};
  justify-content: ${(props) =>
    props.justContent ? "space-between" : "flex-end"};
  background-color: #f5f5f5;

  button {
    margin: ${(props) => props.margin};
  }
  > div {
    height: 48px;
    margin: 24px 24px 0 0;

    > button {
      min-width: ${(props) => (props.loading ? "" : "94px")};
      height: ${(props) => (props.loading ? "" : "48px")};
      padding: 0;
      margin: 0;
    }
    > button:nth-last-child(1) {
      min-width: ${(props) => (props.loading ? "" : "170px")};
      height: ${(props) => (props.loading ? "" : "48px")};
      padding: 0;
      margin: 0;
    }
  }
  button {
    pointer-events: ${(props) => (props.pointer ? "none" : "")};
  }
`;

export const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  padding: 23.5px 24px;
  border-bottom: 1px solid #e0e0e0;
  color: #292929;
  cursor: pointer;

  font-weight: ${({ selected }) => (selected ? "500" : null)};

  .italic {
    font-style: italic;
    font-weight: 500;
  }
`;

export const Radio = styled.div`
  padding: 24px;
`;

export const List = styled.ul`
  width: 100%;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  height: 224px;
  overflow-y: auto;

  li:nth-child(even) {
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 8px;
  }
`;

export const Alert = styled.div`
  > span {
    height: fit-content;
    margin-right: 8px;
  }
`;

export const Card = styled.div`
  border-radius: 16px;
  background-color: #f5f5f5;
  width: 100%;
  margin: 16px 0;
  font-size: 0.85rem;
  color: #666666;

  > div {
    display: grid;
    gap: 8px;
    padding: 16px;
  }

  > p {
    margin-bottom: 8px;
  }

  > p:nth-child(3) {
    margin-bottom: 0;
  }
`;
