import RequestFailedImage from "./Image";
import RequestFailedContent from "./Content";
import RequestFailedAction from "./Action";
import RequestFailedRoot from "./Root";

export const RequestFailed = {
  Image: RequestFailedImage,
  Content: RequestFailedContent,
  Action: RequestFailedAction,
  Root: RequestFailedRoot,
};
