import React, { useEffect } from "react";

import { AuthContext } from "../contexts";
import { setClearAuth, setTokenParameters } from "../features/Auth/authSlice";
import { apiDoctor, apiLogin } from "../services/api";
import { useDispatch, useSelector } from "react-redux";
import {
  setToken,
  setPlugPlayToken,
  setUserInfos,
  setDoctor,
} from "../features/Auth/authSlice";
import { setIsSecretary } from "../features/Auth/authSlice";
import { setSolicitationProperty } from "../features/solicitation/solicitationSlice";
import { saveSignatureDataUrlOnRedux } from "../utils/Auth";
import Auth from "../pages/Auth";

const isUserLogged = (refreshToken, token) => {
  // Se existe um refreshToken, o usuário que acabou de logar deve ser autenticado na API /refresh-token
  if (refreshToken || refreshToken === "") {
    return false;
  }

  // Se não existe, checar se há algum token salvo no store
  return !!token;
};

const params = new URLSearchParams(window.location.search);
let refreshToken = params.get("token");
const nmMedico = params.get("nm_medico");

const AuthProvider = ({ children }) => {
  const { token } = useSelector((state) => state?.auth);
  const signature = useSelector((state) => state.auth.userInfos.assinatura);

  const dispatch = useDispatch();

  const tryToRevalidateAccessToken = async (onFail) => {
    try {
      const response = await apiLogin().post({
        accessToken: token,
      });

      const {
        accessToken,
        expirationDate,
        timeToRevalidate,
        sessionExpirationDate,
      } = response || "";
      const { plugPlayAccessToken } = response || "";

      dispatch(setToken(accessToken));
      dispatch(
        setTokenParameters({
          tokenStoredAt: new Date(),
          tokenExpirationDate: expirationDate,
          timeToRevalidate,
          sessionExpirationDate,
        })
      );
      dispatch(setPlugPlayToken(plugPlayAccessToken));
    } catch (error) {
      onFail();
    }
  };

  useEffect(() => {
    const handleHSLAuth = async () => {
      try {
        if (!refreshToken) {
          throw new Error();
        }
        const response = await apiLogin().post({
          refreshToken,
          nm_medico: nmMedico,
        });

        const authSuccess = JSON.parse(
          atob(response?.accessToken?.split(".")[1])
        );

        if (!authSuccess) {
          throw new Error();
        }

        const {
          accessToken,
          timeToRevalidate,
          sessionExpirationDate,
          expirationDate,
        } = response || "";
        const { plugPlayAccessToken } = response || "";
        const { username } = authSuccess || "";

        dispatch(setDoctor(null));
        dispatch(setIsSecretary(false));
        dispatch(
          setUserInfos({
            nome: "",
            user: "",
            cdPessoaFisica: "",
            crm: "",
            assinatura: undefined,
          })
        );
        refreshToken = null;
        dispatch(setToken(accessToken));
        dispatch(setPlugPlayToken(plugPlayAccessToken));
        dispatch(setUserInfos({ user: username }));
        dispatch(
          setTokenParameters({
            tokenExpirationDate: expirationDate,
            timeToRevalidate,
            sessionExpirationDate,
          })
        );

        await getDoctorInfo(accessToken, username);

        if (nmMedico) {
          dispatch(setDoctor(nmMedico));
          dispatch(setIsSecretary(true));
        }

        history.pushState({}, undefined, "/");
      } catch {
        logout();
      }
    };

    const getDoctorInfo = async (token, user) => {
      const username = nmMedico ?? user;
      if (username && token) {
        try {
          const response = await apiDoctor().getSingle(username);
          if (response.data === null && !signature) {
            dispatch(
              setUserInfos({
                crm: "",
                nome: "",
                telefone: "",
                cdPessoaFisica: "",
                assinatura: null,
              })
            );
          }
          if (response.nm_usuario) {
            const {
              crm_usuario,
              nm_usuario,
              telefone,
              id_pessoa_fisica_gssk,
              cd_pessoa_fisica_pk,
              im_assinatura,
            } = response;

            dispatch(
              setUserInfos({
                crm: crm_usuario,
                nome: nm_usuario,
                telefone: telefone,
                cdPessoaFisica: cd_pessoa_fisica_pk || id_pessoa_fisica_gssk,
                assinatura: im_assinatura,
              })
            );

            dispatch(
              setSolicitationProperty({
                idMedico: cd_pessoa_fisica_pk || id_pessoa_fisica_gssk,
              })
            );

            saveSignatureDataUrlOnRedux(im_assinatura, (urlAssinatura) =>
              dispatch(
                setUserInfos({
                  urlAssinatura,
                })
              )
            );
          }
        } catch {
          console.log("Erro ao consultar as informações do Médico...");
        }
      }
    };

    if (!isUserLogged(refreshToken, token)) {
      handleHSLAuth();
    }
  }, [token]);

  const logout = () => {
    dispatch(setClearAuth());
    window.location.replace(process.env.REACT_APP_PORTAL_MEDICO);
  };

  const value = { logout, tryToRevalidateAccessToken };

  return (
    <AuthContext.Provider value={value}>
      {isUserLogged(refreshToken, token) ? children : <Auth />}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
