import styled from "styled-components";

export const WrapperButton = styled.div`
  @media (max-width: 743px) {
    width: 90%;
    .tmp-button {
      width: 100%;
    }
  }
`;
