import styled from "styled-components";

export const StyledIcon = styled.div`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;

  &:hover {
    background-color: ${props => props.disabled ? "" : "#c5d7fb"};

    svg path,
    svg rect {
      fill: ${props => props.disabled ? "" : "#0a3a99"};
    }
  }
`;
