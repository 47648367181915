import { createSlice } from "@reduxjs/toolkit";
import { updateState, updateObjectState } from "../../utils";
import { auth } from "./auth";

const authSlice = createSlice({
  name: "auth",
  initialState: auth,
  reducers: {
    setClearAuth: () => {
      return auth;
    },
    setToken: (state, action) => {
      return {
        ...state,
        token: action.payload,
      };
    },
    setTokenStoredAt: (state, action) => {
      return {
        ...state,
        tokenStoredAt: action.payload,
      };
    },
    setPlugPlayToken: (state, action) => {
      return {
        ...state,
        plugPlayAccessToken: action.payload,
      };
    },
    setTokenParameters: (state, action) => {
      return {
        ...state,
        tokenExpirationDate: action.payload.tokenExpirationDate,
        timeToRevalidate: action.payload.timeToRevalidate,
        sessionExpirationDate: action.payload.sessionExpirationDate,
      };
    },
    setDoctor: (state, action) => {
      return {
        ...state,
        doctor: action.payload,
      };
    },
    setIsSecretary: (state, action) => {
      state.isSecretary = action.payload;
    },
    setUserInfos: (state, action) => {
      return {
        ...state,
        userInfos: {
          ...state.userInfos,
          ...action.payload,
        },
      };
    },
    setAuthObject: updateObjectState,
    setAuthProperty: updateState,
  },
});

export const {
  setAuthProperty,
  setAuthObject,
  setClearAuth,
  setUserInfos,
  setToken,
  setPlugPlayToken,
  setDoctor,
  setTokenStoredAt,
  setIsSecretary,
  setTokenParameters,
} = authSlice.actions;

export const authReducer = authSlice.reducer;
