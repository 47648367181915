import { TmpCheckbox, TmpRadio } from "@tempo/tempo-design-system-core/dist";
// import CheckBox from 'components/checkbox'
// import Radio from 'components/radio'
import React, { useEffect, useState } from "react";

const FilterItem = ({ type = "checkbox", label, selected, handleSelected }) => {
  const [_selected, setSelected] = useState(!!selected);

  useEffect(() => {
    setSelected(selected);
  }, [selected]);

  const _handleClickItem = () => {
    handleSelected(!_selected);
    setSelected(!_selected);
  };

  return type == "checkbox" ? (
    <TmpCheckbox
      label={label}
      checked={_selected}
      handleChange={_handleClickItem}
    />
  ) : (
    <TmpRadio
      label={label}
      checked={_selected}
      handleChange={_handleClickItem}
    />
  );
};

export default FilterItem;
