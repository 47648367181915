import { createSlice } from "@reduxjs/toolkit";
import { updateState, updateObjectState } from "../../utils";
import { materials } from "./materials";

const materialsSlice = createSlice({
  name: "materials",
  initialState: materials,
  reducers: {
    setClearMaterials: () => {
      return materials;
    },
    setMaterialsProperty: updateState,
    setMaterialsObject: updateObjectState,
  },
});

export const { setMaterialsProperty, setMaterialsObject, setClearMaterials } =
  materialsSlice.actions;

export const materialsReducer = materialsSlice.reducer;
