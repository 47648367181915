export const criticalUnits = {
  "Unidade de Terapia Intensiva": 2205153,
  "Unidade Semi Intensiva": 2209153,
  "Unidade Coronária": 2213153,
  "Unidade Crítica Geral": 2225153,
  "Unidade Semintesiva Pediátrica": 7187267,
  "UTI Cardio": 7187270,
  "UTI Pediátrica": 7187266,
  "Sem Reserva": 0,
};

export const criticalUnitsbyBSB = {
  "Unidade de Terapia Intensiva": 2205153,
  "Sem Reserva": 0,
};

export const criticalUnitsbyItaim = {
  Pernoite: 9038002,
  "Sem Reserva": 0,
};
