import {
  TmpButton,
  TmpButtonGroup,
  TmpListItem,
  TmpIcon,
} from "@tempo/tempo-design-system-core/dist";
import {
  filtro as FilterIcon,
  seta_direcional_esquerda as SetaEsquerda,
} from "@tempo/tempo-assets/dist";
import React, { useEffect, useRef, useState } from "react";

// import Icon from "../icon";
import FilterItem from "./filter-item";
import * as Styled from "./style";

const Filter = ({
  className,
  label,
  searchLabel,
  actived = false,
  title,
  type = "checkbox",
  filterItems = [],
  disabled,
  buttonGroupLabelPrimary = "Aplicar filtro",
  buttonGroupLabelSecondary = "Limpar Filtro",
  handleChange,
  handleClickPrimary,
  handleClickSecondary,
}) => {
  const [_actived, setActived] = useState(actived);
  const [_filterItems, setFilterItems] = useState(filterItems);

  const wrapperRef = useRef(null);

  const _counter = _filterItems.filter((item) => item.selected).length;

  useEffect(() => {
    setActived(actived);
  }, [actived]);

  useEffect(() => {
    setFilterItems(filterItems);
  }, [filterItems]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef?.current?.contains(event.target)) {
        setFilterItems(filterItems);
        setActived(false);
      }
    }
    document.removeEventListener("mousedown", handleClickOutside);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, filterItems]);

  const _handleClick = () => {
    const status = !_actived;
    if (typeof handleChange === "function") {
      handleChange({
        actived: status,
      });
    }
    setActived(status);
  };

  const _handleClickItem = (selected, item) => {
    let newFilter = [];
    _filterItems.forEach((itemFilter, index) => {
      if (index === item) {
        newFilter.push({
          ...itemFilter,
          selected: selected,
        });
      } else newFilter.push(itemFilter);
    });

    if (type === "radio") {
      newFilter = newFilter.map((itemFilter, index) => {
        if (index === item) {
          return { ...itemFilter, selected: true };
        }
        return { ...itemFilter, selected: false };
      });
    }

    setFilterItems(newFilter);
  };

  const _handleClickPrimary = () => {
    const newFilterItems = [];
    filterItems.forEach((itemFilter) => {
      newFilterItems.push({
        ...itemFilter,
        selected: _filterItems.find((item) => item.label === itemFilter.label)
          ?.selected,
      });
    });
    _handleClick();
    handleClickPrimary(newFilterItems);
  };

  const _handleClickSecondary = () => {
    const arFilter = [..._filterItems];
    arFilter.forEach((ar, index) => {
      arFilter[index].selected = false;
    });

    setFilterItems([..._filterItems, {}]);
    setTimeout(() => {
      setFilterItems(arFilter);
    }, 1);

    handleClickSecondary();
    _handleClick();
  };

  return (
    <Styled.Wrapper ref={wrapperRef}>
      <TmpButton
        className={`button-filter ${className} ${
          _actived ? "open-filter" : "close-filter"
        } ${_counter > 0 ? "has-filter" : disabled ? "" : "no-filter"}`}
        type="secondary"
        size="sm"
        startIcon={<TmpIcon icon={<FilterIcon />} />}
        handleClick={_handleClick}
        disabled={disabled}
      >
        {label}
        {_counter >= 1 && <Styled.Counter>{_counter}</Styled.Counter>}
      </TmpButton>

      <Styled.DropDown
        $actived={_actived}
        $hasTitle={!!title}
        $searchLabel={!!searchLabel}
      >
        <ul>
          <TmpListItem
            label={
              <Styled.ListTitle>
                <TmpIcon icon={<SetaEsquerda />} />
                {title}
              </Styled.ListTitle>
            }
            handleClick={_handleClick}
          />

          {_filterItems.map(
            (item, index) =>
              item.label && (
                <TmpListItem
                  key={index}
                  label={
                    <FilterItem
                      type={type}
                      label={item?.label}
                      selected={item.selected}
                      handleSelected={(selected) =>
                        _handleClickItem(selected, index)
                      }
                    />
                  }
                  handleClick={() => false}
                />
              )
          )}
        </ul>
        <Styled.DropDownFooter>
          <TmpButtonGroup
            disabledSecondary={_counter === 0}
            disabledPrimary={_counter === 0}
            labelPrimary={buttonGroupLabelPrimary}
            labelSecondary={buttonGroupLabelSecondary}
            handleClickPrimary={_handleClickPrimary}
            handleClickSecondary={_handleClickSecondary}
          />
        </Styled.DropDownFooter>
      </Styled.DropDown>
    </Styled.Wrapper>
  );
};

export default Filter;
