import { apiSolicitationReports } from "../../services/api";

export const handleUpdateReport = async (
  solicitation,
  setLoading,
  onSuccess,
  onError
) => {
  const report = solicitation?.relatorio || {};
  if (!report.termoConsentimento && !report?.termoConsentimentoUUID) {
    onSuccess(report?.idRelatorio ?? null);
    return;
  }
  try {
    setLoading(true);
    const idRelatorio = report.idRelatorio;

    const response = await apiSolicitationReports(
      solicitation?.solicitacao?.idSolicitacao
    ).put({
      relatorio: {
        idRelatorio: idRelatorio,
        observacoes: report.observacoes,
        carteirinha: report.carteirinha,
        carteirinhaUUID: report.carteirinhaUUID,
        documentoPaciente: report.documentoPaciente,
        documentoPacienteUUID: report.documentoPacienteUUID,
        laudo: report.laudo,
        laudoUUID: report.laudoUUID,
        termoConsentimento: report.termoConsentimento,
        termoConsentimentoUUID: report.termoConsentimentoUUID,
      },

      relatorioDocumento: report.relatorioDocumento,
    });

    if (response?.name === "AxiosError") throw new Error();
    if (response?.idRelatorio) {
      setLoading(false);
      onSuccess(response.idRelatorio);
    }
  } catch (error) {
    setLoading(false);
    onError();
  }
};
