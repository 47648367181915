import styled from "styled-components";

export const Container = styled.span`
  display: ${(props) => (props.display ? "block" : "none")};
  font-size: ${(props) => (props.size === "lg" ? "16px" : "14px")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  align-self: center;

  @media (max-width: 1365px) {
    margin: ${(props) => (props.marginMbl ? props.marginMbl : "")};
    font-size: 14px;
  }
`;
