import atencao from "./assets/atencao.png";

import * as S from "./style";

const RequestFailedImage = ({ image = atencao }) => {
  return (
    <S.WrapperImage>
      <S.ImageView src={image} />
    </S.WrapperImage>
  );
};

export default RequestFailedImage;
