export const requiredHistoryDevices = [
  "queixa",
  "historiaMolestia",
  "antecedentesPessoais",
  "medicamentosEmUso",
  "alergias",
];

export const requiredPhysicalExam = ["estadoGeral"];

export const requiredResults = ["diagnostico", "tratamento"];
