import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  background-color: #fff;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 680px;
  height: 628px;
  padding: 24px;
`;

export const Img = styled.img`
  width: 350px;
`;

export const Title = styled.h2`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 133%;
  letter-spacing: -0.24px;
  margin: 24px 0 8px 0;
`;

export const Text = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 133%;
  color: #666666;
  text-align: center;
  max-width: 540px;
`;

export const BoxBtn = styled.div`
  margin: 24px 0 0 0;

  button {
    width: 257px;
    height: 48px;
  }
`;
