export const auth = {
  token: "",
  plugPlayAccessToken: "",
  doctor: null,
  isSecretary: false,
  userInfos: {
    nome: "",
    user: "",
    cdPessoaFisica: "",
    crm: "",
    signature: undefined,
  },
};
