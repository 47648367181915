export const anamneseInfos = {
  status_anamnse: "",
  historyDevices: {
    queixa: "",
    historiaMolestia: "",
    antecedentesPessoais: "",
    antecedentesFamiliares: "",
    habitos: "",
    medicamentosEmUso: "",
    alergias: "",
    musculoEsqueletico: "Sem queixa",
    geral: "Sem queixa",
    neurologico: "Sem queixa",
    cabecaPescoco: "Sem queixa",
    respiratorio: "Sem queixa",
    cardiovascular: "Sem queixa",
    gastrointestinal: "Sem queixa",
    genitoUrinario: "Sem queixa",
    peleAnexos: "Sem queixa",
  },
  physicalExam: {
    estadoGeral: "",
    vivacidade: "",
    sintomas: "",
    transpiracao: "",
    cabecaPescoco: "Sem queixa",
    respiratorio: "Sem queixa",
    cardiaco: "Sem queixa",
    vascular: "Sem queixa",
    abdome: "Sem queixa",
    genitoUrinario: "Sem queixa",
    neurologico: "Sem queixa",
    locomotor: "Sem queixa",
    peleAnexos: "Sem queixa",
  },
  ResultsDiagnosiSecondary: {
    idAnamneseResultado: null,
    resultadoExame: "",
    diagnostico: "",
    hipertensaoArterial: false,
    diabeteMelitus: false,
    doencaRenal: false,
    pneumopatia: false,
    cardiopatia: false,
    doencaOncohematologica: false,
    observacoes: "",
    tratamento: "",
  },
};

export const patient = {
  cpf: "",
  tipoDocumento: "CPF",
  novoPacienteDocumento: "CPF",
  sexo: "",
  idade: "",
  nome: "",
  passaporte: "",
  dataNascimento: "",
  telefone: {
    ddi: "55",
    ddd: "",
    phone: "",
  },
  telefoneExtra: "55",
  cep: "",
  rne: "",
  endereco: "",
  estado: "",
  cidade: "",
  numero: "",
  uf: "",
  complemento: "",
  bairro: "",
  email: "",
  nacionalidade: "",
  cdNacionalidade: "",
  nomeResponsavel: "",
  documentoResponsavel: "",
  plano: "",
  convenio: "",
  obrigaConvenio: "",
  categoria: "",
  cdConvenio: "",
  cdCategoria: "",
  cdCidPrincipal: "",
  cdPlano: "",
  ufCobranca: "",
  estadoCobranca: "",
  tempoDoenca: "",
  carteirinha: "",
  indicacaoClinica: "",
  necessidadesEspeciais: "Nada a declarar",
  cidPrincipal: "",
  peso: "Nada a declarar",
  prontuario: "",
  idPaciente: null,
  novoPaciente: false,
  codPessoaFisica: "",
  idAnamnese: "",
  anamnese: anamneseInfos,
};
