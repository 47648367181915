import { createSlice } from "@reduxjs/toolkit";
import { updateState, updateObjectState, updateListObject } from "../../utils";
import { procedures } from "./procedures";

const proceduresSlice = createSlice({
  name: "procedures",
  initialState: procedures,
  reducers: {
    setClearProcedures: () => {
      return procedures;
    },
    setProceduresProperty: updateState,
    setProcedureObject: updateObjectState,
    setProcedureListObject: updateListObject,
  },
});

export const proceduresReducer = proceduresSlice.reducer;

export const {
  setProceduresProperty,
  setProcedureObject,
  setClearProcedures,
  setProcedureListObject,
} = proceduresSlice.actions;
