import React, { useState } from "react";
import { pdf } from "@react-pdf/renderer";
import * as S from "./style";
import {
  termos_de_uso as TermosSVG,
  baixar as DownloadSVG,
  cancelar as IconCancelar,
} from "@tempo/tempo-assets/dist";
import {
  TmpDivider,
  TmpButton,
  TmpIcon,
  TmpButtonIcon,
} from "@tempo/tempo-design-system-core";
import Uploader from "../Uploader";
import NewModal from "../NewModal";
import { useSelector } from "react-redux";
import { ReportInfos } from "../../pages/Scheduling/Steps/style";
import InfoPatient from "../PatientInfos";
import MyDocument from "./Document";
import { handleUpdateReport } from "./controller";

import LocalAndDate from "../LocalAndDate";

const ConsentForm = ({
  handleClose,
  dataChange = null,
  reports,
  useReports = false,
  solicitationData = null,
}) => {
  const { nome } = useSelector((state) => state.auth.userInfos);
  const storeSolicitation = useSelector((state) => state.solicitation);

  const solicitation =
    window.location.pathname === "/" ? storeSolicitation : solicitationData;

  const reportsInfos = useReports
    ? reports
    : solicitation?.relatorio?.termoConsentimento !== null
    ? solicitation?.relatorio
    : reports;

  const [loading, setLoading] = useState(false);
  const { loadingTermo } = useSelector((state) => state.auth);

  const removeItem = (type) => {
    dataChange({
      target: {
        name: type,
        value: "",
      },
    });
  };

  const procedures = [
    {
      tipo: "Principal",
      codigoAMB:
        solicitation.procedimento?.cdProcedimentoPrincipal ||
        solicitation.procedimento?.procedimentoPrincipal?.cdProcedimento ||
        "-",
      codigoTUSS:
        solicitation.procedimento?.procedimentoPrincipal?.tuss != 0
          ? solicitation.procedimento?.procedimentoPrincipal?.tuss ||
            solicitation.procedimento?.tuss
          : "-",
      name:
        solicitation.procedimento?.procedimentoPrincipal?.nomeProcedimento ||
        solicitation.procedimento?.procedimentoPrincipal?.procedimento ||
        solicitation.procedimento?.procedimentoPrincipal ||
        "-",
      lateralidade: solicitation.procedimento?.lateralidade || "Não se aplica",
    },
    ...solicitation.procedimento?.procedimentosExtras?.map((procedimento) => ({
      tipo: "Adicional",
      codigoAMB: procedimento?.cdProcedimento || "-",
      codigoTUSS: procedimento?.tuss != 0 ? procedimento?.tuss : "-",
      name: procedimento?.nomeProcedimento || procedimento?.procedimento || "-",
      lateralidade: procedimento?.lateralidade || "-",
    })),
  ];

  const handlePdfDownload = async () => {
    const newPdf = <MyDocument data={solicitation} />;
    const blob = await pdf(newPdf).toBlob();
    const link = document.createElement("a");

    link.download = "Termo de Consentimento";
    link.href = URL.createObjectURL(blob);
    link.click();
    link.remove();
  };

  const handleCloseClick = async (value) => {
    if (dataChange) {
      dataChange({
        target: {
          name: "idRelatorio",
          value,
        },
      });
    }
    handleClose();
  };

  return (
    <>
      <NewModal width="auto" isOpened={true} removeBtn>
        <S.ContainerModal>
          <S.BoxTitle>
            <TmpIcon icon={<TermosSVG />} />
            <S.H1>Termo de Consentimento</S.H1>
            <TmpButtonIcon
              icon={<IconCancelar />}
              size="lg"
              handleClick={handleClose}
            />
          </S.BoxTitle>
          <S.ModalContent>
            <S.ObservationBox>
              Imprima este documento e colete a assinatura do seu paciente ou
              responsável.
              <br />
              Faça o upload do documento assinado no fim desta página ou na
              seção de <b>Relatórios e Laudos</b>.
            </S.ObservationBox>
            <InfoPatient marginBottomDivider="32px" />
            <section>
              <S.Divider margin="56px 0 16px">
                <TmpDivider />
              </S.Divider>
              <S.Text>
                O presente Termo de Consentimento Esclarecido tem o objetivo de
                cumprir o dever ético de informar ao paciente e/ou responsável
                os principais aspectos relacionados com o(s) Tratamento(s),
                Assistência Clínica, Medicamento(s) ou Procedimento(s)
                (exames,cirurgias) ao(s) qual(is) será submetido, complementando
                as informações prestadas pelo seu médico e pela equipe de
                funcionários e prestadores de serviços do Hospital
                Sírio-Libanês.
                <br />
                <br />
                Autorizo o Dr(a). <b>{nome}</b> pertencente ao corpo clínico do
                Hospital Sírio-Libanês a realização do(s) seguinte(s)
                Tratamento(s), Assistência Clínica ou Procedimento(s) *:
              </S.Text>
              <S.Table cellspacing="0">
                <tr className="table-line ">
                  <th width="238px">Tipo</th>
                  <th width="184px">Código AMB</th>
                  <th width="184px">Código TUSS</th>
                </tr>

                {procedures.map((procedure, i) => (
                  <tr
                    key={i}
                    style={{ background: "rgba(245, 245, 245, 0.32)" }}
                    className="table-line"
                  >
                    <td width="238px">{procedure.tipo}</td>
                    <td width="184px">{procedure.codigoAMB}</td>
                    <td width="184px">{procedure.codigoTUSS}</td>
                  </tr>
                ))}
              </S.Table>
              <S.Table margin={0}>
                <tr className="table-line">
                  <th width="446px">Procedimento</th>
                  <th width="210px">Lateralidade</th>
                </tr>
                {procedures.map((procedure, i) => (
                  <tr
                    key={i}
                    style={{ background: "rgba(245, 245, 245, 0.32)" }}
                    className="table-line"
                  >
                    <td width="446px">{procedure.name}</td>
                    <td width="210px">{procedure.lateralidade}</td>
                  </tr>
                ))}
              </S.Table>
              <S.Text>
                A proposta do(s) Tratamento(s) Assitência Clínica ou
                Procedimento(s) a que serei submetido(a), seus benefícios,
                riscos, complicações potenciais e alternativas me foram
                explicados claramente pelo médico. Tive a oportunidade de fazer
                perguntas, que foram respondidas satisfatoriamente. Entendo que
                não existe garantia absoluta sobre os resultados a serem
                obtidos, mas que serão utilizados todos os recursos,
                medicamentos e equipamentos disponíveis no Hospital para ser
                alcançado/obtido o melhor resultado. Também estou ciente de que
                podem ocorrer complicações durante o(s) Tratamento(s),
                Assistência Clínica ou Procedimento, assim como pode ser
                necessária a modificação da proposta inicial em virtude de
                situações imprevistas, bem como aumento no tempo de internação.
                Infecções relacionadas não podem ser descartadas, mesmo com a
                adoção de todas as medidas preventivas preconizada para a
                assistência à saúde. Confirmo que me foi esclarecido os pontos
                necessários e me foi ofertada a possibilidade de questionamento
                para sanar toda e qualquer dúvida que eu poderia ter diante do
                que me foi exposto.
                <br />
                <br />
                <b>Apenas para procedimentos cirúrgicos:</b> Declaro que fui
                devidamente informado que, nas cirurgias em que for possível e
                cuja função de gravação esteja disponível em equipamento, poderá
                haver gravação do procedimento pelo médico cirurgião. As imagens
                gravadas pelo médico ficarão armazenadas no sistema do Hospital
                pelo prazo de 30 dias. Após esse prazo as imagens serão apagadas
                do sistema do Hospital, não sendo possível a sua recuperação.
                <br />
                <br />
                Declaro que fui devidamente esclarecido pelo médico responsável
                que tenho indicação de realizar o procedimento cirúrgico acima
                citado neste momento, pois minha doença pode ser agravada caso
                seja postergado (doença tempo sensível). Estou ciente que mesmo
                com todos os cuidados de segurança instituídos por esta unidade,
                um resultado negativo do teste PCR não exclui que eu possa estar
                em um período de incubação do COVID-19 (que pode ser de até 14
                dias), e compreendo que poderei manifestar sintomas posteriores
                ao meu período de permanência por uma contaminação anterior ou
                posterior ao mesmo.
                <br />
                <br />
                Expliquei todo(s) o(s) Tratamento(s), Assistência Clínica ou
                Procedimento(s) ao paciente acima identificado e/ou seu
                responsável, sobre os benefícios, riscos e alternativas, tendo
                respondido às perguntas formuladas pelos mesmos. De acordo com o
                meu entendimento, o paciente e/ou seu responsável está em
                condições de compreender o que lhes foi informado.
                <br />
                <br />
              </S.Text>
            </section>
            <S.Divider margin="48px 0">
              <TmpDivider />
            </S.Divider>
            <ReportInfos noCentrelize marginBottom="24px">
              <Uploader
                label={
                  <>
                    Termo de Consentimento{" "}
                    <span style={{ fontWeight: "400", fontSize: "14px" }}>{`${
                      reportsInfos?.termoConsentimento ? "(assinado)" : ""
                    }`}</span>
                  </>
                }
                prop="termoConsentimento"
                propUUID="termoConsentimentoUUID"
                value={reportsInfos?.termoConsentimento}
                dataChange={dataChange}
                removeFile={() => {
                  removeItem("termoConsentimento");
                  removeItem("termoConsentimentoUUID");
                }}
                downloadId={reportsInfos?.termoConsentimentoUUID}
              />
            </ReportInfos>
          </S.ModalContent>

          <S.FooterWrapper>
            <LocalAndDate
              local={solicitation.procedimento.local}
              margin={"0 0 0 24px"}
              marginMbl={"0"}
              display={true}
            />
            <S.ButtonWrapper width={loadingTermo}>
              <TmpButton
                size={"lg"}
                handleClick={() =>
                  window.location.pathname === "/"
                    ? handleUpdateReport(
                        solicitation,
                        setLoading,
                        handleCloseClick,
                        () => alert("Erro ao Salvar Anexo")
                      )
                    : handleClose()
                }
                loading={loading}
                type="secondary"
              >
                {window.location.pathname === "/"
                  ? "Salvar e fechar"
                  : "Fechar"}
              </TmpButton>
              <TmpButton
                size={"lg"}
                handleClick={handlePdfDownload}
                startIcon={<DownloadSVG />}
              >
                Baixar Termo (sem assinatura)
              </TmpButton>
            </S.ButtonWrapper>
          </S.FooterWrapper>
        </S.ContainerModal>
      </NewModal>
    </>
  );
};

export default ConsentForm;
