import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const Button = styled.button`
  background: none;
  border: none;
  display: inline-flex;
  cursor: pointer;
  padding: 8px;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.48, 0, 0.48, 1);

  p {
    font-weight: 400;
  }

  &:hover {
    color: #2c6ef2;
    background-color: #fff;
    border-radius: 8px;
  }

  &:focus-visible {
    outline: 2px solid #e0e0e0;
    border-radius: 8px;
  }

  ${css`
    > .tmp-icon {
      padding-left: 24px};
      display: inline-flex;

      .chevron__container {
        transform: translateY(13px);
        transition-property: transform;
        transition-duration: 0.275s;
        transition-timing-function: cubic-bezier(0.48, 0, 0.48, 1);
      }
      .chevron__line1,
      .chevron__line2 {
        transition-duration: 0.275s;
        transition-timing-function: cubic-bezier(0.48, 0, 0.48, 1);
        stroke-width: 10;
        stroke-linecap: round;
        transform-origin: 50px 50px;
      }
      .chevron__line1 {
        transform: rotate(40deg);
      }
      .chevron__line2 {
        transform: rotate(-40deg);
      }
    }
  `}

  ${({ active }) =>
    active &&
    css`
      color: #2c6ef2;
      > .tmp-icon {
        .chevron__container {
          transform: translateY(-13px);
        }
        .chevron__line1,
        .chevron__line2 {
        }
        .chevron__line1 {
          transform: rotate(-40deg);
        }
        .chevron__line2 {
          transform: rotate(40deg);
        }
      }
    `}

  ${({ borde }) =>
    borde &&
    css`
      padding: 10px 16px;
      border: 1px solid #e0e0e0;
      border-radius: 8px;
    `}
`;

export const Counter = styled.span`
  height: 20px;
  width: 34px;

  ${({ theme }) => css`
    border: 2px solid #2c6ef2;
    border-radius: 8px;
    /* font-family: ${theme.font.family}; */
    font-size: 14px;
    color: #2c6ef2;
    margin-left: 8px;
  `}
`;

export const DropDown = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  display: none;
  opacity: 0;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.48, 0, 0.48, 1);
  z-index: 14;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 8px 24px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;

  ${({ actived }) =>
    actived &&
    css`
      display: block;
      opacity: 1;
    `}

  .filter-search {
    margin: 24px 20px 20px 20px;
  }

  ul {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-y: auto;

    ${({ hasTitle }) =>
      hasTitle &&
      css`
        > .tmp-list-item:first-child > div {
          width: 100%;
        }
        span {
          display: flex;
          align-items: center;
        }
        .tmp-button-icon {
          margin-left: auto;
        }
        h3 {
          color: red;
        }
      `}

    .tmp-list-item {
      border-color: transparent;

      &:focus-visible {
        outline: 2px solid #2c6ef2;
        outline-offset: 2px;
      }

      label {
        z-index: 1;
      }
      > div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        > span {
          width: 100%;
          height: 100%;
          > label {
            width: 100%;
            height: 100%;
            cursor: pointer;
            > input {
              top: initial;
            }
            > span {
              line-height: normal;
            }
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    position: absolute;
    top: initial;
    left: initial;
    height: auto;
    min-width: 100%;
    width: max-content;
    margin-top: 8px;

    .tmp-typography {
      display: none;
    }
    ul {
      padding: ${({ searchLabel }) =>
        searchLabel ? "0 16px 16px 16px" : "16px"};

      > .tmp-list-item:first-child {
        display: none;
      }
    }
  }

  @media (max-width: 768px) {
    height: 100%;
    width: 100%;
    padding-top: 16px;

    ul {
      padding-top: 24px;
    }
  }
`;

export const ListTitle = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:focus-visible {
    outline: transparent;
  }

  .tmp-icon {
    padding-right: 4px;
  }
`;

export const WapperItem = styled.li`
  list-style: none;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: ${(props) => (props.disabled ? "50%" : "100%")};
      pointer-events: ${(props) => (props.disabled ? "none" : "")};
      cursor: ${(props) => (props.disabled ? "not-allowed" : "")};
    `}
`;
