import React from "react";
import { TmpAlert } from "@tempo/tempo-design-system-centro-cirurgico";
import * as S from "./style";

const Alert = ({
  bgColor,
  isOpened,
  title,
  type = "error",
  message,
  linkText,
  linkAction,
  handleClose,
}) => {
  return (
    <S.StyledAlert
      id="styled-alert"
      backgroundColor={bgColor}
      isOpened={isOpened}
    >
      <TmpAlert
        id="form-alert"
        isOpened={isOpened}
        type={type}
        title={title}
        message={message}
        linkText={linkText}
        linkAction={linkAction}
        handleClose={handleClose}
      />
    </S.StyledAlert>
  );
};

export default Alert;
