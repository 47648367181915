import styled from "styled-components";

export const StyledModal = styled.div`
  > div > div {
    width: ${(props) => (props.width ? props.width : "624px")};
    height: ${(props) => (props.heigth ? props.heigth : "546px")};
  }
  > div > div > div {
    /* modal-content */
    overflow-y: hidden;
    overflow-x: hidden;
    width: ${(props) => props.width};
    @media (max-width: 760px) {
      width: 100%;
    }
  }
  > div > div {
    /* modal-md */
    width: ${(props) => (props.width ? props.width : "")};
    height: ${(props) => (props.width ? props.heigth : "")};
    box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.24);
    min-height: ${(props) => props.minHeight};
    height: min-content;
    max-width: 1026px;
    justify-content: ${(props) =>
      props.justifyContent ? props.justifyContent : "center"};
    align-items: center;
    max-height: 600px;
    padding: 0;

    @media (max-width: 760px) {
      height: 100%;
    }

    > div > button {
      display: none;
      overflow: revert-layer;
      width: 100%;
    }

    > div {
      background: transparent;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const ModalInfos = styled.div`
  visibility: visible;
  display: flex;
  flex-direction: column;
`;
