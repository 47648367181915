import React from "react";
import { TmpDrawerContent } from "@tempo/tempo-design-system-core/dist";
import { getAbbreviatedFullName, transformDateEndianness } from "../../utils";
import moment from "moment-timezone";

const DrawerUnification = ({
  open,
  obj,
  handleButtonClickPrimary,
  confirmed,
  tipoNotificacao,
}) => {
  const returnInformations = (type) => {
    if (
      confirmed === "Confirmado" &&
      !type &&
      tipoNotificacao !== "Cancelamento"
    ) {
      return (
        <div style={{ width: "65vw" }}>
          <p>
            O(a) médico(a) <b>{obj.nome_medico}</b> confirmou sua participação{" "}
            <br />
            como responsável por equipe <b>adicional</b> para a cirurgia:
            <br />
            <br />
            <b>Nome do Paciente:</b>{" "}
            {getAbbreviatedFullName(obj.nome_paciente, 40)}{" "}
            {transformDateEndianness(
              moment(obj.data_nascimento)
                .tz("Europe/London")
                .format("YYYY-MM-DD")
            )}
            <br />
            <b>Data:</b>{" "}
            {transformDateEndianness(
              moment(obj.data_cirurgia).tz("Europe/London").format("YYYY-MM-DD")
            )}{" "}
            | <b>Hora:</b> {obj.hora_cirurgia}
            <br />
            <b>Procedimento Principal:</b> {obj.procedimento_principal}
          </p>
        </div>
      );
    } else if (
      confirmed === "Recusado" &&
      !type &&
      tipoNotificacao !== "Cancelamento"
    ) {
      return (
        <div style={{ width: "65vw" }}>
          <p>
            O(a) médico(a) <b>{obj.nome_medico}</b> não confirmou sua
            participação como <br />
            responsável por equipe <b>adicional</b> para a cirurgia:
            <br />
            <br />
            <b>Nome do Paciente:</b>{" "}
            {getAbbreviatedFullName(obj.nome_paciente, 40)}{" "}
            {transformDateEndianness(
              moment(obj.data_nascimento)
                .tz("Europe/London")
                .format("YYYY-MM-DD")
            )}
            <br />
            <b>Data:</b>{" "}
            {transformDateEndianness(
              moment(obj.data_cirurgia).tz("Europe/London").format("YYYY-MM-DD")
            )}{" "}
            | <b>Hora:</b> {obj.hora_cirurgia}
            <br />
            <b>Procedimento Principal:</b> {obj.procedimento_principal}
            <br />
            <br />
            Entre na solicitação para indicar outra equipe.
          </p>
        </div>
      );
    } else if (tipoNotificacao === "Cancelamento" && !type) {
      return (
        <div style={{ width: "65vw" }}>
          <p>
            Sua solicitação foi cancelada como equipe adicional na cirurgia:
            <br />
            <br />
            <b>Nome do Paciente:</b>{" "}
            {getAbbreviatedFullName(obj.nome_paciente, 40)}{" "}
            {transformDateEndianness(
              moment(obj.data_nascimento)
                .tz("Europe/London")
                .format("YYYY-MM-DD")
            )}
            <br />
            <b>Data:</b>{" "}
            {transformDateEndianness(
              moment(obj.data_cirurgia).tz("Europe/London").format("YYYY-MM-DD")
            )}{" "}
            | <b>Hora:</b> {obj.hora_cirurgia}
            <br />
            <b>Procedimento Principal:</b> {obj.procedimento_principal}
            <br />
            <br />
            Em caso de erro, verifique com o(a) médico(a) responsável ou entre
            em contato com{" "}
            <b>
              (11) <br /> 3394-0381 /agendamento.cirurgias@hsl.org.br
            </b>{" "}
            ou{" "}
            <b>
              (61) 99997-1452 /<br /> agendamento.cirurgiasbsb@hsl.org.br
            </b>
          </p>
        </div>
      );
    }

    if (
      confirmed === "Confirmado" &&
      type === "title" &&
      tipoNotificacao !== "Cancelamento"
    ) {
      return <p>Confirmação de equipe adicional</p>;
    } else if (
      confirmed === "Recusado" &&
      type === "title" &&
      tipoNotificacao !== "Cancelamento"
    ) {
      return <p>Indicação declinada</p>;
    } else if (tipoNotificacao === "Cancelamento" && type === "title") {
      return <p>Solicitação cancelada</p>;
    }
  };
  return (
    <TmpDrawerContent
      title={returnInformations("title")}
      label={returnInformations()}
      buttonSize={"lg"}
      isOpened={open}
      isDoubleAction={false}
      buttonLabelPrimary={"Ok, entendi"}
      handleButtonClickPrimary={handleButtonClickPrimary}
    />
  );
};

export default DrawerUnification;
