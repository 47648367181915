import React from "react";
import { TmpTextAreaCC } from "@tempo/tempo-design-system-centro-cirurgico";
import { MessageError } from "../../pages/Scheduling/Steps/style";
import { updateProp } from "../../utils";
import { setPatientProperty } from "../../features/patient/patientSlice";
import { useSelector, useDispatch } from "react-redux";
import { erro as ErrorIcon } from "@tempo/tempo-assets/dist";
import * as S from "./style";

const TextArea = ({
  label = null,
  type = "",
  placeholder = null,
  maxLength = null,
  error = null,
  handleChange,
  prop,
  section,
  value,
  message,
  helperIcon,
  helperText,
}) => {
  const patient = useSelector((state) => state.patient);
  const dispatch = useDispatch();

  const getHandleFunction = (e) => {
    if (type === "anamnese" && !handleChange) {
      handleChangeAnamnese(e);
    } else
      handleChange({
        target: {
          name: prop,
          value: e.target.value,
        },
      });
  };

  const handleChangeAnamnese = (e) => {
    var newAnamnese = { ...patient.anamnese };
    newAnamnese[section] = {
      ...newAnamnese[section],
      [prop.value ? prop.value : prop]: e.target.value,
    };
    dispatch(
      setPatientProperty(updateProp({ id: "anamnese", value: newAnamnese }))
    );
  };

  return (
    <>
      {maxLength === null ? (
        <S.BoxTextareaNotCount>
          <TmpTextAreaCC
            resize="none"
            label={label}
            placeholder={placeholder}
            value={value}
            name={prop}
            id={prop}
            message={message}
            helperIcon={helperIcon}
            helperText={helperText}
            error={
              error ? (
                <MessageError>
                  <ErrorIcon />
                  Campo não preenchido
                </MessageError>
              ) : null
            }
            handleChange={(e) => {
              getHandleFunction(e);
            }}
          />
        </S.BoxTextareaNotCount>
      ) : (
        <S.BoxTextarea>
          <TmpTextAreaCC
            label={label}
            resize="none"
            placeholder={placeholder}
            value={value}
            name={prop}
            id={prop}
            message={message}
            maxLength={maxLength}
            helperIcon={helperIcon}
            helperText={helperText}
            error={
              error ? (
                <MessageError>
                  <ErrorIcon />
                  Campo não preenchido
                </MessageError>
              ) : null
            }
            handleChange={(e) => {
              getHandleFunction(e);
            }}
          />
        </S.BoxTextarea>
      )}
    </>
  );
};

export default TextArea;
