import styled, { css } from "styled-components";

export const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 58px - 89px);
`;

export const IconContainer = styled.div`
  position: relative;
  margin-left: 12px;
  .tooltip {
    position: absolute;
    /* left: -100px; */
    visibility: hidden;
    min-width: max-content;
    padding: 16px;
    margin-top: 5px;
    background-color: rgba(0, 0, 0, 1);
    color: #fff;
    font-size: 0.87rem;
    border-radius: 4px;

    opacity: 0.72;
  }

  :hover {
    .tooltip {
      visibility: visible;
    }
  }
`;

export const IconTooltip = styled.div``;

export const ContainerFilter = styled.section`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin: 30px 0 40px;

  @media (max-width: 1365px) {
  }
`;
export const BoxSearch = styled.div`
  width: 100%;
  display: flex;
  @media (max-width: 1365px) {
    flex-wrap: wrap;
  }
`;
export const WapperSearch = styled.div`
  width: 536px;

  @media (max-width: 1365px) {
    width: 100%;
  }
  > div {
    @media (max-width: 1365px) {
      width: 100%;
    }
  }
  input {
    padding: 16px 0;
  }
`;

export const WapperFilter = styled.section`
  width: 165px;
  margin-left: 32px;

  @media (max-width: 1365px) {
    margin: 0px 0 0;
  }
  .button-filter {
    height: 48px;
  }

  .tmp-button-group {
    button {
      width: 148px;
      height: 48px;
    }
  }

  div {
    > div {
      height: 60vh;
      z-index: 5;
      @media (max-width: 767px) {
        height: 100vh;
        z-index: 10;

        .tmp-icon {
          margin: 40px 0 0 0;
        }
      }
      span {
        height: 5vh;
      }
      div {
        height: auto;
      }
    }

    .label > span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const BoxTipes = styled.table`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  border-top: 2px solid rgba(224, 224, 224, 1);
  border-bottom: 2px solid rgba(224, 224, 224, 1);
  width: 100%;

  @media (max-width: 1365px) {
    width: calc(100% - 48px);
  }
`;

export const Tipes = styled.th`
  width: ${({ width }) => width};
  ${({ active }) =>
    active &&
    css`
      background-color: #f5f5f5;
    `}

  padding: 20px 0 20px 24px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  cursor: pointer;
  text-align: left;

  word-wrap: break-word;
  overflow-wrap: break-word;

  @media (max-width: 1365px) {
    display: ${({ displayRes }) => displayRes};
    width: ${({ widthRes, box }) =>
      box === "status" ? `calc(${widthRes} - 48px)` : widthRes};
  }
`;

export const Seta = styled.div`
  position: absolute;
  right: 2vw;
`;

export const NewAdd = styled.div`
  width: calc(100% - 536px);
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1365px) {
    margin: 24px 0 0;
    width: 100%;
  }
`;

export const WrapperBtn = styled.div`
  ${({ renderSize }) =>
    renderSize === "all" &&
    css`
      @media (min-width: 0px) {
        display: block;
      }
    `}
  ${({ renderSize }) =>
    renderSize === "MD" &&
    css`
      @media (max-width: 1365px) {
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }
    `}

  ${({ renderSize }) =>
    renderSize === "LG" &&
    css`
      @media (min-width: 1366px) {
        display: block;
      }
    `}

    button {
    height: 48px;
  }
  display: none;
`;
export const BoxCondition = styled.section`
  width: 100%;
  height: 49vh;
  overflow-y: scroll;

  /* @media (max-width: 1365px) {
    width: calc(100% - 12px);
  } */
  /* margin: 0 auto; */

  /* estilo padrão da barra de rolagem */
  ::-webkit-scrollbar {
    width: 12px;
    display: none;
  }

  /* estilo da thumb da barra de rolagem */
  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }

  /* estilo do fundo da track da barra de rolagem */
  ::-webkit-scrollbar-track {
    position: absolute;
    background-color: #f1f1f1;
    border-radius: 6px;
  }
  @media (max-width: 1365px) {
    padding-left: 0;
  }
`;
export const BoxMap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  cursor: ${(props) => (props.isDisabled ? "default" : "pointer")};
  ${(props) =>
    props.isDisabled
      ? css`
          color: var(--color-neutral-4);
          .ballStatus {
            background-color: var(--color-neutral-4);
          }
          #tmp-button-icon{
            color: var(--color-neutral-4);
          }
        `
      : null}

  &:hover {
    background-color: ${(props) => (props.isDisabled ? "auto" : "#F5F6FD")};
  }

  @media (max-width: 1365px) {
    width: calc(100% - 48px);
  }
`;

export const BoxInfoMap = styled.td`
  width: ${({ width }) => width};

  position: relative;
  padding: 20px 24px;

  display: flex;

  word-wrap: break-word;
  overflow-wrap: break-word;

  @media (max-width: 1365px) {
    width: ${({ widthRes }) => widthRes};
    max-width: none;
    display: ${({ displayRes }) => displayRes};
    flex-direction: ${({ flexDirectionRes }) => flexDirectionRes};
  }

  @media (max-width: 768px) {
    width: ${({ widthCel }) => widthCel};
    max-width: none;
    display: ${({ displayRes }) => displayRes};
  }

  .name-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .anamnese,
  .consent {
    color: #2c6ef2;
    cursor: pointer;
    z-index: 0;
    position: absolute;
    position: relative;
    margin-left: 5px;
    animation: 1s;
  }

  .anamnese {
    color: ${({ disabled }) => (disabled ? "#A3A3A3" : "#2c6ef2")};
  }

  .anamnese:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #2c6ef2;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }
  .anamnese:hover:before {
    visibility: ${({ disabled }) => (disabled ? "hidden" : "visible")};
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  .consent:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #2c6ef2;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }
  .consent:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  .tmp-icon-prescricao > path {
    fill: ${(props) => (props.disabled ? "rgba(163, 163, 163, 1)" : "#292929")};
    stroke: ${(props) =>
      props.disabled ? "rgba(163, 163, 163, 1)" : "#292929"};
  }
`;

export const StyledTextMobile = styled.div`
  span {
    font-size: 14px;
    font-weight: 200;
    display: none;
  }
  @media (max-width: 1365px) {
    span {
      font-size: 14px;
      font-weight: 200;
      display: block;
    }
  }
`;

export const MenuOverFlow = styled.div`
  display: none;

  .tmp-list-item:nth-child(4) {
    opacity: ${(props) => (props.disabledListItems ? "50%" : "100%")};
    pointer-events: ${(props) => (props.disabledListItems ? "none" : "")};
    cursor: ${(props) => (props.disabledListItems ? "not-allowed" : "")};
  }
  .tmp-list-item:nth-child(2) {
    opacity: ${(props) => (props.disableAnamnese ? "50%" : "100%")};
    pointer-events: ${(props) => (props.disableAnamnese ? "none" : "")};
    cursor: ${(props) => (props.disableAnamnese ? "not-allowed" : "")};
  }

  .tmp-list-item:nth-child(3) {
    opacity: ${(props) => (props.disableAnamnese ? "50%" : "100%")};
    pointer-events: ${(props) => (props.disableAnamnese ? "none" : "")};
    cursor: ${(props) => (props.disableAnamnese ? "not-allowed" : "")};
  }

  @media (max-width: 1365px) {
    display: flex;
    align-items: center;
  }

  @media (max-width: 1365px) {
    ul {
      right: 1px;
      z-index: 10;
    }
  }

  @media (max-width: 766px) {
    ul {
      top: 0px;

      z-index: 10;
    }
  }
`;

export const Div = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.08);

  @media (max-width: 1365px) {
    display: flex;
    flex-direction: row;
  }

  background-color: ${(props) =>
    props.background ? "rgba(0, 0, 0, 0.08)" : ""};

  &&:nth-child(even) {
    background: ${(props) => (props.background ? "" : "#fcfcfc")};
  }
`;

export const StyledStatus = styled.td`
  width: 257px;
  max-width: 257px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: flex-start;

  @media (max-width: 1365px) {
    width: ${({ widthRes }) => `calc(${widthRes} - 48px)`};
  }
`;

export const StyledDate = styled.td`
  @media (min-width: 1366px) {
    display: none;
  }
  @media (max-width: 1365px) {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    p:first-child {
      margin-right: 20px;
    }

    p {
      color: #666666;
      margin-top: 5px;
      font-weight: 200;
      font-size: 14px;
    }
  }
`;

export const TextModal = styled.p`
  color: #666666;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 24px 0 32px 0;
`;
export const BoxTitle = styled.div`
  height: 96px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  margin-top: 79px;
  @media (max-width: 1365px) {
    max-width: 564px;
  }
`;
export const ContainerModal = styled.div`
  height: 350px;
  display: flex;
  flex-direction: column;

  .tmp-button-icon {
    margin: 24px 24px 0 0;
  }
`;

export const BoxButtons = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 96px;
  justify-content: flex-end;
  background-color: #f5f5f5;

  button {
    height: 48px;
  }

  button:nth-child(1) {
    min-width: 0;
    padding: 0;
    margin-right: 16px;
    width: 211px;
  }
  button:nth-child(2) {
    min-width: 0;
    padding: 0;
    width: ${(props) => (props.width ? "48px" : "192px")};
    margin-right: 24px;
  }
`;

export const StyledDonwload = styled.div`
  width: ${({ width }) => width};
  padding: 20px 24px;
  position: relative;

  ul {
    min-width: 280px;
  }
  .tmp-icon {
    rotate: 90deg;
  }

  .tmp-list-item:nth-child(2) {
    opacity: ${(props) => (props.disabledListItems ? "50%" : "100%")};
    pointer-events: ${(props) => (props.disabledListItems ? "none" : "")};
    cursor: ${(props) => (props.disabledListItems ? "not-allowed" : "")};
  }

  button {
    width: 40px;
    height: 40px;
    z-index: 1;
    margin-left: 17%;
    pointer-events: ${(props) => (props.disabled ? "none" : "")};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }
  svg {
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin: 0 auto;
  }
  svg:first-child {
    pointer-events: none;
  }

  @media (max-width: 1365px) {
    display: none;
  }
`;

export const Title = styled.div`
  width: 186px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  background: #f5f5f5;
  //opacity: 0;

  position: absolute;
  right: 0vw;
  bottom: 7vh;
  z-index: 5;

  &:hover {
    opacity: 100;
  }
`;

export const Status = styled.span`
  width: 8px;
  height: 8px;
  background-color: ${({ bg }) => bg};
  position: absolute;
  top: 17px;
  left: 37px;
  border-radius: 50%;
  border: 2px solid #fff;
`;

export const StyledLoadingTermo = styled.div`
  display: flex;
  width: 100%;
  height: ${(props) => (props.height ? props.height : "100%")};
  align-items: center;
  justify-content: center;
  top: ${(props) => (props.top ? props.top : "40vh")};
  background-color: #fff;
  z-index: 1;
  opacity: 80%;
`;

export const StyledLoading = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #f5f5f5;
  z-index: 10;
  opacity: 80%;
`;

export const StyledLoadingTable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 49vh;
  width: 100%;
`;

export const BoxDateHour = styled.th`
  display: flex;
  width: ${({ width }) => width};
  padding: 20px 0px 20px 24px;
  display: flex;
  align-items: center;
  flex-direction: ${({ flexDirection }) => flexDirection};
  align-items: ${({ alignItems }) => alignItems};
  cursor: pointer;

  @media (max-width: 1365px) {
    display: none;
  }

  ${({ active }) =>
    active
      ? css`
          background-color: #f5f5f5;
        `
      : null}

  span:first-child {
    font-size: 16px;
    font-weight: 600;
  }
`;

export const BoxDateHourData = styled.td`
  display: flex;
  width: ${({ width }) => width};
  padding: 20px 24px 20px 40px;
  display: flex;
  align-items: center;
  flex-direction: ${({ flexDirection }) => flexDirection};
  align-items: ${({ alignItems }) => alignItems};
  cursor: ${(props) => (props.disabled ? "normal" : "pointer")};

  @media (max-width: 1365px) {
    display: none;
  }

  ${({ active }) =>
    active
      ? css`
          background-color: #f5f5f5;
        `
      : null}

  span:first-child {
    font-size: 16px;
    font-weight: 600;
  }
`;

export const Documents = styled.div`
  width: 60%;
  display: flex;
  justify-content: space-between;
`;

export const BoxInfo = styled.div`
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
`;
export const Pname = styled.p`
  height: 100%;
  hyphens: auto;
  display: flex;
  align-items: center;

  @media (max-width: 1365px) {
    font-weight: 600;
  }
`;

export const NotExist = styled.h3`
  font-size: 20px;
  font-weight: 500;
  color: #696969;
`;

export const StyledAlert = styled.div`
  div div > p {
    width: ${({ widthP }) => widthP};
    min-width: 10px;
  }
  > div {
    top: 0;
    transform: translate(-50%, -110%);
    width: 312px;
    min-width: 312px;
    z-index: 100;
    margin: 0 auto;

    ${(props) =>
      props.isOpened &&
      css`
        top: 0;
        transform: translate(-50%, 90%);
      `}

    h3 {
      font-size: 16px;
      max-width: ${(props) => props.widthTitle};
    }
  }
`;

export const Table = styled.div`
  width: 100%;

  box-sizing: border-box;

  @media (max-width: 560px) {
    padding: 0;
  }
`;

export const PatientInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f5f6fd;
  width: 540px;
  height: 104px;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 8px;
  gap: 9px;
`;

export const ContainerAlert = styled.div`
  #tmp-button-icon {
    width: 32px;
  }
  .tmp-typography {
    width: ${(props) => (props.declined ? "200px" : "240px")};
  }
`;

export const ContainerQuantityPerPage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 16px;
`;

export const PageButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin: 0 5px;
  padding: 5px 10px;
  border: none;
  background-color: ${(props) =>
    props.active ? "deepskyblue" : "transparent"};
  color: ${(props) => (props.active ? "#fff" : "#000")};
  cursor: pointer;
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  border-radius: 50%;
  outline: none;
  border: none;

  &:hover {
    background-color: ${(props) => (props.active ? "#007bff" : "#f0f0f0")};
  }
`;

export const ShowLimit = styled.p`
  font-weight: 500;

  span {
    font-weight: 600;
  }
`;

export const StyledNew = styled.p`
  color: var(--color-brand-056);
  font-feature-settings: "liga" off, "clig" off;
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  margin-left: 12px;
`;
