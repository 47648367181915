import { createSlice } from "@reduxjs/toolkit";
import { updateObjectState, updateState } from "../../utils";
import { model } from "./model";

const modelSlice = createSlice({
  name: "Model",
  initialState: model,
  reducers: {
    setClearModel: () => {
      return model;
    },
    setModelProperty: updateState,
    setModelObject: updateObjectState,
  },
  setClearSelectedModel: (state) => {
    return {
      ...model,
      listModels: state.listModels,
    };
  },
});

export const {
  setClearModel,
  setModelProperty,
  setModelObject,
  setClearSelectedModel,
} = modelSlice.actions;

export const modelReducer = modelSlice.reducer;
