import * as D from "@tempo/tempo-design-system-core/dist";
import * as S from "./style";

const RequestFailedContent = ({ title, content }) => {
  return (
    <S.TypographyContainer>
      <D.TmpTypography component="heading" size="large">
        {title}
      </D.TmpTypography>
      <D.TmpTypography component="caption">{content}</D.TmpTypography>
    </S.TypographyContainer>
  );
};

export default RequestFailedContent;
