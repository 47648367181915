import moment from "moment-timezone";

export function getFileExtension(filename) {
  const lastDotIndex = filename.lastIndexOf(".");

  if (lastDotIndex === -1) return ""; // Caso não haja ponto no nome do arquivo

  return filename.substring(lastDotIndex + 1);
}

export function normalizeString(str) {
  // Mapeamento de caracteres acentuados para equivalentes sem acento
  const accentMap = {
    á: "a",
    à: "a",
    â: "a",
    ã: "a",
    ä: "a",
    é: "e",
    è: "e",
    ê: "e",
    ë: "e",
    í: "i",
    ì: "i",
    î: "i",
    ï: "i",
    ó: "o",
    ò: "o",
    ô: "o",
    õ: "o",
    ö: "o",
    ú: "u",
    ù: "u",
    û: "u",
    ü: "u",
    ç: "c",
    Á: "A",
    À: "A",
    Â: "A",
    Ã: "A",
    Ä: "A",
    É: "E",
    È: "E",
    Ê: "E",
    Ë: "E",
    Í: "I",
    Ì: "I",
    Î: "I",
    Ï: "I",
    Ó: "O",
    Ò: "O",
    Ô: "O",
    Õ: "O",
    Ö: "O",
    Ú: "U",
    Ù: "U",
    Û: "U",
    Ü: "U",
    Ç: "C",
  };

  let extension = getFileExtension(str);
  let nameWithoutExtension = str.substring(0, str.lastIndexOf("."));

  // Função para substituir caracteres acentuados
  function removeAccents(char) {
    return accentMap[char] || char;
  }

  // Remover acentos e caracteres especiais no nome do arquivo
  nameWithoutExtension = nameWithoutExtension
    .split("")
    .map(removeAccents)
    .join("");
  nameWithoutExtension = nameWithoutExtension.replace(/[^a-zA-Z0-9]/g, "");

  return `${nameWithoutExtension}.${extension}`;
}

export const removeAccents = (str) => {
  return normalizeString(str);
};

export const updatePropInList = (list, props, index, listId) => {
  const { id, value } = props;
  var newObj = list[index];
  newObj = { ...newObj, [id]: value };
  var newList = [...list];
  newList[index] = newObj;
  return { prop: listId, value: newList };
};

export const updateProp = (props) => {
  const { id, value } = props;
  return { prop: id, value };
};

export const removeListItem = (list, prop, index) => {
  var newList = [...list];
  newList.splice(index, 1);
  return { prop, value: newList };
};

export const filledFields = (value) => {
  var isError = false;
  if (typeof value === "string") return !!value;
  if (typeof value === "number") return true;

  for (const property in value) {
    isError =
      typeof value[property] === "string" && value[property] === ""
        ? false
        : true;

    if (typeof value[property] === "object") {
      for (const item in value[property]) {
        if (
          typeof value[property][item] === "string" &&
          value[property][item] === ""
        )
          isError = false;
        if (!isError) break;
      }
    }

    if (!isError) break;
  }
  return isError;
};

export const parseToSelectLabel = (prop, array) => {
  if (array.length) {
    return array.reduce(function (newArr, item) {
      newArr.push({ label: item[prop] });
      return newArr;
    }, []);
  }
  return [];
};

export const updateState = (state, action) => {
  const { prop, value } = action.payload;
  return { ...state, [prop]: value };
};

export const updateObjectState = (state, action) => {
  return { ...state, ...action.payload };
};

export const updateListObject = (state, action) => {
  const { prop, value, index } = action.payload;
  var newList = [...state[prop]];
  newList[index] = value;
  return { ...state, [prop]: newList };
};

export const updateIndexList = (list, index, newIndexvalue) => {
  var newIndexList = [...list];
  newIndexList[index] = newIndexvalue;
  return newIndexList;
};

export const addListItem = (list, prop, newItem) => {
  return { prop, value: [...list, newItem] };
};

export const checkFileType = (fileType, acceptTypes) => {
  const index = acceptTypes.indexOf(fileType);
  return index !== -1;
};

export const parseBRDateToUS = (date) => {
  const dateSplit = date.replaceAll("/", "-").split("-");
  const day = dateSplit[0];
  const month = dateSplit[1];
  const year = dateSplit[2];
  return new Date(year, month - 1, day);
};

export const minutesToHourString = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const min = minutes % 60;
  const textHoras = `00${hours}`.slice(-2);
  const textMinutos = `00${min}`.slice(-2);

  return `${textHoras}:${textMinutos}`;
};

export const getMinutes = (hour) => {
  let min = hour.split(":");
  return Number(min[0]) * 60 + Number(min[1]);
};

export const transformDateEndianness = (date) => {
  const dateSplit = date.replaceAll("/", "-").split("-");

  if (dateSplit[0].length > 2) {
    // convert YYYY-MM-DD to DD-MM-YYYYY (big-endian to little-endian)
    const year = dateSplit[0];
    const month = dateSplit[1];
    const day = dateSplit[2];
    return [day, month, year].join().replaceAll(",", "/");
  }

  // convert DD-MM-YYYYY to YYYY-MM-DD (little-endian to big-endian)
  const day = dateSplit[0];
  const month = dateSplit[1];
  const year = dateSplit[2];
  return [year, month, day].join().replaceAll(",", "/");
};

export const getSelectObjValue = (val) => ({
  label: val ?? "",
});

export const getCriticalUnit = (value, criticalUnitsData) => {
  if (typeof value === "string")
    return criticalUnitsData?.find((i) => i.label === value);
};

export const getSelectValueObj = (val) => {
  if (typeof val === "object") return val.label;
  else return val;
};

export const getPatientAge = (birthDate) => {
  if (birthDate) {
    const now = new Date();
    const diff = now - parseBRDateToUS(birthDate).getTime();
    var age = new Date(diff);
    return Math.abs(age.getUTCFullYear() - 1970);
  }
  return "";
};

export const getDaysToProcedure = (procedureData) => {
  const now = new Date();
  const diffInMilisseconds = new Date(procedureData).getTime() - now.getTime();

  // (1000 milliseconds * (60 seconds * 60 minutes) * 24 hours)
  const diffInDays = Math.ceil(diffInMilisseconds / (1000 * 3600 * 24));
  return diffInDays;
};

export const transformDate = (date) => {
  return transformDateEndianness(moment(date).format("YYYY-MM-DD"));
};

export const getSchedulingLocal = (local) => {
  if (local?.toLowerCase().includes("brasilia")) {
    return "Brasília";
  }
  return "São Paulo";
};

export const getMinutesTokenStored = (dateSavedToken) => {
  const now = new Date();
  const past = new Date(dateSavedToken);

  const diff = Math.abs(now.getTime() - past.getTime());
  const minutes = Math.ceil(diff / (1000 * 60));

  return minutes;
};

export const sortModels = (
  a,
  b,
  sortByCid = false,
  currentSolicitation = null
) => {
  if (sortByCid && currentSolicitation) {
    if (
      a.cidPrincipal === currentSolicitation?.paciente?.cidPrincipal &&
      b.cidPrincipal !== currentSolicitation?.paciente?.cidPrincipal
    ) {
      return -1;
    } else if (
      a.cidPrincipal !== currentSolicitation?.paciente?.cidPrincipal &&
      b.cidPrincipal === currentSolicitation?.paciente?.cidPrincipal
    ) {
      return 1;
    }
  }

  let x = sortByCid
    ? a.cidPrincipal?.toUpperCase()
    : a?.nomeModelo?.toUpperCase();
  let y = sortByCid
    ? b.cidPrincipal?.toUpperCase()
    : b?.nomeModelo?.toUpperCase();

  return x === y ? 0 : x > y ? 1 : -1;
};

export const verifyValue = (value) => {
  if (value === null || value === undefined) return "";
  else return value;
};

export function orderAlphabetically(list, listKey, palavraChaveMinuscula) {
  const collator = new Intl.Collator("pt-BR", { sensitivity: "base" });
  const resultado = list;
  // Ordena o resultado com base na prioridade e sensibilidade
  resultado.sort((a, b) => {
    const indexA = a[listKey].toLowerCase().indexOf(palavraChaveMinuscula);
    const indexB = b[listKey].toLowerCase().indexOf(palavraChaveMinuscula);

    // Se a palavra-chave estiver no início de 'a', 'a' deve ter prioridade
    if (indexA === 0 && indexB !== 0) return -1;
    // Se a palavra-chave estiver no início de 'b', 'b' deve ter prioridade
    if (indexB === 0 && indexA !== 0) return 1;
    // Caso contrário, use o collator para determinar a ordem
    return collator.compare(a[listKey], b[listKey]);
  });

  return resultado;
}

export function searchListWithPriorityOrder(
  list = [],
  value = "",
  listKey = "",
  shouldFilter = true
) {
  const palavraChaveMinuscula = value.toLowerCase();

  if (list.length === 1) {
    return list;
  } else {
    let resultado = list;
    if (shouldFilter) {
      resultado = list.filter((item) =>
        item[listKey].toLowerCase().includes(palavraChaveMinuscula)
      );
    }

    resultado = orderAlphabetically(resultado, listKey, palavraChaveMinuscula);

    return resultado;
  }
}

export const returnProcedure = (procedure) => {
  if (typeof procedure.procedimentoPrincipal === "object") {
    return procedure.procedimentoPrincipal.procedimento;
  } else if (typeof procedure.procedimentoPrincipal === "string") {
    return procedure.procedimentoPrincipal;
  }
};

export const handleValue = (proceduresData) => {
  if (proceduresData.biopsiaCongelacao === "radioHsl") {
    return "radioHsl";
  } else if (proceduresData.biopsiaCongelacao === "radioExt") {
    return "radioExt";
  } else return "";
};

export const formatArrayData = (arr) => {
  if (arr.length) {
    var newArr = [];
    arr.map((item) => {
      var newObj = { ...item };
      for (const prop in item) {
        if (item[prop] !== null) {
          newObj = {
            ...newObj,
            [prop]:
              prop === "quantidade"
                ? parseFloat(item[prop].replace(",", "."))
                : item[prop],
          };

          newObj = {
            ...newObj,
            procedimento:
              prop === "nomeProcedimento" && item["nomeProcedimento"]
                ? item[prop]
                : item["procedimento"],
          };
        }
      }

      if (
        !Object.prototype.hasOwnProperty.call(newObj, "ieLado") ||
        newObj["ieLado"].trim() === ""
      ) {
        newObj["ieLado"] = "N";
      }

      newArr.push(newObj);
    });
    return newArr;
  }
  return [];
};

export const handleSurnames = (fullName, fullNameMaxLength) => {
  let name = fullName.trim().split(" ");

  let firstName = name[0];
  let lastName = name[name.length - 1];
  let preposition = ["do", "da", "dos", "das", "de", "e"];
  for (let i = name.length - 2; i >= 1; i--) {
    if (!name[i].includes(".") && !preposition.includes(name[i])) {
      let lastInitialName = name[i].charAt(0);

      name[i] = `${lastInitialName}.`;

      const tempLayout = `${firstName} ${name
        .slice(1, i + 1)
        .join(" ")} ${lastName}`;

      if (tempLayout.length <= fullNameMaxLength) {
        break;
      }
    }
  }

  return name.join(" ");
};

const getFullNameMaxLength = (lines, maxNumberLines) => {
  let linesTextLength = [];

  for (let i = 0; i < maxNumberLines; i++) {
    linesTextLength.push(lines[i]?.text?.length);
  }

  let fullNameMaxLength = Math.max(...linesTextLength) * maxNumberLines;

  return fullNameMaxLength;
};

export const getAbbreviatedFullName = (fullName, maxLength) => {
  if (fullName.length > maxLength) {
    const maxFullNameLength = getFullNameMaxLength(2, 1);

    return handleSurnames(fullName, maxFullNameLength);
  }

  return fullName;
};

export const removeSpecialCharacters = (phrase) => {
  const withoutSpecialChars = phrase.replace(
    /[^\w\sáéíóúàèìòùãõâêîôûäëïöüç]/gi,
    ""
  );
  return withoutSpecialChars;
};

const formatter = new Intl.NumberFormat("pt-BR", {
  maximumFractionDigits: 3,
  minimumFractionDigits: 0,
  useGrouping: false,
  trailingZeroDisplay: "stripIfInteger",
});

export const formatQuantity = (value) => {
  if (value[value.length - 1] === ",") {
    return value;
  }

  return value !== "" ? formatter.format(Number(value.replace(",", "."))) : "";
};

export const valueToWord = {
  1: "uma",
  2: "duas",
  3: "três",
  4: "quatro",
  5: "cinco",
  6: "seis",
  7: "sete",
  8: "oito",
  9: "nove",
  10: "dez",
};

export const verifyIeLado = (list) => {
  return list.map((item) => {
    return item.ieLado !== "" ? item : { ...item, ieLado: "N" };
  });
};

export const checkQuantity = (quant) => {
  if (
    quant === "" ||
    isNaN(quant.replace(",", ".")) ||
    quant.replace(",", ".") < 1
  ) {
    return true;
  }

  return false;
};

export const removeFieldsProcedure = (objeto, list) => {
  const fieldsToExclud = list;

  const newObj = { ...objeto };

  fieldsToExclud.forEach((campo) => {
    if (Object.prototype.hasOwnProperty.call(newObj, campo)) {
      delete newObj[campo];
    }
  });

  return newObj;
};
