import { differenceInMinutes } from "date-fns";

export function checkTokenExpiration(
  accessTokenExpirationDate,
  sessionExpirationDate,
  rangeToRevalidateToken,
  onAccessTokenToExpire,
  onSessionTokenToExpire
) {
  const accessTokenTimeLeft = differenceInMinutes(
    new Date(accessTokenExpirationDate),
    new Date()
  );

  const sessionTokenTimeLeft = differenceInMinutes(
    new Date(sessionExpirationDate),
    new Date()
  );

  if (sessionTokenTimeLeft <= 15) {
    onSessionTokenToExpire();
  }

  if (accessTokenTimeLeft <= rangeToRevalidateToken) {
    onAccessTokenToExpire();
  }
}
