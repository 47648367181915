import { createSlice } from "@reduxjs/toolkit";
import { updateObjectState, updateState } from "../../utils";
import { patient } from "./patient";

const patientSlice = createSlice({
  name: "patient",
  initialState: patient,
  reducers: {
    setClearPatient: () => {
      return patient;
    },
    setPatientProperty: updateState,
    setPatientObject: updateObjectState,
    setAnamnesePart: (state, action) => {
      const { step, value } = action.payload;
      return {
        ...state,
        anamnese: {
          ...state.anamnese,
          [step]: value,
        },
      };
    },
    setClearAnamnese: (state) => {
      return {
        ...state,
        anamnese: {
          ...patient.anamnese,
        },
      };
    },
  },
});

export const {
  setClearPatient,
  setPatientProperty,
  setPatientObject,
  setAnamnesePart,
  setClearAnamnese,
} = patientSlice.actions;

export const patientReducer = patientSlice.reducer;
