import styled, { css } from "styled-components";

export const Container = styled.form`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

export const WrapperTermo = styled.div`
  display: flex;
  align-items: center;

  > div {
  }
  p {
    text-decoration: underline;
    color: #2c6ef2;
  }
  .tmp-icon {
    width: 16px;
    height: 16px;
    margin-left: 8px;
  }

  ${(props) =>
    props.disabled &&
    css`
      p,
      a {
        color: rgba(163, 163, 163, 1);
      }
    `}
`;

export const Documents = styled.div`
  ${(props) =>
    props.disabled &&
    css`
      p,
      a {
        color: rgba(163, 163, 163, 1);
      }
    `}
`;

export const BtnSendWrap = styled.div`
  width: 100%;
  height: 96px;
  display: flex;
  align-items: ${(props) => props.alingItems};
  justify-content: ${(props) =>
    props.justContent ? "space-between" : "flex-end"};
  background-color: #f5f5f5;

  button {
    margin: ${(props) => props.margin};
    height: ${(props) => props.height};
    padding: ${(props) => props.padding};
  }
  > div {
    height: 48px;
    margin: 24px 24px 0 0;

    > button {
      min-width: ${(props) => (props.loading ? "" : "94px")};
      height: ${(props) => (props.loading ? "" : "48px")};
      padding: 0;
      margin: 0;
    }
    > button:nth-last-child(1) {
      min-width: ${(props) => (props.loading ? "" : "170px")};
      height: ${(props) => (props.loading ? "" : "48px")};
      padding: 0;
      margin: 0;
    }
  }
  button {
    pointer-events: ${(props) => (props.pointer ? "none" : "")};
  }
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  ${(props) =>
    props.mobileMarginTop
      ? css`
          @media (max-width: 768px) {
            margin-top: ${props.mobileMarginTop};
          }
        `
      : null}

  div {
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;

    button:first-child {
      margin-right: 16px;
    }
    button {
      pointer-events: ${(props) => (props.pointer ? "none" : "")};
    }
    /* button {
      width: min-content;
      min-width: 133px;
      margin-bottom: 0px;
      white-space: nowrap;
    } */
  }

  @media (max-width: 768px) {
    div {
      width: 100%;
      flex-direction: row;
      justify-content: center;
    }
  }

  @media (max-width: 425px) {
    div {
      width: 100%;
      flex-direction: column;
      align-items: center;

      button:first-child {
        margin: 0 0 24px 0;
      }
      button {
        width: 60vw;
      }
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 64px;

  @media (min-width: 1024px) {
    width: 100%;
    margin-left: 0;
  }

  &.mobile-style {
    @media (max-width: 576px) {
      flex-direction: column;
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: row;
  color: #292929;
  margin: 0 24px 0 0;
  cursor: pointer;

  .tmp-button-icon {
    margin-left: 5px;

    .tmp-icon {
      margin-left: 0;
    }
  }

  > div {
    margin: auto 0 auto 9px;
    /* p:nth-last-child(1) { color: #A3A3A3;}
    p:nth-last-child(2) { color: red;} */
  }

  .tmp-icon-prescricao > svg {
    width: 32px;
    height: 32px;
    position: relative;
  }

  .tmp-icon > svg {
    width: 32px;
    height: 32px;
    position: relative;
  }

  .tmp-icon-prescricao > path {
    fill: ${(props) =>
      props.disabledIcon ? "rgba(163, 163, 163, 1)" : "#292929"};
    stroke: transparent;
  }
`;
export const StyledAlert = styled.div`
  > div {
    top: 0;
    transform: translate(-50%, -110%);
    width: 312px;
    min-width: 312px;
    z-index: 1000;
    margin: 0 auto;

    ${(props) =>
      props.isAnm &&
      css`
        > :nth-child(1) {
          height: 32px;

          button {
            margin: 16px 16px 16px auto;
          }
        }
      `}
    ${(props) =>
      props.isOpened &&
      css`
        top: 0;
        transform: translate(-50%, 90%);
      `}

    h3 {
      font-size: 16px;
    }
  }
`;
export const Status = styled.span`
  width: 8px;
  height: 8px;
  background-color: ${({ bg }) => bg};
  position: absolute;
  margin-top: ${(props) => props.top};
  margin-left: 18px;
  border-radius: 50%;
  border: 2px solid #fff;
`;

export const BoxModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => alignItems ?? "center"};
  text-align: ${({ textAlign }) => textAlign ?? "center"};
  margin-bottom: 32px;
  margin-top: 24px;
  padding: 0 24px;
  max-width: 624px;

  .tmp-button-icon {
    margin: 24px 24px 0 0;
  }
`;

export const TextModal = styled.p`
  font-size: ${(props) => props.fontSize};
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
`;

export const Title = styled.h2`
  font-family: Inter;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "20px")};
  font-style: normal;
  font-weight: 700;
  line-height: 133%;
  letter-spacing: -0.2px;
  text-align: center;
`;

export const RequestSummary = styled.div`
  width: 100%;
  height: 169px;
  margin-top: 32px;
  display: flex;
`;

export const Summary = styled.div`
  width: 345px;
  height: 169px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const SubTitle = styled.h3`
  font-size: 14px;
`;
export const Name = styled.p`
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`;
export const Info = styled.p`
  font-size: 14px;
  font-weight: 700;
`;
export const BigText = styled.p`
  display: -webkit-box;
  text-align: start;
  max-width: 100%;
  -webkit-line-clamp: 2; /* Define o número máximo de linhas */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
`;
export const BoxInformations = styled.div`
  display: flex;
  width: 100%;
  gap: 5px;
  font-size: 14px;
  white-space: nowrap;
`;

export const BoxSignature = styled.div`
  width: 279px;
  font-size: 14px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: ${(props) => (props.secretary ? "none" : "flex")};
`;
export const Signature = styled.img`
  max-width: 200px;
`;

export const WrapperCheck = styled.div`
  align-self: flex-start;
  margin-top: 32px;

  span {
    font-size: 14px;
    line-height: normal;
  }
`;
export const ImgSucess = styled.img`
  widht: ${(props) => props.width};
  height: ${(props) => props.height};
`;

export const Contacts = styled.span`
  width: 496px;
  height: 53px;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  font-style: normal;
  font-weight: 400;
  line-height: 133%;
  text-align: center;
  color: #666;
  margin-top: 16px;
`;

export const Alert = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #292929;
  gap: 5px;
  margin-bottom: 16px;
`;
