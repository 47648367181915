import { createSlice } from "@reduxjs/toolkit";
import { updateState, updateObjectState } from "../../utils";
import { solicitation } from "./solicitation";

const solicitationSlice = createSlice({
  name: "solicitations",
  initialState: solicitation,
  reducers: {
    setClearSolicitation: () => {
      return solicitation;
    },
    setSolicitationProperty: updateState,
    setSolicitationObject: updateObjectState,
    setSolicitationProcedureProperty: (state, action) => {
      const { prop, value } = action.payload;
      return {
        ...state,
        procedimento: {
          ...state.procedimento,
          [prop]: value,
        },
      };
    },
    setSolicitationReportProperty: (state, action) => {
      const { prop, value } = action.payload;
      return {
        ...state,
        relatorio: {
          ...state.relatorio,
          [prop]: value,
        },
      };
    },
    setSolicitationAnamneseProperty: (state, action) => {
      const { prop, value } = action.payload;
      return {
        ...state,
        anamnese: {
          ...state.anamnese,
          [prop]: value,
        },
      };
    },
  },
});

export const solicitationReducer = solicitationSlice.reducer;

export const {
  setClearSolicitation,
  setSolicitationProperty,
  setSolicitationObject,
  setSolicitationProcedureProperty,
  setSolicitationReportProperty,
  setSolicitationAnamneseProperty,
} = solicitationSlice.actions;
