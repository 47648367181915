import React from "react";
import * as S from "./style";

const Footer = () => {
  return (
    <S.Container>
      <S.Text size=".8rem" weight="400" color="#666666">
        © 2022 Hospital Sírio-Libanês
      </S.Text>
    </S.Container>
  );
};

export default Footer;
