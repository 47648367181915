import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import * as S from "./style";
import Header from "../../components/Header";
import { RequestFailed } from "../../components/RequestFailed";
import { sendGAPageView } from "../../utils/ga";

const SystemStatus = ({ image, title, content, isOnline }) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleClearNavigation = (page) => {
    window.location.replace(page);
  };

  useEffect(() => {
    sendGAPageView("Sistema");
  }, []);

  useEffect(() => {
    if (isOnline === false) {
      navigate("/");
    }
  }, []);

  return (
    <>
      {state?.error === true ? <Header /> : <></>}
      <S.Wrapper isOnline={state?.error === true || isOnline === true}>
        {state?.error === true || isOnline === true ? (
          <>
            <RequestFailed.Root>
              <RequestFailed.Image image={state?.image} />
              <RequestFailed.Content
                title={state?.title}
                content={state?.content}
              />
            </RequestFailed.Root>
            <RequestFailed.Action
              handleClick={() => handleClearNavigation(state?.page)}
              titleButton={state?.titleButton}
            />
          </>
        ) : (
          <RequestFailed.Root>
            <RequestFailed.Image image={image} />
            <RequestFailed.Content title={title} content={content} />
          </RequestFailed.Root>
        )}
      </S.Wrapper>
    </>
  );
};

export default SystemStatus;
