import React, { useEffect, useState } from "react";
import SystemStatus from "../pages/SystemStatus";
import { ErrorBoundary } from "react-error-boundary";
import { RequestFailed } from "../components/RequestFailed";
import { seta_esquerda as SetaEsquerda } from "@tempo/tempo-assets/dist";

import { connectionDropped } from "../assets/base64/connectionDropped";

const message = {
  image: connectionDropped,
  title: "Sua conexão caiu...",
  content: "Aguarde o restabelecimento ou tente atualizar sua página.",
};

const isProduction = process.env.REACT_APP_ENVIRONMENT === "production";

const GenericErrorFeedback = ({ error, resetErrorBoundary }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <RequestFailed.Root>
        <RequestFailed.Image />
        <RequestFailed.Content
          title="Falha ao carregar"
          content={
            "Pedimos desculpas pelo ocorrido.\nEstamos verificando para que não aconteça novamente."
          }
        />
        {!isProduction && <h3>{error.message}</h3>}
        <RequestFailed.Action
          startIcon={<SetaEsquerda />}
          handleClick={() => {
            window.history.pushState(null, null, "/");
            resetErrorBoundary();
          }}
          titleButton="Voltar à tela inicial"
        />
      </RequestFailed.Root>
    </div>
  );
};

const SystemFeedback = ({ children }) => {
  const [isOnline, setOnline] = useState(true);

  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  useEffect(() => {
    const handleOnline = () => {
      setOnline(true);
    };

    const handleOffline = () => {
      setOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  if (isOnline) {
    return (
      <ErrorBoundary FallbackComponent={GenericErrorFeedback}>
        {children}
      </ErrorBoundary>
    );
  }

  return (
    <SystemStatus
      image={`data:image/jpeg;base64,${message.image}`}
      title={message.title}
      content={message.content}
      isOnline={isOnline}
    />
  );
};

export default SystemFeedback;
