import { useSelector } from "react-redux";
import { SectionTitle } from "../../globalStyle";
import { TmpDivider } from "@tempo/tempo-design-system-core/dist";
import { getAbbreviatedFullName, parseBRDateToUS } from "../../utils";
import * as S from "./style";

const InfoPatient = ({ marginBottomDivider }) => {
  const patient = useSelector((state) => state.patient);
  const solicitation = useSelector((state) => state.solicitation);

  const patientData =
    solicitation.solicitacao.idSolicitacao !== null
      ? solicitation.paciente
      : patient;

  const getPatientAge = (birthDate) => {
    if (birthDate) {
      const now = new Date();
      const diff = now - parseBRDateToUS(birthDate).getTime();
      var age = new Date(diff);
      return Math.abs(age.getUTCFullYear() - 1970);
    }
    return "";
  };

  const returnDocument = (type) => {
    if (type === "document") {
      if (patientData.tipoDocumento !== "Nome")
        return <S.Info>{patientData.tipoDocumento}:</S.Info>;
      else return null;
    }

    if (type === "valueDoc") {
      if (patientData.tipoDocumento === "CPF") return patientData.cpf;
      else if (patientData.tipoDocumento === "Passaporte")
        return patientData.passaporte;
      else if (patientData.tipoDocumento === "RNE/CRNM") return patientData.rne;
    }
  };

  return (
    <div>
      <SectionTitle marginBottomDivider={marginBottomDivider}>
        <h3 className="title" style={{ marginBottom: "15px" }}>
          Dados do Paciente
        </h3>
        <TmpDivider />
      </SectionTitle>
      <S.InfoWrap>
        <S.PatientInfos>
          <b>Nome do Paciente:</b>
          <span>{getAbbreviatedFullName(patientData?.nome, 40)}</span>
          <S.WrapperInfos>
            {patientData.tipoDocumento !== "Nome" ? (
              <S.WrapperInfos>
                {returnDocument("document")}
                <p>{returnDocument("valueDoc")}</p>
              </S.WrapperInfos>
            ) : null}
            <b>Sexo:</b>
            <span>{`(${patientData?.sexo === "Masculino" ? "M" : "F"})`}</span>
            <b>Idade:</b>
            <span>{getPatientAge(patientData?.dataNascimento)}</span>
          </S.WrapperInfos>
        </S.PatientInfos>
        <div style={{ width: "min-content", alignSelf: "center" }}>
          {patientData?.prontuario ? (
            <>
              <b>Prontuário: </b>
              <span>{patientData.prontuario}</span>
            </>
          ) : null}
        </div>
      </S.InfoWrap>
    </div>
  );
};

export default InfoPatient;
