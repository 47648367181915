import React, { useEffect, useState } from "react";
import { TmpLinkAndIconCC } from "@tempo/tempo-design-system-centro-cirurgico";

import * as S from "./style";
import Loading from "../Loading";

const Autocomplete = ({
  list,
  inputName,
  propLabel,
  onClick,
  isCurrentInput,
  isLoading,
  onNewPatient = () => {},
  setError,
  initialOpenValue = false,
  sugestionTitle,
  ...props
}) => {
  const [isOpenSugestion, setIsOpenSugestion] = useState(initialOpenValue);

  useEffect(() => {
    setIsOpenSugestion(true);
  }, [list]);

  const handleClick = (value) => {
    setIsOpenSugestion(false);
    onClick(value);
  };

  const handleReturn = (item) => {
    if (
      inputName === "procedimentoPrincipal" ||
      inputName === "procedimentosExtras"
    ) {
      return `${item.tuss} - ${item[propLabel]}`;
    } else if (inputName === "nomeCirurgiao") {
      if (item.code) {
        setIsOpenSugestion(false);
        setError();
      } else return `${item[propLabel]} - CRM: ${item?.crmCirurgiao}`;
    } else if (inputName === "nomeAnestesista") {
      if (item.code) {
        setIsOpenSugestion(false);
        setError();
      } else return `${item[propLabel]} - CRM: ${item?.crmAnestesista}`;
    } else if (inputName === "nomePaciente")
      return `${item[propLabel]} - ${item?.dataNascimento}`;
    else return item[propLabel];
  };

  return isLoading ? (
    <S.SugestionBox {...props}>
      <Loading />
    </S.SugestionBox>
  ) : isOpenSugestion && isCurrentInput && !!list.length ? (
    <S.SugestionBox {...props}>
      {sugestionTitle && <S.SugestionTitle>{sugestionTitle}</S.SugestionTitle>}
      {list.map((item, index) => (
        <S.SugestionItem
          showTopBorder={!!sugestionTitle}
          key={index}
          onClick={() => handleClick(item)}
        >
          {handleReturn(item)}
        </S.SugestionItem>
      ))}
      {inputName === "nomePaciente" && (
        <S.SugestionItem onClick={onNewPatient}>
          <p>
            <strong>Nenhum desses é o seu paciente?</strong>{" "}
            <TmpLinkAndIconCC
              data-testid="register-patient"
              handleClick={onNewPatient}
            >
              Fazer cadastro.
            </TmpLinkAndIconCC>
          </p>
        </S.SugestionItem>
      )}
    </S.SugestionBox>
  ) : null;
};
export default Autocomplete;
