import React from "react";
import { getSchedulingLocal } from "../../utils";
import * as S from "./style";

const LocalAndDate = ({ local, display, margin, size, marginMbl }) => {
  const currentDate = new Date();
  return (
    <S.Container
      size={size}
      display={display}
      margin={margin}
      marginMbl={marginMbl}
    >
      <span>{getSchedulingLocal(local)},</span>
      <p>
        {`${currentDate.toLocaleDateString()} - ${currentDate.toLocaleTimeString(
          "pt-BR",
          { hour: "2-digit", minute: "2-digit" }
        )}`}
      </p>
    </S.Container>
  );
};

export default LocalAndDate;
