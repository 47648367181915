import React from "react";
import { TmpLinkAndIconCC } from "@tempo/tempo-design-system-centro-cirurgico";
import { retornar as Return } from "@tempo/tempo-assets/dist";
import * as S from "./style";

const Reload = ({ handleClick }) => {
  return (
    <S.Container>
      <Return />
      <p>
        Falha ao carregar. Continue digitando ou{" "}
        <TmpLinkAndIconCC handleClick={handleClick} marginTop={4}>
          tente de novo.
        </TmpLinkAndIconCC>
      </p>
    </S.Container>
  );
};

export default Reload;
