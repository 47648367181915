import React from "react";
import emptyState from "../../assets/images/emptyState.png";
import * as S from "./style";

export default function index() {
  return (
    <S.Container>
      <img src={emptyState} alt="lupa olhando caixa vazia" />
      <p>Não há nenhuma solicitação na sua lista</p>
    </S.Container>
  );
}
