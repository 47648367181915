import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFileExtension, removeAccents } from "../../utils";
import {
  TmpUploader,
  TmpProgressBar,
  TmpButtonIcon,
} from "@tempo/tempo-design-system-core/dist";
import { TmpLinkAndIconCC } from "@tempo/tempo-design-system-centro-cirurgico";
import { MessageError } from "../../pages/Scheduling/Steps/style";
import * as S from "./style";
import {
  erro as ErrorIcon,
  excluir as Trash,
  baixar as IconBaixar,
} from "@tempo/tempo-assets/dist";
import { ComponentLabel } from "../../globalStyle";
import { apiAttachmentUrl, apiSendFile } from "../../services/api";
import axios from "axios";
import {
  isError,
  setDownloadError,
  setLoadingTermo,
  setTimeNeedErro,
} from "../../features/settings/settingsSlice";

const getFileName = (value) => {
  if (typeof value === "string") {
    return value;
  }

  if (value.documento) {
    return value.documento;
  }

  return "";
};

// Tamanho maximo para upload
const maxSizeValue = 10;

const Uploader = ({
  error = null,
  dataChange,
  label = null,
  prop,
  propUUID,
  value,
  index = null,
  listId = null,
  list = null,
  removeFile,
  downloadId,
  disabled,
}) => {
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(100);
  const [uploadError, setUploadError] = useState(false);
  const [uploadErrorType, setUploadErrorType] = useState(undefined);
  const [isDownlaoding, setDownlaoding] = useState(false);
  const currentSolicitation = useSelector((state) => state.solicitation);

  const errorMessages = {
    upload: "Erro no upload do documento.Tente novamente.",
    type: "Formato inválido. Os formatos aceitos são: doc, docx, pdf, jpg e png.",
    size: `O arquivo é muito pesado. O tamanho máximo é de ${maxSizeValue}MB.`,
    sizeMin:
      "O arquivo parece estar vazio ou corrompido. Tente novamente ou envie outro arquivo.",
  };

  useEffect(() => {
    if (value) setProgress(100);
  }, []);

  const handleSetError = (type) => {
    setUploadError(true);
    setUploadErrorType(type);
    setProgress(0);
    if (index === null) removeFile();
  };

  function sizeValidation(file) {
    // Limitador do tamanho dos arquivos
    const maxSize = maxSizeValue * 1024 * 1024;
    if (file.size > maxSize) {
      handleSetError("size");
      return false;
    }
    return true;
  }

  const clearError = () => {
    setUploadError(false);
    setUploadErrorType(undefined);
  };

  function attachmentValidate(file) {
    const typeDocument = getFileExtension(file.name);
    const typesAccepted = ["doc", "docx", "pdf", "jpg", "png", "jpeg"];
    if (file.size === 0) {
      handleSetError("sizeMin");
      return false;
    }
    if (!typesAccepted.includes(typeDocument.toLowerCase())) {
      handleSetError("type");
      return false;
    }

    return sizeValidation(file);
  }

  const downloadFile = () => {
    setDownlaoding(true);
    const fileType = getFileExtension(value);
    apiAttachmentUrl()
      .getSingle(`${downloadId}.${fileType}`)
      .then((response) => {
        const byteCharacters = Buffer.from(
          response.replace(/^b'|'$/g, ""),
          "base64"
        );

        const byteArray = new Uint8Array(byteCharacters);

        const file = new Blob([byteArray], {
          type: "application/octet-stream",
        });
        const fileURL = URL.createObjectURL(file);

        const link = document.createElement("a");
        link.href = fileURL;
        link.download = value;
        link.click();

        // Liberar o URL temporário após o download
        URL.revokeObjectURL(fileURL);
        setDownlaoding(false);
      })
      .catch((err) => {
        console.log(err);
        setDownlaoding(false);
        dispatch(isError(true));
        dispatch(setDownloadError(true));
      });
  };

  const handleChange = (file) => {
    clearError();
    setProgress(0);
    dispatch(setLoadingTermo(true));
    if (attachmentValidate(file)) {
      if (index === null) {
        dataChange({
          target: {
            name: prop,
            value: removeAccents(file.name),
          },
        });
      } else {
        let newValue = removeAccents(file.name);
        if (prop === "documento")
          newValue = {
            idRelatorioDocumento: null,
            documento: removeAccents(file.name),
            documentoUUID: "",
          };

        let listAux = [...list];
        listAux[index] = newValue;

        dataChange({
          target: {
            name: listId,
            value: listAux,
          },
        });
      }

      apiSendFile(file, currentSolicitation.solicitacao.idSolicitacao)
        .post()
        .then((resp) => {
          dispatch(setTimeNeedErro(true));
          axios
            .put(resp.url_presigned, file, {
              onUploadProgress: (event) => {
                const percentage = parseInt(
                  (event.loaded / event.total) * 100 - 1
                );
                setProgress(percentage);
              },
            })
            .then((response) => {
              const failedUpload = !resp?.uuid;
              const requestError = response?.name === "AxiosError";
              if (requestError || failedUpload) {
                throw new Error(
                  `Erro ao fazer upload do termo de consentimento: ${response?.name}`
                );
              }
              if (resp?.uuid) {
                setProgress(100);
                dispatch(setTimeNeedErro(false));
                dispatch(setLoadingTermo(false));
                if (index !== null) {
                  let newValue = list[index];
                  if (prop === "documento")
                    newValue = {
                      ...newValue,
                      idRelatorioDocumento: null,
                      documento: removeAccents(file.name),
                      documentoUUID: resp.uuid,
                    };

                  let listAux = [...list];
                  listAux[index] = newValue;

                  dataChange({
                    target: {
                      name: listId,
                      value: listAux,
                    },
                  });
                } else {
                  dataChange({
                    target: {
                      name: propUUID,
                      value: resp.uuid,
                    },
                  });
                }
              }
            })
            .catch((err) => {
              console.log(err);
              dispatch(setLoadingTermo(false));
              handleSetError("upload");
            });
        })
        .catch((err) => {
          console.log(err);
          dispatch(setLoadingTermo(false));
          dispatch(setTimeNeedErro(false));
          handleSetError("upload");
        });
    }
  };

  return value ? (
    <>
      <ComponentLabel>{label}</ComponentLabel>
      <S.StyledFile processing={progress < 100}>
        {progress < 100 && uploadError === false && value !== "" && (
          <S.WrapperProgress>
            <TmpProgressBar value={progress} />
          </S.WrapperProgress>
        )}
        {progress === 100 && uploadError === false && value !== "" && (
          <>
            <S.LabelAndButton size={isDownlaoding ? "100px" : "80px"}>
              <TmpButtonIcon
                size="lg"
                icon={<Trash style={{ color: "red" }} />}
                handleClick={() => removeFile()}
              />
              <p>{getFileName(value)}</p>
            </S.LabelAndButton>
            <S.DownlaodBtn>
              <TmpLinkAndIconCC
                loading={isDownlaoding}
                icon={<IconBaixar />}
                handleClick={downloadFile}
                marginTop={8}
              >
                {isDownlaoding ? "Baixando" : "Baixar"}
              </TmpLinkAndIconCC>
            </S.DownlaodBtn>
          </>
        )}
      </S.StyledFile>
    </>
  ) : (
    <S.StyledUploader disabled={disabled} error={error || uploadError}>
      <ComponentLabel error={error || uploadError}>{label}</ComponentLabel>
      <TmpUploader
        label="Procurar"
        labelDrag="ou arraste e solte o documento aqui"
        handleChange={(file) => handleChange(file[0], prop)}
      />
      {error ? (
        <MessageError style={{ marginTop: "8px" }}>
          <ErrorIcon />
          Campo obrigatório
        </MessageError>
      ) : (
        uploadError && (
          <MessageError style={{ marginTop: "8px" }}>
            <ErrorIcon />
            {errorMessages[uploadErrorType]}
          </MessageError>
        )
      )}
    </S.StyledUploader>
  );
};

export default Uploader;
