import styled from "styled-components";

export const MsgMobile = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
    margin: 89px 24px 24px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    .ilustration {
      width: 280px;
      height: 280px;
      margin: 56px 0 24px 0;
    }

    .subtitle {
      font-size: 24px;
      font-weight: 700;
      color: #292929;
      margin-bottom: 16px;
    }

    .text {
      font-size: 20px;
      color: #292929;
    }
  }
`;
