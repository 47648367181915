import React from "react";
import nenhumResultado from "../../assets/images/nenhumResultado.png";
import * as S from "./style";

export default function index() {
  return (
    <S.Container>
      <img src={nenhumResultado} alt="lupa olhando caixa vazia" />
      <S.BoxTexts>
        <h3>Nenhum paciente encontrado</h3>
        <p>Confira se você digitou corretamente</p>
      </S.BoxTexts>
    </S.Container>
  );
}
