import React from "react";
import * as Styled from "./style";
import Tooltip from "../Tooltip";
import WarningIcon from "../WarningIcon";
import IluminationIcon from "../LuzIcon";
const statusColors = {
  "Pendente envio médico": {
    background: "surface-area-low",
    color: "color-neutral-7",
    tooltipText: (
      <p>
        <b> Atenção!</b> Solicitação já preenchida. Pendente validação e envio
        por parte do(a) médico(a).
      </p>
    ),
    icon: <WarningIcon color="#3D3D3D" size="16px" />,
  },

  "Pendente envio edição": {
    background: "surface-area-low",
    color: "color-neutral-7",
    tooltipText: (
      <p>
        <b> Atenção!</b> Solicitação editada. Pendente validação e envio por
        parte do(a) médico(a).
      </p>
    ),
    icon: <WarningIcon color="#3D3D3D" size="16px" />,
  },

  "Aguardando preenchimento": {
    background: "surface-area-medium",
    color: "color-neutral-7",
    tooltipText: (
      <p>
        <b> Atenção!</b> Solicitação já iniciada. Finalize o preenchimento e
        envie para análise.
      </p>
    ),
    icon: <WarningIcon color="#3D3D3D" size="16px" />,
  },

  "Em preenchimento": {
    background: "surface-area-high",
    color: "color-neutral-7",
    tooltipText: (
      <p>Solicitação ainda está sendo preenchida e não foi enviada.</p>
    ),
  },

  "Aguardando atendimento": {
    background: "surface-area-highest",
    color: "color-neutral-7",
    tooltipText: (
      <p>
        <b> Atenção!</b> Erro ao identificar o paciente. Entre em contato com o{" "}
        <b>Service Desk - (11) 3394-0990</b> para resolver.
      </p>
    ),
    icon: <WarningIcon color="#3D3D3D" size="16px" />,
  },

  "Solicitação recebida": {
    background: "primary-area",
    color: "color-neutral-7",
    tooltipText: <p>Aguardando conferência.</p>,
  },

  "Data agendada": {
    background: "primary-fixed-base",
    color: "color-neutral-7",
    tooltipText: <p>Iniciando conferência da solicitação.</p>,
  },

  "Pendência médica": {
    background: "secondary-base",
    color: "color-neutral-100",
    tooltipText: (
      <p>
        <b> Atenção!</b> Existem pendências que precisam ser resolvidas. Veja
        mais detalhes no seu email.
      </p>
    ),
    icon: <WarningIcon color="#ffffff" size="16px" />,
  },

  "Verificando solicitação": {
    background: "primary-fixed-baseVariant",
    color: "color-neutral-7",
    tooltipText: <p>Realizando orçamento ou cotação de material.</p>,
  },

  "Aguardando autorização": {
    background: "secondary-fixed-baseVariant",
    color: "color-neutral-7",
    tooltipText: <p>Em análise do convênio ou do orçamento.</p>,
  },

  "Autorização negada": {
    background: "error-area",
    color: "color-neutral-7",
    tooltipText: (
      <p>
        <b> Atenção!</b> O convênio não aprovou a autorização. Veja mais
        detalhes no seu email.
      </p>
    ),
    icon: <WarningIcon color="#3D3D3D" size="16px" />,
  },

  "Solicitação particular": {
    background: "secondary-area",
    color: "color-neutral-7",
    tooltipText: <p>Solicitação autorizada com orçamento particular.</p>,
  },

  Autorizado: {
    background: "success-base",
    color: "color-neutral-100",
    tooltipText: <p>Solicitação autorizada para realização.</p>,
  },

  "Solicitação cancelada": {
    background: "error-base",
    color: "color-neutral-100",
    tooltipText: <p>Caso tenha sido engano, entrar em contato.</p>,
  },

  "Cirurgia realizada": {
    background: "primary-content-fixed-variant",
    color: "color-neutral-100",
    tooltipText: null,
  },
};
const Status = ({ children, urgente }) => {
  const { background, color, tooltipText, icon } = statusColors[children] || {};
  const disabled = children === "Cirurgia realizada" || children === "Solicitação cancelada" ? "#a3a3a3" : "#B81414"
  
  
  return (
    <Styled.Container>
      <Styled.WrapperTooltip backGround={background} color={color}>
        <Tooltip position="bottom" message={tooltipText}>
          <p className="status">{children}</p> {icon}
        </Tooltip>
      </Styled.WrapperTooltip>
      {urgente && (
        <Tooltip position="bottom" message="Solicitação urgente." disabled={disabled === "#a3a3a3"}>
          <IluminationIcon color={disabled} size="24px" disabled={disabled === "#a3a3a3"}/>
        </Tooltip>
      )}
    </Styled.Container>
  );
};

export default Status;
