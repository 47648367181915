import React from "react";
import Atention from "../../assets/images/atencao2.png";
import * as S from "./style";
import { TmpButton } from "@tempo/tempo-design-system-core/dist";
import { seta_esquerda as SetaEsquerda } from "@tempo/tempo-assets/dist";
import { useAuthContext } from "../../hooks";

const NotSignature = () => {
  const { logout } = useAuthContext();

  return (
    <S.Container>
      <S.Content>
        <S.Img src={Atention} alt="atenção" />
        <S.Title>Assinatura digital não encontrada!</S.Title>
        <S.Text>
          Para acessar o agendamento cirúrgico é necessário ter uma assinatura
          digital. Entre em contato com a equipe do SAM pelo{" "}
          <b>(11) 3394-1000 / sam@hsl.org.br</b> ou{" "}
          <b>(61) 3044-8888 / sambrasilia@hsl.org.br</b> para atualizar o seu
          cadastro.
        </S.Text>
        <S.BoxBtn>
          <TmpButton handleClick={logout} startIcon={<SetaEsquerda />}>
            Retornar à tela de login
          </TmpButton>
        </S.BoxBtn>
      </S.Content>
    </S.Container>
  );
};

export default NotSignature;
