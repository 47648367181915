import {
  apiCreateProcedure,
  apiCreateSolicitation,
  apiSendMaterials,
  apiSolicitationReports,
  apiUpdateSolicitation,
} from "../../services/api";
import {
  handleValue,
  returnProcedure,
  transformDateEndianness,
  getMinutes,
  formatArrayData,
} from "../../utils";

import debounce from "lodash.debounce";

const solicitationObjCreator = (data, nomeMedico) => {
  return {
    solicitacao: {
      idSolicitacao: data.solicitacao.idSolicitacao,
      anteciparAgendamento: data.solicitacao.anteciparAgendamento,
      usuarioSolicitante: data.solicitacao.usuarioSolicitante,
      idMedico: data.solicitacao.idMedico,
      nomeMedico: nomeMedico,
      dataSolicitacao: data.solicitacao.dataSolicitacao,
      status: data.solicitacao.status,
      statusAnamnese: data.solicitacao.statusAnamnese,
      statusRelatorio: data.solicitacao.statusRelatorio,
      SyncWithDynamo: data.solicitacao.SyncWithDynamo,
    },
  };
};

const patientObjCreator = (data) => {
  const { ddi, ddd, phone } = data.paciente.telefone;
  return {
    paciente: {
      idPaciente: data.paciente.idPaciente,
      novoPaciente: data.paciente.novoPaciente,
      tipoDocumento:
        data.paciente.tipoDocumento === "Nome" && data.paciente.novoPaciente
          ? data.paciente.novoPacienteDocumento
          : data.paciente.tipoDocumento,
      idSolicitacao: data.solicitacao.idSolicitacao,
      cpf: data.paciente.cpf,
      rne: data.paciente.rne,
      passaporte: data.paciente.passaporte,
      nome: data.paciente.nome,
      dataNascimento: transformDateEndianness(data.paciente.dataNascimento),
      nacionalidade: data.paciente.nacionalidade,
      cdNacionalidade: data.paciente.cdNacionalidade,
      sexo: data.paciente.sexo === "Feminino" ? "F" : "M",
      telefone: `${ddi} ${ddd}${phone}`,
      email: data.paciente.email,
      convenio: data.paciente.convenio,
      categoria: data.paciente.categoria,
      plano: data.paciente.plano,
      carteirinha: data.paciente.carteirinha,
      cidPrincipal: data.paciente.cidPrincipal,
      indicacaoClinica: data.paciente.indicacaoClinica,
      tempoDoenca: data.paciente.tempoDoenca,
      necessidadesEspeciais:
        data.paciente.necessidadesEspeciais === "---"
          ? ""
          : data.paciente.necessidadesEspeciais,
      nrSequenciaNecessidade: data.paciente.nrSequenciaNecessidade || null,
      peso: data.paciente.peso === "---" ? "" : data.paciente.peso,
      prontuario: data.paciente.prontuario ? data.paciente.prontuario : 0,
      cdConvenio: data.paciente.cdConvenio,
      obrigaConvenio: data.paciente.obrigaConvenio,
      cdPlano: data.paciente.cdPlano,
      cdCategoria: data.paciente.cdCategoria,
      ufCobranca: data.paciente.ufCobranca,
      estadoCobranca: data.paciente.estadoCobranca,
      cdCidPrincipal: data.paciente.cdCidPrincipal,
      codPessoaFisica: data.paciente.codPessoaFisica,
      cep: data.paciente.cep,
      endereco: data.paciente.endereco,
      cidade: data.paciente.cidade,
      numero: data.paciente.numero,
      uf: data.paciente.uf,
      estado: data.paciente.estado,
      complemento: data.paciente.complemento,
      bairro: data.paciente.bairro,
      telefoneExtra: data.paciente.telefoneExtra,
      nomeResponsavel: data.paciente.nomeResponsavel,
      documentoResponsavel: data.paciente.documentoResponsavel,
      internacaoVespera: data.paciente.internacaoVespera,
    },
  };
};

const procedureObjCreator = (data, isSecretary) => {
  const proceduresData = data.procedimento;
  const idSolicitacao = data.solicitacao.idSolicitacao;

  const baseObj = () => ({
    procedimento: {
      idProcedimento: data.procedimento.idProcedimento || null,
      cobranca: data.procedimento.cobranca,
      local: data.procedimento.local,
      cdEstabelecimento: data.procedimento.cdEstabelecimento,
    },
  });

  const fullObj = () => ({
    procedimento: {
      isSecretary: isSecretary,
      idProcedimento: proceduresData.idProcedimento,
      ieLado: !proceduresData.ieLado ? "N" : proceduresData.ieLado,
      idSolicitacao: idSolicitacao,
      procedimentoPrincipal:
        proceduresData?.procedimentoPrincipal?.nomeProcedimento ||
        returnProcedure(proceduresData),
      cdProcedimentoPrincipal: proceduresData.cdProcedimentoPrincipal,
      ieOrigemProcedimentoPrincipal:
        proceduresData.procedimentoPrincipal.ieOrigemProcedimento,
      idSequencia:
        proceduresData.procedimentoPrincipal.idSequencia ||
        proceduresData.idSequencia,
      lateralidade: proceduresData.lateralidade,
      regimeHospitalar: proceduresData.regimeHospitalar,
      carater: proceduresData.carater,
      local: proceduresData.local,
      diarias: proceduresData.diarias ? parseInt(proceduresData.diarias) : 0,
      reservaCritica: proceduresData?.reservaCritica,
      cdReservaCritica: proceduresData?.cdReservaCritica,
      cobranca: proceduresData.cobranca === null ? "" : proceduresData.cobranca,
      tipagemSanguinea: proceduresData.tipagemSanguinea,
      reservaHemocomponentes: proceduresData.reservaHemocomponentes,
      anestesista: proceduresData.anestesista,
      internacaoVespera: proceduresData?.internacaoVespera,
      biopsiaCongelacao: handleValue(proceduresData),
      biobanco: proceduresData.biobanco,
      tomografiaEstereotaxia: proceduresData.tomografiaEstereotaxia,
      ecoTransesofagico: proceduresData.ecoTransesofagico,
      raiox: proceduresData.raiox,
      scopia: proceduresData.scopia,
      medicinaNuclear: proceduresData.medicinaNuclear,
      ultrassonografia: proceduresData.ultrassonografia,
      ultrassonografiaLaparoscopio: proceduresData.ultrassonografiaLaparoscopio,
      ressonanciaMagneticaIntraperatoria:
        proceduresData.ressonanciaMagneticaIntraperatoria,
      monitorizacaoNeurofisiologica:
        proceduresData.monitorizacaoNeurofisiologica,
      endoscopia: proceduresData.endoscopia,
      colonoscopia: proceduresData.colonoscopia,
      microscopio: proceduresData.microscopio,
      roboDaVinci: proceduresData.roboDaVinci,
      roboDaOrtopedia: proceduresData.roboDaOrtopedia,
      videolaparoscopia: proceduresData.videolaparoscopia,
      pth: proceduresData.pth,
      bancoTecidos: proceduresData.bancoTecidos,
      data: proceduresData.data,
      hora: proceduresData.hora,
      tuss: proceduresData.tuss,
      tempoCirurgia:
        typeof proceduresData?.tempoCirurgia !== "number"
          ? getMinutes(proceduresData?.tempoCirurgia)
          : proceduresData?.tempoCirurgia,
      observacoes: proceduresData.observacoes,
      procedimentosExtras:
        formatArrayData(proceduresData.procedimentosExtras) ??
        proceduresData.procedimentosExtras,
      equipe:
        proceduresData.equipeCirurgicaAdicional === "Sim"
          ? formatArrayData(proceduresData.equipe)
          : [],

      equipeResponsavel: proceduresData.equipeResponsavel,
      cdEstabelecimento: proceduresData.cdEstabelecimento,
    },
  });
  return { baseObj, fullObj };
};

const materialsObjCreator = (data) => {
  const materials = data.material;
  return {
    material: {
      idMaterial: materials?.idMaterial,
      materialOPME: formatArrayData(materials.materialOPME),
      materialInstrumentos: formatArrayData(materials.materialInstrumentos),
      materialEquipamentos: formatArrayData(materials.materialEquipamentos),
      materialMedicacao: formatArrayData(materials.materialMedicacao),
    },
  };
};

const reportosObjCreator = (data, solicitacaoInternacaoUUID) => {
  const reportsData = data.relatorio;

  return {
    relatorio: {
      idRelatorio: reportsData.idRelatorio,
      observacoes: reportsData.observacoes,
      carteirinha: reportsData.carteirinha,
      carteirinhaUUID: reportsData.carteirinhaUUID,
      documentoPaciente: reportsData.documentoPaciente,
      documentoPacienteUUID: reportsData.documentoPacienteUUID,
      laudo: reportsData.laudo,
      laudoUUID: reportsData.laudoUUID,
      termoConsentimento: reportsData.termoConsentimento,
      termoConsentimentoUUID: reportsData.termoConsentimentoUUID,
      solicitacaoInternacao: "solicitacaoInternacao.pdf",
      solicitacaoInternacaoUUID: solicitacaoInternacaoUUID,
    },
    relatorioDocumento: reportsData.relatorioDocumento.filter(({ documento, documentoUUID }) => documento && documentoUUID),
  };
};

export const createSolicitation = ({ data, nomeMedico, modelo = null }) => {
  let solicitationData = {
    solicitacao: solicitationObjCreator(data, nomeMedico).solicitacao,
    paciente: patientObjCreator(data).paciente,
    procedimento: procedureObjCreator(data).baseObj().procedimento,
  };

  if (modelo) {
    solicitationData.modelo = modelo;
  }

  return apiCreateSolicitation().put(solicitationData);
};

export const createProcedure = (data, isSecretary) => {
  return apiCreateProcedure(data.solicitacao.idSolicitacao).put(
    procedureObjCreator(data, isSecretary).fullObj().procedimento
  );
};

export const createMaterials = debounce((data) => {
  const { idSolicitacao } = data.solicitacao;
  return apiSendMaterials(idSolicitacao).put(
    materialsObjCreator(data).material
  );
}, 750);

// Função criada par o envio da tela de materiais, pois, o debounce atrasa a resposta da API, disparando erro no avanço.
export const createMaterialsNoDebounce = (data) => {
  const { idSolicitacao } = data.solicitacao;
  return apiSendMaterials(idSolicitacao).put(
    materialsObjCreator(data).material
  );
};

export const createReports = (data, uuid) => {
  return apiSolicitationReports(data.solicitacao.idSolicitacao).put(
    reportosObjCreator(data, uuid)
  );
};

export const updateSolicitation = async ({
  data,
  solicitacaoInternacaoUUID,
  isSecretary,
  usuarioSolicitante,
  nmMedico,
}) => {
  return apiUpdateSolicitation(data.solicitacao.idSolicitacao).put({
    isSecretary: isSecretary,
    solicitacao: {
      ...solicitationObjCreator(data, nmMedico).solicitacao,
      usuarioSolicitante,
    },
    paciente: patientObjCreator(data).paciente,
    procedimento: procedureObjCreator(data).fullObj().procedimento,
    material: materialsObjCreator(data).material,
    relatorio: reportosObjCreator(data, solicitacaoInternacaoUUID).relatorio,
    relatorioDocumento: reportosObjCreator(data).relatorioDocumento,
  });
};
