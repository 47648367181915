import React, { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useTimer } from "react-timer-hook";

import TimeoutModal from "../TimeoutModal";
import Alert from "../Alert";

const MINUTES = process.env.REACT_APP_INACTIVITY_TIME_MINUTES
  ? parseInt(process.env.REACT_APP_INACTIVITY_TIME_MINUTES) - 5
  : 10;

const INACTIVITY_TIME = MINUTES * 60 * 1000;

// 1001 para ser 5 minutos e 5 segundos, para aparecer como 5 minutos por mais de 1 segundo no aviso
const FIVE_MINUTES = 1001 * 60 * 5;

const IdleTimeout = () => {
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const timer = useTimer({
    expiryTimestamp: new Date(),
    autoStart: false,
    onExpire: () => {
      // @TODO: Excluir o token da store também
      // Limpa o token, o que garante  que o usuário será redirecionado
      // para tela de login caso recarregue a página
      localStorage.clear();
      setShowAlert(false);
      setShowModal(true);
    },
  });

  const { reset: resetIdleTimer } = useIdleTimer({
    crossTab: true,
    eventsThrottle: true,
    onIdle: () => {
      timer.restart(new Date(Date.now() + FIVE_MINUTES));
      setShowAlert(true);
    },
    timeout: INACTIVITY_TIME,
  });

  if (showAlert) {
    return (
      <Alert
        title="Ainda está por aqui?"
        message={
          <p>
            Se continuar inativo, sua sessão irá expirar em{" "}
            {timer.minutes >= 1
              ? `${timer.minutes} ${
                  timer.minutes < 2
                    ? `minuto e ${timer.seconds} segundos`
                    : "minutos"
                }`
              : `${timer.seconds} ${
                  timer.seconds > 1 ? "segundos" : "segundo"
                }`}
            .
            <br />
          </p>
        }
        isOpened
        linkText="Continuar no portal"
        linkAction={() => {
          timer.pause();
          resetIdleTimer();
          setShowAlert(false);
        }}
        handleClose={() => {
          timer.pause();
          resetIdleTimer();
          setShowAlert(false);
        }}
        bgColor="#FFE3C2"
      />
    );
  }

  return showModal && <TimeoutModal idle />;
};

export default IdleTimeout;
