import React, {
  useEffect,
  useState,
  useCallback,
  useReducer,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import * as S from "../style";
import { Error } from "../../Steps/style";
import {
  alerta as AlertSVG,
  erro as ErrorIcon,
  info as InfoSVG,
} from "@tempo/tempo-assets/dist";
import Reload from "../../../../components/ReloadHelper";
import {
  TmpDivider,
  TmpRadio,
  TmpIcon,
  TmpInput,
  TmpSelect,
  TmpLoading,
} from "@tempo/tempo-design-system-core/dist";
import {
  TmpSelectCC,
  TmpLinkAndIconCC,
  TmpInputCC,
  TmpPhoneInput,
} from "@tempo/tempo-design-system-centro-cirurgico";
import {
  apiCEP,
  apiCID,
  apiSelectedState,
  apiHealthInsurance,
  apiPatient,
  apiPlanCategory,
  apiSpecialNeeds,
  apiCategory,
  apiNationalities,
  apiEstablishment,
  apiGetHealthcareByPatient,
  apiPattientSolicitations,
  apiRequiredCid,
} from "../../../../services/api";
import {
  setFreeFields,
  setIsNewPatient,
  setInfoPatient,
  setTimeNeedErro,
  setBasicInfoError,
  setRequiredLaudo,
} from "../../../../features/settings/settingsSlice";
import Autocomplete from "../../../../components/Autocomplete";
import { AutocompleteRow } from "../style";
import masks from "../../../../utils/Masks";
import {
  parseToSelectLabel,
  parseBRDateToUS,
  getSelectObjValue,
  searchListWithPriorityOrder,
  removeSpecialCharacters,
} from "../../../../utils";
import debounce from "lodash.debounce";
import { fields } from "../Procedure/comboboxes";
import {
  validationEmail,
  validationFormatDate,
} from "../../../../utils/Validation";
import Validation from "../../../../utils/Validation";

const newPatientInitialState = {
  type: "CPF",
  name: "cpf",
  mask: "CPF",
  inputLength: 14,
  alreadyExists: false,
};

const newPatientByNameReducer = (state, action) => {
  switch (action.type) {
    case "set-type":
      if (action.payload === "CPF") {
        return { ...state, ...newPatientInitialState };
      }

      if (action.payload === "RNE/CRNM") {
        return {
          ...state,
          type: action.payload,
          name: "rne",
          mask: "passaporte",
          inputLength: 25,
        };
      }

      return {
        ...state,
        type: action.payload,
        name: "passaporte",
        mask: "passaporte",
        inputLength: 25,
      };
    case "set-patient-exists":
      return { ...state, alreadyExists: action.payload };
    default:
      return state;
  }
};

let lastDocumentCheck = {
  type: "",
  searchLength: 0,
};

const Basicinfo = ({
  data,
  dataChange,
  dataChangeObj,
  domains,
  dataProc,
  dataProcChange,
  openPlanInfoDrawer,
  basicInfoInitialState,
  useLoadingFields,
  loadingFields,
  navigation,
  populateFormData,
  isAditionalTeam,
  onClickFilledFieldByMainTeam,
  idMedico,
  setPatientSolicitations,
  editingSolicitation,
}) => {
  const { isNewPatient, freeFields, error, editingModel, invalidPhone } =
    useSelector((state) => state.settings);
  const solicitation = useSelector((state) => state.solicitation);
  const [loading, setLoading] = useState(false);
  const [timeNeedErro, setTimeNeedError] = useState(false);
  const model = useSelector((state) => state.model);
  const [fieldsOptions, setFieldsOptions] = useState({
    category: [],
    healthInsurance: [],
    healthPlan: [],
    nacionalities: [],
    federativeUnits: [],
  });
  const [cidSugestion, setCidSugestion] = useState([]);
  const [patientOpts, setPatientOpts] = useState([]);
  const [specialNeeds, setSpecialNeeds] = useState([]);
  const [register, setRegister] = useState(true);
  const [load, setLoad] = useState(false);
  const [erroLoad, setErrorLoad] = useState(false);
  const [invalidDoc, setInvalidDoc] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [reload, setReload] = useState(false);
  const [hasSelectedCid, setHasSelectedCid] = useState(false);
  const [hasSelectedHealth, setHasSelectedHealth] = useState(false);
  const [hasSelectedCategory, setHasSelectedCategory] = useState(false);
  const [hasSelectedPlan, setHasSelectedPlan] = useState(false);
  const [showHealthcare, setShowHealthcare] = useState(false);
  const [establishment, setEstablishment] = useState([]);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [loadHealthInsurance, setLoadHealthInsurance] = useState(false);
  const [isFieldBlurred, setIsFieldBlurred] = useState({
    email: false,
    dataNascimento: false,
    tempoDoenca: false,
  });
  const [listSearchError, setListSearchError] = useState([]);
  const [fieldsGeting, setFieldsGeting] = useState({
    category: false,
    healthInsurance: false,
    healthPlan: false,
    local: false,
    nacionalities: false,
    specialNeeds: false,
  });

  const [newPatientByName, dispatchNewPatientByName] = useReducer(
    newPatientByNameReducer,
    newPatientInitialState
  );

  const phonePopulated = useRef(false);
  let firstFetchHealthcare = useRef(true).current;

  const validatingState =
    dataProc?.local === "HSL - Unidade Brasilia IV"
      ? "Distrito Federal"
      : dataProc?.local !== "" && "São Paulo";

  const returnNac = () => {
    if (isNewPatient) {
      const listaNacionalidades = fieldsOptions?.nacionalities;
      const indiceBrasileira = listaNacionalidades.findIndex(
        (item) => item.desc_nacionalidade === "Brasileira"
      );
      const objetoBrasileira = listaNacionalidades.splice(
        indiceBrasileira,
        1
      )[0];
      listaNacionalidades.unshift(objetoBrasileira);
      return;
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    if (data.tipoDocumento === "Nome") {
      if (data?.nome === "") {
        setRegister(true);
        dispatch(setIsNewPatient(false));
        dispatch(setFreeFields(false));
      }
    }
  }, [register, data?.nome, data?.tipoDocumento]);

  useEffect(() => {
    useFieldsGeting("specialNeeds", true);
    useLoadingFields("specialNeeds", true);
    apiSpecialNeeds()
      .getList()
      .then((res) => {
        if (res.length) {
          let specialNeedsArray = [];
          res?.forEach((specialNeed) => {
            specialNeedsArray.push({
              label: specialNeed.ds_necessidade_especial,
              code: specialNeed.nr_sequencia,
            });
          });
          setSpecialNeeds(specialNeedsArray);
          useFieldsGeting("specialNeeds", false);
          useLoadingFields("specialNeeds", false);
        }
      })
      .catch((err) => {
        console.log(err);
        useFieldsGeting("specialNeeds", false);
        useLoadingFields("specialNeeds", false);
        dispatch(setBasicInfoError(true));
      });
  }, []);

  useEffect(() => {
    if (!editingModel) {
      const date = new Date();
      const birthDate = new Date(parseBRDateToUS(data.dataNascimento));
      const timeNeed = new Date(parseBRDateToUS(data.tempoDoenca));

      if (data?.tempoDoenca?.length === 10) {
        if (birthDate > timeNeed || timeNeed > date) {
          setTimeNeedError(true);
          dispatch(setTimeNeedErro(true));
        } else {
          setTimeNeedError(false);
          dispatch(setTimeNeedErro(false));
        }
      } else {
        dispatch(setTimeNeedErro(false));
        setTimeNeedError(false);
      }
    }
  }, [data.tempoDoenca]);

  useEffect(() => {
    if (!!data.cdConvenio && !!data.cdPlano && !!data.cdCategoria) {
      const isUf = validatingState === "Distrito Federal" ? "DF" : "SP";

      try {
        useFieldsGeting("local", true);
        apiEstablishment({
          convenio: data.cdConvenio,
          plano: data.cdPlano,
          categoria: data.cdCategoria,
          uf: data.ufCobranca || isUf,
        })
          .getList()
          .then((res) => {
            if (Array.isArray(res)) {
              setEstablishment(res);
              useFieldsGeting("local", false);
            }
          });
      } catch (err) {
        useFieldsGeting("local", false);
      }
    }
  }, [data.estadoCobranca, data.cdConvenio, data.cdPlano, data.cdCategoria]);

  const updateData = (id, value) => {
    dataProcChange({
      target: {
        name: id,
        value,
      },
    });
  };

  useEffect(() => {
    if (
      specialNeeds.length > 0 &&
      !specialNeeds.some(
        (specialNeed) => specialNeed.label === data?.necessidadesEspeciais
      )
    ) {
      populateFormData({
        target: {
          name: "necessidadesEspeciais",
          value: "Nada a declarar",
        },
      });
    }
    if (domains.length > 0 && !domains.peso.includes(data?.peso)) {
      populateFormData({
        target: {
          name: "peso",
          value: "Nada a declarar",
        },
      });
    }
  }, [domains, specialNeeds]);

  const checkIfPatientAlreadyExists = async (type, value) => {
    const valueLength = value.length;

    if (type === "cpf" && valueLength !== 11) {
      lastDocumentCheck = { type, searchLength: valueLength };

      return false;
    }

    // Evitar requests com mesmos valores em sequência
    if (
      lastDocumentCheck.type === type &&
      lastDocumentCheck.searchLength === valueLength
    ) {
      return newPatientByName.alreadyExists;
    }

    lastDocumentCheck = { type, searchLength: valueLength };

    setLoading(true);
    try {
      let res = await apiPatient(`${type}=${value}`).getList();

      if (res?.cd_paciente !== undefined) {
        return true;
      }

      return false;
    } catch (err) {
      setErrorLoad(true);
    } finally {
      setLoading(false);
    }
    return false;
  };

  const getPatientByDocument = async (searchType, doc) => {
    setLoading(true);
    setLoad(true);
    setErrorLoad(false);
    dispatch(setIsNewPatient(false));
    dispatch(setFreeFields(false));
    setPatientOpts([]);

    let type =
      searchType === "cpf"
        ? "cpf"
        : searchType === "rne"
        ? "rne"
        : "passaporte";

    if (searchType !== "nome") {
      const docCleaned = removeSpecialCharacters(doc);
      try {
        const res = await apiPatient(`${type}=${docCleaned}`).getList();

        if (res?.cd_paciente) {
          setInvalidDoc(false);
          dispatch(
            setInfoPatient({
              email: res?.email !== null ? res?.email : "",
              telefone: {
                phone: res?.telefone,
                ddi: res?.ddi,
                ddd: res?.ddd,
              },
            })
          );

          let healthcareInfo;

          if (res?.codPessoaFisica) {
            setLoadingScreen(true);
            healthcareInfo = await apiGetHealthcareByPatient(
              res.codPessoaFisica
            ).getList();
          }
          let patientValue = {
            nome: res?.nome !== null ? res?.nome : "",
            cpf:
              res?.cpf === "" || res?.cpf === null
                ? ""
                : masks["CPF"](res?.cpf),
            email: res?.email !== null ? res?.email : "",
            idade: res?.idade !== null ? res?.idade : "",
            sexo:
              res?.sexo !== null
                ? res?.sexo.toLowerCase() === "f"
                  ? "Feminino"
                  : "Masculino"
                : "",
            telefone: {
              phone: res?.telefone,
              ddi: res?.ddi,
              ddd: res?.ddd,
            },
            dataNascimento:
              res?.dataNascimento !== null ? res?.dataNascimento : "",
            prontuario: res?.prontuario !== null ? res?.prontuario : "",
            codPessoaFisica:
              res?.codPessoaFisica !== null ? res?.codPessoaFisica : "",
          };
          if (healthcareInfo?.cdConvenio) {
            patientValue = {
              ...patientValue,
              ...healthcareInfo,
              carteirinha: healthcareInfo.cdUsuarioConvenio,
            };
          }
          dataChangeObj({
            target: {
              name: "paciente",
              value: patientValue,
            },
          });
          setRegister(false);
          await handleSearchPacientSolicitations(patientValue, async () => {
            dataChange({
              target: {
                name: "nome",
                value: patientValue.nome,
              },
            });
            setLoadingScreen(true);
            setLoading(false);
            setLoad(false);
            dispatch(setFreeFields(true));
            dispatch(setIsNewPatient(false));
          });
        } else {
          if (type === "cpf" && !Validation.cpf(doc)) {
            setInvalidDoc(true);
            setLoading(false);
            setLoad(false);
          } else {
            dispatch(setFreeFields(false));
            dispatch(setIsNewPatient(true));
            setLoading(false);
            setLoad(false);
          }
        }
      } catch (err) {
        setErrorLoad(true);
      } finally {
        setLoading(false);
        setLoad(false);
      }
    } else
      try {
        const docCleaned = removeSpecialCharacters(doc);
        const res = await apiPatient(`nome=${docCleaned}`).getList();

        const patientOptions = res?.filter((patient) =>
          patient?.nome?.toLowerCase().includes(docCleaned?.toLowerCase())
        );

        setLoading(true);
        setPatientOpts(patientOptions);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
        setLoad(false);
      }
  };

  const handleSearchPacientSolicitations = async (
    patientData,
    ifPatientHasNotSolicitationCallback
  ) => {
    try {
      setLoadingScreen(true);

      const patientSolicitations = await searchIfPattientHasProcedures(
        new URLSearchParams({
          codPessoaFisica: patientData.codPessoaFisica,
          cpf: patientData.cpf ?? "",
          rne: patientData.rne ?? "",
          passaporte: patientData.passaporte ?? "",
          nome: patientData?.nome ?? "",
          dataNascimento: patientData?.dataNascimento ?? "",
        }).toString()
      );

      if (patientSolicitations?.length) {
        setPatientSolicitations(patientSolicitations);
      } else {
        await ifPatientHasNotSolicitationCallback();
      }
    } finally {
      setLoadingScreen(false);
    }
  };

  const searchIfPattientHasProcedures = async (params) => {
    return await apiPattientSolicitations(idMedico, params).getList();
  };

  const setPatientbasicInfos = async (selectedPatient) => {
    dispatch(
      setInfoPatient({
        email: selectedPatient?.email !== null ? selectedPatient?.email : "",
        telefone: {
          phone: selectedPatient?.telefone,
          ddi: selectedPatient?.ddi,
          ddd: selectedPatient?.ddd,
        },
      })
    );

    let healthcareInfo;

    if (selectedPatient?.codPessoaFisica) {
      setLoadingScreen(true);
      healthcareInfo = await apiGetHealthcareByPatient(
        selectedPatient.codPessoaFisica
      ).getList();
    }

    let patientInfo = {
      ...data,
      nome: selectedPatient?.nome !== null ? selectedPatient?.nome : "",
      cpf:
        selectedPatient?.cpf === "" || selectedPatient?.cpf === null
          ? ""
          : masks["CPF"](selectedPatient?.cpf),
      email: selectedPatient?.email !== null ? selectedPatient?.email : "",
      idade: selectedPatient?.idade !== null ? selectedPatient?.idade : "",
      sexo:
        selectedPatient?.sexo !== null
          ? selectedPatient?.sexo.toLowerCase() === "f"
            ? "Feminino"
            : "Masculino"
          : "",
      telefone: {
        phone: selectedPatient?.telefone,
        ddi: selectedPatient?.ddi,
        ddd: selectedPatient?.ddd,
      },
      dataNascimento:
        selectedPatient?.dataNascimento !== null
          ? selectedPatient?.dataNascimento
          : "",
      prontuario:
        selectedPatient?.prontuario !== null ? selectedPatient?.prontuario : "",
      codPessoaFisica:
        selectedPatient?.codPessoaFisica !== null
          ? selectedPatient?.codPessoaFisica
          : "",
    };

    if (healthcareInfo?.cdConvenio) {
      patientInfo = {
        ...patientInfo,
        ...healthcareInfo,
        carteirinha: healthcareInfo.cdUsuarioConvenio,
      };
    }

    dataChangeObj({ target: { name: "paciente", value: patientInfo } });
    setLoadingScreen(false);
    setLoading(false);
    dispatch(setIsNewPatient(false));
    dispatch(setFreeFields(true));
  };

  useEffect(() => {
    if (cidSugestion) setLoading(false);
  }, [cidSugestion]);

  const getCid = (cid) => {
    if (typeof cid === "string" && cid !== "") {
      const cidCleaned = removeSpecialCharacters(cid);
      apiCID(cidCleaned)
        .getList()
        .then((res) => {
          setListSearchError((prevState) =>
            prevState.filter((i) => i !== "cid")
          );
          setCidSugestion(res);
        })
        .catch((err) => {
          console.log(err);
          setListSearchError((prevState) => [...prevState, "cid"]);
          setCidSugestion([]);
          setLoading(false);
        });
    }
  };

  const getCidDebounce = useCallback(
    debounce((str) => getCid(str), 1000),
    []
  );

  const useFieldsGeting = (key, value) => {
    setFieldsGeting((prevState) => ({ ...prevState, [key]: value }));
  };

  const getSelectState = () => {
    setReload(false);
    useFieldsGeting("federativeUnits", true);
    useLoadingFields("federativeUnits", true);

    apiSelectedState()
      .getList()
      .then((res) => {
        if (res) {
          const rename = res.map((item) => {
            return {
              ...item,
              label: item.estado,
            };
          });
          setFieldsOptions((prevState) => ({
            ...prevState,
            federativeUnits: rename,
          }));
        }
        useFieldsGeting("federativeUnits", false);
        useLoadingFields("federativeUnits", false);
      })
      .catch((err) => {
        console.log(err);
        useFieldsGeting("federativeUnits", false);
        useLoadingFields("federativeUnits", false);
      });
  };

  const getHealthInsurance = () => {
    setReload(false);
    useFieldsGeting("healthInsurance", true);
    useLoadingFields("healthInsurance", true);
    setListSearchError((prevState) =>
      prevState.filter((i) => i !== "categoria")
    );
    setListSearchError((prevState) => prevState.filter((i) => i !== "plano"));
    apiHealthInsurance()
      .getList()
      .then((res) => {
        if (res?.mais_usados && res?.outros) {
          setFieldsOptions((prevState) => ({
            ...prevState,
            healthInsurance: {
              mostUsed: res.mais_usados.map((item) =>
                item.ds_convenio === "INSTITUCIONAL"
                  ? { ...item, ds_convenio: "Institucional" }
                  : item
              ),
              others: [
                ...res.mais_usados.map((item) =>
                  item.ds_convenio === "INSTITUCIONAL"
                    ? { ...item, ds_convenio: "Institucional" }
                    : item
                ),
                ...res.outros.map((item) =>
                  item.ds_convenio === "INSTITUCIONAL"
                    ? { ...item, ds_convenio: "Institucional" }
                    : item
                ),
              ],
            },
          }));
        }
        useFieldsGeting("healthInsurance", false);
        useLoadingFields("healthInsurance", false);
        setLoadHealthInsurance(false);
      })
      .catch((err) => {
        console.log(err);
        setListSearchError((prevState) => [...prevState, "convenio"]);
        setReload(true);
        useFieldsGeting("healthInsurance", false);
        useLoadingFields("healthInsurance", false);
        dispatch(setBasicInfoError(true));
      });
  };

  const getCategory = (convenio, populate = false) => {
    setReload(false);
    setListSearchError((prevState) =>
      prevState.filter((i) => i !== "categoria")
    );
    useFieldsGeting("category", true);
    setFieldsOptions((prevState) => ({ ...prevState, category: [] }));
    apiCategory(convenio)
      .getList()
      .then((res) => {
        const categoriaTitular = [
          {
            cdCategoria: data?.cdCategoriaTitular,
            ds_categoria: data?.categoriaTitular,
          },
        ];

        setFieldsOptions((prevState) => ({
          ...prevState,
          category: isAditionalTeam ? categoriaTitular : res,
        }));
        const AMOUNT_HEALTH_CATEGORY = 1;
        if (res.length === AMOUNT_HEALTH_CATEGORY) {
          setLoadHealthInsurance(true);
          if (populate) {
            populateFormData(
              {
                target: {
                  name: "cdCategoria",
                  value: res[0]?.cdCategoria,
                },
              },
              "paciente"
            );
            populateFormData(
              {
                target: { name: "categoria", value: res[0]?.ds_categoria },
              },
              "paciente"
            );
          } else {
            dataChange({
              target: {
                name: "cdCategoria",
                value: res[0]?.cdCategoria,
              },
            });
            dataChange({
              target: {
                name: "categoria",
                value: res[0]?.ds_categoria,
              },
            });
          }
          setHasSelectedCategory(false);
          getCategoryPlan(convenio, res[0]?.cdCategoria, firstFetchHealthcare);
        }
        useFieldsGeting("category", false);
      })
      .catch((err) => {
        console.log(err);
        setListSearchError((prevState) => [...prevState, "categoria"]);
        setReload(true);
        useFieldsGeting("category", false);
      });
  };
  const getCategoryPlan = (convenio, categoria, populate = false) => {
    setFieldsOptions((prevState) => ({ ...prevState, healthPlan: [] }));
    useFieldsGeting("healthPlan", true);
    setListSearchError((prevState) => prevState.filter((i) => i !== "plano"));
    apiPlanCategory({ convenio: convenio, categoria: categoria })
      .getList()
      .then((res) => {
        const AMOUNT_HEALTH_PLAN = 1;
        if (res.length === AMOUNT_HEALTH_PLAN) {
          if (populate) {
            populateFormData(
              {
                target: {
                  name: "cdPlano",
                  value: res[0]?.cdPlano,
                },
              },
              "paciente"
            );
            populateFormData(
              {
                target: {
                  name: "plano",
                  value: res[0]?.ds_plano,
                },
              },
              "paciente"
            );
          } else {
            dataChange({
              target: {
                name: "cdPlano",
                value: res[0]?.cdPlano,
              },
            });
            handleSelectChange(res[0]?.ds_plano, "plano");
          }
          clearSelectLocal();
          setHasSelectedPlan(false);
        } else if (data.cdCategoria === "14") {
          const resultFiltered = res.filter((item) => item.cdPlano === "1032");
          populateFormData({
            target: {
              name: "cdPlano",
              value: resultFiltered[0].cdPlano,
            },
          });
          populateFormData({
            target: {
              name: "plano",
              value: resultFiltered[0].ds_plano,
            },
          });
        }

        setLoadHealthInsurance(false);

        const planoTitular = [
          {
            ds_plano: data?.planoTitular,
            ds_categoria: data?.categoriaTitular,
            cdPlano: data?.cdPlanoTitular,
            cdCategoria: data?.cdCategoriaTitular,
          },
        ];

        setFieldsOptions((prevState) => ({
          ...prevState,
          healthPlan: isAditionalTeam ? planoTitular : res,
        }));
        useFieldsGeting("healthPlan", false);
      })
      .catch((err) => {
        console.log(err);
        useFieldsGeting("healthPlan", false);
        setListSearchError((prevState) => [...prevState, "plano"]);
        setReload(true);
      });
  };

  const getNationalities = () => {
    try {
      useFieldsGeting("nacionalities", true);
      useLoadingFields("nacionalities", true);
      apiNationalities()
        .getList()
        .then((res) => {
          if (Array.isArray(res)) {
            setFieldsOptions((prevState) => ({
              ...prevState,
              nacionalities: res,
            }));
            useFieldsGeting("nacionalities", false);
            useLoadingFields("nacionalities", false);
          }
        });
    } catch (err) {
      useFieldsGeting("nacionalities", false);
      useLoadingFields("nacionalities", false);
      dispatch(setBasicInfoError(true));
    }
  };

  useEffect(() => {
    if (!editingModel) {
      getSelectState();
      getNationalities();
    }
  }, []);

  useEffect(() => {
    getHealthInsurance();
  }, [data?.estadoCobranca]);

  useEffect(() => {
    if (data?.cdConvenio) {
      setFieldsOptions((prevState) => ({
        ...prevState,
        healthPlan: [],
        category: [],
      }));

      getCategory(data?.cdConvenio, firstFetchHealthcare);
    }
  }, [data?.cdConvenio]);

  useEffect(() => {
    if (data?.cdConvenio !== "" && data?.cdCategoria !== "") {
      getCategoryPlan(
        data?.cdConvenio,
        data?.cdCategoria,
        firstFetchHealthcare
      );
    }
  }, [data?.categoria]);

  const getPatientDebounce = useCallback(
    debounce(async (str) => await getPatientByDocument("nome", str), 1000),
    []
  );

  const renderDignosticDateInput = () => {
    return (
      <S.StyledContent>
        <TmpInput
          autoComplete="off"
          width="auto"
          flex={1}
          name="tempoDoenca"
          value={data?.tempoDoenca}
          error={isErrorTD && !isDisabled()}
          type="text"
          mask="date"
          label="Data do diagnóstico da doença *"
          maxLength={10}
          placeholder="dd/mm/aaaa"
          disabled={isDisabled()}
          helperText={!isDisabled() ? helperTextTD : null}
          helperIcon={!isDisabled() ? helperIconTD : null}
          onChange={dataChange}
          onBlur={() => handleBlurTD(data?.tempoDoenca)}
        />
      </S.StyledContent>
    );
  };

  const cleanUser = (props) => {
    dispatch(setFreeFields(false));
    setInvalidDoc(false);
    setIsFieldBlurred({ email: false });

    dataChangeObj({
      target: {
        name: "paciente",
        value: {
          ...basicInfoInitialState,
          ...model.paciente,
          [props.field]: props?.mask
            ? masks[props.mask](props.value)
            : props.value,
          tipoDocumento:
            props.field === "tipoDocumento" ? props.value : data?.tipoDocumento,
        },
      },
    });
  };

  const renderInput = () => {
    function isInputDisabled(tipoDocumento, value) {
      if (isAditionalTeam) {
        return true;
      }

      return !editingModel &&
        data?.tipoDocumento === tipoDocumento &&
        solicitation.solicitacao.idSolicitacao === null
        ? false
        : disablePatientRegistred(value);
    }

    let lastValue = null;
    if (data?.tipoDocumento === "CPF") {
      return (
        <TmpInput
          autoComplete="off"
          width="auto"
          label="N° do Documento"
          name="cpf"
          type="loader"
          loading={load}
          data-testid="input_id"
          data-style="input_bottom"
          value={masks["CPF"](data?.cpf)}
          error={erroLoad || invalidDoc ? true : data?.cpf === "" && error}
          placeholder={"000.000.000-00"}
          helperIcon={!invalidDoc && renderPatientDoesNotExist("icon")}
          helperText={
            invalidDoc
              ? renderPatientDoesNotExist(
                  "invalidDoc",
                  "CPF inválido. Verifique cadastro."
                )
              : erroLoad
              ? renderPatientDoesNotExist("error")
              : renderPatientDoesNotExist("text")
          }
          onChange={async (e) => {
            const currentValue = e.target.value;
            if (currentValue !== lastValue) {
              lastValue = currentValue;
              setInvalidDoc(false);

              if (lastValue.length === 14 && currentValue.length < 14) {
                setPatientbasicInfos({});
              }

              cleanUser({
                field: "cpf",
                value: currentValue.trim(),
                mask: "CPF",
              });

              if (currentValue.length === 14) {
                await getPatientByCPF(currentValue.trim());
              }
            }
          }}
          onClick={isAditionalTeam ? onClickFilledFieldByMainTeam : undefined}
          disabled={isInputDisabled("CPF", data?.cpf)}
          maxLength={14}
        />
      );
    } else if (data?.tipoDocumento === "RNE/CRNM") {
      return (
        <TmpInput
          autoComplete="off"
          width="auto"
          label="N° do Documento"
          name="rne"
          type="loader"
          loading={load}
          data-testid="input_id"
          data-style="input_bottom"
          value={masks["passaporte"](data?.rne)}
          error={erroLoad ? true : data?.rne === "" && error}
          placeholder={"Insira o N° do Documento"}
          helperIcon={renderPatientDoesNotExist("icon")}
          helperText={
            erroLoad
              ? renderPatientDoesNotExist("error")
              : renderPatientDoesNotExist("text")
          }
          onKeyUp={(e) => {
            if (e.key === "Delete" || e.key === "Backspace") {
              cleanUser({
                field: "rne",
                value: e.target.value.trim(),
              });
            }
          }}
          onChange={async (e) => {
            cleanUser({
              field: "rne",
              value: e.target.value.trim(),
            });
            await getPatientByRNE(e.target.value.trim());
          }}
          onClick={isAditionalTeam ? onClickFilledFieldByMainTeam : undefined}
          disabled={isInputDisabled("RNE/CRNM", data?.rne)}
          maxLength={25}
        />
      );
    } else if (data?.tipoDocumento === "Passaporte") {
      return (
        <TmpInput
          autoComplete="off"
          width="auto"
          label="N° do Documento"
          name="passaporte"
          type="loader"
          loading={load}
          value={masks["passaporte"](data?.passaporte)}
          error={erroLoad ? true : data?.passaporte === "" && error}
          placeholder={"Insira o N° do Documento"}
          data-testid="input_id"
          data-style="input_bottom"
          helperIcon={renderPatientDoesNotExist("icon")}
          helperText={
            erroLoad
              ? renderPatientDoesNotExist("error")
              : renderPatientDoesNotExist("text")
          }
          onChange={(e) => {
            cleanUser({
              field: "passaporte",
              value: e.target.value.trim(),
            });
            getPatientByPass(e.target.value.trim());
          }}
          onKeyUp={(e) => {
            if (e.key === "Delete" || e.key === "Backspace") {
              cleanUser({
                field: "passaporte",
                value: e.target.value.trim(),
              });
            }
          }}
          onClick={isAditionalTeam ? onClickFilledFieldByMainTeam : undefined}
          disabled={isInputDisabled("Passaporte", data?.passaporte)}
          maxLength={25}
        />
      );
    } else return null;
  };

  const getPatientByName = async (str) => {
    if (str.length > 0) {
      setLoading(true);
      setRegister(true);
      dispatch(setIsNewPatient(false));
      await getPatientDebounce(str);
    }
  };

  const getPatientByCPF = async (str) => {
    var unformattedCpf = str;
    unformattedCpf = unformattedCpf.replace(/\D/g, "");
    if (unformattedCpf?.length < 11) {
      if (data?.nome) {
        dataChangeObj({
          target: {
            name: "paciente",
            value: {
              ...data,
              cpf: str,
              cidPrincipal: data.cidPrincipal,
              indicacaoClinica: data.indicacaoClinica,
            },
          },
        });
      }
      dispatch(setFreeFields(false));
    }

    if (unformattedCpf?.length === 11) {
      setLoad(true);

      await getPatientByDocument("cpf", str.replace(/[^0-9]/g, ""));
    }
  };

  const getRneDebounce = useCallback(
    debounce(async (str) => await getPatientByDocument("rne", str), 2000),
    []
  );

  const getPatientByRNE = async (str) => {
    dataChange({
      target: {
        name: "rne",
        value: str,
      },
    });
    var unformattedRne = str;

    if (unformattedRne?.length < 5) {
      if (data?.nome)
        dataChangeObj({
          target: {
            name: "paciente",
            value: { ...data, rne: str },
            cidPrincipal: data.cidPrincipal,
            indicacaoClinica: data.indicacaoClinica,
          },
        });
      dispatch(setFreeFields(false));
    }

    if (unformattedRne?.length >= 5) {
      if (data?.nome) {
        dispatch(setIsNewPatient(false));
      } else {
        await getRneDebounce(str);
      }
    }
  };

  const getPassDebounce = useCallback(
    debounce(async (str) => await getPatientByDocument("pass", str), 2000),
    []
  );

  const getPatientByPass = async (str) => {
    dataChange({
      target: {
        name: "passaporte",
        value: str,
      },
    });
    var unformattedPassaporte = str;
    if (unformattedPassaporte?.length < 7) {
      if (data?.nome)
        dataChangeObj({
          target: {
            name: "paciente",
            value: { ...data, passaporte: masks["passaporte"](str) },
            cidPrincipal: data.cidPrincipal,
            indicacaoClinica: data.indicacaoClinica,
          },
        });
      dispatch(setFreeFields(false));
    }

    if (unformattedPassaporte?.length >= 5) {
      if (data?.nome) {
        dispatch(setIsNewPatient(false));
      } else {
        await getPassDebounce(str);
      }
    }
  };

  const updateCidSugestion = (str) => {
    getCidDebounce(str);
  };

  const isDisabled = (field = null) => {
    if (
      isAditionalTeam &&
      data?.convenio !== "Particular" &&
      [
        "estadoCobranca",
        "convenio",
        "plano",
        "categoria",
        "carteirinha",
        "local",
      ].includes(field)
    ) {
      return field !== "convenio";
    }

    if (
      field === "convenio" &&
      !validatingState &&
      data.estadoCobranca === "" &&
      !isAditionalTeam
    ) {
      return true;
    }

    if (newPatientByName.alreadyExists) {
      return true;
    }

    if (solicitation.solicitacao.idSolicitacao !== null) {
      return false;
    }

    return !freeFields;
  };

  const disablePatientRegistred = (fieldValue) => {
    if (newPatientByName.alreadyExists) {
      return true;
    }
    if (solicitation.solicitacao.idSolicitacao === null && isNewPatient)
      return !freeFields;
    if (
      fieldValue === "" &&
      freeFields &&
      solicitation.solicitacao.idSolicitacao === null
    ) {
      return false;
    } else return true;
  };

  const handleCid = (value) => {
    setHasSelectedCid(true);
    setLoading(true);
    dataChange({
      target: {
        name: "cidPrincipal",
        value,
      },
    });
    dataChange({
      target: {
        name: "cdCidPrincipal",
        value: "",
      },
    });
    updateCidSugestion(value);
  };

  const clearSelectLocal = () => {
    if (isAditionalTeam) {
      populateFormData({
        target: {
          name: "cobranca",
          value: "",
        },
      });
    }
  };

  const handleState = (value) => {
    firstFetchHealthcare = false;
    dataChange({
      target: {
        name: "estadoCobranca",
        value: value,
      },
    });
    dataChange({
      target: {
        name: "ufCobranca",
        value: value === "Distrito Federal" ? "DF" : "SP",
      },
    });
    if (!data?.cdUsuarioConvenio) {
      dataChange({
        target: {
          name: "convenio",
          value: "",
        },
      });
      dataChange({
        target: {
          name: "cdConvenio",
          value: "",
        },
      });
      dataChange({
        target: {
          name: "obrigaConvenio",
          value: "",
        },
      });
      dataChange({
        target: {
          name: "plano",
          value: "",
        },
      });
      dataChange({
        target: {
          name: "categoria",
          value: "",
        },
      });
      dataChange({
        target: {
          name: "carteirinha",
          value: "",
        },
      });
    }
    dataProcChange({
      target: {
        name: "local",
        value: "",
      },
    });

    if (!isAditionalTeam) {
      dataProcChange({
        target: {
          name: "local",
          value: "",
        },
      });
    }
    clearSelectLocal();
  };

  const handleHealth = (value) => {
    setHasSelectedHealth(true);
    firstFetchHealthcare = false;
    dataChange({
      target: {
        name: "convenio",
        value,
      },
    });
    dataChange({
      target: {
        name: "cdConvenio",
        value: "",
      },
    });
    dataChange({
      target: {
        name: "obrigaConvenio",
        value: "",
      },
    });
    dataChange({
      target: {
        name: "plano",
        value: "",
      },
    });
    dataChange({
      target: {
        name: "categoria",
        value: "",
      },
    });
    dataChange({
      target: {
        name: "carteirinha",
        value: "",
      },
    });
    dataChange({
      target: {
        name: "cdCategoria",
        value: "",
      },
    });
    if (!isAditionalTeam) {
      dataProcChange({
        target: {
          name: "local",
          value: "",
        },
      });
    }
    clearSelectLocal();
  };

  const handleCategory = (value) => {
    setHasSelectedCategory(true);
    dataChange({
      target: {
        name: "categoria",
        value,
      },
    });
    dataChange({
      target: {
        name: "cdCategoria",
        value: "",
      },
    });
    dataChange({
      target: {
        name: "plano",
        value: "",
      },
    });
    dataChange({
      target: {
        name: "cdPlano",
        value: "",
      },
    });
    dataChange({
      target: {
        name: "carteirinha",
        value: "",
      },
    });
    if (!isAditionalTeam) {
      dataProcChange({
        target: {
          name: "local",
          value: "",
        },
      });
    }
    clearSelectLocal();
  };

  const handlePlan = (value) => {
    setHasSelectedPlan(true);
    dataChange({
      target: {
        name: "plano",
        value,
      },
    });
    dataChange({
      target: {
        name: "cdPlano",
        value: "",
      },
    });
    dataChange({
      target: {
        name: "carteirinha",
        value: "",
      },
    });
    clearSelectLocal();
  };

  const handleSelectChange = (e, prop) => {
    dataChange({
      target: {
        name: prop,
        value: e,
      },
    });
  };

  useEffect(() => {
    if (solicitation.solicitacao.idSolicitacao === null) {
      populateFormData(
        {
          target: {
            name: "cpf",
            value: "",
          },
        },
        "paciente"
      );
      populateFormData(
        {
          target: {
            name: "rne",
            value: "",
          },
        },
        "paciente"
      );
      populateFormData(
        {
          target: {
            name: "passaporte",
            value: "",
          },
        },
        "paciente"
      );
      populateFormData(
        {
          target: {
            name: "nome",
            value: "",
          },
        },
        "paciente"
      );
      populateFormData(
        {
          target: {
            name: "dataNascimento",
            value: "",
          },
        },
        "paciente"
      );
      populateFormData(
        {
          target: {
            name: "sexo",
            value: "",
          },
        },
        "paciente"
      );
      populateFormData(
        {
          target: {
            name: "email",
            value: "",
          },
        },
        "paciente"
      );
      populateFormData(
        {
          target: {
            name: "telefone",
            value: {
              ddi: "55",
              ddd: "",
              phone: "",
            },
          },
        },
        "paciente"
      );
      dispatch(setIsNewPatient(false));
      setLoad(false);
    }
  }, [data?.tipoDocumento]);

  const handleSelectProcChange = (e, prop) => {
    dataProcChange({
      target: {
        name: prop,
        value: e,
      },
    });
  };

  const handleCreatePatient = () => {
    dispatch(setFreeFields(true));
    dispatch(setIsNewPatient(true));
    setRegister(false);
    returnNac();

    dataChange({
      target: {
        name: "novoPaciente",
        value: true,
      },
    });
  };

  const validateTempoDoenca = (tempoDoenca) => {
    return validationFormatDate(tempoDoenca);
  };

  const validateDataNascimento = (dataNascimento) => {
    return validationFormatDate(dataNascimento);
  };

  const getTempoDoencaErrorHelperData = (
    data,
    freeFields,
    error,
    isFieldBlurred
  ) => {
    let isErrorTD = false;
    let helperTextTD = null;
    let helperIconTD = null;

    if (data?.tempoDoenca === "" && error) {
      isErrorTD = true;
      helperTextTD = <Error>Campo obrigatório</Error>;
      helperIconTD = <ErrorIcon />;
    } else if (timeNeedErro) {
      isErrorTD = true;
      helperTextTD = <Error>Data inválida</Error>;
      helperIconTD = <ErrorIcon />;
    } else if (
      validateTempoDoenca(data?.tempoDoenca) &&
      isFieldBlurred.tempoDoenca
    ) {
      isErrorTD = true;
      helperTextTD = <Error>Data inválida</Error>;
      helperIconTD = <ErrorIcon />;
    }

    return { isErrorTD, helperTextTD, helperIconTD };
  };

  const getHealthInsuranceList = () => {
    if (isAditionalTeam) {
      return fieldsOptions?.healthInsurance?.others.filter(
        (a) =>
          a.ds_convenio === "Particular" ||
          a.ds_convenio === solicitation.paciente?.convenioTitular
      );
    }

    if (data?.convenio === "") {
      return fieldsOptions?.healthInsurance?.mostUsed;
    }

    return searchListWithPriorityOrder(
      fieldsOptions?.healthInsurance?.others,
      data?.convenio,
      "ds_convenio"
    );
  };

  const getDataNascimentoErrorHelperData = (
    data,
    freeFields,
    error,
    isFieldBlurred
  ) => {
    let isErrorDN = false;
    let helperTextDN = null;
    let helperIconDN = null;

    if (data?.dataNascimento === "" && error) {
      isErrorDN = true;
      helperTextDN = <Error>Campo obrigatório</Error>;
      helperIconDN = <ErrorIcon />;
    } else if (
      validateDataNascimento(data?.dataNascimento) &&
      isFieldBlurred.dataNascimento
    ) {
      isErrorDN = true;
      helperTextDN = <Error>Data inválida</Error>;
      helperIconDN = <ErrorIcon />;
    }

    return { isErrorDN, helperTextDN, helperIconDN };
  };

  const { isErrorTD, helperTextTD, helperIconTD } =
    getTempoDoencaErrorHelperData(data, freeFields, error, isFieldBlurred);

  const { isErrorDN, helperTextDN, helperIconDN } =
    getDataNascimentoErrorHelperData(data, freeFields, error, isFieldBlurred);

  const handleBlurCEP = (event) => {
    const { value } = event.target;

    try {
      apiCEP(value).then((res) => {
        const address = {
          endereco: res.logradouro,
          cidade: res.localidade,
          uf: res.uf,
          bairro: res.bairro,
          complemento: res.complemento,
        };

        Object.keys(address).forEach((item) => {
          dataChange({
            target: {
              name: item,
              value: address[item],
            },
          });
        });
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleBlurEmail = (value) => {
    setIsFieldBlurred({ email: true });
    if (value === "") {
      setIsFieldBlurred({ email: false });
    }
  };

  const handleBlurDN = (value) => {
    setIsFieldBlurred({ dataNascimento: true });
    if (value === "") {
      setIsFieldBlurred({ dataNascimento: false });
    }
    if (value.length === 10 && !validationFormatDate(value)) {
      setIsFieldBlurred({ dataNascimento: false });
    }
  };
  const handleBlurTD = (value) => {
    setIsFieldBlurred({ tempoDoenca: true });
    if (value === "") {
      setIsFieldBlurred({ tempoDoenca: false });
    }
    if (value.length === 10 && !validationFormatDate(value)) {
      setIsFieldBlurred({ tempoDoenca: false });
    }
  };

  const handleHealthInsurance = (value) => {
    firstFetchHealthcare = false;

    setHasSelectedHealth(false);
    if (value?.cdConvenio !== data.cdConvenio) {
      dataChange({
        target: {
          name: "convenio",
          value: value.ds_convenio,
        },
      });
      dataChange({
        target: {
          name: "cdConvenio",
          value: value.cdConvenio,
        },
      });
      dataChange({
        target: {
          name: "obrigaConvenio",
          value: value.obriga_convenio,
        },
      });

      dataChange({
        target: {
          name: "categoria",
          value:
            isAditionalTeam && value.ds_convenio !== "Particular"
              ? solicitation.paciente.categoriaTitular
              : "",
        },
      });

      dataChange({
        target: {
          name: "plano",
          value:
            isAditionalTeam && value.ds_convenio !== "Particular"
              ? solicitation.paciente.planoTitular
              : "",
        },
      });

      dataChange({
        target: {
          name: "carteirinha",
          value:
            isAditionalTeam && value.ds_convenio !== "Particular"
              ? solicitation.paciente.carteirinhaTitular
              : "",
        },
      });
    }
    setShowHealthcare(false);
  };

  const handleHealthCategory = (value) => {
    firstFetchHealthcare = false;

    if (value?.cdCategoria !== data.cdCategoria) {
      dataChange({
        target: {
          name: "cdCategoria",
          value: value.cdCategoria,
        },
      });
      dataChange({
        target: {
          name: "categoria",
          value: value.ds_categoria,
        },
      });
    }
    setHasSelectedCategory(false);
  };

  const renderPatientDoesNotExist = (type, customMessage) => {
    if (data?.tipoDocumento === "CPF") {
      if (!freeFields && !load && data?.cpf?.length === 14 && isNewPatient) {
        if (type === "icon") return <AlertSVG />;
        if (type === "text")
          return (
            <div>
              <b>Paciente não encontrado.</b>{" "}
              <TmpLinkAndIconCC
                data-testid="register-patient"
                handleClick={handleCreatePatient}
              >
                Fazer cadastro.
              </TmpLinkAndIconCC>
            </div>
          );
      }
    } else if (data?.tipoDocumento === "Nome") {
      if (
        data?.nome !== "" &&
        register &&
        solicitation.solicitacao.idSolicitacao === null
      ) {
        if (type === "icon") return <AlertSVG />;
        if (type === "text")
          return (
            <div style={{ fontSize: "14px" }}>
              <b>Paciente não encontrado.</b>{" "}
              <TmpLinkAndIconCC
                data-testid="register-patient"
                handleClick={handleCreatePatient}
              >
                Fazer cadastro.
              </TmpLinkAndIconCC>
            </div>
          );
      }
    } else if (data?.tipoDocumento === "RNE/CRNM") {
      if (data?.rne?.length >= 5 && isNewPatient && !freeFields) {
        if (type === "icon") return <AlertSVG />;
        if (type === "text")
          return (
            <div>
              <b>Paciente não encontrado.</b>{" "}
              <TmpLinkAndIconCC
                data-testid="register-patient"
                handleClick={handleCreatePatient}
              >
                Fazer cadastro.
              </TmpLinkAndIconCC>
            </div>
          );
      }
    } else {
      if (data?.passaporte?.length >= 5 && isNewPatient && !freeFields) {
        if (type === "icon") return <AlertSVG />;
        if (type === "text")
          return (
            <div className="box-render">
              <b>Paciente não encontrado.</b>{" "}
              <TmpLinkAndIconCC
                data-testid="register-patient"
                handleClick={handleCreatePatient}
              >
                Fazer cadastro.
              </TmpLinkAndIconCC>
            </div>
          );
      }
    }

    if (error && data?.cpf?.length < 14) {
      if (type === "icon") return <ErrorIcon />;
      if (type === "text") return "Campo obrigatório";
    }

    if (type === "nac") {
      return (
        <div
          style={{
            fontSize: "14px",
            display: "flex",
            gap: "5px",
            overflowY: "hidden",
          }}
        >
          <AlertSVG style={{ color: "#2C6EF2" }} />
          <b>Paciente brasileiro?</b> <p>Cadastre com CPF.</p>
        </div>
      );
    }

    if (type === "error") {
      return (
        <div
          style={{
            fontSize: "14px",
            display: "flex",
            gap: "5px",
            overflowY: "hidden",
          }}
        >
          <ErrorIcon style={{ color: "#EA1F1F" }} />
          <p>Falha ao buscar paciente. Tente de novo.</p>
        </div>
      );
    }

    if (type === "invalidDoc") {
      return (
        <div
          style={{
            fontSize: "14px",
            display: "flex",
            gap: "5px",
            overflowY: "hidden",
            color: "#EA1F1F",
            alignItems: "center",
          }}
        >
          <ErrorIcon style={{ color: "#EA1F1F" }} />
          <p>{customMessage ?? "CPF inválido."}</p>
        </div>
      );
    }

    return "";
  };

  const optionsToLocal = establishment.filter((e) => {
    return [321, 51, 1].includes(e.cdEstabelecimento);
  });

  const mapOptionsToSelectCC = optionsToLocal
    .filter((e) => e.cobertura === true)
    .map((e) => e.nome);

  const getPatientAge = (birthDate) => {
    const now = new Date();
    const diff = now - birthDate.getTime();
    var age = new Date(diff);
    age = Math.abs(age.getUTCFullYear() - 1970);
    return age;
  };

  const patientAge = getPatientAge(
    data?.dataNascimento > 9
      ? new Date(data?.dataNascimento)
      : parseBRDateToUS(data?.dataNascimento)
  );

  const isMinor = () => {
    if (patientAge < 18) {
      return true;
    } else return false;
  };

  const clearServicesDisableds  = async () => {
    fields.local.forEach((field) => {
    if (field.disabled.includes(dataProc.cdEstabelecimento.toString())) {
      populateFormData({
        target: {
          name: field.name,
          value: field.initialValue,
        },
      });
    }
  })
  }

  const isPatientMinor = () => {
    return (
      <S.StyledContent columns={"2fr 1fr"} marginBottomTablet="20px">
        <TmpInput
          autoComplete="off"
          flex={1}
          name="nomeResponsavel"
          value={data?.nomeResponsavel}
          type="text"
          data-style="input_top"
          label="Nome do responsável legal *"
          placeholder="Informe o nome do responsável"
          error={data?.nomeResponsavel === "" && error}
          helperText={
            data?.nomeResponsavel === "" && error ? (
              <Error>Campo obrigatório</Error>
            ) : null
          }
          helperIcon={
            data?.nomeResponsavel === "" && error ? (
              <ErrorIcon style={{ color: "#EA1F1F" }} />
            ) : null
          }
          onChange={dataChange}
        />
        <TmpInput
          autoComplete="off"
          value={masks["CPF"](data?.documentoResponsavel)}
          flex={1}
          name="documentoResponsavel"
          type="text"
          label="CPF do responsável *"
          placeholder="Informe o número do CPF do responsável"
          maxLength={14}
          onChange={dataChange}
          error={data?.documentoResponsavel === "" && error}
          helperText={
            data?.documentoResponsavel === "" && error ? (
              <Error>Campo obrigatório</Error>
            ) : null
          }
          helperIcon={
            data?.documentoResponsavel === "" && error ? (
              <ErrorIcon style={{ color: "#EA1F1F" }} />
            ) : null
          }
        />
      </S.StyledContent>
    );
  };

  const adressInfo = () => {
    if (freeFields && isNewPatient) {
      return (
        <S.StyledSectionWrapper marginBottom={"0"}>
          <S.StyledContent columns={"minmax(0, 298px) 2fr minmax(0, 298px)"}>
            <TmpInput
              autoComplete="off"
              value={data?.cep}
              name="cep"
              type="text"
              label="CEP"
              mask="cep"
              placeholder="Insira o CEP"
              disabled={isDisabled()}
              onChange={dataChange}
              onBlur={(e) => {
                handleBlurCEP(e, ["endereco", "bairro", "cidade", "uf"]);
              }}
            />
            <TmpInput
              autoComplete="off"
              value={data?.endereco}
              name="endereco"
              type="text"
              label="Endereço"
              placeholder="Informe o endereço"
              disabled={isDisabled()}
              onChange={dataChange}
            />
            <TmpInput
              autoComplete="off"
              value={data?.bairro}
              name="bairro"
              type="text"
              label="Bairro"
              placeholder="Insira o bairro"
              disabled={isDisabled()}
              onChange={dataChange}
            />
          </S.StyledContent>

          <S.StyledContent columns={"repeat(4, 1fr)"}>
            <TmpInput
              autoComplete="off"
              value={data?.numero}
              name="numero"
              type="text"
              label="Número"
              placeholder="Insira o número"
              disabled={isDisabled()}
              onChange={dataChange}
            />
            <TmpInput
              autoComplete="off"
              value={data?.cidade}
              name="cidade"
              type="text"
              label="Cidade"
              placeholder="Insira a cidade"
              disabled={isDisabled()}
              onChange={dataChange}
            />
            <TmpInput
              autoComplete="off"
              value={data?.uf}
              name="uf"
              type="text"
              label="Estado"
              placeholder="Informe o estado"
              disabled={isDisabled()}
              onChange={dataChange}
            />
            <TmpInput
              autoComplete="off"
              value={data?.complemento}
              name="complemento"
              type="text"
              label="Complemento"
              placeholder="Insira o complemento"
              disabled={isDisabled()}
              onChange={dataChange}
            />
          </S.StyledContent>

          <S.StyledContent columns={"repeat(2, minmax(0, 298px)) 2fr"}>
            <TmpPhoneInput
              autoComplete="off"
              name="telefone"
              defaultCountry={"BR"}
              defaultValue={`${data?.telefone.ddi}${data?.telefone.ddd}${data?.telefone.phone}`}
              value={data?.telefone}
              label="Telefone *"
              maxLength={17}
              placeholder="Insira o telefone do paciente"
              onClick={
                isAditionalTeam ? onClickFilledFieldByMainTeam : undefined
              }
              disabled={isAditionalTeam || isDisabled()}
              // getValue permite que quebremos o telefone em DDI, DDD e telefone. Sem ela, não temos como saber qual é o tamanho do DDI
              getValue={(val) => {
                if (val?.phone !== data.telefone?.phone) {
                  if (!phonePopulated.current && editingSolicitation) {
                    populateFormData(
                      {
                        target: {
                          name: "telefone",
                          value: val,
                        },
                      },
                      "paciente"
                    );
                  } else {
                    dataChange({
                      target: {
                        name: "telefone",
                        value: val,
                      },
                    });
                  }
                }
              }}
              onChange={(e) => {
                phonePopulated.current = true;
                dataChange({
                  target: {
                    name: "telefone",
                    value: {
                      ...data.telefone,
                      completeNumber: e.target.value,
                    },
                  },
                });
              }}
              error={
                invalidPhone || (data?.telefone === "" && freeFields && error)
              }
              helperText={
                invalidPhone ? (
                  <Error>Número inválido</Error>
                ) : data?.telefone === "" && freeFields && error ? (
                  <Error>Campo obrigatório</Error>
                ) : null
              }
              helperIcon={
                invalidPhone ? (
                  <ErrorIcon style={{ color: "red" }} />
                ) : data?.telefone === "" && freeFields && error ? (
                  <ErrorIcon />
                ) : null
              }
            />
            <TmpPhoneInput
              autoComplete="off"
              name="telefoneExtra"
              defaultCountry={"BR"}
              value={data?.telefoneExtra}
              label="Telefone 2"
              maxLength={17}
              placeholder="Insira outro telefone do paciente"
              disabled={isDisabled()}
              onChange={(e) => {
                dataChange({
                  target: {
                    name: "telefoneExtra",
                    value: e.target.value,
                  },
                });
              }}
            />
            <TmpInput
              autoComplete="off"
              name="email"
              type="email"
              value={data?.email}
              data-testid="input_id"
              label="E-mail"
              placeholder="Insira o email do paciente"
              onChange={(e) => {
                dataChange({
                  target: {
                    name: "email",
                    value: e.target.value.trim().toLowerCase(),
                  },
                });
              }}
              onClick={
                isAditionalTeam ? onClickFilledFieldByMainTeam : undefined
              }
              disabled={isAditionalTeam || isDisabled()}
              error={!validationEmail(data?.email) && isFieldBlurred.email}
              helperText={
                !validationEmail(data?.email) && isFieldBlurred.email ? (
                  <Error>E-mail inválido</Error>
                ) : null
              }
              helperIcon={
                !validationEmail(data?.email) &&
                isFieldBlurred.email && <ErrorIcon />
              }
              onBlur={() => handleBlurEmail(data?.email)}
            />
          </S.StyledContent>
        </S.StyledSectionWrapper>
      );
    }
  };

  const renderInputName = () => {
    function isInputDisabled() {
      if (isAditionalTeam) {
        return true;
      }

      if (
        data?.tipoDocumento === "Nome" &&
        solicitation.solicitacao.idSolicitacao === null
      ) {
        return false;
      }

      return disablePatientRegistred(data?.nome);
    }

    return (
      <>
        {data?.tipoDocumento === "Nome" ? (
          <AutocompleteRow
            flex={1}
            error={data?.nome === "" && freeFields && error}
            placeholderColor={data?.nome !== ""}
          >
            <TmpInput
              autoComplete="off"
              name="nome"
              label="Nome completo *"
              data-testid="input_id"
              value={data?.nome}
              error={
                data?.nome === "" &&
                (freeFields || data?.tipoDocumento === "Nome") &&
                error
              }
              placeholder="Insira o nome do paciente"
              onClick={
                isAditionalTeam ? onClickFilledFieldByMainTeam : undefined
              }
              disabled={isInputDisabled()}
              onChange={async (e) => {
                cleanUser({
                  field: "nome",
                  value: e.target.value.trim(),
                  // Máscara de passaporte apenas retorna o valor. Usando ela para evitar criar uma flag para checar se precisa de máscara
                  mask: "passaporte",
                });
                await getPatientByName(e.target.value.trim());
              }}
              helperText={
                data?.nome === "" && freeFields && error ? (
                  <Error>Campo obrigatório</Error>
                ) : null
              }
              helperIcon={
                data?.nome === "" && freeFields && error ? <ErrorIcon /> : null
              }
            />

            {data?.nome && register ? (
              <Autocomplete
                style={{ top: "80px", width: "700px" }}
                isCurrentInput={true}
                list={patientOpts}
                inputName="nomePaciente"
                propLabel="nome"
                isLoading={loading}
                onNewPatient={() => {
                  handleCreatePatient();
                }}
                onClick={async (value) => {
                  setRegister(false);
                  await handleSearchPacientSolicitations(value, async () => {
                    dataChange({
                      target: {
                        name: "nome",
                        value: value.nome,
                      },
                    });
                    setLoadingScreen(true);
                    await setPatientbasicInfos(value);
                    dispatch(setIsNewPatient(false));
                    dispatch(setFreeFields(true));
                  });
                }}
              />
            ) : null}
          </AutocompleteRow>
        ) : (
          <TmpInput
            autoComplete="off"
            flex={1}
            width="auto"
            name="nome"
            type="text"
            data-testid="input_id"
            value={data?.nome}
            error={data?.nome === "" && freeFields && error}
            label="Nome completo *"
            data-style="input_bottom"
            placeholder="Insira o nome do paciente"
            onClick={isAditionalTeam ? onClickFilledFieldByMainTeam : undefined}
            disabled={isInputDisabled()}
            onChange={dataChange}
            helperText={
              data?.nome === "" && freeFields && error ? (
                <Error>Campo obrigatório</Error>
              ) : null
            }
            helperIcon={
              data?.nome === "" && freeFields && error ? <ErrorIcon /> : null
            }
          />
        )}
        {data?.tipoDocumento === "Nome" &&
        register &&
        data?.codPessoaFisica === "" &&
        !loading &&
        data?.nome?.length > 3 ? (
          <S.PatientDoesNotExist>
            {renderPatientDoesNotExist("icon") &&
              renderPatientDoesNotExist("text")}
          </S.PatientDoesNotExist>
        ) : null}
      </>
    );
  };

  const renderWeight = () => {
    const list = domains?.peso?.length
      ? [
          ...domains.peso,
          {
            label: "Nada a declarar",
          },
        ]
      : [
          {
            label: "entre 10kgs e 20kgs",
          },
          {
            label: "entre 21kgs e 30kgs",
          },
          {
            label: "acima de 180kgs",
          },
        ];

    return (
      <S.WrapperPatienrCare>
        <S.Label
          disabled={isDisabled() ? "rgba(0,0,0,0.32);" : "#000"}
          style={{ fontWeight: 500 }}
        >
          Peso do paciente
        </S.Label>
        <S.Radios>
          {list.map((i) => (
            <TmpRadio
              key={i.label}
              label={i.label}
              value={i.label}
              name="peso"
              onClick={
                isAditionalTeam ? onClickFilledFieldByMainTeam : undefined
              }
              disabled={isAditionalTeam || isDisabled()}
              checked={data?.peso === i.label && !isDisabled()}
              handleChange={() => handleSelectChange(i.label, "peso")}
            />
          ))}
        </S.Radios>
      </S.WrapperPatienrCare>
    );
  };

  return (
    <>
      {Object.values(loadingFields).includes(true) && !navigation && (
        <S.StyledLoading>
          <TmpLoading label="Carregando..." />
        </S.StyledLoading>
      )}
      {loadingScreen ? (
        <S.StyledLoading>
          <TmpLoading label="Carregando..." />
        </S.StyledLoading>
      ) : null}
      <S.Container>
        <S.BoxTitle>
          <S.Title id="step-basic-infos" margin="0 0 16px 0">
            Dados do paciente
          </S.Title>
          <TmpDivider />
        </S.BoxTitle>

        <S.StyledSectionWrapper>
          <S.StyledContent
            columns={
              data?.tipoDocumento === "Nome"
                ? "minmax(0, 298px) 2fr minmax(0, 298px)"
                : "repeat(2, minmax(0, 298px)) 2fr"
            }
            columnsMd={"repeat(2, 1fr)"}
            marginBottomTablet="40px"
          >
            <S.SelectWrap
              placeholderColor={data?.tipoDocumento !== ""}
              onClick={
                isAditionalTeam ? onClickFilledFieldByMainTeam : undefined
              }
            >
              <TmpSelect
                name="tipoDocumento"
                label="Buscar o paciente por: *"
                value={getSelectObjValue(data?.tipoDocumento)}
                error={data?.tipoDocumento === "" && error}
                disabled={
                  editingModel ||
                  solicitation.solicitacao.idSolicitacao !== null
                }
                handleChange={(e) => {
                  cleanUser({ field: "tipoDocumento", value: e.label });
                }}
                helperText={
                  data?.tipoDocumento === "" && error ? (
                    <Error>Campo obrigatório</Error>
                  ) : null
                }
                helperIcon={
                  data?.tipoDocumento === "" && error ? (
                    <ErrorIcon style={{ color: "#EA1F1F" }} />
                  ) : null
                }
                listItems={[
                  {
                    label: "CPF",
                  },
                  {
                    label: "Nome",
                  },
                  {
                    label: "RNE/CRNM",
                  },
                  {
                    label: "Passaporte",
                  },
                ]}
              />
            </S.SelectWrap>
            {renderInput()}
            <S.LGrender maxHeight={"88px"}>{renderInputName()}</S.LGrender>
            {isNewPatient && data?.tipoDocumento === "Nome" && (
              <S.SelectWrap placeholderColor={data?.tipoDocumento !== ""}>
                <TmpSelect
                  name="novoPacienteDocumento"
                  label="Documento: *"
                  value={getSelectObjValue(data?.novoPacienteDocumento)}
                  error={data?.novoPacienteDocumento === "" && error}
                  disabled={
                    editingModel ||
                    solicitation.solicitacao.idSolicitacao !== null
                  }
                  handleChange={(e) => {
                    setLoading(false);
                    setIsNewPatient(true);
                    setInvalidDoc(false);

                    dataChangeObj({
                      target: {
                        name: "paciente",
                        value: {
                          ...data,
                          [newPatientByName.name]: "",
                          novoPacienteDocumento: e.label,
                        },
                      },
                    });

                    dispatchNewPatientByName({
                      type: "set-type",
                      payload: e.label,
                    });
                  }}
                  helperText={
                    data?.novoPacienteDocumento === "" && error ? (
                      <Error>Campo obrigatório</Error>
                    ) : null
                  }
                  helperIcon={
                    data?.novoPacienteDocumento === "" && error ? (
                      <ErrorIcon style={{ color: "#EA1F1F" }} />
                    ) : null
                  }
                  listItems={[
                    {
                      label: "CPF",
                    },
                    {
                      label: "RNE/CRNM",
                    },
                    {
                      label: "Passaporte",
                    },
                  ]}
                />
              </S.SelectWrap>
            )}
          </S.StyledContent>

          <S.MDrender maxHeight={"88px"}>{renderInputName()}</S.MDrender>

          <S.StyledContent
            columns={
              freeFields && isNewPatient
                ? "repeat(4, minmax(0, 298px))"
                : "repeat(4, 1fr)"
            }
            columnsMd={"repeat(2, 1fr)"}
            gapMD={"40px 24px"}
          >
            {freeFields && isNewPatient && data?.tipoDocumento === "Nome" && (
              <TmpInput
                autoComplete="off"
                name={newPatientByName.name}
                value={masks[newPatientByName.mask](
                  data?.[newPatientByName.name]
                )}
                label="N° do documento *"
                maxLength={newPatientByName.inputLength}
                loading={loading}
                data-testid="input_id"
                data-style="input_bottom"
                placeholder="Insira o N° do Documento"
                onChange={async (e) => {
                  const value = e.target.value.trim();
                  setInvalidDoc(false);
                  dataChangeObj({
                    target: {
                      name: "paciente",
                      value: {
                        ...data,
                        [newPatientByName.name]: value,
                      },
                    },
                  });

                  const check = await checkIfPatientAlreadyExists(
                    newPatientByName.name,
                    value
                  );

                  dispatchNewPatientByName({
                    type: "set-patient-exists",
                    payload: check,
                  });

                  if (
                    value.replaceAll(/\D/g, "").length === 11 &&
                    !check &&
                    newPatientByName.name === "cpf" &&
                    !Validation.cpf(value)
                  ) {
                    setInvalidDoc(true);
                  }
                }}
                error={
                  (data?.[newPatientByName.name] === "" && error) || invalidDoc
                }
                helperText={(() => {
                  if (data?.[newPatientByName.name] === "" && error) {
                    return <Error>Campo obrigatório</Error>;
                  }

                  if (newPatientByName.alreadyExists && !loading) {
                    return <strong>Documento já cadastrado.</strong>;
                  }

                  if (invalidDoc) {
                    return renderPatientDoesNotExist("invalidDoc");
                  }

                  return null;
                })()}
                helperIcon={(() => {
                  if (invalidDoc) {
                    return null;
                  }

                  if (data?.[newPatientByName.name] === "" && error) {
                    return <ErrorIcon />;
                  }

                  if (newPatientByName.alreadyExists && !loading) {
                    return <AlertSVG />;
                  }

                  return null;
                })()}
              />
            )}

            <TmpInput
              autoComplete="off"
              name="dataNascimento"
              type="date"
              mask="date"
              maxLength={10}
              data-testid="input_id"
              value={data?.dataNascimento}
              error={isErrorDN && !isDisabled()}
              label="Data de Nascimento *"
              placeholder="dd/mm/aaaa"
              onClick={
                isAditionalTeam ? onClickFilledFieldByMainTeam : undefined
              }
              disabled={
                isAditionalTeam || disablePatientRegistred(data?.dataNascimento)
              }
              helperText={helperTextDN && !isDisabled()}
              helperIcon={helperIconDN && !isDisabled()}
              onChange={(e) =>
                dataChange({
                  target: { name: "dataNascimento", value: e.target.value },
                })
              }
              onBlur={() => handleBlurDN(data?.dataNascimento)}
            />
            <S.SelectWrap
              error={data?.sexo === "" && freeFields && error}
              placeholderColor={data?.sexo !== ""}
              maxHeight
            >
              <TmpSelect
                data-testid="select_id"
                id="sex"
                name="sexo"
                label="Sexo *"
                placeholder="Selecione o sexo do paciente"
                onClick={
                  isAditionalTeam ? onClickFilledFieldByMainTeam : undefined
                }
                disabled={
                  isAditionalTeam || disablePatientRegistred(data?.sexo)
                }
                value={getSelectObjValue(data?.sexo)}
                error={data?.sexo === "" && freeFields && error}
                handleChange={(e) => handleSelectChange(e.label, "sexo")}
                helperText={
                  data?.sexo === "" && freeFields && error ? (
                    <Error>Campo obrigatório</Error>
                  ) : null
                }
                helperIcon={
                  data?.sexo === "" && freeFields && error ? (
                    <ErrorIcon style={{ color: "#EA1F1F" }} />
                  ) : null
                }
                listItems={[
                  {
                    label: "Masculino",
                  },
                  {
                    label: "Feminino",
                  },
                ]}
              />
            </S.SelectWrap>

            {freeFields && isNewPatient ? (
              <>
                <S.SelectWrap
                  error={data?.nacionalidade === "" && error}
                  placeholderColor={data?.nacionalidade !== ""}
                >
                  <TmpSelect
                    name="nacionalidade"
                    label="Nacionalidade *"
                    placeholder="Informe a nacionalidade"
                    value={getSelectObjValue(data?.nacionalidade)}
                    error={data?.nacionalidade === "" && error}
                    helperText={
                      (data?.tipoDocumento === "RNE/CRNM" ||
                        data?.tipoDocumento === "Passaporte" ||
                        newPatientByName.type === "RNE/CRNM" ||
                        newPatientByName.type === "Passaporte") &&
                      data?.nacionalidade === "Brasileira" ? (
                        renderPatientDoesNotExist("nac")
                      ) : data?.nacionalidade === "" && error ? (
                        <Error>Campo obrigatório</Error>
                      ) : null
                    }
                    helperIcon={
                      data?.nacionalidade === "" && error ? (
                        <ErrorIcon style={{ color: "#EA1F1F" }} />
                      ) : null
                    }
                    handleChange={(e) => {
                      handleSelectChange(e.label, "nacionalidade");
                      dataChange({
                        target: {
                          name: "cdNacionalidade",
                          value: fieldsOptions["nacionalities"]?.filter(
                            (item) => item?.desc_nacionalidade === e.label
                          )[0]?.cd_nacionalidade,
                        },
                      });
                    }}
                    listItems={parseToSelectLabel(
                      "desc_nacionalidade",
                      fieldsOptions?.nacionalities
                    )}
                    disabled={isDisabled()}
                  />
                </S.SelectWrap>
                {(data?.tipoDocumento === "Passaporte" && !data?.passaporte) ||
                (data?.nacionalidade !== "Brasileira" &&
                  data?.nacionalidade !== "" &&
                  data?.tipoDocumento === "CPF") ? (
                  <TmpInput
                    autoComplete="off"
                    name="rne"
                    value={data?.rne}
                    label="RNE/CRNM *"
                    maxLength={25}
                    data-testid="input_id"
                    data-style="input_bottom"
                    placeholder="Insira o N° do Documento"
                    onChange={dataChange}
                    error={data?.rne === "" && freeFields && error}
                    helperText={
                      data?.rne === "" && freeFields && error ? (
                        <Error>Campo obrigatório</Error>
                      ) : null
                    }
                    helperIcon={
                      data?.rne === "" && freeFields && error ? (
                        <ErrorIcon />
                      ) : null
                    }
                    disabled={isDisabled()}
                  />
                ) : null}
              </>
            ) : (
              <>
                <div style={{ maxHeight: "88px" }}>
                  <TmpPhoneInput
                    autoComplete="off"
                    name="telefone"
                    defaultCountry={"BR"}
                    defaultValue={`${data?.telefone.ddi}${data?.telefone.ddd}${data?.telefone.phone}`}
                    value={data?.telefone}
                    label="Telefone *"
                    maxLength={17}
                    placeholder="Insira o telefone do paciente"
                    onClick={
                      isAditionalTeam ? onClickFilledFieldByMainTeam : undefined
                    }
                    disabled={isAditionalTeam || isDisabled()}
                    // getValue permite que quebremos o telefone em DDI, DDD e telefone. Sem ela, não temos como saber qual é o tamanho do DDI
                    getValue={(val) => {
                      if (
                        val?.phone !== data.telefone?.phone &&
                        !isDisabled()
                      ) {
                        if (!phonePopulated.current && editingSolicitation) {
                          populateFormData(
                            {
                              target: {
                                name: "telefone",
                                value: val,
                              },
                            },
                            "paciente"
                          );
                        } else if (!isDisabled()) {
                          dataChange({
                            target: {
                              name: "telefone",
                              value: val,
                            },
                          });
                        }
                      }
                    }}
                    onChange={(e) => {
                      phonePopulated.current = true;

                      dataChange({
                        target: {
                          name: "telefone",
                          value: {
                            completeNumber: e.target.value,
                          },
                        },
                      });
                    }}
                    error={
                      invalidPhone ||
                      (data?.telefone === "" && freeFields && error)
                    }
                    helperText={
                      invalidPhone ? (
                        <Error>Número inválido</Error>
                      ) : data?.telefone === "" && freeFields && error ? (
                        <Error>Campo obrigatório</Error>
                      ) : null
                    }
                    helperIcon={
                      invalidPhone ? (
                        <ErrorIcon style={{ color: "red" }} />
                      ) : data?.telefone === "" && freeFields && error ? (
                        <ErrorIcon />
                      ) : null
                    }
                  />
                </div>

                <TmpInput
                  autoComplete="off"
                  name="email"
                  type="email"
                  value={data?.email}
                  data-testid="input_id"
                  data-style="input_bottom"
                  label="E-mail"
                  placeholder="Insira o email do paciente"
                  onClick={
                    isAditionalTeam ? onClickFilledFieldByMainTeam : undefined
                  }
                  disabled={isAditionalTeam || isDisabled()}
                  onChange={(e) => {
                    dataChange({
                      target: {
                        name: "email",
                        value: e.target.value.trim().toLowerCase(),
                      },
                    });
                  }}
                  error={!validationEmail(data?.email) && isFieldBlurred.email}
                  helperText={
                    !validationEmail(data?.email) && isFieldBlurred.email ? (
                      <Error>E-mail inválido</Error>
                    ) : null
                  }
                  helperIcon={
                    !validationEmail(data?.email) &&
                    isFieldBlurred.email && <ErrorIcon />
                  }
                  onBlur={() => handleBlurEmail(data?.email)}
                />
              </>
            )}
          </S.StyledContent>

          {patientAge !== null && isMinor() ? isPatientMinor() : null}
          {adressInfo()}
        </S.StyledSectionWrapper>

        <S.BoxTitle>
          <S.Title margin="0 0 16px 0">Dados de Cobrança</S.Title>
          <TmpDivider />
        </S.BoxTitle>
        <S.StyledSectionWrapper rows={"repeat(3, auto)"}>
          <S.StyledContent
            columns={"repeat(2, 1fr)"}
            columnsMd={"1fr"}
            gapMD={"40px"}
          >
            <AutocompleteRow
              flex={1}
              width="auto"
              error={data?.estadoCobranca === "" && freeFields && error}
              placeholderColor={data?.estadoCobranca !== ""}
              isLoading={loading}
            >
              <TmpSelect
                name="estadoCobranca"
                label="Estado *"
                placeholder="Selecione um Estado"
                value={getSelectObjValue(
                  data?.estadoCobranca || validatingState
                )}
                error={data?.estadoCobranca === "" && error && !validatingState}
                disabled={
                  isAditionalTeam ||
                  editingModel ||
                  isDisabled("estadoCobranca")
                }
                handleChange={(e) => handleState(e.label)}
                helperText={
                  data?.estadoCobranca === "" && !validatingState && error ? (
                    <Error>Campo obrigatório</Error>
                  ) : null
                }
                helperIcon={
                  data?.estadoCobranca === "" && !validatingState && error ? (
                    <ErrorIcon style={{ color: "#EA1F1F" }} />
                  ) : null
                }
                listItems={fieldsOptions?.federativeUnits}
              />
            </AutocompleteRow>
            <AutocompleteRow
              flex={1}
              width="auto"
              error={data?.convenio === "" && freeFields && error}
              placeholderColor={data?.convenio !== ""}
              isLoading={loading}
            >
              <TmpInput
                autoComplete="off"
                name="convenio"
                label="Convênio *"
                data-testid="input_id"
                value={data?.convenio}
                error={
                  data?.convenio === "" && !isDisabled("convenio") && error
                }
                placeholder="Digite o convênio do paciente"
                disabled={editingModel || isDisabled("convenio")}
                initialOpenValue
                helperText={
                  listSearchError.includes("convenio") &&
                  !isDisabled("convenio") ? (
                    <Reload
                      handleClick={() => {
                        getHealthInsurance();
                        setListSearchError((prevState) =>
                          prevState.filter((i) => i !== "convenio")
                        );
                      }}
                    />
                  ) : data?.convenio === "" &&
                    !isDisabled("convenio") &&
                    error ? (
                    <Error>Campo obrigatório</Error>
                  ) : null
                }
                helperIcon={
                  data?.convenio === "" && !isDisabled("convenio") && error ? (
                    <ErrorIcon style={{ color: "#EA1F1F" }} />
                  ) : null
                }
                onChange={(e) => {
                  handleHealth(e.target.value.trim());
                }}
                onFocus={() => setShowHealthcare(true)}
                onBlur={() => setTimeout(() => setShowHealthcare(false), 200)}
              />
              {fieldsOptions?.healthInsurance?.mostUsed ? (
                <Autocomplete
                  style={{ top: "79px" }}
                  isCurrentInput={showHealthcare}
                  initialOpenValue={true}
                  sugestionTitle={
                    data?.convenio === "" && !isAditionalTeam
                      ? "Mais escolhidos"
                      : null
                  }
                  list={getHealthInsuranceList()}
                  propLabel="ds_convenio"
                  inputName="convenio"
                  isLoading={fieldsGeting.healthInsurance && hasSelectedHealth}
                  onClick={handleHealthInsurance}
                />
              ) : null}
            </AutocompleteRow>
          </S.StyledContent>

          <S.StyledContent columns={"repeat(2, 1fr)"}>
            <AutocompleteRow
              flex={1}
              width="auto"
              error={data?.categoria === "" && freeFields && error}
              placeholderColor={data?.categoria !== ""}
              isLoading={loading}
              maxHeight
            >
              <TmpInput
                autoComplete="off"
                name="categoria"
                label="Categoria *"
                data-testid="input_id"
                value={loadHealthInsurance ? "" : data?.categoria}
                error={
                  data?.categoria === "" && !isDisabled("categoria") && error
                }
                placeholder={
                  loadHealthInsurance
                    ? "Carregando..."
                    : "Digite a categoria do plano"
                }
                disabled={
                  isDisabled("categoria") || data?.obrigaConvenio === ""
                }
                helperText={
                  listSearchError.includes("categoria") &&
                  !isDisabled("categoria") ? (
                    <Reload
                      handleClick={() => {
                        getCategory(data.cdConvenio);
                        setListSearchError((prevState) =>
                          prevState.filter((i) => i !== "categoria")
                        );
                      }}
                    />
                  ) : data?.categoria === "" &&
                    !isDisabled("categoria") &&
                    error ? (
                    <Error>Campo obrigatório</Error>
                  ) : null
                }
                helperIcon={
                  data?.categoria === "" &&
                  !isDisabled("categoria") &&
                  error ? (
                    <ErrorIcon style={{ color: "#EA1F1F" }} />
                  ) : null
                }
                onChange={(e) => {
                  handleCategory(e.target.value.trim());
                }}
                onFocus={() => setHasSelectedCategory(true)}
                onBlur={() =>
                  setTimeout(() => setHasSelectedCategory(false), 200)
                }
              />

              {data?.categoria || hasSelectedCategory ? (
                <Autocomplete
                  style={{ top: "79px" }}
                  isCurrentInput={hasSelectedCategory}
                  list={searchListWithPriorityOrder(
                    fieldsOptions?.category,
                    isAditionalTeam ? data?.categoriaTitular : data?.categoria,
                    "ds_categoria"
                  )}
                  propLabel="ds_categoria"
                  inputName="categoria"
                  isLoading={fieldsGeting.category && hasSelectedCategory}
                  onClick={handleHealthCategory}
                />
              ) : null}
            </AutocompleteRow>
            <AutocompleteRow
              flex={1}
              width="auto"
              error={data?.plano === "" && freeFields && error}
              placeholderColor={data?.plano !== ""}
              isLoading={fieldsGeting?.healthPlan}
            >
              <TmpInput
                autoComplete="off"
                name="plano"
                label="Plano *"
                data-testid="input_id"
                value={data?.plano}
                error={data?.plano === "" && !isDisabled("plano") && error}
                placeholder={
                  loadHealthInsurance
                    ? "Carregando..."
                    : "Digite o plano do paciente"
                }
                disabled={
                  isDisabled("plano") ||
                  data.convenio === "" ||
                  data.cdCategoria === ""
                }
                helperText={
                  listSearchError.includes("plano") &&
                  !isDisabled("plano") &&
                  data.cdCategoria !== "" ? (
                    <Reload
                      handleClick={() => {
                        getCategoryPlan(data?.cdConvenio, data?.cdCategoria);
                        setListSearchError((prevState) =>
                          prevState.filter((i) => i !== "plano")
                        );
                      }}
                    />
                  ) : data?.plano === "" && !isDisabled("plano") && error ? (
                    <Error>Campo obrigatório</Error>
                  ) : null
                }
                helperIcon={
                  data?.plano === "" && !isDisabled("plano") && error ? (
                    <ErrorIcon style={{ color: "#EA1F1F" }} />
                  ) : null
                }
                onChange={(e) => {
                  handlePlan(e.target.value.trim());
                }}
                onFocus={() => setHasSelectedPlan(true)}
                onBlur={() => setTimeout(() => setHasSelectedPlan(false), 200)}
              />
              {data?.plano || hasSelectedPlan ? (
                <Autocomplete
                  style={{ top: "79px" }}
                  isCurrentInput={hasSelectedPlan}
                  list={searchListWithPriorityOrder(
                    fieldsOptions?.healthPlan,
                    isAditionalTeam ? data?.planoTitular : data?.plano,
                    "ds_plano"
                  )}
                  propLabel="ds_plano"
                  inputName="plano"
                  isLoading={fieldsGeting.healthPlan && hasSelectedPlan}
                  onClick={(value) => {
                    firstFetchHealthcare = false;

                    dataChange({
                      target: {
                        name: "cdPlano",
                        value: value.cdPlano,
                      },
                    });
                    clearSelectLocal();
                    handleSelectChange(value.ds_plano, "plano");
                    setHasSelectedPlan(false);
                  }}
                />
              ) : null}
            </AutocompleteRow>
          </S.StyledContent>

          <S.StyledContent columns={"repeat(2, 1fr)"} alignItems={"flex-start"}>
            <S.SelectWrap
              error={dataProc?.local === "" && freeFields && error}
              width="auto"
              placeholderColor={dataProc?.local !== ""}
              marginmedialg="0 24px 0 0"
              onClick={
                isAditionalTeam && (isDisabled("local") || data?.plano === "")
                  ? onClickFilledFieldByMainTeam
                  : undefined
              }
            >
              <TmpInputCC
                autoComplete="off"
                width="auto"
                name="carteirinha"
                type="text"
                data-testid="input_id"
                data-style="input_bottom"
                value={data?.carteirinha}
                label={
                  data?.cdConvenio !== "2" && data?.cdConvenio !== "984"
                    ? "Carteirinha *"
                    : "Carteirinha"
                }
                maxLength={30}
                message="Você atingiu o limite de caracteres."
                showCounter={true}
                placeholder="Insira o número da carteirinha do paciente"
                disabled={isDisabled("carteirinha")}
                onChange={dataChange}
                error={
                  data?.cdConvenio !== "2" && data?.cdConvenio !== "984"
                    ? data?.carteirinha === "" &&
                      !isDisabled("carteirinha") &&
                      error
                    : false
                }
                helperText={
                  data?.cdConvenio !== "2" && data?.cdConvenio !== "984" ? (
                    data?.carteirinha === "" &&
                    !isDisabled("carteirinha") &&
                    error ? (
                      <Error>Campo obrigatório</Error>
                    ) : null
                  ) : null
                }
                helperIcon={
                  data?.cdConvenio !== "2" && data?.cdConvenio !== "984" ? (
                    data?.carteirinha === "" &&
                    !isDisabled("carteirinha") &&
                    error ? (
                      <ErrorIcon />
                    ) : null
                  ) : null
                }
              />
            </S.SelectWrap>
            <TmpSelectCC
              label="Local *"
              name="Local"
              disabledListItem={true}
              options={mapOptionsToSelectCC}
              handleSelectUnavailableItem={() => openPlanInfoDrawer()}
              aditionalTextDisabled="(Indisponível no Plano)"
              disabled={
                isAditionalTeam || isDisabled("local") || data?.plano === ""
              }
              placeholder="Selecionar uma Unidade HSL"
              loading={fieldsGeting?.local}
              listItems={parseToSelectLabel("nome", optionsToLocal)}
              value={getSelectObjValue(dataProc?.local)}
              error={dataProc?.local === "" && !isDisabled() && error}
              handleChange={(args) => {
                handleSelectProcChange(args.label, "local");
                dataProcChange({
                  target: {
                    name: "cdEstabelecimento",
                    value: establishment.filter(
                      (item) => item.nome === args.label
                    )[0].cdEstabelecimento,
                  },
                });
                clearServicesDisableds()
              }}
              helperText={
                dataProc?.local === "" && !isDisabled() && error ? (
                  <Error>Campo obrigatório</Error>
                ) : (
                  <S.HelpText>
                    Opções disponíveis de acordo com o Plano
                  </S.HelpText>
                )
              }
              helperIcon={
                dataProc?.local === "" && !isDisabled() && error ? (
                  <ErrorIcon style={{ color: "#EA1F1F" }} />
                ) : (
                  <InfoSVG
                    style={{
                      color:
                        isDisabled() || data?.plano === ""
                          ? "rgba(0,0,0,0.32)"
                          : "#000",
                    }}
                  />
                )
              }
            />
          </S.StyledContent>

          <S.StyledContent columns={"repeat(2, 1fr)"}>
            <S.WapperMedicalFee>
              <S.SelectMedicalFee>
                <S.Label
                  disabled={
                    //cenário de erro
                    error && dataProc?.cobranca === ""
                      ? "#EA1F1F"
                      : //cenário do campo desabilitado
                      dataProc?.local === ""
                      ? "rgba(0,0,0,0.32);"
                      : //cenário do campo habilitado para edição
                        "#000"
                  }
                  fontWeight="500"
                >
                  Honorários Médicos *:
                </S.Label>
                <S.Radios
                  error={error && dataProc?.cobranca === ""}
                  padding={0}
                >
                  <TmpRadio
                    label="Particular"
                    value="medicalFee"
                    name="medicalFee"
                    disabled={dataProc?.local === ""}
                    checked={dataProc?.cobranca === "Particular"}
                    handleChange={() => updateData("cobranca", "Particular")}
                  />
                  <TmpRadio
                    label="Hospital"
                    value="medicalFee"
                    name="medicalFee"
                    disabled={dataProc?.local === ""}
                    checked={dataProc?.cobranca === "Hospital"}
                    handleChange={() => updateData("cobranca", "Hospital")}
                  />
                </S.Radios>
              </S.SelectMedicalFee>
              <S.TextHelp
                disabled={dataProc?.local === "" ? "rgba(0,0,0,0.32);" : "#000"}
              >
                {error && dataProc?.cobranca === "" ? (
                  <>
                    <ErrorIcon style={{ color: "#EA1F1F" }} />
                    <Error>Campo obrigatório</Error>
                  </>
                ) : (
                  <>
                    <TmpIcon size="sm" icon={<InfoSVG />} />
                    <p>Seleção disponível de acordo com o Local</p>
                  </>
                )}
              </S.TextHelp>
            </S.WapperMedicalFee>
          </S.StyledContent>
        </S.StyledSectionWrapper>

        <S.BoxTitle>
          <S.Title margin="0 0 16px 0">Informações clínicas</S.Title>
          <TmpDivider />
        </S.BoxTitle>

        <S.StyledSectionWrapper>
          <S.StyledContent columns={"1fr"}>
            <AutocompleteRow>
              <TmpInput
                autoComplete="off"
                width="auto"
                flex={1}
                name="cidPrincipal"
                id="cidPrincipal"
                type="text"
                value={data?.cidPrincipal}
                error={
                  !isDisabled() &&
                  error &&
                  (data?.cidPrincipal === "" || data?.cdCidPrincipal === "")
                }
                label="CID Principal *"
                placeholder="Insira o número da cid"
                disabled={editingModel ? false : isDisabled()}
                onBlur={() => {}}
                helperText={
                  listSearchError.includes("cid") && !isDisabled() ? (
                    <Reload
                      handleClick={() => {
                        getCid(data?.cidPrincipal);
                        setListSearchError((prevState) =>
                          prevState.filter((i) => i !== "cid")
                        );
                      }}
                    />
                  ) : !isDisabled() && error && data?.cidPrincipal === "" ? (
                    <Error>Campo obrigatório</Error>
                  ) : null
                }
                helperIcon={
                  !isDisabled() && error && data?.cidPrincipal === "" ? (
                    <ErrorIcon />
                  ) : null
                }
                onChange={(e) => {
                  handleCid(e.target.value.trim());
                }}
              />

              {data?.cidPrincipal ? (
                <Autocomplete
                  style={{ top: "79px" }}
                  isCurrentInput={hasSelectedCid}
                  inputName="cid"
                  list={searchListWithPriorityOrder(
                    cidSugestion,
                    removeSpecialCharacters(data?.cidPrincipal),
                    "ds_doenca"
                  )}
                  isLoading={loading}
                  propLabel="ds_doenca"
                  onClick={async (value) => {
                    const setInformations = () => {
                      setHasSelectedCid(false);
                      dataChange({
                        target: {
                          name: "cidPrincipal",
                          value: value.ds_doenca,
                        },
                      });
                      dataChange({
                        target: {
                          name: "cdCidPrincipal",
                          value: value.cd_doenca,
                        },
                      });
                      dataChange({
                        target: {
                          name: "cdCidPrincipalFormatado",
                          value: value.cd_doenca_formatado,
                        },
                      });
                    };
                    if (editingSolicitation) {
                      setInformations();
                      const cid = await apiRequiredCid(
                        value?.cd_doenca_formatado
                      ).getList();
                      if (cid?.laudo && data?.convenio !== "Particular") {
                        dispatch(setRequiredLaudo(true));
                      } else {
                        dispatch(setRequiredLaudo(false));
                      }
                    } else {
                      setInformations();
                    }
                  }}
                />
              ) : null}
            </AutocompleteRow>
          </S.StyledContent>

          <S.StyledContent
            columns={"3fr minmax(0, 298px)"}
            columnsMd={"1fr"}
            maxHeight={"88px"}
            alignItems={"baseline"}
          >
            <TmpInput
              autoComplete="off"
              width="auto"
              flex={1}
              name="indicacaoClinica"
              value={data?.indicacaoClinica}
              error={data?.indicacaoClinica === "" && !isDisabled() && error}
              type="text"
              label="Indicação Clínica/Justificativa do Procedimento *"
              placeholder="Insira a indicação clínica"
              disabled={editingModel ? false : isDisabled()}
              helperText={
                data?.indicacaoClinica === "" && !isDisabled() && error ? (
                  <Error>Campo obrigatório</Error>
                ) : null
              }
              helperIcon={
                data?.indicacaoClinica === "" && !isDisabled() && error ? (
                  <ErrorIcon />
                ) : null
              }
              onChange={dataChange}
            />
            <S.LGrender>{renderDignosticDateInput()}</S.LGrender>
          </S.StyledContent>
          <S.MDrender maxHeight="88px">{renderDignosticDateInput()}</S.MDrender>
        </S.StyledSectionWrapper>

        <S.BoxTitle>
          <S.Title margin="0 0 16px 0">Cuidados com o paciente</S.Title>
          <TmpDivider />
        </S.BoxTitle>

        <S.StyledSectionWrapper rows={"1fr"}>
          <S.StyledContent
            columns={"repeat(2,1fr)"}
            columnsMd={"repeat(1,1fr)"}
            gap={"16px"}
          >
            <S.WrapperPatienrCare>
              <S.Label
                disabled={isDisabled() ? "rgba(0,0,0,0.32);" : "#000"}
                style={{ fontWeight: 500 }}
              >
                Necessidades Especiais
              </S.Label>
              <S.Radios>
                {[
                  ...specialNeeds,
                  {
                    label: "Nada a declarar",
                  },
                ].map((i) => (
                  <TmpRadio
                    key={i.label}
                    label={i.label}
                    value={i.label}
                    name="necessidadesEspeciais"
                    onClick={
                      isAditionalTeam ? onClickFilledFieldByMainTeam : undefined
                    }
                    disabled={isAditionalTeam || isDisabled()}
                    checked={
                      data?.necessidadesEspeciais === i.label && !isDisabled()
                    }
                    handleChange={() => {
                      handleSelectChange(i.label, "necessidadesEspeciais");
                      handleSelectChange(i.code, "nrSequenciaNecessidade");
                    }}
                  />
                ))}
              </S.Radios>
            </S.WrapperPatienrCare>
            {renderWeight()}
          </S.StyledContent>
        </S.StyledSectionWrapper>
      </S.Container>
    </>
  );
};

export default Basicinfo;
