import React, { useEffect, useRef, useState } from "react";
import {
  TmpRadio,
  TmpListItem,
  TmpIcon,
} from "@tempo/tempo-design-system-core/dist";

import Chevron from "./chevron";
import * as S from "./style";

const DropdownRadio = ({
  className,
  label,
  searchLabel,
  actived = false,
  valueSelect,
  totalItems,
  title,
  borde,
  listItems = [],
  handleChange,
}) => {
  const [_actived, setActived] = useState(actived);

  const wrapperRef = useRef(null);

  const _handleClick = () => {
    const status = !_actived;
    if (typeof handleChange === "function") {
      handleChange({
        actived: status,
      });
    }
    setActived(status);
  };

  useEffect(() => {
    setActived(actived);
  }, [actived]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef?.current?.contains(event.target)) {
        setActived(false);
      }
    }
    document.removeEventListener("mousedown", handleClickOutside);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, listItems]);

  return (
    <S.Wrapper ref={wrapperRef} className={className}>
      <S.Button active={_actived} borde={borde} onClick={_handleClick}>
        {label}

        <TmpIcon size="sm" icon={<Chevron />} />
      </S.Button>

      <S.DropDown
        actived={_actived}
        hasTitle={!!title}
        searchLabel={!!searchLabel}
      >
        <ul>
          <TmpListItem
            label={
              <S.ListTitle>
                <TmpIcon name="seta_direcional_esquerda" size="24" />
                {title}
              </S.ListTitle>
            }
            handleClick={_handleClick}
          />

          {listItems.map((item, index) => (
            <S.WapperItem key={index} disabled={totalItems < item?.value}>
              <TmpListItem
                key={index}
                label={
                  <TmpRadio
                    label={item.label}
                    checked={valueSelect === item.value}
                    handleChange={() => {
                      item.handleClick?.();
                      setActived(false);
                    }}
                  />
                }
                handleClick={() => false}
              />
            </S.WapperItem>
          ))}
        </ul>
      </S.DropDown>
    </S.Wrapper>
  );
};

export default DropdownRadio;
