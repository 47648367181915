export const supportServicesFields = [
  "biopsiaCongelacao",
  "ultrassonografiaLaparoscopio",
  "biobanco",
  "ressonanciaMagneticaIntraperatoria",
  "tomografiaEstereotaxia",
  "monitorizacaoNeurofisiologica",
  "ecoTransesofagico",
  "endoscopia",
  "raiox",
  "scopia",
  "colonoscopia",
  "medicinaNuclear",
  "pth",
  "ultrassonografia",
  "bancoTecidos",
  "roboDaVinci",
  "roboDaOrtopedia",
  "videolaparoscopia",
  "microscopio",
];

export const supportServicesExhibition = {
  biopsiaCongelacao: "Biópsia de congelação",
  ultrassonografiaLaparoscopio: "Ultrassonografia laparoscópio",
  biobanco: "Biobanco",
  ressonanciaMagneticaIntraperatoria: "Ressonância magnética intraoperatória",
  tomografiaEstereotaxia: "Tomografia para estereotaxia",
  monitorizacaoNeurofisiologica: "Monitoração neurofisiológica (Coluna)",
  ecoTransesofagico: "Eco transesofágico",
  endoscopia: "Endoscopia",
  raiox: "Raio X",
  scopia: "Scopia",
  colonoscopia: "Colonoscopia",
  medicinaNuclear: "Medicina Nuclear (Roll)",
  pth: "PTH",
  ultrassonografia: "Ultrassonografia",
  bancoTecidos: "Banco de tecidos",
  roboDaVinci: "Robô da Vinci",
  roboDaOrtopedia: "Robô da Ortopedia",
  videolaparoscopia: "Videolaparoscopia",
  microscopio: "Microscópio",
};
