import styled from "styled-components";

export const TypographyContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  white-space: pre-line;
  flex-direction: column;
  margin: 1rem 0.5rem;
  .tmp-typography__heading--large {
    color: #292929;
    font-weight: 700;
    margin-bottom: 1rem;
    @media (max-width: 475px) {
      font-size: 24px;
    }
  }
  .tmp-typography__caption {
    color: #666666;
    font-weight: 400;
    @media (max-width: 475px) {
      font-size: 20px;
    }
  }
`;
