import React from "react";
import { TmpButton } from "@tempo/tempo-design-system-core/dist";
import sessaoExpirada from "../../assets/images/sessaoExpirada.png";
import { useAuthContext } from "../../hooks";

import Modal from "../Modal";
import * as S from "./style";

const TimeoutModal = ({ idle }) => {
  const { logout } = useAuthContext();

  return (
    <Modal
      removeBtn
      size={"lg"}
      maxHeight={"450px"}
      maxWidth={"624px"}
      isOpened={true}
      paddingNone={true}
    >
      <S.ContainerLogout>
        <S.ImgLogout src={sessaoExpirada} alt="logout" />
        <S.TitleLogout>Seu tempo de sessão expirou!</S.TitleLogout>
        {idle && <S.TextLogout>Você esteve inativo por um tempo.</S.TextLogout>}
        <S.TextLogout>Por favor, faça o login novamente. </S.TextLogout>
      </S.ContainerLogout>
      <S.WrapperBtn>
        <TmpButton handleClick={logout}>Ir para a Tela de Login</TmpButton>
      </S.WrapperBtn>
    </Modal>
  );
};

export default TimeoutModal;
