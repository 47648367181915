export const requiredPatient = [
  "sexo",
  "nome",
  "dataNascimento",
  "telefone",
  "plano",
  "convenio",
  "cobranca",
  "categoria",
  "tempoDoenca",
  "indicacaoClinica",
  "cidPrincipal",
  "cdCidPrincipal",
  "local",
];

export const requiredProcedures = [
  "procedimentoPrincipal",
  "cdProcedimentoPrincipal",
  "regimeHospitalar",
  "carater",
  "local",
  "tipagemSanguinea",
  "reservaHemocomponentes",
  "reservaCritica",
  "tipoAnestesista",
  "data",
  "hora",
  "tempoCirurgia",
];
