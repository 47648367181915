import * as S from "./style";
import { seta_direcional_baixo as IconSetaBaixo } from "@tempo/tempo-assets/dist";
import { TABLE_WIDTH } from "./constants";

const TableList = ({
  orderedByName,
  orderedByDate,
  orderedByStatus,
  orderedByTermo,
  orderedByAnamnese,
  onClickOrderByAnamnese,
  onClickOrderByName,
  onClickOrderByDate,
  onClickOrderByStatus,
  onClickOrderByTermo,
  order,
}) => {
  const setaStyles = {
    width: "16px",
    height: "16px",
    color: "#2C6EF2",
    marginLeft: "8px",
    transition: "0.5s",
  };

  return (
    <S.BoxTipes>
      <S.Tipes
        active={orderedByName}
        width={"23.5%"}
        widthRes="46%"
        onClick={onClickOrderByName}
      >
        <S.StyledTextMobile>
          <p>Paciente</p>
          <span>Data da Cirurgia</span>
        </S.StyledTextMobile>
        {order === "cresc" && orderedByName ? (
          <IconSetaBaixo style={setaStyles} />
        ) : (
          <IconSetaBaixo
            style={{
              ...setaStyles,
              transform: "rotate(180deg)",
            }}
          />
        )}
      </S.Tipes>
      <S.BoxDateHour
        active={orderedByDate}
        width={TABLE_WIDTH.date.desktop}
        onClick={onClickOrderByDate}
      >
        <span>
          Data da <br />
          Cirurgia
        </span>
        {order === "cresc" && orderedByDate ? (
          <IconSetaBaixo style={setaStyles} />
        ) : (
          <IconSetaBaixo
            style={{
              ...setaStyles,
              transform: "rotate(180deg)",
            }}
          />
        )}
      </S.BoxDateHour>
      <S.Tipes
        active={orderedByStatus}
        width={TABLE_WIDTH.status.desktop}
        widthRes="48%"
        onClick={onClickOrderByStatus}
        box={"status"}
      >
        Status
        {order === "cresc" && orderedByStatus ? (
          <IconSetaBaixo style={setaStyles} />
        ) : (
          <IconSetaBaixo
            style={{
              ...setaStyles,
              transform: "rotate(180deg)",
            }}
          />
        )}
      </S.Tipes>
      <S.Tipes
        active={orderedByAnamnese}
        displayRes="none"
        width={TABLE_WIDTH.anamnese.desktop}
        onClick={onClickOrderByAnamnese}
      >
        Anamnese
        {order === "cresc" && orderedByAnamnese ? (
          <IconSetaBaixo style={setaStyles} />
        ) : (
          <IconSetaBaixo
            style={{
              ...setaStyles,
              transform: "rotate(180deg)",
            }}
          />
        )}
      </S.Tipes>
      <S.Tipes
        active={orderedByTermo}
        displayRes="none"
        width={TABLE_WIDTH.consentForm.desktop}
        onClick={onClickOrderByTermo}
      >
        Termo de <br /> Consentimento
        {order === "cresc" && orderedByTermo ? (
          <IconSetaBaixo style={setaStyles} />
        ) : (
          <IconSetaBaixo
            style={{
              ...setaStyles,
              transform: "rotate(180deg)",
            }}
          />
        )}
      </S.Tipes>
      <S.Tipes displayRes="none" width={TABLE_WIDTH.download.desktop}>
        Ações
      </S.Tipes>
    </S.BoxTipes>
  );
};

export default TableList;
