import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  isSuccessAnam,
  setOpenAnamnese,
  isError,
} from "../../features/settings/settingsSlice";
import {
  setPatientProperty,
  setAnamnesePart,
  setClearAnamnese,
} from "../../features/patient/patientSlice";
import {
  requiredHistoryDevices,
  requiredPhysicalExam,
  requiredResults,
} from "./requiredFields";
import {
  TmpButtonGroup,
  TmpIcon,
  TmpCheckbox,
  TmpRadio,
  TmpDivider,
  TmpAlert,
  TmpButtonIcon,
} from "@tempo/tempo-design-system-core/dist";
import { TmpInputCC } from "@tempo/tempo-design-system-centro-cirurgico";

import NewModal from "../NewModal";
import { prescricao_guia as PrescricaoSVG } from "@tempo/tempo-assets/dist";
import * as S from "./style";
import { updateProp } from "../../utils";

import {
  Saving,
  StyledSaving,
  TextareaWrap,
} from "../../pages/Scheduling/Steps/style";
import {
  pontinhos_horizontal as Pontinhos,
  erro as ErrorIcon,
  cancelar as IconCancelar,
} from "@tempo/tempo-assets/dist";
import { ComponentLabel } from "../../globalStyle";
import InfoPatient from "../PatientInfos";
import TextArea from "../TextArea";
import {
  anamneseSolicitation,
  apiAnamneseExameFisico,
  apiAnamneseResultado,
  getAnamneseSolicitation,
  apiFinalizeAnamnese,
} from "../../services/api";

import { StyledAlert } from "../../pages/Scheduling/style";
import { Error } from "../../pages/Scheduling/Steps/style";
import { filledFields } from "../../utils";
import { anamneseExameFisico } from "./data";
import LocalAndDate from "../LocalAndDate";

const Anamnese = ({ dataChange }) => {
  const [error, setError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const patient = useSelector((state) => state.patient);
  const modalOpen = useSelector((state) => state.settings.openAnamnese);
  const currentSolicitation = useSelector((state) => state.solicitation);
  const { urlAssinatura } = useSelector((state) => state.auth.userInfos);
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(1);
  const [saving, setSaving] = useState(false);
  const [requiredAnamnese, setRequiredAnanmnese] = useState();

  useEffect(() => {
    if (currentStep === 1) setRequiredAnanmnese(requiredHistoryDevices);
    else if (currentStep === 2) setRequiredAnanmnese(requiredPhysicalExam);
    else if (currentStep === 3) setRequiredAnanmnese(requiredResults);
    else return "";
  }, [currentStep]);

  const stepsLabel = [
    "Histórico e aparelhos",
    "Exame Físico",
    "Resultados de exames e diagnóstico secundário",
  ];

  const getAnamneseItemCode = (value, prop) => {
    if (value !== "") {
      return anamneseExameFisico.fields[prop].find((obj) => obj.label === value)
        .code;
    } else return "";
  };

  const getAnamneseItemLabel = (value, prop) => {
    return anamneseExameFisico.fields[prop].find((obj) => obj.code === value)[
      "label"
    ];
  };

  const consulting = async () => {
    try {
      const responseData = await getAnamneseSolicitation().getSingle(
        currentSolicitation.solicitacao.idSolicitacao
      );
      if (Object.keys(responseData)?.length) {
        if (responseData?.anamneseHistorico?.idAnamneseHistorico !== null) {
          dispatch(
            setAnamnesePart({
              step: "historyDevices",
              value: responseData.anamneseHistorico,
            })
          );
        }
        if (responseData?.anamneseExameFisico?.idAnamneseExameFisico !== null) {
          const fisicalExam = responseData?.anamneseExameFisico;
          fisicalExam.estadoGeral = getAnamneseItemLabel(
            fisicalExam.estadoGeral,
            "estadoGeral"
          );
          fisicalExam.vivacidade = getAnamneseItemLabel(
            fisicalExam.vivacidade,
            "vivacidade"
          );
          fisicalExam.sintomas = getAnamneseItemLabel(
            fisicalExam.sintomas,
            "sintomas"
          );
          fisicalExam.transpiracao = getAnamneseItemLabel(
            fisicalExam.transpiracao,
            "transpiracao"
          );

          dispatch(
            setAnamnesePart({
              step: "physicalExam",
              value: fisicalExam,
            })
          );
        }
        if (responseData?.anamneseResultado?.idAnamneseResultado !== null) {
          dispatch(
            setAnamnesePart({
              step: "ResultsDiagnosiSecondary",
              value: responseData?.anamneseResultado,
            })
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (currentSolicitation.solicitacao.idSolicitacao !== null) consulting();
  }, []);

  const handleChangeInput = (e, prop, section) => {
    var newValue = { ...patient.anamnese[section], [prop]: e.target.value };
    dispatch(setAnamnesePart({ step: section, value: newValue }));
  };
  useEffect(() => {
    scroll();
  }, [currentStep]);

  const scroll = () => {
    const section = document.querySelector("#top");
    section.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleChangeRadio = (value, section, prop) => {
    var newValue = { ...patient.anamnese[section], [prop]: value };
    dispatch(setAnamnesePart({ step: section, value: newValue }));

    if (value !== "NDN" && value !== "Sem queixa") {
      setRequiredAnanmnese([...requiredAnamnese, prop]);
    } else setRequiredAnanmnese([...requiredAnamnese]);
  };

  const handleCheckBoxChange = (prop, section) => {
    var newAnamnese = { ...patient.anamnese };
    var newValue = !patient.anamnese[section][prop];
    newAnamnese[section] = { ...newAnamnese[section], [prop]: newValue };
    dispatch(
      setPatientProperty(updateProp({ id: "anamnese", value: newAnamnese }))
    );
  };

  const getInputValue = (val) => {
    if (val === "Sem queixa") return "";
    if (val === "NDN") return "Nada digno de Nota";
    return val;
  };

  const historyAndDevices = () => {
    return (
      <>
        <S.AnamneseRow id="history">
          <TextareaWrap
            marginBottom="40px"
            mobileWidth="100%"
            style={{ flex: 1 }}
            height="158px"
            marginRight="0px"
          >
            <TextArea
              value={patient.anamnese.historyDevices.queixa}
              getHandleFunction={(e) => e}
              handleChange={(e) =>
                handleChangeInput(e, "queixa", "historyDevices")
              }
              section={"historyDevices"}
              prop={"claimDuration"}
              type="anamnese"
              label="Queixa e Duração *"
              maxLength={2000}
              message="Você atingiu o limite de caractere."
              helperText={
                patient.anamnese.historyDevices.queixa === "" && typeError ? (
                  <Error>Campo obrigatório</Error>
                ) : (
                  ""
                )
              }
              helperIcon={
                patient.anamnese.historyDevices.queixa === "" && typeError ? (
                  <ErrorIcon style={{ color: "#EA1F1F" }} />
                ) : (
                  <ErrorIcon style={{ visibility: "hidden" }} />
                )
              }
              error={patient.anamnese.historyDevices.queixa === "" && typeError}
            />
          </TextareaWrap>
          <TextareaWrap marginBottom="40px" style={{ flex: 1 }} height="158px">
            <TextArea
              value={patient.anamnese.historyDevices.historiaMolestia}
              getHandleFunction={(e) => e}
              section={"historyDevices"}
              prop={"historiaMolestia"}
              type="anamnese"
              label="História da Moléstia Atual *"
              maxLength={1000}
              message="Você atingiu o limite de caractere."
              helperText={
                patient.anamnese.historyDevices.historiaMolestia === "" &&
                typeError ? (
                  <Error>Campo obrigatório</Error>
                ) : (
                  ""
                )
              }
              helperIcon={
                patient.anamnese.historyDevices.historiaMolestia === "" &&
                typeError ? (
                  <ErrorIcon style={{ color: "#EA1F1F" }} />
                ) : (
                  <ErrorIcon style={{ visibility: "hidden" }} />
                )
              }
              error={
                patient.anamnese.historyDevices.historiaMolestia === "" &&
                typeError
              }
            />
          </TextareaWrap>
        </S.AnamneseRow>
        <S.AnamneseRow>
          <TextareaWrap
            marginBottom="40px"
            style={{ flex: 1 }}
            height="158px"
            marginRight="0px"
          >
            <TextArea
              value={patient.anamnese.historyDevices.antecedentesPessoais}
              type="anamnese"
              getHandleFunction={(e) => e}
              setProp={setPatientProperty}
              prop={"antecedentesPessoais"}
              section={"historyDevices"}
              label="Antecedentes pessoais *"
              maxLength={1000}
              message="Você atingiu o limite de caractere."
              helperText={
                patient.anamnese.historyDevices.antecedentesPessoais === "" &&
                typeError ? (
                  <Error>Campo obrigatório</Error>
                ) : (
                  ""
                )
              }
              helperIcon={
                patient.anamnese.historyDevices.antecedentesPessoais === "" &&
                typeError ? (
                  <ErrorIcon style={{ color: "#EA1F1F" }} />
                ) : (
                  <ErrorIcon style={{ visibility: "hidden" }} />
                )
              }
              error={
                patient.anamnese.historyDevices.antecedentesPessoais === "" &&
                typeError
              }
            />
          </TextareaWrap>
          <TextareaWrap marginBottom="40px" style={{ flex: 1 }} height="158px">
            <TextArea
              value={patient.anamnese.historyDevices.antecedentesFamiliares}
              getHandleFunction={(e) => e}
              prop={"antecedentesFamiliares"}
              setProp={setPatientProperty}
              section={"historyDevices"}
              type="anamnese"
              label="Antecedentes familiares e/ou sociais:"
              maxLength={1000}
              message="Você atingiu o limite de caractere."
            />
          </TextareaWrap>
        </S.AnamneseRow>
        <S.AnamneseRow>
          <TextareaWrap
            marginBottom="40px"
            style={{ flex: 1 }}
            height="158px"
            marginRight="0px"
          >
            <TextArea
              value={patient.anamnese.historyDevices.habitos}
              placeholder=""
              getHandleFunction={(e) => e}
              setProp={setPatientProperty}
              section={"historyDevices"}
              type="anamnese"
              prop={"habitos"}
              label="Hábitos"
              maxLength={1000}
              message="Você atingiu o limite de caractere."
            />
          </TextareaWrap>
          <TextareaWrap marginBottom="40px" style={{ flex: 1 }} height="158px">
            <TextArea
              value={patient.anamnese.historyDevices.medicamentosEmUso}
              getHandleFunction={(e) => e}
              setProp={setPatientProperty}
              section={"historyDevices"}
              type="anamnese"
              prop={"medicamentosEmUso"}
              label="Medicamentos em USO: *"
              maxLength={1000}
              message="Você atingiu o limite de caractere."
              helperText={
                patient.anamnese.historyDevices.medicamentosEmUso === "" &&
                typeError ? (
                  <Error>Campo obrigatório</Error>
                ) : (
                  ""
                )
              }
              helperIcon={
                patient.anamnese.historyDevices.medicamentosEmUso === "" &&
                typeError ? (
                  <ErrorIcon style={{ color: "#EA1F1F" }} />
                ) : (
                  <ErrorIcon style={{ visibility: "hidden" }} />
                )
              }
              error={
                patient.anamnese.historyDevices.medicamentosEmUso === "" &&
                typeError
              }
            />
          </TextareaWrap>
        </S.AnamneseRow>
        <S.AnamneseRow marginRight="0px">
          <TmpInputCC
            label="Alergias *"
            type="text"
            placeholder=""
            value={patient.anamnese.historyDevices.alergias}
            style={{ width: "100%" }}
            // getHandleFunction={e => e}
            handleChange={(e) =>
              handleChangeInput(e, "alergias", "historyDevices")
            }
            maxLength={1000}
            message="Você atingiu o limite de caractere."
            showCounter={true}
            helperText={
              patient.anamnese.historyDevices.alergias === "" && typeError ? (
                <Error>Campo obrigatório</Error>
              ) : (
                ""
              )
            }
            helperIcon={
              patient.anamnese.historyDevices.alergias === "" && typeError ? (
                <ErrorIcon style={{ color: "#EA1F1F" }} />
              ) : (
                <ErrorIcon style={{ visibility: "hidden" }} />
              )
            }
            error={patient.anamnese.historyDevices.alergias === "" && typeError}
          />
        </S.AnamneseRow>
        <S.AnamneseRow style={{ justifyContent: "center" }}>
          <S.StyledRadio style={{ flex: 1 }}>
            <ComponentLabel>
              {patient.anamnese.historyDevices.geral !== "NDN" &&
              patient.anamnese.historyDevices.geral !== "Sem queixa"
                ? "Geral *"
                : "Geral"}
            </ComponentLabel>
            <S.StyledRadioGroup>
              <TmpRadio
                label="Sem queixa"
                value="Sem queixa"
                name="general"
                checked={patient.anamnese.historyDevices.geral === "Sem queixa"}
                handleChange={() =>
                  handleChangeRadio("Sem queixa", "historyDevices", "geral")
                }
              />
              <TmpRadio
                label="NDN"
                value="NDN"
                name="general"
                checked={patient.anamnese.historyDevices.geral === "NDN"}
                handleChange={() =>
                  handleChangeRadio("NDN", "historyDevices", "geral")
                }
              />
              <TmpRadio
                label="Sim"
                value="Sim"
                name="general"
                checked={
                  patient.anamnese.historyDevices.geral !== "NDN" &&
                  patient.anamnese.historyDevices.geral !== "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio("", "historyDevices", "geral")
                }
              />
            </S.StyledRadioGroup>
            <S.InputWrap>
              <TmpInputCC
                maxLength={250}
                showCounter={true}
                message="Você atingiu o limite de caractere."
                type="text"
                placeholder=""
                value={getInputValue(patient.anamnese.historyDevices.geral)}
                disabled={
                  patient.anamnese.historyDevices.geral === "Sem queixa" ||
                  patient.anamnese.historyDevices.geral === "NDN"
                }
                handleChange={(e) =>
                  handleChangeInput(e, "geral", "historyDevices")
                }
                error={
                  typeError &&
                  patient.anamnese.historyDevices.geral === "" &&
                  patient.anamnese.historyDevices.geral !== "NDN" &&
                  patient.anamnese.historyDevices.geral !== "Sem queixa"
                }
                helperText={
                  typeError &&
                  patient.anamnese.historyDevices.geral === "" &&
                  patient.anamnese.historyDevices.geral !== "NDN" &&
                  patient.anamnese.historyDevices.geral !== "Sem queixa" ? (
                    <Error>Campo obrigatório</Error>
                  ) : null
                }
                helperIcon={
                  typeError &&
                  patient.anamnese.historyDevices.geral === "" &&
                  patient.anamnese.historyDevices.geral !== "NDN" &&
                  patient.anamnese.historyDevices.geral !== "Sem queixa" ? (
                    <ErrorIcon />
                  ) : null
                }
              />
            </S.InputWrap>
          </S.StyledRadio>
          <S.StyledRadio style={{ flex: 1 }}>
            <ComponentLabel>
              {patient.anamnese.historyDevices.neurologico !== "NDN" &&
              patient.anamnese.historyDevices.neurologico !== "Sem queixa"
                ? "Neurológico *"
                : "Neurológico"}
            </ComponentLabel>
            <S.StyledRadioGroup>
              <TmpRadio
                label="Sem queixa"
                value="Sem queixa"
                name="neurological"
                checked={
                  patient.anamnese.historyDevices.neurologico === "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio(
                    "Sem queixa",
                    "historyDevices",
                    "neurologico"
                  )
                }
              />
              <TmpRadio
                label="NDN"
                value="NDN"
                name="neurological"
                checked={patient.anamnese.historyDevices.neurologico === "NDN"}
                handleChange={() =>
                  handleChangeRadio("NDN", "historyDevices", "neurologico")
                }
              />
              <TmpRadio
                label="Sim"
                value="Sim"
                name="neurological"
                checked={
                  patient.anamnese.historyDevices.neurologico !== "NDN" &&
                  patient.anamnese.historyDevices.neurologico !== "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio("", "historyDevices", "neurologico")
                }
              />
            </S.StyledRadioGroup>
            <S.InputWrap>
              <TmpInputCC
                maxLength={250}
                showCounter={true}
                message="Você atingiu o limite de caractere."
                type="text"
                placeholder=""
                value={getInputValue(
                  patient.anamnese.historyDevices.neurologico
                )}
                disabled={
                  patient.anamnese.historyDevices.neurologico ===
                    "Sem queixa" ||
                  patient.anamnese.historyDevices.neurologico === "NDN"
                }
                handleChange={(e) =>
                  handleChangeInput(e, "neurologico", "historyDevices")
                }
                error={
                  typeError &&
                  patient.anamnese.historyDevices.neurologico === "" &&
                  patient.anamnese.historyDevices.neurologico !== "NDN" &&
                  patient.anamnese.historyDevices.neurologico !== "Sem queixa"
                }
                helperText={
                  typeError &&
                  patient.anamnese.historyDevices.neurologico === "" &&
                  patient.anamnese.historyDevices.neurologico !== "NDN" &&
                  patient.anamnese.historyDevices.neurologico !==
                    "Sem queixa" ? (
                    <Error>Campo obrigatório</Error>
                  ) : null
                }
                helperIcon={
                  typeError &&
                  patient.anamnese.historyDevices.neurologico === "" &&
                  patient.anamnese.historyDevices.neurologico !== "NDN" &&
                  patient.anamnese.historyDevices.neurologico !==
                    "Sem queixa" ? (
                    <ErrorIcon />
                  ) : null
                }
              />
            </S.InputWrap>
          </S.StyledRadio>
        </S.AnamneseRow>
        <S.AnamneseRow>
          <S.StyledRadio style={{ flex: 1 }}>
            <ComponentLabel>
              {patient.anamnese.historyDevices.cabecaPescoco !== "NDN" &&
              patient.anamnese.historyDevices.cabecaPescoco !== "Sem queixa"
                ? "Cabeça/Pescoço *"
                : "Cabeça/Pescoço"}
            </ComponentLabel>
            <S.StyledRadioGroup>
              <TmpRadio
                label="Sem queixa"
                value="Sem queixa"
                name="headNeck"
                checked={
                  patient.anamnese.historyDevices.cabecaPescoco === "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio(
                    "Sem queixa",
                    "historyDevices",
                    "cabecaPescoco"
                  )
                }
              />
              <TmpRadio
                label="NDN"
                value="NDN"
                name="headNeck"
                checked={
                  patient.anamnese.historyDevices.cabecaPescoco === "NDN"
                }
                handleChange={() =>
                  handleChangeRadio("NDN", "historyDevices", "cabecaPescoco")
                }
              />
              <TmpRadio
                label="Sim"
                value="Sim"
                name="headNeck"
                checked={
                  patient.anamnese.historyDevices.cabecaPescoco !== "NDN" &&
                  patient.anamnese.historyDevices.cabecaPescoco !== "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio("", "historyDevices", "cabecaPescoco")
                }
              />
            </S.StyledRadioGroup>
            <S.InputWrap>
              <TmpInputCC
                maxLength={250}
                showCounter={true}
                message="Você atingiu o limite de caractere."
                type="text"
                placeholder=""
                value={getInputValue(
                  patient.anamnese.historyDevices.cabecaPescoco
                )}
                disabled={
                  patient.anamnese.historyDevices.cabecaPescoco ===
                    "Sem queixa" ||
                  patient.anamnese.historyDevices.cabecaPescoco === "NDN"
                }
                handleChange={(e) =>
                  handleChangeInput(e, "cabecaPescoco", "historyDevices")
                }
                error={
                  typeError &&
                  patient.anamnese.historyDevices.cabecaPescoco === "" &&
                  patient.anamnese.historyDevices.cabecaPescoco !== "NDN" &&
                  patient.anamnese.historyDevices.cabecaPescoco !== "Sem queixa"
                }
                helperText={
                  typeError &&
                  patient.anamnese.historyDevices.cabecaPescoco === "" &&
                  patient.anamnese.historyDevices.cabecaPescoco !== "NDN" &&
                  patient.anamnese.historyDevices.cabecaPescoco !==
                    "Sem queixa" ? (
                    <Error>Campo obrigatório</Error>
                  ) : null
                }
                helperIcon={
                  typeError &&
                  patient.anamnese.historyDevices.cabecaPescoco === "" &&
                  patient.anamnese.historyDevices.cabecaPescoco !== "NDN" &&
                  patient.anamnese.historyDevices.cabecaPescoco !==
                    "Sem queixa" ? (
                    <ErrorIcon />
                  ) : null
                }
              />
            </S.InputWrap>
          </S.StyledRadio>
          <S.StyledRadio style={{ flex: 1 }}>
            <ComponentLabel>
              {patient.anamnese.historyDevices.respiratorio !== "NDN" &&
              patient.anamnese.historyDevices.respiratorio !== "Sem queixa"
                ? "Respiratório *"
                : "Respiratório"}
            </ComponentLabel>
            <S.StyledRadioGroup>
              <TmpRadio
                label="Sem queixa"
                value="Sem queixa"
                name="respiratory"
                checked={
                  patient.anamnese.historyDevices.respiratorio === "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio(
                    "Sem queixa",
                    "historyDevices",
                    "respiratorio"
                  )
                }
              />
              <TmpRadio
                label="NDN"
                value="NDN"
                name="respiratory"
                checked={patient.anamnese.historyDevices.respiratorio === "NDN"}
                handleChange={() =>
                  handleChangeRadio("NDN", "historyDevices", "respiratorio")
                }
              />
              <TmpRadio
                label="Sim"
                value="Sim"
                name="respiratory"
                checked={
                  patient.anamnese.historyDevices.respiratorio !== "NDN" &&
                  patient.anamnese.historyDevices.respiratorio !== "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio("", "historyDevices", "respiratorio")
                }
              />
            </S.StyledRadioGroup>
            <S.InputWrap>
              <TmpInputCC
                maxLength={250}
                showCounter={true}
                message="Você atingiu o limite de caractere."
                type="text"
                placeholder=""
                value={getInputValue(
                  patient.anamnese.historyDevices.respiratorio
                )}
                disabled={
                  patient.anamnese.historyDevices.respiratorio ===
                    "Sem queixa" ||
                  patient.anamnese.historyDevices.respiratorio === "NDN"
                }
                handleChange={(e) =>
                  handleChangeInput(e, "respiratorio", "historyDevices")
                }
                error={
                  typeError &&
                  patient.anamnese.historyDevices.respiratorio === "" &&
                  patient.anamnese.historyDevices.respiratorio !== "NDN" &&
                  patient.anamnese.historyDevices.respiratorio !== "Sem queixa"
                }
                helperText={
                  typeError &&
                  patient.anamnese.historyDevices.respiratorio === "" &&
                  patient.anamnese.historyDevices.respiratorio !== "NDN" &&
                  patient.anamnese.historyDevices.respiratorio !==
                    "Sem queixa" ? (
                    <Error>Campo obrigatório</Error>
                  ) : null
                }
                helperIcon={
                  typeError &&
                  patient.anamnese.historyDevices.respiratorio === "" &&
                  patient.anamnese.historyDevices.respiratorio !== "NDN" &&
                  patient.anamnese.historyDevices.respiratorio !==
                    "Sem queixa" ? (
                    <ErrorIcon />
                  ) : null
                }
              />
            </S.InputWrap>
          </S.StyledRadio>
        </S.AnamneseRow>
        <S.AnamneseRow>
          <S.StyledRadio style={{ flex: 1 }}>
            <ComponentLabel>
              {patient.anamnese.historyDevices.cardiovascular !== "NDN" &&
              patient.anamnese.historyDevices.cardiovascular !== "Sem queixa"
                ? "Cardiovascular *"
                : "Cardiovascular"}
            </ComponentLabel>
            <S.StyledRadioGroup>
              <TmpRadio
                label="Sem queixa"
                value="Sem queixa"
                name="cardiovascular"
                checked={
                  patient.anamnese.historyDevices.cardiovascular ===
                  "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio(
                    "Sem queixa",
                    "historyDevices",
                    "cardiovascular"
                  )
                }
              />
              <TmpRadio
                label="NDN"
                value="NDN"
                name="cardiovascular"
                checked={
                  patient.anamnese.historyDevices.cardiovascular === "NDN"
                }
                handleChange={() =>
                  handleChangeRadio("NDN", "historyDevices", "cardiovascular")
                }
              />
              <TmpRadio
                label="Sim"
                value="Sim"
                name="cardiovascular"
                checked={
                  patient.anamnese.historyDevices.cardiovascular !== "NDN" &&
                  patient.anamnese.historyDevices.cardiovascular !==
                    "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio("", "historyDevices", "cardiovascular")
                }
              />
            </S.StyledRadioGroup>
            <S.InputWrap>
              <TmpInputCC
                maxLength={250}
                showCounter={true}
                message="Você atingiu o limite de caractere."
                type="text"
                placeholder=""
                value={getInputValue(
                  patient.anamnese.historyDevices.cardiovascular
                )}
                disabled={
                  patient.anamnese.historyDevices.cardiovascular ===
                    "Sem queixa" ||
                  patient.anamnese.historyDevices.cardiovascular === "NDN"
                }
                handleChange={(e) =>
                  handleChangeInput(e, "cardiovascular", "historyDevices")
                }
                error={
                  typeError &&
                  patient.anamnese.historyDevices.cardiovascular === "" &&
                  patient.anamnese.historyDevices.cardiovascular !== "NDN" &&
                  patient.anamnese.historyDevices.cardiovascular !==
                    "Sem queixa"
                }
                helperText={
                  typeError &&
                  patient.anamnese.historyDevices.cardiovascular === "" &&
                  patient.anamnese.historyDevices.cardiovascular !== "NDN" &&
                  patient.anamnese.historyDevices.cardiovascular !==
                    "Sem queixa" ? (
                    <Error>Campo obrigatório</Error>
                  ) : null
                }
                helperIcon={
                  typeError &&
                  patient.anamnese.historyDevices.cardiovascular === "" &&
                  patient.anamnese.historyDevices.cardiovascular !== "NDN" &&
                  patient.anamnese.historyDevices.cardiovascular !==
                    "Sem queixa" ? (
                    <ErrorIcon />
                  ) : null
                }
              />
            </S.InputWrap>
          </S.StyledRadio>
          <S.StyledRadio style={{ flex: 1 }}>
            <ComponentLabel>
              {patient.anamnese.historyDevices.gastrointestinal !== "NDN" &&
              patient.anamnese.historyDevices.gastrointestinal !== "Sem queixa"
                ? "Gastrointestinal *"
                : "Gastrointestinal"}
            </ComponentLabel>
            <S.StyledRadioGroup>
              <TmpRadio
                label="Sem queixa"
                value="Sem queixa"
                name="gastrointestinal"
                checked={
                  patient.anamnese.historyDevices.gastrointestinal ===
                  "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio(
                    "Sem queixa",
                    "historyDevices",
                    "gastrointestinal"
                  )
                }
              />
              <TmpRadio
                label="NDN"
                value="NDN"
                name="gastrointestinal"
                checked={
                  patient.anamnese.historyDevices.gastrointestinal === "NDN"
                }
                handleChange={() =>
                  handleChangeRadio("NDN", "historyDevices", "gastrointestinal")
                }
              />
              <TmpRadio
                label="Sim"
                value="Sim"
                name="gastrointestinal"
                checked={
                  patient.anamnese.historyDevices.gastrointestinal !== "NDN" &&
                  patient.anamnese.historyDevices.gastrointestinal !==
                    "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio("", "historyDevices", "gastrointestinal")
                }
              />
            </S.StyledRadioGroup>
            <S.InputWrap>
              <TmpInputCC
                maxLength={250}
                showCounter={true}
                message="Você atingiu o limite de caractere."
                type="text"
                placeholder=""
                value={getInputValue(
                  patient.anamnese.historyDevices.gastrointestinal
                )}
                disabled={
                  patient.anamnese.historyDevices.gastrointestinal ===
                    "Sem queixa" ||
                  patient.anamnese.historyDevices.gastrointestinal === "NDN"
                }
                handleChange={(e) =>
                  handleChangeInput(e, "gastrointestinal", "historyDevices")
                }
                error={
                  typeError &&
                  patient.anamnese.historyDevices.gastrointestinal === "" &&
                  patient.anamnese.historyDevices.gastrointestinal !== "NDN" &&
                  patient.anamnese.historyDevices.gastrointestinal !==
                    "Sem queixa"
                }
                helperText={
                  typeError &&
                  patient.anamnese.historyDevices.gastrointestinal === "" &&
                  patient.anamnese.historyDevices.gastrointestinal !== "NDN" &&
                  patient.anamnese.historyDevices.gastrointestinal !==
                    "Sem queixa" ? (
                    <Error>Campo obrigatório</Error>
                  ) : null
                }
                helperIcon={
                  typeError &&
                  patient.anamnese.historyDevices.gastrointestinal === "" &&
                  patient.anamnese.historyDevices.gastrointestinal !== "NDN" &&
                  patient.anamnese.historyDevices.gastrointestinal !==
                    "Sem queixa" ? (
                    <ErrorIcon />
                  ) : null
                }
              />
            </S.InputWrap>
          </S.StyledRadio>
        </S.AnamneseRow>
        <S.AnamneseRow>
          <S.StyledRadio style={{ flex: 1 }}>
            <ComponentLabel>
              {patient.anamnese.historyDevices.genitoUrinario !== "NDN" &&
              patient.anamnese.historyDevices.genitoUrinario !== "Sem queixa"
                ? "Gênito-Urinário *"
                : "Gênito-Urinário"}
            </ComponentLabel>
            <S.StyledRadioGroup>
              <TmpRadio
                label="Sem queixa"
                value="Sem queixa"
                name="urinaryGenitourinary"
                checked={
                  patient.anamnese.historyDevices.genitoUrinario ===
                  "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio(
                    "Sem queixa",
                    "historyDevices",
                    "genitoUrinario"
                  )
                }
              />
              <TmpRadio
                label="NDN"
                value="NDN"
                name="urinaryGenitourinary"
                checked={
                  patient.anamnese.historyDevices.genitoUrinario === "NDN"
                }
                handleChange={() =>
                  handleChangeRadio("NDN", "historyDevices", "genitoUrinario")
                }
              />
              <TmpRadio
                label="Sim"
                value="Sim"
                name="urinaryGenitourinary"
                checked={
                  patient.anamnese.historyDevices.genitoUrinario !== "NDN" &&
                  patient.anamnese.historyDevices.genitoUrinario !==
                    "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio("", "historyDevices", "genitoUrinario")
                }
              />
            </S.StyledRadioGroup>
            <S.InputWrap>
              <TmpInputCC
                showCounter={true}
                message="Você atingiu o limite de caractere."
                maxLength={250}
                type="text"
                placeholder=""
                value={getInputValue(
                  patient.anamnese.historyDevices.genitoUrinario
                )}
                disabled={
                  patient.anamnese.historyDevices.genitoUrinario ===
                    "Sem queixa" ||
                  patient.anamnese.historyDevices.genitoUrinario === "NDN"
                }
                handleChange={(e) =>
                  handleChangeInput(e, "genitoUrinario", "historyDevices")
                }
                error={
                  typeError &&
                  patient.anamnese.historyDevices.genitoUrinario === "" &&
                  patient.anamnese.historyDevices.genitoUrinario !== "NDN" &&
                  patient.anamnese.historyDevices.genitoUrinario !==
                    "Sem queixa"
                }
                helperText={
                  typeError &&
                  patient.anamnese.historyDevices.genitoUrinario === "" &&
                  patient.anamnese.historyDevices.genitoUrinario !== "NDN" &&
                  patient.anamnese.historyDevices.genitoUrinario !==
                    "Sem queixa" ? (
                    <Error>Campo obrigatório</Error>
                  ) : null
                }
                helperIcon={
                  typeError &&
                  patient.anamnese.historyDevices.genitoUrinario === "" &&
                  patient.anamnese.historyDevices.genitoUrinario !== "NDN" &&
                  patient.anamnese.historyDevices.genitoUrinario !==
                    "Sem queixa" ? (
                    <ErrorIcon />
                  ) : null
                }
              />
            </S.InputWrap>
          </S.StyledRadio>
          <S.StyledRadio style={{ flex: 1 }}>
            <ComponentLabel>
              {patient.anamnese.historyDevices.peleAnexos !== "NDN" &&
              patient.anamnese.historyDevices.peleAnexos !== "Sem queixa"
                ? "Pele / Anexos *"
                : "Pele / Anexos"}
            </ComponentLabel>
            <S.StyledRadioGroup>
              <TmpRadio
                label="Sem queixa"
                value="Sem queixa"
                name="skinAttachments"
                checked={
                  patient.anamnese.historyDevices.peleAnexos === "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio(
                    "Sem queixa",
                    "historyDevices",
                    "peleAnexos"
                  )
                }
              />
              <TmpRadio
                label="NDN"
                value="NDN"
                name="skinAttachments"
                checked={patient.anamnese.historyDevices.peleAnexos === "NDN"}
                handleChange={() =>
                  handleChangeRadio("NDN", "historyDevices", "peleAnexos")
                }
              />
              <TmpRadio
                label="Sim"
                value="Sim"
                name="skinAttachments"
                checked={
                  patient.anamnese.historyDevices.peleAnexos !== "NDN" &&
                  patient.anamnese.historyDevices.peleAnexos !== "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio("", "historyDevices", "peleAnexos")
                }
              />
            </S.StyledRadioGroup>
            <S.InputWrap>
              <TmpInputCC
                maxLength={250}
                showCounter={true}
                message="Você atingiu o limite de caractere."
                type="text"
                placeholder=""
                value={getInputValue(
                  patient.anamnese.historyDevices.peleAnexos
                )}
                disabled={
                  patient.anamnese.historyDevices.peleAnexos === "Sem queixa" ||
                  patient.anamnese.historyDevices.peleAnexos === "NDN"
                }
                handleChange={(e) =>
                  handleChangeInput(e, "peleAnexos", "historyDevices")
                }
                error={
                  typeError &&
                  patient.anamnese.historyDevices.peleAnexos === "" &&
                  patient.anamnese.historyDevices.peleAnexos !== "NDN" &&
                  patient.anamnese.historyDevices.peleAnexos !== "Sem queixa"
                }
                helperText={
                  typeError &&
                  patient.anamnese.historyDevices.peleAnexos === "" &&
                  patient.anamnese.historyDevices.peleAnexos !== "NDN" &&
                  patient.anamnese.historyDevices.peleAnexos !==
                    "Sem queixa" ? (
                    <Error>Campo obrigatório</Error>
                  ) : null
                }
                helperIcon={
                  typeError &&
                  patient.anamnese.historyDevices.peleAnexos === "" &&
                  patient.anamnese.historyDevices.peleAnexos !== "NDN" &&
                  patient.anamnese.historyDevices.peleAnexos !==
                    "Sem queixa" ? (
                    <ErrorIcon />
                  ) : null
                }
              />
            </S.InputWrap>
          </S.StyledRadio>
        </S.AnamneseRow>
        <S.AnamneseRow alignSelf="flex-start">
          <S.StyledRadio style={{ flex: 1 }}>
            <ComponentLabel>
              {patient.anamnese.historyDevices.musculoEsqueletico !== "NDN" &&
              patient.anamnese.historyDevices.musculoEsqueletico !==
                "Sem queixa"
                ? "Músculo-esquelético *"
                : "Músculo-esquelético"}
            </ComponentLabel>
            <S.StyledRadioGroup>
              <TmpRadio
                label="Sem queixa"
                value="Sem queixa"
                name="muscleEsqueletic"
                checked={
                  patient.anamnese.historyDevices.musculoEsqueletico ===
                  "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio(
                    "Sem queixa",
                    "historyDevices",
                    "musculoEsqueletico"
                  )
                }
              />
              <TmpRadio
                label="NDN"
                value="NDN"
                name="muscleEsqueletic"
                checked={
                  patient.anamnese.historyDevices.musculoEsqueletico === "NDN"
                }
                handleChange={() =>
                  handleChangeRadio(
                    "NDN",
                    "historyDevices",
                    "musculoEsqueletico"
                  )
                }
              />
              <TmpRadio
                label="Sim"
                value="Sim"
                name="muscleEsqueletic"
                checked={
                  patient.anamnese.historyDevices.musculoEsqueletico !==
                    "NDN" &&
                  patient.anamnese.historyDevices.musculoEsqueletico !==
                    "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio("", "historyDevices", "musculoEsqueletico")
                }
              />
            </S.StyledRadioGroup>
            <S.InputWrap>
              <TmpInputCC
                maxLength={250}
                showCounter={true}
                message="Você atingiu o limite de caractere."
                type="text"
                placeholder=""
                value={getInputValue(
                  patient.anamnese.historyDevices.musculoEsqueletico
                )}
                disabled={
                  patient.anamnese.historyDevices.musculoEsqueletico ===
                    "Sem queixa" ||
                  patient.anamnese.historyDevices.musculoEsqueletico === "NDN"
                }
                handleChange={(e) =>
                  handleChangeInput(e, "musculoEsqueletico", "historyDevices")
                }
                error={
                  typeError &&
                  patient.anamnese.historyDevices.musculoEsqueletico === "" &&
                  patient.anamnese.historyDevices.musculoEsqueletico !==
                    "NDN" &&
                  patient.anamnese.historyDevices.musculoEsqueletico !==
                    "Sem queixa"
                }
                helperText={
                  typeError &&
                  patient.anamnese.historyDevices.musculoEsqueletico === "" &&
                  patient.anamnese.historyDevices.musculoEsqueletico !==
                    "NDN" &&
                  patient.anamnese.historyDevices.musculoEsqueletico !==
                    "Sem queixa" ? (
                    <Error>Campo obrigatório</Error>
                  ) : null
                }
                helperIcon={
                  typeError &&
                  patient.anamnese.historyDevices.musculoEsqueletico === "" &&
                  patient.anamnese.historyDevices.musculoEsqueletico !==
                    "NDN" &&
                  patient.anamnese.historyDevices.musculoEsqueletico !==
                    "Sem queixa" ? (
                    <ErrorIcon />
                  ) : null
                }
              />
            </S.InputWrap>
          </S.StyledRadio>
        </S.AnamneseRow>
      </>
    );
  };

  const physicalExam = () => {
    return (
      <>
        <S.AnamneseRow style={{ justifyContent: "center" }}>
          <S.RadioTable display={"flex"} marginTop={"16px"} mobileColumn>
            <S.StyledRadioColumn
              mobileColumn
              margin="0 24px 24px 0"
              width="329px"
              marginRight="10px"
            >
              <ComponentLabel
                error={
                  typeError && patient.anamnese.physicalExam.estadoGeral === ""
                }
                margin="0 0 24px 0"
              >
                Estado Geral *
              </ComponentLabel>
              <TmpRadio
                label="Bom"
                value="Bom"
                name="estadoGeral"
                checked={patient.anamnese.physicalExam.estadoGeral === "Bom"}
                handleChange={() =>
                  handleChangeRadio("Bom", "physicalExam", "estadoGeral")
                }
              />
              <TmpDivider />
              <TmpRadio
                label="Regular"
                value="Regular"
                name="estadoGeral"
                checked={
                  patient.anamnese.physicalExam.estadoGeral === "Regular"
                }
                handleChange={() =>
                  handleChangeRadio("Regular", "physicalExam", "estadoGeral")
                }
              />
              <TmpDivider />
              <TmpRadio
                label="Mal"
                value="Mal"
                name="estadoGeral"
                checked={patient.anamnese.physicalExam.estadoGeral === "Mal"}
                handleChange={() =>
                  handleChangeRadio("Mal", "physicalExam", "estadoGeral")
                }
              />
              <TmpDivider />
              <S.Helper
                display={
                  typeError && patient.anamnese.physicalExam.estadoGeral === ""
                }
              >
                <ErrorIcon
                  style={{ color: "#EA1F1F", width: "16px", height: "16px" }}
                />
                Campo não preenchido
              </S.Helper>
            </S.StyledRadioColumn>
            <S.StyledRadioColumn
              mobileColumn
              margin="0 0 24px 0px"
              width="329px"
            >
              <ComponentLabel margin="0 0 24px 0">Vivacidade</ComponentLabel>
              <TmpRadio
                label="Apático"
                value="Apático"
                name="vivacidade"
                checked={patient.anamnese.physicalExam.vivacidade === "Apático"}
                handleChange={() =>
                  handleChangeRadio("Apático", "physicalExam", "vivacidade")
                }
              />
              <TmpDivider />
              <TmpRadio
                label="Agitado"
                value="Agitado"
                name="vivacidade"
                checked={patient.anamnese.physicalExam.vivacidade === "Agitado"}
                handleChange={() =>
                  handleChangeRadio("Agitado", "physicalExam", "vivacidade")
                }
              />
              <TmpDivider />
              <TmpRadio
                label="Palidez cutâneo-mucosa"
                value="Palidez cutâneo-mucosa"
                name="vivacidade"
                checked={
                  patient.anamnese.physicalExam.vivacidade ===
                  "Palidez cutâneo-mucosa"
                }
                handleChange={() =>
                  handleChangeRadio(
                    "Palidez cutâneo-mucosa",
                    "physicalExam",
                    "vivacidade"
                  )
                }
              />
              <TmpDivider />
            </S.StyledRadioColumn>
          </S.RadioTable>
          <S.RadioTable display={"flex"} marginTop={"16px"} mobileColumn>
            <S.StyledRadioColumn
              mobileColumn
              margin="0 24px 24px 0px"
              width="329px"
            >
              <ComponentLabel margin="0 0 24px 0">Sintomas</ComponentLabel>
              <TmpRadio
                label="Cianótico"
                value="Cianótico"
                name="sintomas"
                checked={patient.anamnese.physicalExam.sintomas === "Cianótico"}
                handleChange={() =>
                  handleChangeRadio("Cianótico", "physicalExam", "sintomas")
                }
              />
              <TmpDivider />
              <TmpRadio
                label="Ictérico"
                value="Ictérico"
                name="sintomas"
                checked={patient.anamnese.physicalExam.sintomas === "Ictérico"}
                handleChange={() =>
                  handleChangeRadio("Ictérico", "physicalExam", "sintomas")
                }
              />
              <TmpDivider />
              <TmpRadio
                label="Desidratado"
                value="Desidratado"
                name="sintomas"
                checked={
                  patient.anamnese.physicalExam.sintomas === "Desidratado"
                }
                handleChange={() =>
                  handleChangeRadio("Desidratado", "physicalExam", "sintomas")
                }
              />
              <TmpDivider />
            </S.StyledRadioColumn>
            <S.StyledRadioColumn
              mobileColumn
              margin="0 0 24px 0px"
              width="329px"
            >
              <ComponentLabel margin="0 0 24px 0">Transpiração</ComponentLabel>
              <TmpRadio
                label="Sudoreico"
                value="Sudoreico"
                name="transpiracao"
                checked={
                  patient.anamnese.physicalExam.transpiracao === "Sudoreico"
                }
                handleChange={() =>
                  handleChangeRadio("Sudoreico", "physicalExam", "transpiracao")
                }
              />
              <TmpDivider />
              <TmpRadio
                label="Dispneico"
                value="Dispneico"
                name="transpiracao"
                checked={
                  patient.anamnese.physicalExam.transpiracao === "Dispneico"
                }
                handleChange={() =>
                  handleChangeRadio("Dispneico", "physicalExam", "transpiracao")
                }
              />
              <TmpDivider />
              <TmpRadio
                label="Edema"
                value="Edema"
                name="transpiracao"
                checked={patient.anamnese.physicalExam.transpiracao === "Edema"}
                handleChange={() =>
                  handleChangeRadio("Edema", "physicalExam", "transpiracao")
                }
              />
              <TmpDivider />
            </S.StyledRadioColumn>
          </S.RadioTable>
        </S.AnamneseRow>
        <S.AnamneseRow>
          <S.StyledRadio style={{ flex: 1 }}>
            <ComponentLabel>
              {patient.anamnese.physicalExam.cabecaPescoco !== "NDN" &&
              patient.anamnese.physicalExam.cabecaPescoco !== "Sem queixa"
                ? "Cabeça/Pescoço *"
                : "Cabeça/Pescoço"}
            </ComponentLabel>
            <S.StyledRadioGroup>
              <TmpRadio
                label="Sem queixa"
                value="Sem queixa"
                name="cabecaPescoco"
                checked={
                  patient.anamnese.physicalExam.cabecaPescoco === "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio(
                    "Sem queixa",
                    "physicalExam",
                    "cabecaPescoco"
                  )
                }
              />
              <TmpRadio
                label="NDN"
                value="NDN"
                name="cabecaPescoco"
                checked={patient.anamnese.physicalExam.cabecaPescoco === "NDN"}
                handleChange={() =>
                  handleChangeRadio("NDN", "physicalExam", "cabecaPescoco")
                }
              />
              <TmpRadio
                label="Sim"
                value="Sim"
                name="cabecaPescoco"
                checked={
                  patient.anamnese.physicalExam.cabecaPescoco !== "NDN" &&
                  patient.anamnese.physicalExam.cabecaPescoco !== "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio("", "physicalExam", "cabecaPescoco")
                }
              />
            </S.StyledRadioGroup>
            <S.InputWrap>
              <TmpInputCC
                maxLength={250}
                showCounter={true}
                message="Você atingiu o limite de caractere."
                type="text"
                placeholder=""
                value={getInputValue(
                  patient.anamnese.physicalExam.cabecaPescoco
                )}
                disabled={
                  patient.anamnese.physicalExam.cabecaPescoco ===
                    "Sem queixa" ||
                  patient.anamnese.physicalExam.cabecaPescoco === "NDN"
                }
                handleChange={(e) =>
                  handleChangeInput(e, "cabecaPescoco", "physicalExam")
                }
                error={
                  typeError &&
                  patient.anamnese.physicalExam.cabecaPescoco === "" &&
                  patient.anamnese.physicalExam.cabecaPescoco !== "NDN" &&
                  patient.anamnese.physicalExam.cabecaPescoco !== "Sem queixa"
                }
                helperText={
                  typeError &&
                  patient.anamnese.physicalExam.cabecaPescoco === "" &&
                  patient.anamnese.physicalExam.cabecaPescoco !== "NDN" &&
                  patient.anamnese.physicalExam.cabecaPescoco !==
                    "Sem queixa" ? (
                    <Error>Campo obrigatório</Error>
                  ) : null
                }
                helperIcon={
                  typeError &&
                  patient.anamnese.physicalExam.cabecaPescoco === "" &&
                  patient.anamnese.physicalExam.cabecaPescoco !== "NDN" &&
                  patient.anamnese.physicalExam.cabecaPescoco !==
                    "Sem queixa" ? (
                    <ErrorIcon />
                  ) : null
                }
              />
            </S.InputWrap>
          </S.StyledRadio>
          <S.StyledRadio style={{ flex: 1 }}>
            <ComponentLabel>
              {patient.anamnese.physicalExam.respiratorio !== "NDN" &&
              patient.anamnese.physicalExam.respiratorio !== "Sem queixa"
                ? "Respiratório *"
                : "Respiratório"}
            </ComponentLabel>
            <S.StyledRadioGroup>
              <TmpRadio
                label="Sem queixa"
                value="Sem queixa"
                name="respiratorio"
                checked={
                  patient.anamnese.physicalExam.respiratorio === "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio(
                    "Sem queixa",
                    "physicalExam",
                    "respiratorio"
                  )
                }
              />
              <TmpRadio
                label="NDN"
                value="NDN"
                name="respiratorio"
                checked={patient.anamnese.physicalExam.respiratorio === "NDN"}
                handleChange={() =>
                  handleChangeRadio("NDN", "physicalExam", "respiratorio")
                }
              />
              <TmpRadio
                label="Sim"
                value="Sim"
                name="respiratorio"
                checked={
                  patient.anamnese.physicalExam.respiratorio !== "NDN" &&
                  patient.anamnese.physicalExam.respiratorio !== "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio("", "physicalExam", "respiratorio")
                }
              />
            </S.StyledRadioGroup>
            <S.InputWrap>
              <TmpInputCC
                maxLength={250}
                showCounter={true}
                message="Você atingiu o limite de caractere."
                type="text"
                placeholder=""
                value={getInputValue(
                  patient.anamnese.physicalExam.respiratorio
                )}
                disabled={
                  patient.anamnese.physicalExam.respiratorio === "Sem queixa" ||
                  patient.anamnese.physicalExam.respiratorio === "NDN"
                }
                handleChange={(e) =>
                  handleChangeInput(e, "respiratorio", "physicalExam")
                }
                error={
                  typeError &&
                  patient.anamnese.physicalExam.respiratorio === "" &&
                  patient.anamnese.physicalExam.respiratorio !== "NDN" &&
                  patient.anamnese.physicalExam.respiratorio !== "Sem queixa"
                }
                helperText={
                  typeError &&
                  patient.anamnese.physicalExam.respiratorio === "" &&
                  patient.anamnese.physicalExam.respiratorio !== "NDN" &&
                  patient.anamnese.physicalExam.respiratorio !==
                    "Sem queixa" ? (
                    <Error>Campo obrigatório</Error>
                  ) : null
                }
                helperIcon={
                  typeError &&
                  patient.anamnese.physicalExam.respiratorio === "" &&
                  patient.anamnese.physicalExam.respiratorio !== "NDN" &&
                  patient.anamnese.physicalExam.respiratorio !==
                    "Sem queixa" ? (
                    <ErrorIcon />
                  ) : null
                }
              />
            </S.InputWrap>
          </S.StyledRadio>
        </S.AnamneseRow>
        <S.AnamneseRow style={{ justifyContent: "center" }}>
          <S.StyledRadio style={{ flex: 1 }}>
            <ComponentLabel>
              {patient.anamnese.physicalExam.cardiaco !== "NDN" &&
              patient.anamnese.physicalExam.cardiaco !== "Sem queixa"
                ? "Cardíaco *"
                : "Cardíaco"}
            </ComponentLabel>
            <S.StyledRadioGroup>
              <TmpRadio
                label="Sem queixa"
                value="Sem queixa"
                name="cardiaco"
                checked={
                  patient.anamnese.physicalExam.cardiaco === "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio("Sem queixa", "physicalExam", "cardiaco")
                }
              />
              <TmpRadio
                label="NDN"
                value="NDN"
                name="cardiaco"
                checked={patient.anamnese.physicalExam.cardiaco === "NDN"}
                handleChange={() =>
                  handleChangeRadio("NDN", "physicalExam", "cardiaco")
                }
              />
              <TmpRadio
                label="Sim"
                value="Sim"
                name="cardiaco"
                checked={
                  patient.anamnese.physicalExam.cardiaco !== "NDN" &&
                  patient.anamnese.physicalExam.cardiaco !== "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio("", "physicalExam", "cardiaco")
                }
              />
            </S.StyledRadioGroup>
            <S.InputWrap>
              <TmpInputCC
                maxLength={250}
                showCounter={true}
                message="Você atingiu o limite de caractere."
                type="text"
                placeholder=""
                value={getInputValue(patient.anamnese.physicalExam.cardiaco)}
                disabled={
                  patient.anamnese.physicalExam.cardiaco === "Sem queixa" ||
                  patient.anamnese.physicalExam.cardiaco === "NDN"
                }
                handleChange={(e) =>
                  handleChangeInput(e, "cardiaco", "physicalExam")
                }
                error={
                  typeError &&
                  patient.anamnese.physicalExam.cardiaco === "" &&
                  patient.anamnese.physicalExam.cardiaco !== "NDN" &&
                  patient.anamnese.physicalExam.cardiaco !== "Sem queixa"
                }
                helperText={
                  typeError &&
                  patient.anamnese.physicalExam.cardiaco === "" &&
                  patient.anamnese.physicalExam.cardiaco !== "NDN" &&
                  patient.anamnese.physicalExam.cardiaco !== "Sem queixa" ? (
                    <Error>Campo obrigatório</Error>
                  ) : null
                }
                helperIcon={
                  typeError &&
                  patient.anamnese.physicalExam.cardiaco === "" &&
                  patient.anamnese.physicalExam.cardiaco !== "NDN" &&
                  patient.anamnese.physicalExam.cardiaco !== "Sem queixa" ? (
                    <ErrorIcon />
                  ) : null
                }
              />
            </S.InputWrap>
          </S.StyledRadio>
          <S.StyledRadio style={{ flex: 1 }}>
            <ComponentLabel>
              {patient.anamnese.physicalExam.vascular !== "NDN" &&
              patient.anamnese.physicalExam.vascular !== "Sem queixa"
                ? "Vascular *"
                : "Vascular"}
            </ComponentLabel>
            <S.StyledRadioGroup>
              <TmpRadio
                label="Sem queixa"
                value="Sem queixa"
                name="vascular"
                checked={
                  patient.anamnese.physicalExam.vascular === "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio("Sem queixa", "physicalExam", "vascular")
                }
              />
              <TmpRadio
                label="NDN"
                value="NDN"
                name="vascular"
                checked={patient.anamnese.physicalExam.vascular === "NDN"}
                handleChange={() =>
                  handleChangeRadio("NDN", "physicalExam", "vascular")
                }
              />
              <TmpRadio
                label="Sim"
                value="Sim"
                name="vascular"
                checked={
                  patient.anamnese.physicalExam.vascular !== "NDN" &&
                  patient.anamnese.physicalExam.vascular !== "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio("", "physicalExam", "vascular")
                }
              />
            </S.StyledRadioGroup>
            <S.InputWrap>
              <TmpInputCC
                maxLength={250}
                showCounter={true}
                message="Você atingiu o limite de caractere."
                type="text"
                placeholder=""
                value={getInputValue(patient.anamnese.physicalExam.vascular)}
                disabled={
                  patient.anamnese.physicalExam.vascular === "Sem queixa" ||
                  patient.anamnese.physicalExam.vascular === "NDN"
                }
                handleChange={(e) =>
                  handleChangeInput(e, "vascular", "physicalExam")
                }
                error={
                  typeError &&
                  patient.anamnese.physicalExam.vascular === "" &&
                  patient.anamnese.physicalExam.vascular !== "NDN" &&
                  patient.anamnese.physicalExam.vascular !== "Sem queixa"
                }
                helperText={
                  typeError &&
                  patient.anamnese.physicalExam.vascular === "" &&
                  patient.anamnese.physicalExam.vascular !== "NDN" &&
                  patient.anamnese.physicalExam.vascular !== "Sem queixa" ? (
                    <Error>Campo obrigatório</Error>
                  ) : null
                }
                helperIcon={
                  typeError &&
                  patient.anamnese.physicalExam.vascular === "" &&
                  patient.anamnese.physicalExam.vascular !== "NDN" &&
                  patient.anamnese.physicalExam.vascular !== "Sem queixa" ? (
                    <ErrorIcon />
                  ) : null
                }
              />
            </S.InputWrap>
          </S.StyledRadio>
        </S.AnamneseRow>
        <S.AnamneseRow>
          <S.StyledRadio style={{ flex: 1 }}>
            <ComponentLabel>
              {patient.anamnese.physicalExam.abdome !== "NDN" &&
              patient.anamnese.physicalExam.abdome !== "Sem queixa"
                ? "Abdome *"
                : "Abdome"}
            </ComponentLabel>
            <S.StyledRadioGroup>
              <TmpRadio
                label="Sem queixa"
                value="Sem queixa"
                name="abdome"
                checked={patient.anamnese.physicalExam.abdome === "Sem queixa"}
                handleChange={() =>
                  handleChangeRadio("Sem queixa", "physicalExam", "abdome")
                }
              />
              <TmpRadio
                label="NDN"
                value="NDN"
                name="abdome"
                checked={patient.anamnese.physicalExam.abdome === "NDN"}
                handleChange={() =>
                  handleChangeRadio("NDN", "physicalExam", "abdome")
                }
              />
              <TmpRadio
                label="Sim"
                value="Sim"
                name="abdome"
                checked={
                  patient.anamnese.physicalExam.abdome !== "NDN" &&
                  patient.anamnese.physicalExam.abdome !== "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio("", "physicalExam", "abdome")
                }
              />
            </S.StyledRadioGroup>
            <S.InputWrap>
              <TmpInputCC
                maxLength={250}
                showCounter={true}
                message="Você atingiu o limite de caractere."
                type="text"
                placeholder=""
                value={getInputValue(patient.anamnese.physicalExam.abdome)}
                disabled={
                  patient.anamnese.physicalExam.abdome === "Sem queixa" ||
                  patient.anamnese.physicalExam.abdome === "NDN"
                }
                handleChange={(e) =>
                  handleChangeInput(e, "abdome", "physicalExam")
                }
                error={
                  typeError &&
                  patient.anamnese.physicalExam.abdome === "" &&
                  patient.anamnese.physicalExam.abdome !== "NDN" &&
                  patient.anamnese.physicalExam.abdome !== "Sem queixa"
                }
                helperText={
                  typeError &&
                  patient.anamnese.physicalExam.abdome === "" &&
                  patient.anamnese.physicalExam.abdome !== "NDN" &&
                  patient.anamnese.physicalExam.abdome !== "Sem queixa" ? (
                    <Error>Campo obrigatório</Error>
                  ) : null
                }
                helperIcon={
                  typeError &&
                  patient.anamnese.physicalExam.abdome === "" &&
                  patient.anamnese.physicalExam.abdome !== "NDN" &&
                  patient.anamnese.physicalExam.abdome !== "Sem queixa" ? (
                    <ErrorIcon />
                  ) : null
                }
              />
            </S.InputWrap>
          </S.StyledRadio>
          <S.StyledRadio style={{ flex: 1 }}>
            <ComponentLabel>
              {patient.anamnese.physicalExam.genitoUrinario !== "NDN" &&
              patient.anamnese.physicalExam.genitoUrinario !== "Sem queixa"
                ? "Gênito-Urinário *"
                : "Gênito-Urinário"}
            </ComponentLabel>
            <S.StyledRadioGroup>
              <TmpRadio
                label="Sem queixa"
                value="Sem queixa"
                name="genitoUrinario"
                checked={
                  patient.anamnese.physicalExam.genitoUrinario === "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio(
                    "Sem queixa",
                    "physicalExam",
                    "genitoUrinario"
                  )
                }
              />
              <TmpRadio
                label="NDN"
                value="NDN"
                name="genitoUrinario"
                checked={patient.anamnese.physicalExam.genitoUrinario === "NDN"}
                handleChange={() =>
                  handleChangeRadio("NDN", "physicalExam", "genitoUrinario")
                }
              />
              <TmpRadio
                label="Sim"
                value="Sim"
                name="genitoUrinario"
                checked={
                  patient.anamnese.physicalExam.genitoUrinario !== "NDN" &&
                  patient.anamnese.physicalExam.genitoUrinario !== "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio("", "physicalExam", "genitoUrinario")
                }
              />
            </S.StyledRadioGroup>
            <S.InputWrap>
              <TmpInputCC
                maxLength={250}
                showCounter={true}
                message="Você atingiu o limite de caractere."
                type="text"
                placeholder=""
                value={getInputValue(
                  patient.anamnese.physicalExam.genitoUrinario
                )}
                disabled={
                  patient.anamnese.physicalExam.genitoUrinario ===
                    "Sem queixa" ||
                  patient.anamnese.physicalExam.genitoUrinario === "NDN"
                }
                handleChange={(e) =>
                  handleChangeInput(e, "genitoUrinario", "physicalExam")
                }
                error={
                  typeError &&
                  patient.anamnese.physicalExam.genitoUrinario === "" &&
                  patient.anamnese.physicalExam.genitoUrinario !== "NDN" &&
                  patient.anamnese.physicalExam.genitoUrinario !== "Sem queixa"
                }
                helperText={
                  typeError &&
                  patient.anamnese.physicalExam.genitoUrinario === "" &&
                  patient.anamnese.physicalExam.genitoUrinario !== "NDN" &&
                  patient.anamnese.physicalExam.genitoUrinario !==
                    "Sem queixa" ? (
                    <Error>Campo obrigatório</Error>
                  ) : null
                }
                helperIcon={
                  typeError &&
                  patient.anamnese.physicalExam.genitoUrinario === "" &&
                  patient.anamnese.physicalExam.genitoUrinario !== "NDN" &&
                  patient.anamnese.physicalExam.genitoUrinario !==
                    "Sem queixa" ? (
                    <ErrorIcon />
                  ) : null
                }
              />
            </S.InputWrap>
          </S.StyledRadio>
        </S.AnamneseRow>
        <S.AnamneseRow>
          <S.StyledRadio style={{ flex: 1 }}>
            <ComponentLabel>
              {patient.anamnese.physicalExam.neurologico !== "NDN" &&
              patient.anamnese.physicalExam.neurologico !== "Sem queixa"
                ? "Neurológico *"
                : "Neurológico"}
            </ComponentLabel>
            <S.StyledRadioGroup>
              <TmpRadio
                label="Sem queixa"
                value="Sem queixa"
                name="neurologico"
                checked={
                  patient.anamnese.physicalExam.neurologico === "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio("Sem queixa", "physicalExam", "neurologico")
                }
              />
              <TmpRadio
                label="NDN"
                value="NDN"
                name="neurologico"
                checked={patient.anamnese.physicalExam.neurologico === "NDN"}
                handleChange={() =>
                  handleChangeRadio("NDN", "physicalExam", "neurologico")
                }
              />
              <TmpRadio
                label="Sim"
                value="Sim"
                name="neurologico"
                checked={
                  patient.anamnese.physicalExam.neurologico !== "NDN" &&
                  patient.anamnese.physicalExam.neurologico !== "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio("", "physicalExam", "neurologico")
                }
              />
            </S.StyledRadioGroup>
            <S.InputWrap>
              <TmpInputCC
                maxLength={250}
                showCounter={true}
                message="Você atingiu o limite de caractere."
                type="text"
                placeholder=""
                value={getInputValue(patient.anamnese.physicalExam.neurologico)}
                disabled={
                  patient.anamnese.physicalExam.neurologico === "Sem queixa" ||
                  patient.anamnese.physicalExam.neurologico === "NDN"
                }
                handleChange={(e) =>
                  handleChangeInput(e, "neurologico", "physicalExam")
                }
                error={
                  typeError &&
                  patient.anamnese.physicalExam.neurologico === "" &&
                  patient.anamnese.physicalExam.neurologico !== "NDN" &&
                  patient.anamnese.physicalExam.neurologico !== "Sem queixa"
                }
                helperText={
                  typeError &&
                  patient.anamnese.physicalExam.neurologico === "" &&
                  patient.anamnese.physicalExam.neurologico !== "NDN" &&
                  patient.anamnese.physicalExam.neurologico !== "Sem queixa" ? (
                    <Error>Campo obrigatório</Error>
                  ) : null
                }
                helperIcon={
                  typeError &&
                  patient.anamnese.physicalExam.neurologico === "" &&
                  patient.anamnese.physicalExam.neurologico !== "NDN" &&
                  patient.anamnese.physicalExam.neurologico !== "Sem queixa" ? (
                    <ErrorIcon />
                  ) : null
                }
              />
            </S.InputWrap>
          </S.StyledRadio>
          <S.StyledRadio style={{ flex: 1 }}>
            <ComponentLabel>
              {patient.anamnese.physicalExam.locomotor !== "NDN" &&
              patient.anamnese.physicalExam.locomotor !== "Sem queixa"
                ? "Locomotor *"
                : "Locomotor"}
            </ComponentLabel>
            <S.StyledRadioGroup>
              <TmpRadio
                label="Sem queixa"
                value="Sem queixa"
                name="locomotor"
                checked={
                  patient.anamnese.physicalExam.locomotor === "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio("Sem queixa", "physicalExam", "locomotor")
                }
              />
              <TmpRadio
                label="NDN"
                value="NDN"
                name="locomotor"
                checked={patient.anamnese.physicalExam.locomotor === "NDN"}
                handleChange={() =>
                  handleChangeRadio("NDN", "physicalExam", "locomotor")
                }
              />
              <TmpRadio
                label="Sim"
                value="Sim"
                name="locomotor"
                checked={
                  patient.anamnese.physicalExam.locomotor !== "NDN" &&
                  patient.anamnese.physicalExam.locomotor !== "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio("", "physicalExam", "locomotor")
                }
              />
            </S.StyledRadioGroup>
            <S.InputWrap>
              <TmpInputCC
                maxLength={250}
                showCounter={true}
                message="Você atingiu o limite de caractere."
                type="text"
                placeholder=""
                value={getInputValue(patient.anamnese.physicalExam.locomotor)}
                disabled={
                  patient.anamnese.physicalExam.locomotor === "Sem queixa" ||
                  patient.anamnese.physicalExam.locomotor === "NDN"
                }
                handleChange={(e) =>
                  handleChangeInput(e, "locomotor", "physicalExam")
                }
                error={
                  typeError &&
                  patient.anamnese.physicalExam.locomotor === "" &&
                  patient.anamnese.physicalExam.locomotor !== "NDN" &&
                  patient.anamnese.physicalExam.locomotor !== "Sem queixa"
                }
                helperText={
                  typeError &&
                  patient.anamnese.physicalExam.locomotor === "" &&
                  patient.anamnese.physicalExam.locomotor !== "NDN" &&
                  patient.anamnese.physicalExam.locomotor !== "Sem queixa" ? (
                    <Error>Campo obrigatório</Error>
                  ) : null
                }
                helperIcon={
                  typeError &&
                  patient.anamnese.physicalExam.locomotor === "" &&
                  patient.anamnese.physicalExam.locomotor !== "NDN" &&
                  patient.anamnese.physicalExam.locomotor !== "Sem queixa" ? (
                    <ErrorIcon />
                  ) : null
                }
              />
            </S.InputWrap>
          </S.StyledRadio>
        </S.AnamneseRow>
        <S.AnamneseRow alignSelf="center">
          <S.StyledRadio style={{ flex: 1 }}>
            <ComponentLabel>
              {patient.anamnese.physicalExam.peleAnexos !== "NDN" &&
              patient.anamnese.physicalExam.peleAnexos !== "Sem queixa"
                ? "Pele / Anexos *"
                : "Pele / Anexos"}
            </ComponentLabel>
            <S.StyledRadioGroup>
              <TmpRadio
                label="Sem queixa"
                value="Sem queixa"
                name="skinAttachments"
                checked={
                  patient.anamnese.physicalExam.peleAnexos === "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio("Sem queixa", "physicalExam", "peleAnexos")
                }
              />
              <TmpRadio
                label="NDN"
                value="NDN"
                name="skinAttachments"
                checked={patient.anamnese.physicalExam.peleAnexos === "NDN"}
                handleChange={() =>
                  handleChangeRadio("NDN", "physicalExam", "peleAnexos")
                }
              />
              <TmpRadio
                label="Sim"
                value="Sim"
                name="skinAttachments"
                checked={
                  patient.anamnese.physicalExam.peleAnexos !== "NDN" &&
                  patient.anamnese.physicalExam.peleAnexos !== "Sem queixa"
                }
                handleChange={() =>
                  handleChangeRadio("", "physicalExam", "peleAnexos")
                }
              />
            </S.StyledRadioGroup>
            <S.InputWrap>
              <TmpInputCC
                maxLength={250}
                showCounter={true}
                message="Você atingiu o limite de caractere."
                type="text"
                placeholder=""
                value={getInputValue(patient.anamnese.physicalExam.peleAnexos)}
                disabled={
                  patient.anamnese.physicalExam.peleAnexos === "Sem queixa" ||
                  patient.anamnese.physicalExam.peleAnexos === "NDN"
                }
                handleChange={(e) =>
                  handleChangeInput(e, "peleAnexos", "physicalExam")
                }
                error={
                  typeError &&
                  patient.anamnese.physicalExam.peleAnexos === "" &&
                  patient.anamnese.physicalExam.peleAnexos !== "NDN" &&
                  patient.anamnese.physicalExam.peleAnexos !== "Sem queixa"
                }
                helperText={
                  typeError &&
                  patient.anamnese.physicalExam.peleAnexos === "" &&
                  patient.anamnese.physicalExam.peleAnexos !== "NDN" &&
                  patient.anamnese.physicalExam.peleAnexos !== "Sem queixa" ? (
                    <Error>Campo obrigatório</Error>
                  ) : null
                }
                helperIcon={
                  typeError &&
                  patient.anamnese.physicalExam.peleAnexos === "" &&
                  patient.anamnese.physicalExam.peleAnexos !== "NDN" &&
                  patient.anamnese.physicalExam.peleAnexos !== "Sem queixa" ? (
                    <ErrorIcon />
                  ) : null
                }
              />
            </S.InputWrap>
          </S.StyledRadio>
        </S.AnamneseRow>
      </>
    );
  };

  const ResultsDiagnosiSecondary = () => {
    return (
      <>
        <S.AnamneseRow id="results">
          <TextareaWrap style={{ flex: 1 }} height="158px" marginBottom="40px">
            <TextArea
              maxLength={2000}
              value={patient.anamnese.ResultsDiagnosiSecondary.resultadoExame}
              getHandleFunction={(e) => e}
              section={"ResultsDiagnosiSecondary"}
              type="anamnese"
              setProp={setPatientProperty}
              prop={"resultadoExame"}
              label="Resultados de exames relevantes p/ internação"
              message="Você atingiu o limite de caractere."
            />
          </TextareaWrap>

          <TextareaWrap style={{ flex: 1 }} height="158px" marginBottom="9px">
            <TextArea
              maxLength={250}
              value={patient.anamnese.ResultsDiagnosiSecondary.diagnostico}
              getHandleFunction={(e) => e}
              section={"ResultsDiagnosiSecondary"}
              type="anamnese"
              setProp={setPatientProperty}
              prop={"diagnostico"}
              label="Hipótese diagnóstica *"
              message="Você atingiu o limite de caractere."
              helperText={
                patient.anamnese.ResultsDiagnosiSecondary.diagnostico === "" &&
                typeError ? (
                  <Error>Campo obrigatório</Error>
                ) : (
                  ""
                )
              }
              helperIcon={
                patient.anamnese.ResultsDiagnosiSecondary.diagnostico === "" &&
                typeError ? (
                  <ErrorIcon style={{ color: "#EA1F1F" }} />
                ) : (
                  <ErrorIcon style={{ visibility: "hidden" }} />
                )
              }
              error={
                patient.anamnese.ResultsDiagnosiSecondary.diagnostico === "" &&
                typeError
              }
            />
          </TextareaWrap>
        </S.AnamneseRow>
        <S.AnamneseRow style={{ justifyContent: "center" }}>
          <div style={{ width: "100%" }}>
            <ComponentLabel style={{ fontWeight: "400" }}>
              Diagnósticos Secundários
            </ComponentLabel>
          </div>
          <S.RadioTable>
            <S.StyledRadioGroup mobileColumn width="329px">
              <TmpCheckbox
                label="Hipertensão Arterial"
                name="hipertensãoArterial"
                value="hipertensãoArterial"
                checked={
                  !!patient.anamnese.ResultsDiagnosiSecondary
                    .hipertensaoArterial
                }
                handleChange={() => {
                  handleCheckBoxChange(
                    "hipertensaoArterial",
                    "ResultsDiagnosiSecondary"
                  );
                }}
              />
              <TmpCheckbox
                label="Diabetes Mellitus"
                value="diabeteMelitus"
                name="diabeteMelitus"
                checked={
                  !!patient.anamnese.ResultsDiagnosiSecondary.diabeteMelitus
                }
                handleChange={() =>
                  handleCheckBoxChange(
                    "diabeteMelitus",
                    "ResultsDiagnosiSecondary"
                  )
                }
              />
              <TmpCheckbox
                label="Doença Renal"
                value="doencaRenal"
                name="doencaRenal"
                checked={
                  !!patient.anamnese.ResultsDiagnosiSecondary.doencaRenal
                }
                handleChange={() =>
                  handleCheckBoxChange(
                    "doencaRenal",
                    "ResultsDiagnosiSecondary"
                  )
                }
              />
            </S.StyledRadioGroup>
            <TmpDivider />
            <S.StyledRadioGroup mobileColumn width="329px">
              <TmpCheckbox
                label="Pneumopatia"
                value="pneumopatia"
                name="pneumopatia"
                checked={
                  !!patient.anamnese.ResultsDiagnosiSecondary.pneumopatia
                }
                handleChange={() =>
                  handleCheckBoxChange(
                    "pneumopatia",
                    "ResultsDiagnosiSecondary"
                  )
                }
              />
              <TmpCheckbox
                label="Cardiopatia"
                value="cardiopatia"
                name="cardiopatia"
                checked={
                  !!patient.anamnese.ResultsDiagnosiSecondary.cardiopatia
                }
                handleChange={() =>
                  handleCheckBoxChange(
                    "cardiopatia",
                    "ResultsDiagnosiSecondary"
                  )
                }
              />
              <TmpCheckbox
                label="Doença Onco-hematológica"
                value="doencaOncohematologica"
                name="doencaOncohematologica"
                checked={
                  !!patient.anamnese.ResultsDiagnosiSecondary
                    .doencaOncohematologica
                }
                handleChange={() =>
                  handleCheckBoxChange(
                    "doencaOncohematologica",
                    "ResultsDiagnosiSecondary"
                  )
                }
              />
            </S.StyledRadioGroup>
            <TmpDivider />
          </S.RadioTable>
        </S.AnamneseRow>
        <S.AnamneseRow>
          <TextareaWrap style={{ flex: 1 }} height="158px" marginBottom="40px">
            <TextArea
              maxLength={2000}
              value={patient.anamnese.ResultsDiagnosiSecondary.observacoes}
              getHandleFunction={(e) => e}
              section={"ResultsDiagnosiSecondary"}
              type="anamnese"
              setProp={setPatientProperty}
              prop={"observacoes"}
              label="Observações"
              message="Você atingiu o limite de caractere."
            />
          </TextareaWrap>
          <TextareaWrap style={{ flex: 1 }} height="158px" marginBottom="40px">
            <TextArea
              maxLength={1000}
              value={patient.anamnese.ResultsDiagnosiSecondary.tratamento}
              getHandleFunction={(e) => e}
              section={"ResultsDiagnosiSecondary"}
              type="anamnese"
              setProp={setPatientProperty}
              prop={"tratamento"}
              label="Plano de tratamento *"
              message="Você atingiu o limite de caractere."
              helperText={
                patient.anamnese.ResultsDiagnosiSecondary.tratamento === "" &&
                typeError ? (
                  <Error>Campo obrigatório</Error>
                ) : (
                  ""
                )
              }
              helperIcon={
                patient.anamnese.ResultsDiagnosiSecondary.tratamento === "" &&
                typeError ? (
                  <ErrorIcon style={{ color: "#EA1F1F" }} />
                ) : (
                  <ErrorIcon style={{ visibility: "hidden" }} />
                )
              }
              error={
                patient.anamnese.ResultsDiagnosiSecondary.tratamento === "" &&
                typeError
              }
            />
          </TextareaWrap>
        </S.AnamneseRow>
      </>
    );
  };

  const removeOptionalFields = (obj, requiredFields) => {
    var newObj = {};
    requiredFields.forEach((field) => {
      if (obj[field] !== undefined) {
        newObj[field] = obj[field];
      }
    });
    return newObj;
  };
  const verifyAllFields = (state, e) => {
    var fieldError = false;
    for (const property in state) {
      if (!fieldError) {
        if (Array.isArray(state[property])) {
          if (state[property].length) {
            state[property].forEach((item) => {
              if (!fieldError) {
                fieldError = property === !filledFields(item);
              }
            });
          }
        } else {
          fieldError = !filledFields(state[property]);
        }
      } else {
        if (e?.target.id !== "tmp-button-icon") dispatch(isError(fieldError));
        return fieldError;
      }
    }
    if (e?.target.id !== "tmp-button-icon") dispatch(isError(fieldError));
    return fieldError;
  };

  const handleClickAdvance = async (e) => {
    dispatch(isError(false));
    if (currentStep === 1) {
      const historyDevicesRequiredFields = removeOptionalFields(
        { ...patient.anamnese?.historyDevices },
        requiredAnamnese
      );
      if (
        verifyAllFields(historyDevicesRequiredFields, e) &&
        e.target.id !== "tmp-button-icon"
      )
        setTypeError(true);
      else {
        try {
          setSaving(true);
          const historyDevices = patient.anamnese.historyDevices;
          const response = await anamneseSolicitation(
            currentSolicitation.anamnese.idAnamnese
          ).put({
            anamneseHistorico: {
              queixa: historyDevices.queixa,
              historiaMolestia: historyDevices.historiaMolestia,
              antecedentesPessoais: historyDevices.antecedentesPessoais,
              antecedentesFamiliares: historyDevices.antecedentesFamiliares,
              habitos: historyDevices.habitos,
              medicamentosEmUso: historyDevices.medicamentosEmUso,
              alergias: historyDevices.alergias,
              geral: historyDevices.geral,
              neurologico: historyDevices.neurologico,
              cabecaPescoco: historyDevices.cabecaPescoco,
              respiratorio: historyDevices.respiratorio,
              cardiovascular: historyDevices.cardiovascular,
              gastrointestinal: historyDevices.gastrointestinal,
              genitoUrinario: historyDevices.genitoUrinario,
              peleAnexos: historyDevices.peleAnexos,
              musculoEsqueletico: historyDevices.musculoEsqueletico,
              cdGeral: handleSendRadioBtn(historyDevices.geral),
              cdNeurologico: handleSendRadioBtn(historyDevices.neurologico),
              cdCabecaPescoco: handleSendRadioBtn(historyDevices.cabecaPescoco),
              cdRespiratorio: handleSendRadioBtn(historyDevices.respiratorio),
              cdCardiovascular: handleSendRadioBtn(
                historyDevices.cardiovascular
              ),
              cdGastrointestinal: handleSendRadioBtn(
                historyDevices.gastrointestinal
              ),
              cdGenitoUrinario: handleSendRadioBtn(
                historyDevices.genitoUrinario
              ),
              cdPeleAnexos: handleSendRadioBtn(historyDevices.peleAnexos),
              cdMusculoEsqueletico: handleSendRadioBtn(
                historyDevices.musculoEsqueletico
              ),
            },
          });

          if (response?.idAnamnese) {
            setCurrentStep((prevState) => prevState + 1);
            if (e.target.id === "tmp-button-icon")
              dispatch(setOpenAnamnese(false));
          } else {
            setError(true);
          }
        } catch (error) {
          setError(true);
        } finally {
          setSaving(false);
          setTypeError(false);
        }
      }
    }

    if (currentStep === 2) {
      const physicalRequiredFields = removeOptionalFields(
        { ...patient.anamnese?.physicalExam },
        requiredAnamnese
      );
      if (
        verifyAllFields(physicalRequiredFields, e) &&
        e.target.id !== "tmp-button-icon"
      )
        setTypeError(true);
      else {
        try {
          setSaving(true);
          const physicalExam = patient.anamnese.physicalExam;
          const response = await apiAnamneseExameFisico(
            currentSolicitation.anamnese.idAnamnese
          ).put({
            anamneseExameFisico: {
              estadoGeral: getAnamneseItemCode(
                physicalExam.estadoGeral,
                "estadoGeral"
              ),
              vivacidade: getAnamneseItemCode(
                physicalExam.vivacidade,
                "vivacidade"
              ),
              sintomas: getAnamneseItemCode(physicalExam.sintomas, "sintomas"),
              transpiracao: getAnamneseItemCode(
                physicalExam.transpiracao,
                "transpiracao"
              ),
              cabecaPescoco: physicalExam.cabecaPescoco,
              respiratorio: physicalExam.respiratorio,
              cardiaco: physicalExam.cardiaco,
              vascular: physicalExam.vascular,
              abdome: physicalExam.abdome,
              genitoUrinario: physicalExam.genitoUrinario,
              neurologico: physicalExam.neurologico,
              locomotor: physicalExam.locomotor,
              peleAnexos: physicalExam.peleAnexos,
              cdCabecaPescoco: handleSendRadioBtn(physicalExam.cabecaPescoco),
              cdRespiratorio: handleSendRadioBtn(physicalExam.respiratorio),
              cdCardiaco: handleSendRadioBtn(physicalExam.cardiaco),
              cdVascular: handleSendRadioBtn(physicalExam.vascular),
              cdAbdome: handleSendRadioBtn(physicalExam.abdome),
              cdGenitoUrinario: handleSendRadioBtn(physicalExam.genitoUrinario),
              cdNeurologico: handleSendRadioBtn(physicalExam.neurologico),
              cdLocomotor: handleSendRadioBtn(physicalExam.locomotor),
              cdPeleAnexos: handleSendRadioBtn(physicalExam.peleAnexos),
            },
          });

          if (response?.idAnamnese) {
            setCurrentStep((prevState) => prevState + 1);
            if (e.target.id === "tmp-button-icon")
              dispatch(setOpenAnamnese(false));
          } else {
            setError(true);
          }
        } catch (error) {
          setError(true);
        } finally {
          setSaving(false);
          setTypeError(false);
        }
      }
    }

    if (currentStep === 3) {
      const resultsRequiredFields = removeOptionalFields(
        { ...patient.anamnese?.ResultsDiagnosiSecondary },
        requiredAnamnese
      );
      if (
        verifyAllFields(resultsRequiredFields, e) &&
        e.target.id !== "tmp-button-icon"
      )
        setTypeError(true);
      else {
        try {
          setSaving(true);
          const responseAnamneseResult = await apiAnamneseResultado(
            currentSolicitation.anamnese.idAnamnese
          ).put({
            anamneseResultado: {
              idAnamneseResultado:
                patient.anamnese.ResultsDiagnosiSecondary.idAnamneseResultado,
              resultadoExame:
                patient.anamnese.ResultsDiagnosiSecondary.resultadoExame,
              diagnostico:
                patient.anamnese.ResultsDiagnosiSecondary.diagnostico,
              hipertensaoArterial:
                patient.anamnese.ResultsDiagnosiSecondary.hipertensaoArterial,
              diabeteMelitus:
                patient.anamnese.ResultsDiagnosiSecondary.diabeteMelitus,
              doencaRenal:
                patient.anamnese.ResultsDiagnosiSecondary.doencaRenal,
              pneumopatia:
                patient.anamnese.ResultsDiagnosiSecondary.pneumopatia,
              cardiopatia:
                patient.anamnese.ResultsDiagnosiSecondary.cardiopatia,
              doencaOncohematologica:
                patient.anamnese.ResultsDiagnosiSecondary
                  .doencaOncohematologica,
              observacoes:
                patient.anamnese.ResultsDiagnosiSecondary.observacoes,
              tratamento: patient.anamnese.ResultsDiagnosiSecondary.tratamento,
            },
          });

          if (responseAnamneseResult.idAnamnese) {
            if (responseAnamneseResult?.idAnamnese) {
              if (e.target.id === "tmp-button-icon")
                dispatch(setOpenAnamnese(false));
              if (currentSolicitation.relatorio.idRelatorio) {
                await apiFinalizeAnamnese(
                  currentSolicitation?.solicitacao?.idSolicitacao
                ).put();
              }
              dispatch(setOpenAnamnese(false));
              dispatch(isSuccessAnam(true));
              dispatch(setClearAnamnese());
              dataChange({
                target: {
                  name: "statusAnamnese",
                  value: "Concluida",
                },
              });
            } else {
              setError(true);
            }
          } else {
            setError(true);
          }
        } catch (error) {
          setError(true);
          console.log(error);
        } finally {
          setSaving(false);
        }
      }
    }
  };

  const handleClickBack = () => {
    if (currentStep <= stepsLabel.length && currentStep > 1)
      setCurrentStep((prevState) => prevState - 1);
    else if (currentStep === 1) {
      dispatch(setOpenAnamnese(false));
      dispatch(setClearAnamnese());
      setCurrentStep(1);
    }
  };

  const handleSendRadioBtn = (value) => {
    if (currentStep === 1) {
      if (value === "Sem queixa") return "SQ";
      else if (value === "NDN") return "NDN";
      else return "S";
    } else {
      if (value === "Sem queixa") return "NA";
      else if (value === "NDN") return "NDN";
      else return "S";
    }
  };

  const getForm = () => {
    switch (currentStep) {
      case 1:
        return historyAndDevices();
      case 2:
        return physicalExam();
      case 3:
        return ResultsDiagnosiSecondary();
      default:
        return null;
    }
  };

  return (
    <div>
      <NewModal
        isOpened={modalOpen}
        labelPrimary=""
        removeBtn
        width="auto"
        height="auto"
      >
        {error && (
          <StyledAlert isOpened>
            <TmpAlert
              id="form-alert"
              isOpened
              type="error"
              title={"Erro ao salvar Anamnese"}
              message={"Erro no envio das informações. Tente novamente."}
              handleClose={() => setError(false)}
            />
          </StyledAlert>
        )}
        {typeError && (
          <StyledAlert isOpened isAnm>
            <TmpAlert
              id="form-alert"
              isOpened
              type="error"
              title={"Erro de Preenchimento"}
              message={"Existem campos obrigatórios que não foram preenchidos"}
              handleClose={() => setTypeError(false)}
            />
          </StyledAlert>
        )}
        <S.Container>
          <S.BoxTitle>
            <TmpIcon style={{ marginRight: "12px" }} icon={<PrescricaoSVG />} />
            <S.AnamneseTitle>Anamnese Exame Físico</S.AnamneseTitle>
            <TmpButtonIcon
              icon={<IconCancelar />}
              size="lg"
              handleClick={(e) => {
                if (currentStep < 3) {
                  handleClickAdvance(e);
                } else {
                  dispatch(setOpenAnamnese(false));
                  dispatch(isError(false));
                }
              }}
            />
          </S.BoxTitle>
          <S.ModalContent id="top">
            <InfoPatient marginBottomDivider="32px" />
            <S.Progress width={`${(100 / stepsLabel.length) * currentStep}%`}>
              <div />
            </S.Progress>
            <S.Steps>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span className="step-label">
                  {stepsLabel[currentStep - 1]}
                </span>{" "}
                -{" "}
                <span className="step">{` ETAPA ${currentStep} DE ${stepsLabel.length}`}</span>
              </div>
            </S.Steps>
            {getForm()}
            {currentStep < stepsLabel.length ? (
              <S.Legend>NDN = Nada Digno de Nota</S.Legend>
            ) : null}
            <S.AnamneseFooter mobileColumn>
              {currentStep >= stepsLabel.length ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <LocalAndDate
                    local={currentSolicitation.procedimento.local}
                    size="lg"
                    display
                    margin={"0 33px 0 0"}
                  />
                  {urlAssinatura && (
                    <img src={urlAssinatura} style={{ marginRight: "51px" }} />
                  )}
                </div>
              ) : null}
            </S.AnamneseFooter>
          </S.ModalContent>
          <S.BtnWrap SaveClose={currentStep >= stepsLabel.length}>
            <StyledSaving
              padding="0px"
              style={{ width: "100%", margin: "0px" }}
            >
              {saving ? (
                <Saving>
                  <TmpIcon
                    size="lg"
                    icon={<Pontinhos />}
                    style={{ marginRight: "12px" }}
                  />
                  Salvando automaticamente...
                </Saving>
              ) : null}
            </StyledSaving>
            <TmpButtonGroup
              size="lg"
              labelPrimary={
                currentStep < stepsLabel.length ? "Avançar" : "Salvar e Fechar"
              }
              labelSecondary={currentStep >= 2 ? "Voltar" : "Cancelar"}
              handleClickPrimary={handleClickAdvance}
              disabledPrimary={saving}
              handleClickSecondary={handleClickBack}
            />
          </S.BtnWrap>
        </S.Container>
      </NewModal>
    </div>
  );
};

export default Anamnese;
