import styled from "styled-components";

export const WrapperInfos = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
`;
export const Info = styled.p`
  font-weight: 700;
`;
export const PatientInfos = styled.div`
  width: 80%;

  @media (max-width: 760px) {
    width: 100%;
  }

  font-size: 16px;
  b {
    margin-right: 5px;
  }

  > div {
    margin-top: 12px;
  }

  > div > span {
    margin-right: 22px;
  }
`;

export const InfoWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;

  @media (max-width: 760px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`;
