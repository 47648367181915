import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import {
  authReducer,
  patientReducer,
  proceduresReducer,
  materialsReducer,
  reportsReducer,
  settingsReducer,
  solicitationReducer,
  modelReducer,
} from "../features";

const authPersistConfig = {
  key: "auth",
  storage,
};

const authPersistedReducer = persistReducer(authPersistConfig, authReducer);

export const store = configureStore({
  reducer: {
    auth: authPersistedReducer,
    patient: patientReducer,
    materials: materialsReducer,
    settings: settingsReducer,
    procedures: proceduresReducer,
    reports: reportsReducer,
    solicitation: solicitationReducer,
    model: modelReducer,
  },
});
export const persistor = persistStore(store);
