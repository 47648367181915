import Modal from "../NewModal";
import { TmpButtonGroup } from "@tempo/tempo-design-system-core/dist";

import * as S from "./style";
import { getAbbreviatedFullName, transformDate } from "../../utils";

const ModalUnifiedSolicitation = ({
  mainDoctor,
  mainProcedure,
  patientName,
  patientBirthday,
  surgeryDate,
  surgeryTime,
  onConfirm,
  onDecline,
  isOpened,
  loading,
}) => {
  return (
    <Modal
      minHeight="min-content"
      isOpened={isOpened}
      labelPrimary={"Sucesso"}
      removeBtn
    >
      <S.Container>
        <S.Title>Você foi indicado para uma cirurgia unificada</S.Title>
        <S.Text
          marginTop="24px"
          width="540px"
          lineHeigth="24px"
          color="#292929"
        >
          O(a) médico(a) <b>Dr(a). {mainDoctor}</b> te indicou como equipe
          adicional para uma cirurgia.
        </S.Text>
        <S.PatientInfoContainer>
          <S.PatientInfoRow>
            <strong>Nome do Paciente: </strong>
            <S.Name>{`${getAbbreviatedFullName(
              patientName,
              30
            )} - ${transformDate(patientBirthday)}`}</S.Name>
          </S.PatientInfoRow>
          <S.PatientInfoRow>
            <S.Text marginTop="8px">
              <strong>Data da Cirurgia: </strong>
            </S.Text>
            <S.Text marginTop="8px">{`${transformDate(
              surgeryDate
            )} | `}</S.Text>
            <S.Text marginTop="8px">
              <strong>Horário: </strong>
            </S.Text>
            <S.Text marginTop="8px">{`${surgeryTime} ${
              parseInt(surgeryTime.substr(0, 2)) > 12 ? "PM" : "AM"
            }`}</S.Text>
          </S.PatientInfoRow>
          <S.PatientInfoRow>
            <S.Text marginTop="8px">
              <strong>Procedimento principal: </strong>
            </S.Text>
            <S.Procedure width="335px" marginTop="8px" whiteSpace="initial">
              {mainProcedure}
            </S.Procedure>
          </S.PatientInfoRow>
        </S.PatientInfoContainer>
        <S.QuestionTextContainer>
          <S.Text marginTop="8px" color="#292929">
            Você confirma sua participação nessa cirurgia?
          </S.Text>
        </S.QuestionTextContainer>
        <S.BtnSendWrap paddingButton="12px 24px">
          <TmpButtonGroup
            size="sm"
            icon={false}
            labelPrimary="Sim, confirmar"
            labelSecondary="Não, recusar"
            loadingButtonPrimary={loading.primary}
            loadingButtonSecondary={loading.secondary}
            disabledPrimary={loading.secondary}
            disabledSecondary={loading.primary}
            handleClickPrimary={() => {
              onConfirm();
            }}
            handleClickSecondary={() => {
              onDecline();
            }}
          />
        </S.BtnSendWrap>
      </S.Container>
    </Modal>
  );
};

export default ModalUnifiedSolicitation;
