import styled from "styled-components";

export const Container = styled.div`
  border-radius: 16px;
  display: flex;
  padding: 32px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  img {
    width: 304px;
    height: 290px;
  }

  p {
    color: #292929;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 133%;
  }
`;
