import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: inline-block;
  user-select: none;

  .button-filter {
    min-width: max-content;
  }

  .no-filter {
    border: 1px solid #2c6ef2;
  }
`;

export const Counter = styled.span`
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: #c5d7fb;
  border: 2px solid #c5d7fb;
  font-size: 14px;
  color: #0a3a99;
  margin-left: 8px;
`;

export const DropDown = styled.div`
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  display: none;
  opacity: 0;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.48, 0, 0.48, 1);
  z-index: 16;

  box-shadow: rgba(0, 0, 0, 0.16) 0px 8px 24px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 4px;

  ${({ $actived }) =>
    $actived &&
    css`
      display: flex;
      opacity: 1;
    `}

  .filter-search {
    margin: 24px 20px 20px 20px;
  }

  ul {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-track {
      background: #e0e0e0;
      border-radius: 16px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #cccccc;
      border-radius: 16px;
    }

    li > div > span > label > span {
      min-height: 24px;

      &::before {
        margin: 0px 16px 0px 0px;
      }
    }

    ${({ $hasTitle }) =>
      $hasTitle &&
      css`
        > .tmp-list-item:first-child > div {
          width: 100%;
        }
        span {
          display: flex;
          align-items: center;
        }
        .tmp-button-icon {
          margin-left: auto;
        }
        h3 {
          color: currentColor;
        }
      `}

    .tmp-list-item {
      border-color: transparent;
      border-radius: 8px;

      &:focus-visible {
        outline: 2px solid #ffbd70;
        outline-offset: 2px;
      }

      label {
        z-index: 1;
      }
      > div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        > span {
          width: 100%;
          height: 100%;
          > label {
            width: 100%;
            height: 100%;
            cursor: pointer;
            &:not(.disable):hover {
              /* apply hover effect here */
            }
            > input {
              top: initial;
              border: none;
            }
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    position: absolute;
    top: initial;
    left: initial;
    height: auto;
    width: 360px;
    margin-top: 16px;

    .tmp-typography {
      display: none;
    }
    ul {
      padding: ${({ $searchLabel }) =>
        $searchLabel ? "0 16px 16px 16px" : "16px"};

      > .tmp-list-item:first-child {
        display: none;
      }
    }
  }

  @media (max-width: 768px) {
    height: 100%;
    width: 100%;
    padding-top: 16px;

    ul {
      padding-top: 24px;
    }
  }
`;

export const ListTitle = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:focus-visible {
    outline: transparent;
  }

  .tmp-icon {
    padding-right: 4px;
  }
`;

export const DropDownFooter = styled.div`
  margin-top: auto;
  background-color: #f5f5f5;
  padding: 24px;

  button {
    min-width: auto;
  }
`;
