export const model = {
  listModels: [],
  idModelo: null,
  idMedico: null,
  nomeModelo: "",

  paciente: {
    cid: "",
    indicacaoClinica: "",
  },

  procedimento: {
    procedimentoPrincipal: {
      ieOrigemProcedimento: "",
      procedimento: "",
      exige_lado: "",
      lateralidades: [],
    },
    equipe: [
      {
        nomeCirurgiao: "",
        crmCirurgiao: "",
        telefoneCirurgiao: "",
      },
    ],
    lateralidade: "",
    procedimentosExtras: [],
    regimeHospitalar: "",
    carater: "",
    tipagemSanguinea: "",
    reservaHemocomponentes: "",
    reservaCritica: "",
    biobanco: false,
    tomografiaEstereotaxia: false,
    ecoTransesofagico: false,
    raiox: false,
    scopia: false,
    medicinaNuclear: false,
    ultrassonografia: false,
    ultrassonografiaLaparoscopio: false,
    ressonanciaMagneticaIntraperatoria: false,
    monitorizacaoNeurofisiologica: false,
    endoscopia: false,
    colonoscopia: false,
    pth: false,
    bancoTecidos: false,
    internacaoVespera: false,
    datasReservadas: false,
    cirurgiaSequencia: false,
    radioHsl: false,
    radioExt: false,
  },

  material: {
    materialOPME: [],
    materialInstrumentos: [],
    materialEquipamentos: [],
    materialMedicacao: [],
  },

  relatorio: {
    observacoes: "",
  },
};
