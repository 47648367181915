import * as React from "react";

const SVGComponent = (props) => (
  <svg
    width="1em"
    height="1em"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <g
      className="chevron__container"
      fill="currentColor"
      strokeWidth={10}
      strokeLinecap="round"
      stroke="currentColor"
    >
      <line className="chevron__line1" x1={10} y1={50} x2={50} y2={50} />
      <line className="chevron__line2" x1={90} y1={50} x2={50} y2={50} />
    </g>
  </svg>
);

export default SVGComponent;
