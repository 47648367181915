import { createSlice } from "@reduxjs/toolkit";
import { updateState, updateObjectState } from "../../utils";
import { reports } from "./reports";

const reportsSlice = createSlice({
  name: "reports",
  initialState: reports,
  reducers: {
    setClearReports: () => {
      return reports;
    },
    setReportsObject: updateObjectState,
    setReportsProperty: updateState,
  },
});

export const { setReportsProperty, setReportsObject, setClearReports } =
  reportsSlice.actions;

export const reportsReducer = reportsSlice.reducer;
