import React from "react";
import { TmpModal } from "@tempo/tempo-design-system-core/dist";
import * as S from "./style";

const Modal = ({
  removeBtn = false,
  isOpened = false,
  isDoubleAction,
  labelPrimary,
  labelSecondary,
  handleConfirm,
  handleCancel,
  children,
  maxHeight,
  minHeight,
  maxWidth,
}) => {
  return (
    <S.StyledModal
      justifyContent={!removeBtn ? "center" : "flex-start"}
      minHeight={minHeight}
      maxHeight={maxHeight ? maxHeight : "300px"}
      showBtn={removeBtn}
      maxWidth={maxWidth}
    >
      <TmpModal
        isOpened={isOpened}
        isDoubleAction={isDoubleAction}
        labelPrimary={labelPrimary}
        labelSecondary={labelSecondary}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      >
        <S.ModalInfos>{children}</S.ModalInfos>
      </TmpModal>
    </S.StyledModal>
  );
};
export default Modal;
