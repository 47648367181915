import { createSlice } from "@reduxjs/toolkit";
import { settings } from "./settings";

const settingsSlice = createSlice({
  name: "settings",
  initialState: settings,
  reducers: {
    isError: (state, action) => {
      state.error = action.payload;
    },
    isSuccess: (state, action) => {
      state.success = action.payload;
    },
    setRegisterSuccess: (state, action) => {
      state.registerSuccess = action.payload;
    },
    isSuccessAnam: (state, action) => {
      state.successAnam = action.payload;
    },
    isSuccessConsent: (state, action) => {
      state.successConsent = action.payload;
    },
    setOpenAnamnese: (state, action) => {
      state.openAnamnese = action.payload;
    },
    setConsentFormOpen: (state, action) => {
      state.consentForm = action.payload;
    },
    setFreeFields: (state, action) => {
      state.freeFields = action.payload;
    },
    setIsNewPatient: (state, action) => {
      state.isNewPatient = action.payload;
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setInfoPatient: (state, action) => {
      state.infoPatient = action.payload;
    },
    setTimeNeedErro: (state, action) => {
      state.timeNeedError = action.payload;
    },
    setOpenGoToHomeModal: (state, action) => {
      state.confirmGoToHomeOpen = action.payload;
    },

    setEditingModel: (state, action) => {
      state.editingModel = action.payload;
    },
    setDownloadError: (state, action) => {
      state.downloadError = action.payload;
    },
    setLoadingTermo: (state, action) => {
      state.loadingTermo = action.payload;
    },
    setModelSave: (state, action) => {
      state.modelSave = action.payload;
    },
    setBasicInfoError: (state, action) => {
      state.basicInfoError = action.payload;
    },
    setInvalidPhone: (state, action) => {
      state.invalidPhone = action.payload;
    },
    setRequiredLaudo: (state, action) => {
      state.requiredLaudo = action.payload;
    },
    setSaveSolicitationInfoBeforeEditing: (state, action) => {
      state.saveSolicitationInfoBeforeEditing = action.payload;
    },
  },
});

export const {
  isError,
  isSuccess,
  isSuccessAnam,
  isSuccessConsent,
  setOpenAnamnese,
  setConsentFormOpen,
  setFreeFields,
  setIsNewPatient,
  setCurrentStep,
  setInfoPatient,
  setTimeNeedErro,
  setOpenGoToHomeModal,
  setEditingModel,
  setDownloadError,
  setLoadingTermo,
  setModelSave,
  setBasicInfoError,
  setRegisterSuccess,
  setInvalidPhone,
  setRequiredLaudo,
  setSaveSolicitationInfoBeforeEditing,
} = settingsSlice.actions;

export const settingsReducer = settingsSlice.reducer;
