import React, { useReducer } from "react";
import { useSelector } from "react-redux";
import * as S from "../style";
import { opmeType, genericType } from "../types";
import {
  updatePropInList,
  addListItem,
  removeListItem,
  getSelectObjValue,
  getSchedulingLocal,
  formatQuantity,
} from "../../../../utils";
import {
  TmpSelect,
  TmpDivider,
  TmpButton,
  TmpCheckbox,
} from "@tempo/tempo-design-system-core/dist";
import { TmpInputCC } from "@tempo/tempo-design-system-centro-cirurgico";
import {
  adicionar as Plus,
  excluir as Trash,
  erro as Error,
} from "@tempo/tempo-assets/dist";
import TextArea from "../../../../components/TextArea";
import Reload from "../../../../components/ReloadHelper";
import debounce from "lodash.debounce";
import { apiGetSuppliers } from "../../../../services/api";
import Autocomplete from "./Autocomplete";

const checkQuantity = (quant) => {
  if (
    quant === "" ||
    isNaN(quant.replace(",", ".")) ||
    quant.replace(",", ".") < 1
  ) {
    return true;
  }

  return false;
};

const autocompleteInitialValue = {
  query: "",
  loading: false,
  suggestions: null,
  field: "",
  value: "",
  error: false,
};

const autocompleteReducer = (state, action) => {
  switch (action.type) {
    case "set-query":
      return {
        ...state,
        query: action.payload.query,
        loading: action.payload.query.length > 0,
        field: action.payload.field,
        value: action.payload.query,
      };

    case "set-suggestions":
      return {
        ...state,
        loading: false,
        suggestions: action.payload,
        error: false,
      };

    case "reset":
      return { ...autocompleteInitialValue };

    case "set-error":
      return { ...state, error: action.payload };

    default:
      return state;
  }
};

const Materials = ({ data, dataChange }) => {
  const error = useSelector((state) => state.settings.error);
  const auth = useSelector((state) => state.auth);
  const local = useSelector((state) => state.solicitation.procedimento.local);

  const addNewItem = (type) => {
    dataChange({
      target: {
        name: type,
        value: addListItem(
          data[type],
          type,
          type === "materialOPME" ? opmeType : genericType
        ).value,
      },
    });
  };

  const removeItem = (type, listIndex) => {
    dataChange({
      target: {
        name: type,
        value: removeListItem(data[type], type, listIndex).value,
      },
    });
  };

  const handleChangeInput = (e, prop, index, listId, type) => {
    if (type === "qtd") {
      const pattern = /^([0-9]{0,12})+(,\d{0,3})?$/;
      const isComma = e.target.value.includes(",");
      const valueLength = e.target.value.length;

      if (
        pattern.test(e.target.value) &&
        ((isComma && valueLength <= 16) || (!isComma && valueLength <= 12)) &&
        !isNaN(e.target.value.replace(",", "."))
      ) {
        dataChange({
          target: {
            name: listId,
            value: updatePropInList(
              data[listId],
              {
                id: prop,
                value: formatQuantity(e.target.value),
              },
              index,
              listId
            ).value,
          },
        });
      }
    } else if (
      (prop === "material" &&
        e.target.value.trim().length <=
          (listId === "materialOPME" ? 255 : 255)) ||
      prop !== "material"
    ) {
      dataChange({
        target: {
          name: listId,
          value: updatePropInList(
            data[listId],
            { id: prop, value: e.target.value },
            index,
            listId
          ).value,
        },
      });
    }
  };

  const handleChangeSelect = (args, prop, index, listId) => {
    dataChange({
      target: {
        name: listId,
        value: updatePropInList(
          data[listId],
          { id: prop, value: args },
          index,
          listId
        ).value,
      },
    });
  };

  const handleCheckboxChange = (value, prop, index, listId) => {
    var newObj = updatePropInList(
      data[listId],
      { id: prop, value: value },
      index,
      listId
    ).value;
    newObj[index] = {
      ...newObj[index],
      naoIndicarFabricanteJustificativa: "",
      naoIndicarFabricanteMotivoData: new Date().toDateString(),
      naoIndicarFabricanteMotivoHora: new Date().toLocaleTimeString("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      }),
      naoIndicarFabricanteMotivo: "",
    };
    dataChange({
      target: {
        name: listId,
        value: newObj,
      },
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
    }
  };

  const [autocompleteState, dispatchAutocomplete] = useReducer(
    autocompleteReducer,
    autocompleteInitialValue
  );

  const handleSupplierChange = debounce(
    async ({ e, prop, query, field, id, index }) => {
      const queryFiltered = query.replaceAll(/\s/g, "");

      // Caso tenha apenas espaços em brancos, voltar para os valores iniciais do estado
      if (
        (queryFiltered.length === 0 && query.length > 0) ||
        query.length === 0
      ) {
        dispatchAutocomplete({
          type: "reset",
        });

        dataChange({
          target: {
            name: "materialOPME",
            value: updatePropInList(
              data["materialOPME"],
              { id, value: "" },
              index,
              "materialOPME"
            ).value,
          },
        });
      }

      if (queryFiltered.length) {
        dataChange({
          target: {
            name: "materialOPME",
            value: updatePropInList(
              data["materialOPME"],
              { id, value: "" },
              index,
              "materialOPME"
            ).value,
          },
        });

        handleChangeInput(e, prop, index, "materialOPME");
        dispatchAutocomplete({
          type: "set-query",
          payload: {
            query,
            field,
          },
        });
        if (query.length) {
          const suppliers = await apiGetSuppliers(query).getList();

          if (Array.isArray(suppliers)) {
            dispatchAutocomplete({
              type: "set-suggestions",
              payload: suppliers,
            });
          } else {
            dispatchAutocomplete({
              type: "set-error",
              payload: true,
            });
          }
        } else {
          dataChange({
            target: {
              name: "materialOPME",
              value: updatePropInList(
                data["materialOPME"],
                { id, value: "" },
                index,
                "materialOPME"
              ).value,
            },
          });
        }
      }
    },
    650
  );

  return (
    <div style={{ width: "100%" }}>
      <S.BoxTitle marginBottom="0">
        <S.Title id="step-materials">
          Materiais OPME (Órteses, Próteses e Materiais Especiais)
        </S.Title>
        <TmpDivider />
      </S.BoxTitle>

      <S.StyledSectionWrapper rows={"1fr"}>
        <div>
          {data?.materialOPME?.map((item, index) => (
            <S.MaterialContainer
              backgroundColor={
                (index + 1) % 2 ? "transparent" : "rgba(252, 252, 252, 1)"
              }
              borderTop={
                index === 0 ? "none" : "1px solid rgba(224, 224, 224, 1)"
              }
              key={index}
            >
              <S.StyledContent columns={"2fr 1fr"}>
                <S.InputWrap>
                  <S.RemoveBtn
                    onClick={() => removeItem("materialOPME", index)}
                  >
                    <Trash />
                  </S.RemoveBtn>
                  <TmpInputCC
                    width="100%"
                    widthmedialg="100%"
                    widthmediamd="100%"
                    type="text"
                    label={`Material OPME ${index + 1} *`}
                    placeholder="Inserir apenas um material por campo"
                    id={`materialOPME${index}`}
                    error={item.material.trim() === "" && error}
                    value={item.material.slice(0, 225)}
                    maxLength={225}
                    showCounter={true}
                    message="Você atingiu o limite de caractere."
                    handleChange={(e) =>
                      handleChangeInput(e, "material", index, "materialOPME")
                    }
                    helperText={
                      item.material.trim() === "" && error ? (
                        <S.Error>Campo obrigatório</S.Error>
                      ) : (
                        ""
                      )
                    }
                    helperIcon={
                      item.material.trim() === "" && error ? <Error /> : null
                    }
                  />
                </S.InputWrap>
                <S.InputWrap>
                  <TmpInputCC
                    autoComplete="off"
                    type="text"
                    id={`quantidadeOPME${index}`}
                    name="quantidade"
                    label="Quantidade *"
                    onKeyDown={(e) => handleKeyDown(e)}
                    onWheel={(e) => e.target.blur()}
                    error={checkQuantity(item.quantidade) && error}
                    placeholder="Inserir quantidade"
                    lang="pt-BR"
                    value={formatQuantity(item.quantidade)}
                    formatValue={true}
                    handleChange={(e) =>
                      handleChangeInput(
                        e,
                        "quantidade",
                        index,
                        "materialOPME",
                        "qtd"
                      )
                    }
                    helperText={
                      error ? (
                        <S.Error>
                          {item.quantidade === ""
                            ? "Campo obrigatório"
                            : checkQuantity(item.quantidade) &&
                              "Quantidade inválida. O valor deve ser maior ou igual a 1."}
                        </S.Error>
                      ) : (
                        ""
                      )
                    }
                    helperIcon={
                      checkQuantity(item.quantidade) && error ? (
                        <Error style={{ color: "#EA1F1F" }} />
                      ) : (
                        <Error style={{ visibility: "hidden" }} />
                      )
                    }
                  />
                </S.InputWrap>
              </S.StyledContent>

              <S.StyledContent columns={"repeat(3, 1fr)"} columnsMd={"1fr"}>
                <S.AutocompleteRow>
                  <S.Input
                    autoComplete="off"
                    widthmedialg="100%"
                    widthmediamd="100%"
                    error={item.fabricante1 === "" && error}
                    type="text"
                    onBlur={() =>
                      setTimeout(
                        () =>
                          dispatchAutocomplete({
                            type: "reset",
                          }),
                        200
                      )
                    }
                    id={`fabricante1${index}`}
                    label="Fabricante/Fornecedor 1 *"
                    placeholder="Informe o Fabricante/Fornecedor 1"
                    value={item.fabricante1}
                    handleChange={async (e) => {
                      const query = e.target.value;

                      handleSupplierChange({
                        e,
                        prop: "fabricante1",
                        query,
                        field: `fabricante1${index}`,
                        id: "fabricante1",
                        index,
                      });
                    }}
                    helperText={
                      autocompleteState.error &&
                      autocompleteState.field === `fabricante1${index}` ? (
                        <Reload
                          handleClick={() => {
                            dispatchAutocomplete({
                              type: "set-query",
                              payload: {
                                query: autocompleteState.query,
                                field: `fabricante1${index}`,
                              },
                            });
                          }}
                        />
                      ) : item.fabricante1 === "" && error ? (
                        <S.Error>Campo obrigatório</S.Error>
                      ) : (
                        ""
                      )
                    }
                    helperIcon={
                      item.fabricante1.trim() === "" && error ? (
                        <Error />
                      ) : (
                        <Error style={{ visibility: "hidden" }} />
                      )
                    }
                  />
                  {autocompleteState.query.length > 0 &&
                  autocompleteState.field === `fabricante1${index}` ? (
                    <Autocomplete
                      isCurrentInput
                      list={autocompleteState.suggestions ?? []}
                      isLoading={autocompleteState.loading}
                      propLabel="fornecedor"
                      emptyMessage="Nenhum fornecedor foi encontrado para a sua busca."
                      onClick={(value) => {
                        dataChange({
                          target: {
                            name: "materialOPME",
                            value: updatePropInList(
                              data["materialOPME"],
                              { id: "fabricante1", value: value.fornecedor },
                              index,
                              "materialOPME"
                            ).value,
                          },
                        });

                        dispatchAutocomplete({
                          type: "reset",
                        });
                      }}
                    />
                  ) : null}
                </S.AutocompleteRow>

                <S.AutocompleteRow>
                  <S.Input
                    autoComplete="off"
                    widthmedialg="100%"
                    widthmediamd="100%"
                    type="text"
                    disabled={item.naoIndicarFabricante}
                    id={`fabricante2${index}`}
                    label={`Fabricante/Fornecedor 2 ${
                      item.naoIndicarFabricante ? "" : "*"
                    }`}
                    onBlur={() =>
                      setTimeout(
                        () =>
                          dispatchAutocomplete({
                            type: "reset",
                          }),
                        200
                      )
                    }
                    error={item.fabricante2 === "" && error}
                    placeholder="Informe o Fabricante/Fornecedor 2"
                    value={item.fabricante2}
                    handleChange={async (e) => {
                      const query = e.target.value;

                      handleSupplierChange({
                        e,
                        prop: "fabricante2",
                        query,
                        field: `fabricante2${index}`,
                        id: "fabricante2",
                        index,
                      });
                    }}
                    helperText={
                      autocompleteState.error &&
                      autocompleteState.field === `fabricante2${index}` ? (
                        <Reload
                          handleClick={() => {
                            dispatchAutocomplete({
                              type: "set-query",
                              payload: {
                                query: autocompleteState.query,
                                field: `fabricante2${index}`,
                              },
                            });
                          }}
                        />
                      ) : item.fabricante2 === "" &&
                        error &&
                        !item.naoIndicarFabricante ? (
                        <S.Error>Campo obrigatório</S.Error>
                      ) : (
                        ""
                      )
                    }
                    helperIcon={
                      item.fabricante2.trim() === "" &&
                      error &&
                      !item.naoIndicarFabricante ? (
                        <Error />
                      ) : (
                        <Error style={{ visibility: "hidden" }} />
                      )
                    }
                  />
                  {autocompleteState.query.length > 0 &&
                  autocompleteState.field === `fabricante2${index}` ? (
                    <Autocomplete
                      isCurrentInput
                      list={autocompleteState.suggestions ?? []}
                      isLoading={autocompleteState.loading}
                      emptyMessage="Nenhum fornecedor foi encontrado para a sua busca."
                      propLabel="fornecedor"
                      onClick={(value) => {
                        dataChange({
                          target: {
                            name: "materialOPME",
                            value: updatePropInList(
                              data["materialOPME"],
                              { id: "fabricante2", value: value.fornecedor },
                              index,
                              "materialOPME"
                            ).value,
                          },
                        });

                        dispatchAutocomplete({
                          type: "reset",
                        });
                      }}
                    />
                  ) : null}
                </S.AutocompleteRow>

                <S.AutocompleteRow>
                  <S.Input
                    autoComplete="off"
                    widthmedialg="100%"
                    widthmediamd="100%"
                    type="text"
                    id={`fabricante3${index}`}
                    placeholder="Informe o Fabricante/Fornecedor 3"
                    label={`Fabricante/Fornecedor 3  ${
                      item.naoIndicarFabricante ? "" : "*"
                    }`}
                    error={
                      item.fabricante3.trim() === "" &&
                      error &&
                      !item.naoIndicarFabricante
                    }
                    onBlur={() =>
                      setTimeout(
                        () =>
                          dispatchAutocomplete({
                            type: "reset",
                          }),
                        200
                      )
                    }
                    disabled={item.naoIndicarFabricante}
                    value={item.fabricante3}
                    handleChange={async (e) => {
                      const query = e.target.value;

                      handleSupplierChange({
                        e,
                        prop: "fabricante3",
                        query,
                        field: `fabricante3${index}`,
                        id: "fabricante3",
                        index,
                      });
                    }}
                    helperText={
                      autocompleteState.error &&
                      autocompleteState.field === `fabricante3${index}` ? (
                        <Reload
                          handleClick={() => {
                            dispatchAutocomplete({
                              type: "set-query",
                              payload: {
                                query: autocompleteState.query,
                                field: `fabricante3${index}`,
                              },
                            });
                          }}
                        />
                      ) : item.fabricante3 === "" &&
                        error &&
                        !item.naoIndicarFabricante ? (
                        <S.Error>Campo obrigatório</S.Error>
                      ) : (
                        ""
                      )
                    }
                    helperIcon={
                      item.fabricante3.trim() === "" &&
                      error &&
                      !item.naoIndicarFabricante ? (
                        <Error />
                      ) : (
                        <Error style={{ visibility: "hidden" }} />
                      )
                    }
                  />
                  {autocompleteState.query.length > 0 &&
                  autocompleteState.field === `fabricante3${index}` ? (
                    <Autocomplete
                      isCurrentInput
                      list={autocompleteState.suggestions ?? []}
                      isLoading={autocompleteState.loading}
                      emptyMessage="Nenhum fornecedor foi encontrado para a sua busca."
                      propLabel="fornecedor"
                      onClick={(value) => {
                        dataChange({
                          target: {
                            name: "materialOPME",
                            value: updatePropInList(
                              data["materialOPME"],
                              { id: "fabricante3", value: value.fornecedor },
                              index,
                              "materialOPME"
                            ).value,
                          },
                        });

                        dispatchAutocomplete({
                          type: "reset",
                        });
                      }}
                    />
                  ) : null}
                </S.AutocompleteRow>
              </S.StyledContent>

              <S.StyledContent>
                <TmpCheckbox
                  label="Não quero indicar outro fabricante/fornecedor"
                  disabled={
                    !!item.fabricante3.trim() &&
                    !!item.fabricante2.trim() &&
                    !!item.fabricante1.trim()
                  }
                  id="naoIndicarFabricante"
                  name="naoIndicarFabricante"
                  handleChange={(value) => {
                    handleCheckboxChange(
                      value,
                      "naoIndicarFabricante",
                      index,
                      "materialOPME"
                    );
                  }}
                  checked={item.naoIndicarFabricante}
                  value={item.naoIndicarFabricante}
                />
              </S.StyledContent>

              {item?.naoIndicarFabricante ? (
                <>
                  <S.MaterialInfos>
                    Conforme Resolução Normativa da ANS, para evitar a
                    configuração de conflitos de interesses, fato que fere o
                    código de ética médica, a disponibilização de 3 marcas/
                    fornecedores é necessária e imprescindível. A dispensa dessa
                    opção só é legitima quando o material for de ÚNICO
                    FORNECEDOR atestado e documentado ou quando o médico
                    assistente entender que existe insuficiência técnica do
                    material.
                  </S.MaterialInfos>
                  <S.StyledContent alignItems={"none"} columns={"1fr 2fr"}>
                    <S.MotiveInfos>
                      <S.SelectWrap
                        margin="0px"
                        placeholderColor={
                          item.naoIndicarFabricanteMotivo !== ""
                        }
                        error={item.naoIndicarFabricanteMotivo === "" && error}
                      >
                        <TmpSelect
                          id="naoIndicarFabricanteMotivo"
                          name="naoIndicarFabricanteMotivo"
                          label="Informe o motivo *"
                          placeholder="Selecione o motivo"
                          value={getSelectObjValue(
                            item.naoIndicarFabricanteMotivo
                          )}
                          error={
                            item.naoIndicarFabricanteMotivo === "" && error
                          }
                          handleChange={(args) =>
                            handleChangeSelect(
                              args.label,
                              "naoIndicarFabricanteMotivo",
                              index,
                              "materialOPME"
                            )
                          }
                          listItems={[
                            {
                              label: "Justificativa Técnica",
                            },
                            {
                              label: "Uso exclusivo",
                            },
                            {
                              label: "Desconhece outro fornecedor",
                            },
                          ]}
                          helperText={
                            item.naoIndicarFabricanteMotivo === "" && error ? (
                              <S.Error>Campo obrigatório</S.Error>
                            ) : (
                              ""
                            )
                          }
                          helperIcon={
                            item.naoIndicarFabricanteMotivo === "" && error ? (
                              <Error style={{ color: "#EA1F1F" }} />
                            ) : (
                              <Error style={{ visibility: "hidden" }} />
                            )
                          }
                        />
                      </S.SelectWrap>
                      <S.WrapperSignature displayTablet="none">
                        <img
                          src={auth?.userInfos?.urlAssinatura}
                          style={{ marginRight: "51px" }}
                        />
                        <div>
                          <span>{getSchedulingLocal(local)},</span>
                          <p>
                            {item?.naoIndicarFabricanteMotivoData !== ""
                              ? `${new Date(
                                  item.naoIndicarFabricanteMotivoData
                                ).toLocaleDateString()} - ${
                                  item.naoIndicarFabricanteMotivoHora
                                    ? item.naoIndicarFabricanteMotivoHora
                                    : ""
                                }`
                              : null}
                          </p>
                        </div>
                      </S.WrapperSignature>
                    </S.MotiveInfos>
                    <S.TextareaWrap
                      height="136px"
                      id="target"
                      error={
                        item.naoIndicarFabricanteJustificativa === "" && error
                      }
                    >
                      <TextArea
                        value={item.naoIndicarFabricanteJustificativa}
                        id={`naoIndicarFabricanteJustificativa${index}`}
                        error={
                          item.naoIndicarFabricanteJustificativa === "" && error
                        }
                        prop={"naoIndicarFabricanteJustificativa"}
                        index={"materialOPME"}
                        maxLength={4000}
                        placeholder="Escreva aqui..."
                        message="Você atingiu o limite de caractere."
                        handleChange={(e) => {
                          dataChange({
                            target: {
                              name: "materialOPME",
                              value: updatePropInList(
                                data["materialOPME"],
                                {
                                  id: "naoIndicarFabricanteJustificativa",
                                  value: e.target.value.trim(),
                                },
                                index,
                                "materialOPME"
                              ).value,
                            },
                          });
                        }}
                        label="Justificativa *"
                        helperText={
                          item.naoIndicarFabricanteJustificativa === "" &&
                          error ? (
                            <S.Error>Campo obrigatório</S.Error>
                          ) : (
                            ""
                          )
                        }
                        helperIcon={
                          item.naoIndicarFabricanteJustificativa === "" &&
                          error ? (
                            <Error />
                          ) : (
                            <Error style={{ visibility: "hidden" }} />
                          )
                        }
                      />
                    </S.TextareaWrap>
                  </S.StyledContent>
                </>
              ) : null}
            </S.MaterialContainer>
          ))}
        </div>
        <S.AddButtonWrap>
          <TmpButton
            type="secondary"
            size="sm"
            startIcon={<Plus />}
            handleClick={() => addNewItem("materialOPME")}
          >
            {data?.materialOPME.length > 0
              ? "Adicionar mais OPME"
              : "Adicionar OPME"}
          </TmpButton>
        </S.AddButtonWrap>
      </S.StyledSectionWrapper>

      <S.BoxTitle marginBottom="0">
        <S.Title>Instrumentais</S.Title>
        <TmpDivider />
      </S.BoxTitle>

      <S.StyledSectionWrapper rows={"1fr"}>
        <div>
          {data?.materialInstrumentos?.map((item, index) => (
            <S.MaterialContainer
              backgroundColor={
                (index + 1) % 2 ? "transparent" : "rgba(252, 252, 252, 1)"
              }
              borderTop={
                index === 0 ? "none" : "1px solid rgba(224, 224, 224, 1)"
              }
              key={index}
            >
              <S.StyledContent columns={"2fr 1fr"}>
                <S.InputWrap>
                  <S.RemoveBtn
                    onClick={() => removeItem("materialInstrumentos", index)}
                  >
                    <Trash />
                  </S.RemoveBtn>
                  <TmpInputCC
                    widthmedia="100%"
                    widthmedialg="100%"
                    widthmediamd="100%"
                    type="text"
                    label={`Instrumental ${index + 1} *`}
                    placeholder="Inserir apenas um instrumento por campo"
                    id={`instrumental${index}`}
                    showCounter={true}
                    message="Você atingiu o limite de caractere."
                    error={item.material === "" && error}
                    value={item.material.slice(0, 225)}
                    maxLength={225}
                    handleChange={(e) =>
                      handleChangeInput(
                        e,
                        "material",
                        index,
                        "materialInstrumentos"
                      )
                    }
                    helperText={
                      item.material === "" && error ? (
                        <S.Error>Campo obrigatório</S.Error>
                      ) : (
                        ""
                      )
                    }
                    helperIcon={
                      item.material === "" && error ? <Error /> : null
                    }
                  />
                </S.InputWrap>
                <S.InputWrap
                  placeholderColor={item.quantidade !== ""}
                  error={checkQuantity(item.quantidade) && error}
                  maxWidth="100%"
                  inputMarginTop="0px"
                >
                  <TmpInputCC
                    autoComplete="off"
                    type="text"
                    id={`qtdInstrumental${index}`}
                    name="quantidade"
                    label="Quantidade *"
                    onKeyDown={(e) => handleKeyDown(e)}
                    onWheel={(e) => e.target.blur()}
                    error={checkQuantity(item.quantidade) && error}
                    placeholder="Inserir quantidade"
                    value={formatQuantity(item.quantidade)}
                    formatValue={true}
                    handleChange={(e) =>
                      handleChangeInput(
                        e,
                        "quantidade",
                        index,
                        "materialInstrumentos",
                        "qtd"
                      )
                    }
                    helperText={
                      error ? (
                        <S.Error>
                          {item.quantidade === ""
                            ? "Campo obrigatório"
                            : checkQuantity(item.quantidade) &&
                              "Quantidade inválida. O valor deve ser maior ou igual a 1."}
                        </S.Error>
                      ) : (
                        ""
                      )
                    }
                    helperIcon={
                      checkQuantity(item.quantidade) && error ? (
                        <Error style={{ color: "#EA1F1F" }} />
                      ) : (
                        <Error style={{ visibility: "hidden" }} />
                      )
                    }
                  />
                </S.InputWrap>
              </S.StyledContent>
            </S.MaterialContainer>
          ))}
        </div>

        <S.AddButtonWrap>
          <TmpButton
            type="secondary"
            size="sm"
            startIcon={<Plus />}
            handleClick={() => addNewItem("materialInstrumentos")}
          >
            {data?.materialInstrumentos?.length > 0
              ? "Adicionar mais CME"
              : "Adicionar CME"}
          </TmpButton>
        </S.AddButtonWrap>
      </S.StyledSectionWrapper>

      <S.BoxTitle marginBottom="0">
        <S.Title>Equipamentos</S.Title>
        <TmpDivider />
      </S.BoxTitle>
      <S.StyledSectionWrapper rows={"1fr"}>
        <div>
          {data?.materialEquipamentos?.map((item, index) => (
            <S.MaterialContainer
              backgroundColor={
                (index + 1) % 2 ? "transparent" : "rgba(252, 252, 252, 1)"
              }
              borderTop={
                index === 0 ? "none" : "1px solid rgba(224, 224, 224, 1)"
              }
              key={index}
            >
              <S.StyledContent columns={"2fr 1fr"}>
                <S.InputWrap>
                  <S.RemoveBtn
                    onClick={() => removeItem("materialEquipamentos", index)}
                  >
                    <Trash />
                  </S.RemoveBtn>
                  <TmpInputCC
                    widthmedia="100%"
                    widthmedialg="100%"
                    widthmediamd="100%"
                    type="text"
                    label={`Equipamento ${index + 1} *`}
                    placeholder="Inserir apenas um equipamento por campo"
                    id={`equipamento${index}`}
                    error={item.material === "" && error}
                    value={item.material.slice(0, 225)}
                    maxLength={225}
                    showCounter={true}
                    message="Você atingiu o limite de caractere."
                    handleChange={(e) =>
                      handleChangeInput(
                        e,
                        "material",
                        index,
                        "materialEquipamentos"
                      )
                    }
                    helperText={
                      item.material === "" && error ? (
                        <S.Error>Campo obrigatório</S.Error>
                      ) : (
                        ""
                      )
                    }
                    helperIcon={
                      item.material === "" && error ? <Error /> : null
                    }
                  />
                </S.InputWrap>
                <S.InputWrap
                  placeholderColor={item.quantidade !== ""}
                  error={checkQuantity(item.quantidade) && error}
                  maxWidth="100%"
                  inputMarginTop="0px"
                >
                  <TmpInputCC
                    autoComplete="off"
                    type="text"
                    id={`qtdEquipamento${index}`}
                    name="quantidade"
                    label="Quantidade *"
                    onKeyDown={(e) => handleKeyDown(e)}
                    onWheel={(e) => e.target.blur()}
                    error={checkQuantity(item.quantidade) && error}
                    placeholder="Inserir quantidade"
                    value={formatQuantity(item.quantidade)}
                    formatValue={true}
                    handleChange={(e) =>
                      handleChangeInput(
                        e,
                        "quantidade",
                        index,
                        "materialEquipamentos",
                        "qtd"
                      )
                    }
                    helperText={
                      error ? (
                        <S.Error>
                          {item.quantidade === ""
                            ? "Campo obrigatório"
                            : checkQuantity(item.quantidade) &&
                              "Quantidade inválida. O valor deve ser maior ou igual a 1."}
                        </S.Error>
                      ) : (
                        ""
                      )
                    }
                    helperIcon={
                      checkQuantity(item.quantidade) && error ? (
                        <Error style={{ color: "#EA1F1F" }} />
                      ) : (
                        <Error style={{ visibility: "hidden" }} />
                      )
                    }
                  />
                </S.InputWrap>
              </S.StyledContent>
            </S.MaterialContainer>
          ))}
        </div>
        <S.AddButtonWrap>
          <TmpButton
            type="secondary"
            size="sm"
            startIcon={<Plus />}
            handleClick={() => addNewItem("materialEquipamentos")}
          >
            {data?.materialEquipamentos?.length > 0
              ? "Adicionar mais equipamentos"
              : "Adicionar equipamentos"}
          </TmpButton>
        </S.AddButtonWrap>
      </S.StyledSectionWrapper>
      <S.BoxTitle marginBottom="0">
        <S.Title>Medicação Intra-operatória</S.Title>
        <TmpDivider />
      </S.BoxTitle>

      <S.StyledSectionWrapper rows={"1fr"}>
        <div>
          {data?.materialMedicacao?.map((item, index) => (
            <S.MaterialContainer
              backgroundColor={
                (index + 1) % 2 ? "transparent" : "rgba(252, 252, 252, 1)"
              }
              borderTop={
                index === 0 ? "none" : "1px solid rgba(224, 224, 224, 1)"
              }
              key={index}
            >
              <S.StyledContent columns={"2fr 1fr"}>
                <S.InputWrap>
                  <S.RemoveBtn
                    onClick={() => removeItem("materialMedicacao", index)}
                  >
                    <Trash />
                  </S.RemoveBtn>
                  <TmpInputCC
                    width="100%"
                    widthmedialg="100%"
                    widthmediamd="100%"
                    type="text"
                    label={`Intra-operatório ${index + 1} *`}
                    placeholder="Inserir apenas um intra-operatório por campo"
                    id={`materialOperatorio${index}`}
                    error={item.material === "" && error}
                    value={item.material.slice(0, 225)}
                    maxLength={225}
                    showCounter={true}
                    message="Você atingiu o limite de caractere."
                    handleChange={(e) =>
                      handleChangeInput(
                        e,
                        "material",
                        index,
                        "materialMedicacao"
                      )
                    }
                    helperText={
                      item.material === "" && error ? (
                        <S.Error>Campo obrigatório</S.Error>
                      ) : (
                        ""
                      )
                    }
                    helperIcon={
                      item.material === "" && error ? <Error /> : null
                    }
                  />
                </S.InputWrap>
                <S.InputWrap
                  placeholderColor={item.quantidade !== ""}
                  error={checkQuantity(item.quantidade) && error}
                >
                  <TmpInputCC
                    autoComplete="off"
                    type="text"
                    id={`qtdOperatorio${index}`}
                    name="quantidade"
                    label="Quantidade *"
                    onKeyDown={(e) => handleKeyDown(e)}
                    onWheel={(e) => e.target.blur()}
                    error={checkQuantity(item.quantidade) && error}
                    placeholder="Inserir quantidade"
                    value={formatQuantity(item.quantidade)}
                    formatValue={true}
                    handleChange={(e) =>
                      handleChangeInput(
                        e,
                        "quantidade",
                        index,
                        "materialMedicacao",
                        "qtd"
                      )
                    }
                    helperText={
                      error ? (
                        <S.Error>
                          {item.quantidade === ""
                            ? "Campo obrigatório"
                            : checkQuantity(item.quantidade) &&
                              "Quantidade inválida. O valor deve ser maior ou igual a 1."}
                        </S.Error>
                      ) : (
                        ""
                      )
                    }
                    helperIcon={
                      checkQuantity(item.quantidade) && error ? (
                        <Error style={{ color: "#EA1F1F" }} />
                      ) : (
                        <Error style={{ visibility: "hidden" }} />
                      )
                    }
                  />
                </S.InputWrap>
              </S.StyledContent>
            </S.MaterialContainer>
          ))}
        </div>
        <S.AddButtonWrap>
          <TmpButton
            type="secondary"
            size="sm"
            startIcon={<Plus />}
            handleClick={() => addNewItem("materialMedicacao")}
          >
            {data?.materialMedicacao?.length > 0
              ? "Adicionar mais intra-operatórios"
              : "Adicionar intra-operatórios"}
          </TmpButton>
        </S.AddButtonWrap>
      </S.StyledSectionWrapper>
    </div>
  );
};

export default Materials;
