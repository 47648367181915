export const TABLE_WIDTH = {
  name: {
    desktop: "23.5%",
    title: "18.5%",
  },
  date: {
    desktop: "15%",
  },
  status: {
    desktop: "20%",
  },
  anamnese: {
    desktop: "15%",
  },
  consentForm: {
    desktop: "15%",
  },
  download: {
    desktop: "12%",
  },
};
