import React, { useEffect, useState } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { seta_direcional_esquerda as ArrowToLeft } from "@tempo/tempo-assets/dist";
import { useDispatch, useSelector } from "react-redux";
import { setOpenGoToHomeModal } from "../../features/settings/settingsSlice";
import MsgMobile from "../MessageMobile";

import Header from "../Header";
import Footer from "../Footer";
import * as S from "./style";
import NotSignature from "../NotSignature";
import LoadingSpinner from "../LoadingSpinner";

const MainLayout = ({ children }) => {
  const isEditingModel = useSelector((state) => state.settings.editingModel);
  const solicitaion = useSelector((state) => state.solicitation);
  const signature = useSelector((state) => state.auth.userInfos.assinatura);
  const basicInfoError = useSelector((state) => state.settings.basicInfoError);
  const modelName = useSelector((state) => state.model)?.nomeModelo;
  const navigate = useNavigate();
  const { pathname } = location;
  const previousScreen = location?.state?.fromScreen;
  const dispatch = useDispatch();

  const [editingSolicitation, setEditingSolicitation] = useState(
    solicitaion?.solicitacao?.SyncWithDynamo
  );

  useEffect(() => {
    setEditingSolicitation(solicitaion?.solicitacao?.SyncWithDynamo);
  }, [solicitaion?.solicitacao?.status]);

  const handleGoBack = () => {
    if (pathname === "/agendamento" && !basicInfoError) {
      dispatch(
        setOpenGoToHomeModal({
          value: true,
          toScreen: editingSolicitation ? "" : previousScreen,
        })
      );
    } else navigate(-1);
  };

  const getRender = () => {
    if (signature === undefined) {
      return <LoadingSpinner />;
    }

    if (signature === null) {
      return <NotSignature />;
    }

    return children || <Outlet />;
  };

  return (
    <>
      <Header text={isEditingModel && "Edição de modelo"} />

      <S.NavWrapper>
        {pathname !== "/" && !basicInfoError && (
          <S.Nav>
            <S.HoveredButton onClick={handleGoBack} type="button">
              <ArrowToLeft />
              <p>
                Voltar à Tela{" "}
                {previousScreen === "Modelos"
                  ? `de ${previousScreen}`
                  : previousScreen ?? "Inicial"}
              </p>
            </S.HoveredButton>
            {!isEditingModel && !editingSolicitation && (
              <S.TextWrapper>
                <S.Text>
                  <p>Novo agendamento</p>
                </S.Text>
              </S.TextWrapper>
            )}

            {isEditingModel && (
              <S.TooltipContainer>
                <S.TextWrapper>
                  <S.Text limitWidth>
                    <p>{modelName}</p>
                  </S.Text>
                </S.TextWrapper>
                <S.Tooltip>{modelName}</S.Tooltip>
              </S.TooltipContainer>
            )}

            {!isEditingModel && editingSolicitation && (
              <S.TextWrapper>
                <S.Text>
                  <p>Editar agendamento</p>
                </S.Text>
              </S.TextWrapper>
            )}
          </S.Nav>
        )}
      </S.NavWrapper>
      <S.MainCointainer>{getRender()}</S.MainCointainer>
      <MsgMobile />
      <Footer />
    </>
  );
};

export default MainLayout;
