import { apiProvider } from "./provider";
import { solicitationProvider } from "../utilities/solicitationProvider/provider";
import { store } from "../../../redux/store";

export const ApiAuth = (options) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      ...options.headers,
    },
    onUploadProgress: options.onUploadProgress,
  };

  return {
    getList: () => apiProvider.getList(options.url, config),
    getSingle: (id) => {
      return apiProvider.getSingle(options.url, id, config);
    },
    post: (model) => {
      return apiProvider.post(options.url, model, config);
    },
    put: (model) => {
      return apiProvider.put(options.url, model, config);
    },
  };
};

export const ApiCore = (options) => {
  const { token } = store.getState().auth || "";
  const { plugPlayAccessToken } = store.getState().auth || "";

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
      channel: "centro-cirurgico",
      ...options.headers,
    },
    onUploadProgress: options.onUploadProgress,
  };

  if (options.sendPlugPlayToken) {
    config.headers.plugplayaccesstoken = plugPlayAccessToken;
  }

  return {
    getList: () => apiProvider.getList(options.url, config),
    getSingle: (id) => {
      return apiProvider.getSingle(options.url, id, config);
    },
    post: (model) => {
      return apiProvider.post(options.url, model, config);
    },
    put: (model) => {
      return apiProvider.put(options.url, model, config);
    },
  };
};

export const ApiCoreSolicitations = (options) => {
  const { token } = store.getState().auth || "";
  const { plugPlayAccessToken } = store.getState().auth || "";

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
      Channel: "centro-cirurgico",
      ...options.headers,
    },
    onUploadProgress: options.onUploadProgress,
  };

  if (options.sendPlugPlayToken) {
    config.headers.plugplayaccesstoken = plugPlayAccessToken;
  }

  return {
    put: (model) => {
      return solicitationProvider.put(options.url, model, config);
    },
    post: (model, shouldBlockOnErrors) => {
      return solicitationProvider.post(
        options.url,
        model,
        config,
        shouldBlockOnErrors
      );
    },
    getList: () => solicitationProvider.getList(options.url, config),
    getSingle: (id) => {
      return solicitationProvider.getSingle(options.url, id, config);
    },
    deleteSingle: (id) => {
      return solicitationProvider.deleteSingle(options.url, id, config);
    },
    patch: (model) => {
      return solicitationProvider.patch(options.url, model, config);
    },
  };
};
