/* eslint-disable no-case-declarations */
import { useEffect, useState, useReducer, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setProcedureObject } from "../../features/procedures/proceduresSlice";
import { setMaterialsObject } from "../../features/materials/materialsSlice";
import { requiredPatient, requiredProcedures } from "./Steps/requiredFields";
import { setReportsObject } from "../../features/reports/reportsSlice";
import SolicitationPDF from "../../features/Documents/Solicitation";
import {
  isError,
  setOpenAnamnese,
  setCurrentStep,
  setOpenGoToHomeModal,
  setEditingModel,
  setDownloadError,
  isSuccessAnam,
  setModelSave,
  isSuccessConsent,
  setBasicInfoError,
  setRegisterSuccess,
  setInvalidPhone,
  setRequiredLaudo,
} from "../../features/settings/settingsSlice";
import {
  apiDomains,
  apiFinalizeSolicitation,
  apiSendFile,
  apiModels,
  apiConsentForm,
  apiCodeStates,
  apiRequiredCid,
} from "../../services/api";
import {
  TmpButtonGroup,
  TmpAlert,
  TmpIcon,
  TmpButton,
  TmpCheckbox,
  TmpDrawerContent,
  TmpLoading,
  TmpButtonIcon,
} from "@tempo/tempo-design-system-core/dist";
import {
  termos_de_uso as TermosSVG,
  prescricao_guia as PrescricaoSVG,
} from "@tempo/tempo-assets/dist";
import { useNavigate, useLocation } from "react-router-dom";

import Basicinfo from "./Steps/BasicInfo";
import Materials from "./Steps/Materials";
import Procedure from "./Steps/Procedure";
import Reports from "./Steps/Reports";
import Anamnese from "../../components/Anamnese";
import {
  parseToSelectLabel,
  getDaysToProcedure,
  getSchedulingLocal,
  getAbbreviatedFullName,
  verifyIeLado,
  removeFieldsProcedure,
} from "../../utils";
import * as S from "./style";
import { Saving, StyledSaving } from "./Steps/style";
import { filledFields } from "../../utils";
import {
  alerta as Alert,
  pontinhos_horizontal as Pontinhos,
  cancelar as IconCancelar,
} from "@tempo/tempo-assets/dist";
import ConsentForm from "../../components/ConsentForm";

import { setPatientObject } from "../../features/patient/patientSlice";
import { solicitation } from "../../features/solicitation/solicitation";
import {
  setClearSolicitation,
  setSolicitationObject,
} from "../../features/solicitation/solicitationSlice";
import { pdf } from "@react-pdf/renderer";

import NewModal from "../../components/NewModal";
import ModalNameModel from "../../components/ModalNameModel";
import { StyledLoadingTermo } from "../ListSolicitations/style";
import Validation, { validationFormatDate } from "../../utils/Validation";
import axios from "axios";
import Sucesso from "../../assets/images/sucesso.png";
import Atention from "../../assets/images/atencao.svg";
import LocalAndDate from "../../components/LocalAndDate";
import { RequestFailed } from "../../components/RequestFailed";
import { sendGAPageView } from "../../utils/ga";
import { TmpStepCC } from "@tempo/tempo-design-system-centro-cirurgico";
import {
  createMaterials,
  createProcedure,
  createReports,
  createSolicitation,
  updateSolicitation,
  createMaterialsNoDebounce,
} from "./controllers";
import Drawer from "../../components/Drawer";
import { Help } from "../../components/Help";
import { ChoiceSolicitationModal } from "../../components/ChoiceSolicitationModal";
import { fields } from "./Steps/Procedure/comboboxes";

let newValueCid = "";
const Scheduling = () => {
  const formReducer = (state, event) => {
    if (event.type === "update-all") {
      return { ...event.value };
    }

    if (event.type === "clean") {
      return {
        ...formInitialState,
      };
    }
    if (event.slice) {
      return {
        ...state,
        [event.slice]: {
          ...state[event.slice],
          [event.name]: event.value,
        },
      };
    } else
      return {
        ...state,
        [event.name]: { ...state[event.name], ...event.value },
      };
  };

  const populateFormData = (event, slice) => {
    setFormData({
      name: event.target.name,
      value: event.target.value,
      slice,
    });
  };

  const stepNavigation = useRef(null);

  const location = useLocation();
  const noRenderCreateModel = location.state?.noRenderCreateModel;
  const from = location.state?.from?.pathname || "/";

  const isEditingModel = useSelector((state) => state.settings.editingModel);
  const model = useSelector((state) => state.model);

  const [planInfoDrawer, setPlanInfoDrawer] = useState(false);
  const [modelInfoDrawer, setModelInfoDrawer] = useState(false);
  const [extraTeamsDrawer, setExtraTeamsDrawer] = useState({
    renderOnAdvanceStep: false,
    render: false,
  });
  const [idPatientNotReturnedDrawer, setIdPatientNotReturnedDrawer] =
    useState(false);
  const [nameModelModal, setNameModel] = useState(false);
  const [solicitationEdited, setSolicitationEdited] = useState(false);

  const {
    currentStep,
    error,
    isNewPatient,
    timeNeedError,
    openAnamnese,
    editingModel,
    confirmGoToHomeOpen,
    downloadError,
    successAnam,
    successConsent,
    basicInfoError,
  } = useSelector((state) => state.settings);
  const materials = useSelector((state) => state.materials);
  const patient = useSelector((state) => state.patient);
  const procedures = useSelector((state) => state.procedures);
  const reports = useSelector((state) => state.reports);
  const auth = useSelector((state) => state.auth);
  const { isSecretary } = useSelector((state) => state.auth);
  const currentSolicitation = useSelector((state) => state.solicitation);
  const requiredLaudo = useSelector((state) => state.settings.requiredLaudo);
  const formInitialState = {
    solicitacao: {
      ...solicitation.solicitacao,
      idMedico: auth.userInfos.cdPessoaFisica,
      usuarioSolicitante: auth.userInfos.user,
    },
    paciente: patient,
    procedimento: procedures,
    material: materials,
    relatorio: reports,
  };
  const [cidModified, setCidModified] = useState(false);
  const [otherStateModified, setOtherStateModified] = useState(false);

  const isUnifiedSolicitation =
    currentSolicitation.solicitacao.solicitacaoUnificada;

  const isAditionalTeam =
    isUnifiedSolicitation &&
    currentSolicitation.solicitacao.idSolicitacao !==
      currentSolicitation.solicitacao.idSolicitacaoPrincipal;

  const isSolicitation = () => {
    return !!currentSolicitation?.solicitacao?.idSolicitacao;
  };

  const isModel = () => {
    return !!model?.idModelo;
  };

  const objProcedure = removeFieldsProcedure(
    model.procedimento,
    isAditionalTeam
      ? [
          "cdReservaCritica",
          "regimeHospitalar",
          "reservaCritica",
          "reservaHemocomponentes",
          "tipagemSanguinea",
          "anestesista",
          "carater",
          "data",
          "equipe",
        ]
      : []
  );

  const populateForm = () => {
    if (
      !currentSolicitation.solicitacao.wasOpened &&
      model.idModelo &&
      (isAditionalTeam || currentSolicitation.solicitacao.origem === "Tasy")
    ) {
      setFormData({
        name: "solicitacao",
        value: currentSolicitation.solicitacao,
      });
      setFormData({
        name: "paciente",
        value: {
          ...currentSolicitation.paciente,
          ...model.paciente,
        },
      });
      setFormData({
        name: "procedimento",
        value: {
          ...currentSolicitation.procedimento,
          ...objProcedure,
        },
      });
      setFormData({
        name: "material",
        value: {
          materialOPME: [
            ...currentSolicitation.material.materialOPME,
            ...model.material.materialOPME,
          ],
          materialEquipamentos: [
            ...currentSolicitation.material.materialEquipamentos,
            ...model.material.materialEquipamentos,
          ],
          materialInstrumentos: [
            ...currentSolicitation.material.materialInstrumentos,
            ...model.material.materialInstrumentos,
          ],
          materialMedicacao: [
            ...currentSolicitation.material.materialMedicacao,
            ...model.material.materialMedicacao,
          ],
        },
      });
      setFormData({
        name: "relatorio",
        value: {
          ...currentSolicitation.relatorio,
          ...model.relatorio,
        },
      });
    } else if (isSolicitation()) {
      setFormData({
        name: "solicitacao",
        value: currentSolicitation.solicitacao,
      });
      setFormData({
        name: "paciente",
        value: currentSolicitation.paciente,
      });
      setFormData({
        name: "procedimento",
        value: currentSolicitation.procedimento,
      });

      setFormData({
        name: "material",
        value: currentSolicitation.material,
      });

      setFormData({
        name: "relatorio",
        value: currentSolicitation.relatorio,
      });
    } else if (isModel()) {
      setFormData({
        name: "solicitacao",
        value: model.solicitacao,
      });
      setFormData({
        name: "paciente",
        value: model.paciente,
      });
      setFormData({
        name: "procedimento",
        value: {
          ...model.procedimento,
          procedimentoPrincipal: {
            procedimento: model.procedimento.procedimentoPrincipal,
            lateralidade: model.lateralidade,
          },
        },
      });

      setFormData({
        name: "material",
        value: model.material,
      });

      setFormData({
        name: "relatorio",
        value: model.relatorio,
      });
    }
  };

  useEffect(() => {
    populateForm();
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      const confirmationMessage = true;

      e.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
      return confirmationMessage; // Gecko, WebKit, Chrome <34
    };

    if (solicitationEdited) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [solicitationEdited]);

  const handleDataChange = (event, slice) => {
    if (isSolicitation() && editingSolicitation) {
      setSolicitationEdited(true);
    }
    if (
      event?.target?.name !== "cidPrincipal" &&
      event?.target?.name !== "cdCidPrincipal" &&
      event?.target?.name !== "cdCidPrincipalFormatado"
    ) {
      setOtherStateModified(true);
    }
    setFormData({
      name: event.target.name,
      value: event.target.value,
      slice,
    });
  };

  const [formData, setFormData] = useReducer(
    formReducer,
    currentSolicitation.solicitacao.idSolicitacao
      ? currentSolicitation
      : formInitialState
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [showFilledModalByMainTeam, setShowFilledModalByMainTeam] =
    useState(false);
  const [patientSolicitations, setPatientSolicitations] = useState([]);
  const [consentFormOpen, setConsentFormOpen] = useState(false);
  const [sendingError, setSendingError] = useState(false);
  const [confirmRegistration, setConfirmRegistration] = useState(false);
  const [codeState, setCodeState] = useState([]);
  const [navigation, setNavigation] = useState(false);
  const [domains, setDomains] = useState({
    carater_atend: [],
    regime_atend: [],
    peso: [],
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anticipateSurgery, setAnticipateSurgery] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [saving, setSaving] = useState(false);
  const [advancing, setAdvancing] = useState(false);
  const [sending, setSending] = useState(false);
  const [editingSolicitation, setEditingSolicitation] = useState(
    formData.solicitacao?.SyncWithDynamo
  );

  useEffect(() => {
    setEditingSolicitation(formData?.solicitacao?.SyncWithDynamo);
  }, [formData?.solicitacao?.SyncWithDynamo]);

  const updateSolicitationAfterChoosing = (newSolicitation) => {
    setFormData({ type: "update-all", value: newSolicitation });
  };

  const [loadingFields, setLoadingFields] = useState({
    nacionalities: false,
    specialNeeds: false,
    healthInsurance: false,
    domains: false,
    surgicalTimeSuggestion: false,
  });

  const useLoadingFields = (key, value) => {
    setLoadingFields((prev) => ({ ...prev, [key]: value }));
  };

  function getScreenTitle() {
    const titles = [
      "Informacoes Basicas",
      "Procedimento",
      "Materiais",
      "Relatorio e laudos",
    ];

    return titles[currentStep - 1];
  }

  useEffect(() => {
    sendGAPageView(getScreenTitle());
  }, [currentStep]);

  const onClickFilledFieldByMainTeam = () => {
    setModalOpen(true);
    setShowFilledModalByMainTeam(true);
  };

  const sendMaterials = useCallback(
    async (e) => {
      try {
        setSendingError(false);
        setAdvancing(true);
        setSaving(true);

        const response = await createMaterialsNoDebounce(formData);

        if (response?.idSolicitacao) {
          dispatch(setMaterialsObject(formData.material));
          dispatch(setSolicitationObject({ materials: formData.material }));

          if (e?.type === "click") {
            dispatch(setCurrentStep(currentStep + 1));
          }

          setModalOpen(false);
        } else {
          setSendingError(true);
        }
      } catch (error) {
        setSendingError(true);
        setOpenToast(true);
        console.log("Erro no envio de materiais", error);
      } finally {
        setAdvancing(false);
        setSaving(false);
      }
    },
    [formData, currentStep, dispatch]
  );

  const filterMaterials = (materials) => {
    const materialEquipamentos = materials.materialEquipamentos.filter(
      (material) => !filledQuantityFields(material)
    );

    const materialInstrumentos = materials.materialInstrumentos.filter(
      (material) => !filledQuantityFields(material)
    );
    const materialMedicacao = materials.materialMedicacao.filter(
      (material) => !filledQuantityFields(material)
    );
    const materialOPME = materials.materialOPME.filter(
      (material) => !verifyOPMEFields(material)
    );

    return {
      materialEquipamentos,
      materialInstrumentos,
      materialMedicacao,
      materialOPME,
    };
  };

  useEffect(() => {
    (async () => {
      const materials = formData.material;

      if (materials && currentSolicitation.solicitacao.origem !== "Tasy") {
        if (!filledQuantityFields(materials)) {
          const filteredMaterials = filterMaterials(materials);

          if (currentStep === 3 && !editingModel && !editingSolicitation) {
            await createMaterials({
              ...formData,
              material: { ...materials, ...filteredMaterials },
            });
          }
        }
      }
    })();
  }, [formData.material, currentStep, editingModel, editingSolicitation]);

  const steps = [
    <Basicinfo
      domains={domains}
      dataChangeObj={(e) => handleDataChange(e, "")}
      dataChange={(e) => handleDataChange(e, "paciente")}
      populateFormData={(e) => populateFormData(e, "paciente")}
      basicInfoInitialState={formInitialState.paciente}
      data={formData.paciente}
      dataProc={formData.procedimento}
      idMedico={auth.userInfos.cdPessoaFisica}
      dataProcChange={(e) => handleDataChange(e, "procedimento")}
      openPlanInfoDrawer={() => setPlanInfoDrawer(true)}
      useLoadingFields={useLoadingFields}
      loadingFields={loadingFields}
      navigation={navigation}
      isAditionalTeam={isAditionalTeam}
      onClickFilledFieldByMainTeam={onClickFilledFieldByMainTeam}
      setPatientSolicitations={setPatientSolicitations}
      editingSolicitation={editingSolicitation}
      key={1}
    />,
    <Procedure
      domains={domains}
      dataChangeObj={(e) => {
        if (
          ["tuss", "lateralidade", "procedimentosExtras"].includes(
            e.target.name
          )
        ) {
          handleDataChange(
            {
              target: {
                name: "termoConsentimento",
                value: "",
              },
            },
            "relatorio"
          );
        }
        handleDataChange(e, "");
      }}
      dataChange={(e) => {
        if (
          ["tuss", "lateralidade", "procedimentosExtras"].includes(
            e.target.name
          )
        ) {
          handleDataChange(
            {
              target: {
                name: "termoConsentimento",
                value: "",
              },
            },
            "relatorio"
          );
        }
        handleDataChange(e, "procedimento");
      }}
      data={formData.procedimento}
      dataPatient={formData.paciente}
      setExtraTeamsDrawer={setExtraTeamsDrawer}
      useLoadingFields={useLoadingFields}
      surgicalTimeLoading={loadingFields.surgicalTimeSuggestion}
      populateFormData={(e) => populateFormData(e, "procedimento")}
      key={2}
      step={currentStep}
      codeState={codeState}
      isAditionalTeam={isAditionalTeam}
      isUnifiedSolicitation={isUnifiedSolicitation}
      editingSolicitation={editingSolicitation}
      onClickFilledFieldByMainTeam={onClickFilledFieldByMainTeam}
    />,
    <Materials
      dataChangeObj={(e) => handleDataChange(e, "")}
      dataChange={(e) => handleDataChange(e, "material")}
      data={formData.material}
      key={3}
      obrigaConvenio={formData.paciente.obrigaConvenio}
    />,
    <Reports
      dataChange={(e) => handleDataChange(e, "relatorio")}
      data={formData.relatorio}
      local={formData?.procedimento.local}
      healthInsurance={formData?.paciente?.convenio}
      key={4}
      requiredLaudo={requiredLaudo}
    />,
  ];

  const transformAnticipateSurgery = (value) => {
    if (value === 1) return true;
    else return false;
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await apiCodeStates();
        const code = response.filter((obj) =>
          formData.procedimento.cdEstabelecimento === 1
            ? obj.valueDomain === "34"
            : obj.valueDomain === "53"
        );
        setCodeState(code[0].valueDomain);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [formData.procedimento.local]);

  useEffect(() => {
    setAnticipateSurgery(
      transformAnticipateSurgery(formData?.solicitacao.anteciparAgendamento)
    );
  }, [formData?.solicitacao]);

  const stepsLabel = [
    { label: "Informações básicas" },
    { label: "Procedimento" },
    { label: "Materiais e outros serviços" },
    { label: "Relatórios e Laudos" },
  ];

  const handleStepSelect = (index) => {
    verifyRequiredReport();
    const step = index + 1;
    if (formData.relatorio.idRelatorio !== null && editingSolicitation) {
      if (solicitationEdited) {
        if (currentStep === 1 && validateBasicInfo()) {
          dispatch(setCurrentStep(step));
          setNavigation(true);
        }

        if (currentStep === 2 && validateProcedures()) {
          dispatch(setCurrentStep(step));
          setNavigation(true);
        }

        if (currentStep === 3 && validateMaterials(step)) {
          dispatch(setCurrentStep(step));
          setNavigation(true);
        }

        if (currentStep === 4 && validateMedicalCard()) {
          dispatch(setCurrentStep(step));
          setNavigation(true);
        }
      } else {
        dispatch(setCurrentStep(step));
        setNavigation(true);
      }
    }
  };

  useEffect(async () => {
    useLoadingFields("domains", true);
    try {
      const res = await apiDomains().getList();
      setDomains({
        carater_atend: parseToSelectLabel(
          "ds_valor_dominio",
          res.carater_atend
        ),
        regime_atend: parseToSelectLabel("ds_valor_dominio", res.regime_atend),
        peso: parseToSelectLabel("ds_valor_dominio", res.peso),
        lateralidade: sortLaterality(
          res.lados.map((item) => item.ds_valor_dominio)
        ),
        ieLado: res.lados,
      });
    } catch (error) {
      dispatch(setBasicInfoError(true));
    }
    useLoadingFields("domains", false);
  }, []);

  const sortLaterality = (laterality) => {
    const lateralityOrder = [
      "Não se Aplica",
      "Direito",
      "Esquerdo",
      "Bilateral (Ambos)",
      "Cervical",
      "Lombar",
      "Torácica",
      "Superior",
      "Inferior",
    ];

    return laterality
      .sort((a, b) => lateralityOrder.indexOf(a) - lateralityOrder.indexOf(b))
      .map((item) => {
        return { label: item };
      });
  };

  useEffect(() => {
    if (currentSolicitation?.solicitacao?.idSolicitacao === null)
      dispatch(setCurrentStep(1));
  }, []);

  useEffect(() => {
    if (error || sendingError) setOpenToast(true);
    else setOpenToast(false);
  }, [error, sendingError, advancing]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [currentStep]);

  useEffect(() => {
    if (
      confirmGoToHomeOpen.value &&
      editingSolicitation &&
      !solicitationEdited
    ) {
      navigate("/");
    }
  }, [confirmGoToHomeOpen.value, editingSolicitation, solicitationEdited]);

  const verifyOPMEFields = (item) => {
    if (item.material?.trim() && item.quantidade?.trim()) {
      if (item.naoIndicarFabricante) {
        if (
          item.naoIndicarFabricanteJustificativa &&
          item.naoIndicarFabricanteMotivo?.trim()
        ) {
          if (item.fabricante1?.trim()) {
            return false;
          }
        }
      }

      if (
        item.fabricante1?.trim() &&
        item.fabricante2?.trim() &&
        item.fabricante3?.trim()
      ) {
        return false;
      }
    }

    return true;
  };

  const checkQuantityFields = (state) => {
    const hasError = filledQuantityFields(state);
    dispatch(isError(hasError));
    return !hasError;
  };

  const filledQuantityFields = (state) => {
    var hasError = false;
    for (let property in state) {
      const value = state[property];
      if (property === "quantidade") {
        if (
          value === null ||
          Number.isNaN(parseInt(value)) ||
          parseInt(value) <= 0
        ) {
          hasError = true;
        }
      }
      if (typeof value === "object" && value !== null) {
        hasError = filledQuantityFields(value);
      }
      if (hasError) break;
    }

    return hasError;
  };

  const verifyAllFields = (state) => {
    var fieldError = false;
    for (const property in state) {
      if (!fieldError) {
        if (Array.isArray(state[property])) {
          if (state[property].length) {
            state[property].forEach((item) => {
              if (!fieldError) {
                fieldError =
                  property === "materialOPME"
                    ? verifyOPMEFields(item)
                    : !filledFields(item);
              }
            });
          }
        } else {
          fieldError = !filledFields(state[property]);
        }
      } else {
        dispatch(isError(fieldError));
        return fieldError;
      }
    }
    dispatch(isError(fieldError));
    return fieldError;
  };

  const verifyLists = () => {
    for (const property in formData.material) {
      if (Array.isArray(formData.material[property])) {
        if (formData.material[property].length) return false;
      }
    }
    return true;
  };

  const getSolicitationPdf = async (solicitation) => {
    const newPdf = <SolicitationPDF solicitation={solicitation} />;
    const blob = await pdf(newPdf).toBlob();
    const file = new File([blob], "solicitacaoInternacao.pdf", {
      type: blob.type,
    });

    try {
      const solicitacaoDados = await apiSendFile(
        file,
        formData.solicitacao.idSolicitacao
      ).post();
      const response = await axios.put(solicitacaoDados.url_presigned, file);

      const failedUpload = !solicitacaoDados?.uuid;
      const requestError = response?.name === "AxiosError";

      if (requestError || failedUpload) {
        throw new Error(
          `Erro ao fazer upload do arquivo de solicitação: ${response?.name}`
        );
      }

      if (solicitacaoDados?.uuid) {
        populateFormData(
          {
            target: {
              name: "solicitacaoInternacaoUUID",
              value: solicitacaoDados?.uuid,
            },
          },
          "relatorio"
        );
        return solicitacaoDados?.uuid;
      }
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  const optionalLaterality = (list) => {
    return list.map((item) => {
      return item.exige_lado === "S" || item?.lateralidade !== ""
        ? item
        : { ...item, lateralidade: "Não se aplica" };
    });
  };

  const removeOptionalFields = (obj, requiredFields) => {
    var newObj = {};
    requiredFields.forEach((field) => {
      if (obj[field] !== undefined) {
        newObj[field] = obj[field];
      }
    });
    return newObj;
  };

  const handleOpenModal = () => {
    if (verifyLists()) setModalOpen(true);
  };

  const editSolicitation = async () => {
    try {
      setSending(true);
      setSendingError(false);

      setAdvancing(true);

      let response = await apiConsentForm().getSingle(
        formData.solicitacao.idSolicitacao
      );

      if (response.idMedico) {
        const solicitacaoInternacaoUUID = await getSolicitationPdf(formData);

        populateFormData(
          {
            target: {
              name: "solicitacaoInternacaoUUID",
              value: solicitacaoInternacaoUUID,
            },
          },
          "relatorio"
        );

        const nmMedico = auth?.userInfos.nome;
        const usuarioSolicitante = auth?.userInfos?.user;

        response = await updateSolicitation({
          data: formData,
          solicitacaoInternacaoUUID,
          isSecretary,
          usuarioSolicitante,
          nmMedico,
        });

        if (response.statusCode === 201) {
          dispatch(setRegisterSuccess(true));

          setSending(false);
          setSaving(false);
          navigate("/");
          return;
        }

        if (response?.request?.status === 404) {
          setIdPatientNotReturnedDrawer(true);
          setConfirmRegistration(false);
          setModalOpen(false);
          setSending(false);
          setSaving(false);
          return;
        }
      }

      setModalOpen(false);
      setSendingError(true);
      setSending(false);
    } catch (error) {
      setModalOpen(false);
      setSendingError(true);
      setOpenToast(true);
      setSending(false);
      console.log("Finalização", error);
    } finally {
      setAdvancing(false);
      setSaving(false);
    }
  };

  const sendSolicitation = async () => {
    try {
      setSending(true);
      setSendingError(false);
      setAdvancing(true);

      let response = await apiConsentForm().getSingle(
        formData.solicitacao.idSolicitacao
      );

      if (response.idMedico) {
        if (editingSolicitation) {
          const solicitacaoInternacaoUUID = await getSolicitationPdf(formData);

          populateFormData(
            {
              target: {
                name: "solicitacaoInternacaoUUID",
                value: solicitacaoInternacaoUUID,
              },
            },
            "relatorio"
          );

          const nmMedico = auth?.userInfos.nome;
          const usuarioSolicitante = auth?.userInfos?.user;

          response = await updateSolicitation({
            data: formData,
            solicitacaoInternacaoUUID,
            isSecretary,
            usuarioSolicitante,
            nmMedico,
          });

          if (response.statusCode === 201) {
            if (requiredLaudo) {
              if (otherStateModified) {
                dispatch(setRegisterSuccess(true));
              }
            } else {
              dispatch(setRegisterSuccess(true));
            }
            navigate("/");
            setCidModified(false);
            dispatch(setRequiredLaudo(false));
            return;
          }
        } else {
          setSaving(true);
          response = await apiFinalizeSolicitation(
            currentSolicitation?.solicitacao.idSolicitacao
          ).put({
            anteciparAgendamento: anticipateSurgery,
            isSecretary: isSecretary,
          });
          if (response.statusCode === 201) {
            setConfirmRegistration(true);
            setSending(false);
            return;
          }
        }
      }

      if (response?.request?.status === 404) {
        setIdPatientNotReturnedDrawer(true);
        setConfirmRegistration(false);
        setModalOpen(false);
        setSending(false);
        return;
      }

      setModalOpen(false);
      setSendingError(true);
      setSending(false);
    } catch (error) {
      setModalOpen(false);
      setSendingError(true);
      setOpenToast(true);
      setSending(false);
      console.log("Finalização", error);
    } finally {
      setSaving(false);
      setAdvancing(false);
    }
  };

  const verifySpecificScenariosError = () => {
    const { paciente } = formData;
    if (
      validationFormatDate(paciente?.dataNascimento) ||
      paciente?.dataNascimento === ""
    ) {
      return true;
    }
    if (
      paciente?.telefone?.ddi === "" ||
      paciente?.telefone?.phone === "" ||
      paciente?.telefone?.completeNumber?.length <= 5
    ) {
      if (!isAditionalTeam) {
        dispatch(setInvalidPhone(true));
        return true;
      }
    } else {
      dispatch(setInvalidPhone(false));
    }
    if (
      validationFormatDate(paciente?.tempoDoenca) ||
      paciente?.tempoDoenca === ""
    ) {
      return true;
    }
    if (isNewPatient) {
      if (
        paciente.tipoDocumento === "CPF" ||
        (paciente.tipoDocumento === "Nome" &&
          paciente.novoPacienteDocumento === "CPF")
      ) {
        if (!Validation.cpf(paciente?.cpf)) {
          return true;
        }
      }
    }
    return false;
  };

  const verificationDisabledAnamnese = () => {
    if (
      formData?.solicitacao?.idSolicitacao === null ||
      formData?.procedimento?.data === "" ||
      getDaysToProcedure(formData?.procedimento?.data) >= 60
    )
      return true;
  };

  const validateProcedures = () => {
    const procedureRequiredFields = removeOptionalFields(
      { ...formData.procedimento },
      editingSolicitation && isAditionalTeam
        ? requiredProcedures.filter((a) => a !== "tempoCirurgia")
        : requiredProcedures
    );
    if (formData.procedimento.regimeHospitalar === "Internado")
      procedureRequiredFields["diarias"] =
        Number(formData.procedimento.diarias) < 1
          ? ""
          : Number(formData.procedimento.diarias);

    if (
      formData.procedimento.anestesista.tipo === "Anestesista Particular" ||
      formData.procedimento.anestesista.tipo === ""
    ) {
      procedureRequiredFields["nomeAnestesista"] =
        formData.procedimento.anestesista.nome;
      procedureRequiredFields["crmAnestesista"] =
        formData.procedimento.anestesista.crm;
    }

    if (formData.procedimento.procedimentoPrincipal?.exige_lado === "S")
      procedureRequiredFields["lateralidade"] =
        formData.procedimento.lateralidade;

    if (
      procedureRequiredFields.procedimentoPrincipal?.invasivo &&
      procedureRequiredFields.procedimentoPrincipal?.invasivo !== ""
    ) {
      procedureRequiredFields.procedimentoPrincipal = {
        ...formData.procedimento.procedimentoPrincipal,
        invasivo: formData.procedimento.procedimentoPrincipal.invasivo,
      };
    }

    let equipeError = false;
    let procedimentosExtraError = false;

    if (formData.procedimento.procedimentosExtras.length > 0) {
      formData.procedimento.procedimentosExtras.map((procedExtra) => {
        if (!procedExtra.cdProcedimento) {
          procedimentosExtraError = true;
        }
      });

      const filteredOthersProc = optionalLaterality(
        formData.procedimento.procedimentosExtras
      );
      const verifyLado = verifyIeLado(filteredOthersProc);

      if (verifyAllFields(verifyLado)) equipeError = true;
    }

    if (formData.procedimento.equipeCirurgicaAdicional === "Sim") {
      let doctors = formData.procedimento.equipe.map((doctor) => {
        return removeOptionalFields(doctor, ["crmCirurgiao", "nomeCirurgiao"]);
      });

      if (verifyAllFields(doctors)) procedimentosExtraError = true;
    }

    if (editingModel) {
      dispatch(setCurrentStep(currentStep + 1));
      return false;
    }

    if (
      equipeError ||
      procedimentosExtraError ||
      verifyAllFields(procedureRequiredFields)
    ) {
      dispatch(isError(true));
      return false;
    }

    return true;
  };

  const validateBasicInfo = () => {
    const telefone =
      formData.paciente.telefone.phone === ""
        ? formData.paciente.telefone.ddi
        : formData.paciente.telefone.phone;

    const patientRequiredFields = removeOptionalFields(
      {
        ...formData.paciente,
        local: formData.procedimento.local,
        telefone: telefone,
      },
      requiredPatient
    );

    if (isNewPatient) {
      if (formData.paciente.tipoDocumento === "Nome") {
        if (formData.paciente.novoPacienteDocumento === "CPF") {
          patientRequiredFields["cpf"] = formData.paciente.cpf;
        }

        if (formData.paciente.novoPacienteDocumento === "RNE/CRNM") {
          patientRequiredFields["rne"] = formData.paciente.rne;
        }

        if (formData.paciente.novoPacienteDocumento === "Passaporte") {
          patientRequiredFields["passaporte"] = formData.paciente.passaporte;
        }
      }

      patientRequiredFields["nacionalidade"] = formData.paciente.nacionalidade;
    }
    if (formData.procedimento.local !== "")
      patientRequiredFields["cobranca"] = formData.procedimento.cobranca;
    if (
      formData.paciente.convenio !== "Particular" &&
      formData.paciente.convenio !== "Institucional"
    ) {
      patientRequiredFields["carteirinha"] = formData.paciente.carteirinha;
    }

    if (formData.paciente.tipoDocumento === "RNE/CRNM") {
      patientRequiredFields["rne"] = formData.paciente.rne;
    }
    if (formData.paciente.tipoDocumento === "Passaporte") {
      patientRequiredFields["passaporte"] = formData.paciente.passaporte;
    }
    if (
      formData.paciente.idade !== null &&
      formData.paciente.idade !== "" &&
      formData.paciente.idade < 18
    ) {
      patientRequiredFields["nomeResponsavel"] =
        formData.paciente.nomeResponsavel;
      patientRequiredFields["documentoResponsavel"] =
        formData.paciente.documentoResponsavel;
    }
    if (editingModel) {
      dispatch(setCurrentStep(currentStep + 1));
      return false;
    }

    if (
      verifySpecificScenariosError() ||
      verifyAllFields(patientRequiredFields)
    ) {
      dispatch(isError(true));
      return false;
    }

    return true;
  };

  const validateMaterials = (index) => {
    if (editingModel) {
      dispatch(setCurrentStep(currentStep + 1));
      return;
    }
    const materialLists = formData.material;
    handleOpenModal();

    if (
      checkQuantityFields(materialLists) &&
      !verifyAllFields(materialLists) &&
      !verifyLists()
    ) {
      if (index) {
        stepNavigation.current = index;
      }
      return true;
    }

    return false;
  };

  const verifyRequiredReport = async () => {
    const cid = await apiRequiredCid(
      formData?.paciente?.cdCidPrincipalFormatado ||
        formData?.paciente?.cdCidPrincipal
    ).getList();

    if (cid?.laudo && formData?.paciente?.convenio !== "Particular") {
      dispatch(setRequiredLaudo(cid?.laudo));
    } else {
      dispatch(setRequiredLaudo(false));
    }
  };

  useEffect(() => {
    if (requiredLaudo && confirmGoToHomeOpen.value && editingSolicitation) {
      sendSolicitation();
    }
  }, [cidModified]);

  useEffect(() => {
    newValueCid = formData?.paciente?.cdCidPrincipalFormatado;
  }, [formData?.paciente?.cidPrincipal]);

  const includReport = () => {
    dispatch(setCurrentStep(4));
    dispatch(isError(true));
    dispatch(
      setOpenGoToHomeModal({
        value: false,
        toScreen: "",
      })
    );
  };

  const validateMedicalCard = () => {
    if (
      !formData?.relatorio?.laudo &&
      formData.paciente.convenio !== "Particular" &&
      requiredLaudo
    ) {
      const savingDocument = async () => {
        if (formData?.procedimento.local !== "") {
          try {
            setAdvancing(true);
            setSaving(true);
            const solicitacaoInternacaoUUID = await getSolicitationPdf(
              formData
            );

            populateFormData(
              {
                target: {
                  name: "solicitacaoInternacaoUUID",
                  value: solicitacaoInternacaoUUID,
                },
              },
              "relatorio"
            );

            const response = await createReports(
              formData,
              solicitacaoInternacaoUUID
            );

            if (response?.name === "AxiosError")
              throw new Error(`Erro ao criar relatórios: ${response?.name}`);

            if (response.idRelatorio) {
              populateFormData(
                {
                  target: {
                    name: "idRelatorio",
                    value: response.idRelatorio,
                  },
                },
                "relatorio"
              );

              dispatch(
                setReportsObject({
                  idRelatorio: response.idRelatorio,
                  ...formData.relatorio,
                })
              );
              dispatch(
                setSolicitationObject({
                  relatorio: {
                    idRelatorio: response.idRelatorio,
                    ...formData.relatorio,
                  },
                })
              );
              setSaving(false);
              setAdvancing(false);
            } else {
              setAdvancing(false);
            }
          } catch (error) {
            console.log("Relatórios e Laudos erro", error);
          } finally {
            setAdvancing(false);
            setSaving(false);
          }
        }
      };

      savingDocument();
      dispatch(isError(true));

      return false;
    }
    return true;
  };

  const clearServicesDisableds  = async () => {
    fields.services.forEach((field) => {
    if (field.disabled.includes(formData.procedimento.cdEstabelecimento.toString())) {
      populateFormData({
        target: {
          name: field.name,
          value: field.initialValue,
        },
      }, "procedimento");
    }
  })
  }

  const handleClickAdvance = async (e) => {
    if (currentStep <= steps.length) {
      switch (currentStep) {
        case 1:
          if (validateBasicInfo()) {
            try {
              setAdvancing(true);
              setSaving(true);
              setSendingError(false);

              verifyRequiredReport();

              if (!editingSolicitation) {
                const nomeMedico = auth?.userInfos.nome;
                const idMedico = auth?.userInfos?.cdPessoaFisica;

                await createSolicitation({
                  data: formData,
                  nomeMedico,
                  modelo: model?.idMedico
                    ? {
                        idMedico,
                        idModelo: String(model?.idModelo),
                      }
                    : null,
                }).then((res) => {
                  if (res?.idSolicitacao) {
                    populateFormData(
                      {
                        target: {
                          name: "idSolicitacao",
                          value: res.idSolicitacao,
                        },
                      },
                      "solicitacao"
                    );

                    populateFormData(
                      {
                        target: {
                          name: "idAnamnese",
                          value: res.idAnamnese,
                        },
                      },
                      "anamnese"
                    );

                    populateFormData(
                      {
                        target: {
                          name: "idProcedimento",
                          value: res.idProcedimento,
                        },
                      },
                      "procedimento"
                    );

                    if (formData.paciente.idPaciente === null) {
                      populateFormData(
                        {
                          target: {
                            name: "idPaciente",
                            value: res.idPaciente,
                          },
                        },
                        "paciente"
                      );
                    }

                    dispatch(
                      setSolicitationObject({
                        solicitacao: {
                          ...formData.solicitacao,
                          idSolicitacao: res.idSolicitacao,
                        },
                        anamnese: {
                          ...formData.anamnese,
                          idAnamnese: res.idAnamnese,
                        },
                      })
                    );
                    dispatch(setPatientObject(formData.paciente));
                    dispatch(
                      setSolicitationObject({ paciente: formData.paciente })
                    );
                  } else {
                    setSendingError(true);
                  }
                });
              }

              dispatch(setCurrentStep(currentStep + 1));
              setAdvancing(false);
            } catch (error) {
              setSendingError(true);
              console.log("Solicitação", error);
            } finally {
              setAdvancing(false);
              setSaving(false);
            }
          }
          break;
        case 2:
          if (validateProcedures()) {
           await clearServicesDisableds()
            try {
              setAdvancing(true);
              setSaving(true);
              if (!editingSolicitation) {
                setSendingError(false);
                await createProcedure(formData, isSecretary).then((res) => {
                  if (res.idProcedimento) {
                    populateFormData(
                      {
                        target: {
                          name: "idProcedimento",
                          value: res.idProcedimento,
                        },
                      },
                      "procedimento"
                    );

                    dispatch(setProcedureObject(formData.procedimento));
                    dispatch(
                      setSolicitationObject({
                        procedimento: formData.procedimento,
                      })
                    );
                    dispatch(setCurrentStep(currentStep + 1));
                    setAdvancing(false);
                  } else {
                    setSendingError(true);
                    setAdvancing(false);
                  }
                });
              } else {
                dispatch(setCurrentStep(currentStep + 1));
                setAdvancing(false);
              }
            } catch (error) {
              setSendingError(true);
              console.log("Solicitação", error);
            } finally {
              setAdvancing(false);
              setSaving(false);
            }
          }
          break;
        case 3:
          if (validateMaterials()) {
            if (!editingSolicitation) {
              sendMaterials(e);
            } else {
              dispatch(setCurrentStep(currentStep + 1));
              setAdvancing(false);
            }
          }
          break;
        case 4:
          if (validateMedicalCard()) {
            setSaving(false);
            setModalOpen(true);

            if (editingModel) {
              setNameModel(true);
            } else if (editingSolicitation) {
              sendSolicitation();
            } else {
              if (formData?.procedimento.local !== "") {
                try {
                  setAdvancing(true);
                  setSaving(true);
                  const solicitacaoInternacaoUUID = await getSolicitationPdf(
                    formData
                  );

                  populateFormData(
                    {
                      target: {
                        name: "solicitacaoInternacaoUUID",
                        value: solicitacaoInternacaoUUID,
                      },
                    },
                    "relatorio"
                  );

                  const response = await createReports(
                    formData,
                    solicitacaoInternacaoUUID
                  );
                  if (response?.name === "AxiosError")
                    throw new Error(
                      `Erro ao criar relatórios: ${response?.name}`
                    );
                  if (response.idRelatorio) {
                    populateFormData(
                      {
                        target: {
                          name: "idRelatorio",
                          value: response.idRelatorio,
                        },
                      },
                      "relatorio"
                    );

                    dispatch(
                      setReportsObject({
                        idRelatorio: response.idRelatorio,
                        ...formData.relatorio,
                      })
                    );
                    dispatch(
                      setSolicitationObject({
                        relatorio: {
                          idRelatorio: response.idRelatorio,
                          ...formData.relatorio,
                        },
                      })
                    );
                    setSaving(false);
                    setAdvancing(false);
                  } else {
                    setSendingError(true);
                    setAdvancing(false);
                  }
                } catch (error) {
                  setSendingError(true);
                  console.log("Relatórios e Laudos erro", error);
                } finally {
                  setAdvancing(false);

                  setSaving(false);
                }
              }
            }
          }
          break;
        default:
          return null;
      }
    }
  };

  const contactsHTML = (telefone, email) => {
    return (
      <>
        <b>{telefone}</b> ou pelo email <b>{email}</b>.
      </>
    );
  };

  const renderContacts = () => {
    const local = getSchedulingLocal(formData.procedimento.local);

    switch (local) {
      case "Brasília":
        return contactsHTML(
          "(61)99997-1452",
          "agendamento.cirurgiasbsb@hsl.org.br"
        );

      case "São Paulo":
        return contactsHTML(
          "(11)3394-0381",
          "agendamento.cirurgias@hsl.org.br"
        );
    }
  };

  const returnDocument = (type) => {
    if (type === "document") {
      if (formData.paciente.tipoDocumento !== "Nome")
        return <S.Info>{formData.paciente.tipoDocumento}:</S.Info>;
      else return null;
    }

    if (type === "valueDoc") {
      if (formData.paciente.tipoDocumento === "CPF")
        return formData.paciente.cpf;
      else if (formData.paciente.tipoDocumento === "Passaporte")
        return formData.paciente.passaporte;
      else if (formData.paciente.tipoDocumento === "RNE/CRNM")
        return formData.paciente.rne;
    }
  };

  const getModal = () => {
    if (showFilledModalByMainTeam && isAditionalTeam) {
      return (
        <Drawer
          title="Campos já preenchidos"
          label={
            <div style={{ marginTop: "8px", width: "65vw" }}>
              <p style={{ maxWidth: "702px", whiteSpace: "pre" }}>
                O(a) médico(a){" "}
                <strong>
                  Dr(a) {currentSolicitation.solicitacao.medicoTitular}
                </strong>{" "}
                já preencheu previamente esses dados. Caso deseje <br />
                mudá-los, verifique com ele(a) ou entre em contato com{" "}
                <strong>(11) 3394-0381</strong> /<br />
                <strong>agendamento.cirurgias@hsl.org.br</strong> ou{" "}
                <strong>(61) 99997-1452</strong> /<br />
                <strong>agendamento.cirurgiasbsb@hsl.org.br</strong>
              </p>
            </div>
          }
          isOpened={showFilledModalByMainTeam}
          buttonLabelPrimary="Ok, entendi"
          handleButtonClickPrimary={() => {
            setModalOpen(false);
            setShowFilledModalByMainTeam(false);
          }}
          isDoubleAction={false}
        />
      );
    }

    if (currentStep === 1 && patientSolicitations && !editingSolicitation) {
      return (
        <ChoiceSolicitationModal
          doctorName={auth.userInfos.nome}
          solicitations={patientSolicitations}
          clearSolicitations={(newSolicitation) => {
            updateSolicitationAfterChoosing(newSolicitation);
            setPatientSolicitations([]);
          }}
          onClickReturnToHome={() => {
            setConfirmRegistration(false);
            navigate("/");
          }}
        />
      );
    }

    if (currentStep === 3 && !openAnamnese && !editingModel) {
      return (
        <Drawer
          title={<p>Atenção!</p>}
          label={
            <p>
              Você está prestes a seguir o agendamento sem incluir nenhum
              material.
            </p>
          }
          buttonSize={"sm"}
          isOpened={modalOpen}
          isDoubleAction={true}
          buttonLabelPrimary={"Seguir mesmo assim"}
          buttonLabelSecondary={"Voltar"}
          handleButtonClickPrimary={(e) => {
            if (!editingSolicitation) {
              sendMaterials(e);
              dispatch(setCurrentStep(currentStep + 1));
            } else {
              dispatch(
                setCurrentStep(stepNavigation.current ?? currentStep + 1)
              );
              stepNavigation.current = null;
            }
          }}
          handleButtonClickSecondary={() => setModalOpen(false)}
          handleClose={() => setModalOpen(false)}
        />
      );
    }
    if (currentStep === 4 && !isEditingModel) {
      if (advancing && !editingSolicitation) {
        return (
          <NewModal isOpened={true} labelPrimary={""} removeBtn>
            <StyledLoadingTermo height={"450px"} top={"0vh"}>
              <TmpLoading label="Carregando..." />
            </StyledLoadingTermo>
          </NewModal>
        );
      } else {
        return (
          !editingSolicitation && (
            <NewModal
              minHeight="min-content"
              isOpened={modalOpen}
              // maxHeight="650px"
              labelPrimary={
                !confirmRegistration
                  ? "Confirmar envio da solicitação"
                  : "Ir para o início"
              }
              removeBtn
              width="624px"
            >
              {confirmRegistration ? (
                <>
                  <S.BoxModal>
                    <S.ImgSucess src={Sucesso} />
                    <S.Title>
                      {isSecretary
                        ? "Preenchimento realizado"
                        : "Solicitação enviada com sucesso!"}
                    </S.Title>
                    <S.Contacts>
                      {isSecretary ? (
                        <S.Alert>
                          <TmpIcon
                            size="sm"
                            icon={<Alert style={{ color: "#292929" }} />}
                          />
                          <p>Pendente de envio por parte do médico.</p>
                        </S.Alert>
                      ) : null}
                      {isSecretary
                        ? "Em caso de dúvidas, entre em contato com a central pelo telefone"
                        : "Agora você pode acompanhar o status do seu pedido e, em caso de dúvidas, entre em contato com a central pelo telefone"}{" "}
                      {renderContacts()}
                    </S.Contacts>
                  </S.BoxModal>
                  <S.BtnSendWrap alingItems={"center"} margin={"0 24px 0 0"}>
                    <TmpButton
                      type="primary"
                      size="sm"
                      handleClick={() => {
                        setConfirmRegistration(false);
                        setModalOpen(false);
                        setModelInfoDrawer(true);
                        if (model?.idModelo || noRenderCreateModel)
                          navigate("/");
                      }}
                    >
                      Ok, entendi
                    </TmpButton>
                  </S.BtnSendWrap>
                </>
              ) : (
                <>
                  <S.BoxModal>
                    <S.Title>
                      {isSecretary
                        ? "Você está prestes a finalizar o preenchimento!"
                        : "Você está prestes a enviar sua solicitação!"}
                    </S.Title>
                    <S.RequestSummary>
                      <S.Summary>
                        <S.SubTitle>RESUMO DA SOLICITAÇÃO</S.SubTitle>
                        <S.Name>
                          <b>Nome:</b>{" "}
                          {getAbbreviatedFullName(formData.paciente.nome, 40)}
                        </S.Name>
                        {formData.paciente.tipoDocumento !== "Nome" ? (
                          <S.BoxInformations>
                            {returnDocument("document")}
                            {returnDocument("valueDoc")}
                          </S.BoxInformations>
                        ) : null}
                        <S.BoxInformations>
                          <S.Info>Data da Cirurgia:</S.Info>
                          <p>
                            {new Date(
                              formData.procedimento.data
                            ).toLocaleDateString()}{" "}
                          </p>
                          | <S.Info>Horário:</S.Info>
                          <p>{`${formData.procedimento.hora} ${
                            parseInt(
                              formData.procedimento.hora?.substring(0, 2)
                            ) > 12
                              ? "pm"
                              : "am"
                          }`}</p>
                        </S.BoxInformations>
                        <S.BigText>
                          <b>Procedimento Principal:</b>{" "}
                          {formData.procedimento.procedimentoPrincipal
                            ?.procedimento ||
                            formData.procedimento.procedimentoPrincipal}
                        </S.BigText>
                      </S.Summary>
                      <S.BoxSignature secretary={isSecretary}>
                        <S.Signature src={auth?.userInfos?.urlAssinatura} />
                        <LocalAndDate
                          local={formData.procedimento.local}
                          display
                          margin={"24px 0 0 0"}
                        />
                      </S.BoxSignature>
                    </S.RequestSummary>
                    <S.WrapperCheck>
                      <TmpCheckbox
                        label="Concordo em antecipar minha cirurgia, caso necessário"
                        id="anticipateSurgery"
                        handleChange={() =>
                          setAnticipateSurgery((prevState) => !prevState)
                        }
                        checked={anticipateSurgery}
                        value={anticipateSurgery}
                      />
                    </S.WrapperCheck>
                  </S.BoxModal>
                  <S.BtnSendWrap
                    loading={sending}
                    pointer={sending}
                    justContent={isSecretary}
                  >
                    <LocalAndDate
                      local={formData.procedimento.local}
                      margin={"0 0 0 24px"}
                      display={isSecretary}
                    />
                    <TmpButtonGroup
                      size="sm"
                      labelPrimary="Confirmar envio"
                      labelSecondary="Voltar"
                      loadingButtonPrimary={sending}
                      handleClickPrimary={sendSolicitation}
                      handleClickSecondary={() => setModalOpen(false)}
                    />
                  </S.BtnSendWrap>
                </>
              )}
            </NewModal>
          )
        );
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const renderFinalButton = () => {
    if (isEditingModel) {
      return "Salvar Modelo";
    } else if (editingSolicitation) {
      return "Salvar";
    } else {
      return "Enviar Solicitação";
    }
  };

  const createModel = async (modelName) => {
    try {
      setSending(true);
      const idMedico = auth?.userInfos?.cdPessoaFisica;
      const procedimento = {
        ...model.procedimento,
        ...formData.procedimento,
        procedimentoPrincipal:
          formData?.procedimento?.procedimentoPrincipal?.nomeProcedimento ||
          formData?.procedimento?.procedimentoPrincipal?.procedimento ||
          formData?.procedimento?.procedimentoPrincipal,
        idSequencia:
          formData?.procedimento?.procedimentoPrincipal?.idSequencia ||
          formData?.procedimento?.idSequencia,
        equipeCirurgicaAdicional: "Não",
        equipe: [],
        equipeResponsavel: "",
      };
      const modelObj = {
        idMedico: idMedico,
        idModelo: isEditingModel ? model.idModelo : null,
        nomeModelo: modelName,
        procedimento: procedimento,
        paciente: {
          cdCidPrincipal: formData.paciente.cdCidPrincipal,
          indicacaoClinica: formData.paciente.indicacaoClinica,
          cidPrincipal: formData.paciente.cidPrincipal,
        },
        material: formData.material,
        relatorio: formData.relatorio,
      };

      await apiModels(idMedico).put(modelObj);
      setNameModel(false);
      setEditingModel(false);
      setSending(false);
      if (editingModel) {
        navigate("/modelos");
        dispatch(setModelSave(true));
      } else if (!editingModel) {
        navigate("/");
      }
      setNameModel(false);
    } catch (error) {
      setSending(false);
      console.log(error);
    }
  };

  document.addEventListener("keydown", function (e) {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      e.preventDefault();
    }
  });

  return (
    <S.Container onSubmit={(e) => handleSubmit(e)}>
      {basicInfoError ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            minHeight: "49vh",
          }}
        >
          <RequestFailed.Root>
            <RequestFailed.Image />
            <RequestFailed.Content
              title="Falha ao carregar"
              content={`Pedimos desculpas pelo ocorrido.
              Estamos trabalhando para que ele retorne o mais breve possível.`}
            />
            <RequestFailed.Action
              handleClick={() => navigate(-1)}
              titleButton="Voltar"
            />
          </RequestFailed.Root>
        </div>
      ) : (
        <>
          {successAnam === true ? (
            <S.StyledAlert isOpened={successAnam}>
              <TmpAlert
                id="form-alert"
                isOpened={successAnam}
                type="success"
                title="Sucesso!"
                message="A anamnese foi anexada à solicitação."
                handleClose={() => {
                  dispatch(isSuccessAnam(false));
                }}
              />
            </S.StyledAlert>
          ) : null}
          {successConsent === true ? (
            <S.StyledAlert isOpened={successConsent}>
              <TmpAlert
                id="form-alert"
                isOpened={successConsent}
                type="success"
                title="Sucesso!"
                message="O Termo de Consentimento foi anexadado à solicitação."
                handleClose={() => {
                  dispatch(isSuccessConsent(false));
                }}
              />
            </S.StyledAlert>
          ) : null}
          {nameModelModal ? (
            <ModalNameModel
              title={
                model?.idModelo
                  ? "Deseja renomear o modelo?"
                  : "Escolha um nome para o novo modelo:"
              }
              subtitle={
                "É possível trocar de nome e editar o modelo posteriormente."
              }
              labelPrimary={
                model?.idModelo
                  ? "Salvar e retornar à Tela de Modelos"
                  : "Salvar e retornar à Tela Inicial"
              }
              isEditingModel={!!model?.idModelo}
              initialName={model?.nomeModelo || ""}
              onCancel={() => setNameModel(false)}
              onSave={(newModelName) => createModel(newModelName)}
              loading={sending}
              minPrimaryWidth="269px"
              minSecondaryWidth="117px"
            />
          ) : null}
          {!editingModel ? (
            <S.Row className="mobile-style">
              <S.Buttons disabledIcon={verificationDisabledAnamnese()}>
                <TmpIcon
                  style={{
                    color: verificationDisabledAnamnese()
                      ? "rgba(163, 163, 163, 1)"
                      : "#292929",
                  }}
                  icon={
                    verificationDisabledAnamnese() ? (
                      <PrescricaoSVG className="tmp-icon-prescricao" />
                    ) : (
                      <PrescricaoSVG />
                    )
                  }
                />
                {formData?.solicitacao?.statusAnamnese === "Concluida" ? (
                  <S.Status top="4px" bg="#78CE7E"></S.Status>
                ) : (
                  <S.Status top="4px" bg="#FF8A00"></S.Status>
                )}
                <S.Documents disabled={verificationDisabledAnamnese()}>
                  <p>Anamnese Exame Físico</p>
                  <a
                    onClick={() => {
                      if (
                        formData?.solicitacao?.idSolicitacao !== null &&
                        formData?.procedimento.data !== "" &&
                        getDaysToProcedure(formData?.procedimento?.data) < 60
                      )
                        dispatch(setOpenAnamnese(true));
                    }}
                  >
                    {formData?.solicitacao.statusAnamnese}
                  </a>
                </S.Documents>
              </S.Buttons>

              <S.Buttons>
                <TmpIcon
                  style={{
                    color:
                      currentStep < 3 ? "rgba(163, 163, 163, 1)" : "#292929",
                  }}
                  icon={<TermosSVG />}
                />
                {formData?.procedimento?.idProcedimento === null ||
                formData?.relatorio.termoConsentimento === "" ? (
                  <S.Status bg="#FF8A00"></S.Status>
                ) : (
                  <S.Status bg="#78CE7E"></S.Status>
                )}
                <S.WrapperTermo disabled={currentStep < 3}>
                  <p
                    disabled={currentStep < 3}
                    onClick={() => {
                      if (currentStep > 2) setConsentFormOpen(true);
                    }}
                  >
                    Termo de Consentimento
                  </p>
                </S.WrapperTermo>
              </S.Buttons>
            </S.Row>
          ) : null}
          <S.StyledAlert isOpened={openToast && !openAnamnese}>
            <TmpAlert
              id="form-alert"
              isOpened={openToast && !openAnamnese}
              type="error"
              title={
                error && downloadError
                  ? "Falha ao baixar"
                  : error
                  ? "Erro de Preenchimento"
                  : "Falha ao avançar"
              }
              message={
                error && downloadError
                  ? "Tente novamente"
                  : error
                  ? "Existem campos obrigatórios que não foram preenchidos"
                  : "Tente novamente"
              }
              handleClose={() => {
                setOpenToast(false);
                dispatch(isError(false));
                downloadError && dispatch(setDownloadError(false));
                dispatch(setInvalidPhone(false));
              }}
            />
          </S.StyledAlert>
          <div style={{ margin: "64px 0" }}>
            <TmpStepCC
              current={currentStep}
              steps={stepsLabel}
              onSelectStep={handleStepSelect}
            />
          </div>
          {steps[currentStep - 1]}

          <S.BtnWrap pointer={advancing}>
            <StyledSaving>
              {saving ? (
                <Saving>
                  <TmpIcon
                    size="lg"
                    icon={<Pontinhos />}
                    style={{ marginRight: "12px" }}
                  />
                  Salvando automaticamente...
                </Saving>
              ) : null}
            </StyledSaving>
            <TmpButtonGroup
              size="lg"
              icon={false}
              labelPrimary={
                currentStep <= steps.length - 1
                  ? "Avançar"
                  : renderFinalButton()
              }
              labelSecondary={currentStep === 1 ? "Cancelar" : "Voltar"}
              disabledPrimary={timeNeedError ? true : false}
              handleClickPrimary={(e) => {
                if (currentStep === 2 && extraTeamsDrawer.renderOnAdvanceStep) {
                  setExtraTeamsDrawer((prevState) => ({
                    ...prevState,
                    render: true,
                  }));
                } else {
                  handleClickAdvance(e);
                }
              }}
              loadingButtonPrimary={advancing}
              handleClickSecondary={() => {
                if (currentStep !== 1) {
                  dispatch(setCurrentStep(currentStep - 1));
                } else {
                  navigate(from);
                }
              }}
            />
          </S.BtnWrap>
          {modalOpen || patientSolicitations.length ? getModal() : null}
          {confirmGoToHomeOpen.value && !editingSolicitation ? (
            <Drawer
              title={
                <p>
                  Tem certeza que deseja retornar à Tela{" "}
                  {`${
                    confirmGoToHomeOpen.toScreen === "Modelos"
                      ? "de Modelos"
                      : confirmGoToHomeOpen.toScreen ?? "Inicial"
                  }`}
                  ?
                </p>
              }
              label={
                currentStep > 1 ? (
                  <p>
                    Se você retornar, todas as informações incluídas até agora
                    poderão ser perdidas.
                    <br />
                    A solicitação ficará salva temporariamente com o status de
                    ”pendente” e será possível
                    <br />
                    finalizar o preenchimento posteiormente.
                  </p>
                ) : (
                  <p>
                    Se você retornar, todas as informações incluídas até agora
                    serão perdidas.
                  </p>
                )
              }
              buttonSize={"sm"}
              isOpened={confirmGoToHomeOpen}
              isDoubleAction={true}
              buttonLabelPrimary={"Sim, retornar"}
              buttonLabelSecondary={"Cancelar"}
              handleButtonClickPrimary={() => {
                if (confirmGoToHomeOpen.toScreen) {
                  if (editingSolicitation) {
                    navigate("/", { replace: true });
                  } else {
                    navigate(-1);
                  }
                  dispatch(
                    setOpenGoToHomeModal({ value: false, toScreen: "" })
                  );
                } else {
                  navigate("/", { replace: true });
                  dispatch(
                    setOpenGoToHomeModal({ value: false, toScreen: "" })
                  );
                }
                dispatch(setCurrentStep(1));
                dispatch(setClearSolicitation());
              }}
              handleButtonClickSecondary={() =>
                dispatch(setOpenGoToHomeModal({ value: false, toScreen: "" }))
              }
              handleClose={() => setModalOpen(false)}
            />
          ) : null}
          {openAnamnese ? (
            <Anamnese
              solicitation={formData?.solicitacao}
              dataChange={(e) => handleDataChange(e, "solicitacao")}
            />
          ) : null}
          {consentFormOpen ? (
            <ConsentForm
              handleClose={() => {
                setConsentFormOpen(false);
              }}
              dataChange={(e) => handleDataChange(e, "relatorio")}
              reports={formData.relatorio}
              solicitationData={formData}
              errorLoad={false}
              useReports={true}
            />
          ) : null}

          {confirmGoToHomeOpen.value && editingSolicitation ? (
            requiredLaudo && formData?.relatorio.laudo === "" ? (
              <NewModal
                minHeight="min-content"
                isOpened={true}
                removeBtn
                width="624px"
              >
                <S.BoxModal>
                  <TmpButtonIcon
                    icon={<IconCancelar />}
                    size="lg"
                    handleClick={() => {
                      dispatch(
                        setOpenGoToHomeModal({
                          value: false,
                          toScreen: "",
                        })
                      );
                    }}
                  />
                  <S.ImgSucess
                    width={"240px"}
                    height={"235.7px"}
                    src={Atention}
                  />
                  <S.Title fontSize="24px">
                    O CID {newValueCid} exige laudo do paciente
                  </S.Title>
                  <S.Contacts fontSize="16px">
                    Clique abaixo para incluir o laudo do paciente
                  </S.Contacts>
                </S.BoxModal>
                <S.BtnSendWrap
                  height={"48px"}
                  padding={"8px 24px"}
                  alingItems={"center"}
                  margin={"0 24px 0 0"}
                >
                  <TmpButton
                    type="secondary"
                    size="sm"
                    loading={advancing && !sendingError}
                    handleClick={() => {
                      handleDataChange(
                        {
                          target: {
                            name: "cidPrincipal",
                            value: currentSolicitation?.paciente.cidPrincipal,
                          },
                        },
                        "paciente"
                      );

                      handleDataChange(
                        {
                          target: {
                            name: "cdCidPrincipal",
                            value: currentSolicitation?.paciente.cdCidPrincipal,
                          },
                        },
                        "paciente"
                      );
                      setCidModified(true);
                    }}
                  >
                    Sair sem alterar o CID
                  </TmpButton>
                  <TmpButton
                    type="primary"
                    size="sm"
                    handleClick={() => includReport()}
                  >
                    Incluir o laudo do paciente
                  </TmpButton>
                </S.BtnSendWrap>
              </NewModal>
            ) : solicitationEdited ? (
              <NewModal
                minHeight="min-content"
                isOpened={true}
                removeBtn
                width="624px"
              >
                <S.BoxModal>
                  <S.ImgSucess
                    width={"240px"}
                    height={"235.7px"}
                    src={Atention}
                  />
                  {isSecretary ? (
                    <>
                      <S.Title>
                        Deseja salvar pedido de alterações antes de sair?
                      </S.Title>
                      <S.Contacts>
                        Seu pedido de alterações ainda não foi registrado.
                      </S.Contacts>
                    </>
                  ) : (
                    <>
                      <S.Title>Deseja salvar alterações antes de sair?</S.Title>
                      <S.Contacts>
                        Suas alterações ainda não foram salvas.
                      </S.Contacts>
                    </>
                  )}
                </S.BoxModal>
                <S.BtnSendWrap alingItems={"center"} margin={"0 24px 0 0"}>
                  <TmpButton
                    type="secondary"
                    size="sm"
                    handleClick={() => {
                      navigate("/");
                    }}
                  >
                    Não, descartar
                  </TmpButton>
                  <TmpButton
                    type="primary"
                    size="sm"
                    loading={advancing && !sendingError}
                    handleClick={() =>
                      editingSolicitation
                        ? editSolicitation()
                        : sendSolicitation()
                    }
                  >
                    Sim, salvar
                  </TmpButton>
                </S.BtnSendWrap>
              </NewModal>
            ) : null
          ) : null}

          <TmpDrawerContent
            title={
              <p>
                Parece que este plano não cobre
                <br />
                internações nesta unidade!
              </p>
            }
            label={
              <p>
                O plano selecionado não está cadastrado como elegível para
                internação nesta unidade.
                <br />
                Caso queira seguir com este serviço, é possível utilizá-lo de
                forma particular.
                <br />
                Em caso de dúvidas, entre em contato com
                <p style={{ overflowWrap: "anywhere" }}>
                  <b>(11) 3394-0381 / agendamento.cirurgias@hsl.org.br</b>
                </p>
                <p style={{ overflowWrap: "anywhere" }}>
                  ou{" "}
                  <b>(61) 99997-1452 / agendamento.cirurgiasbsb@hsl.org.br</b>
                </p>
              </p>
            }
            buttonSize={"lg"}
            isOpened={planInfoDrawer}
            isDoubleAction={false}
            buttonLabelPrimary={"Ok, entendi"}
            handleButtonClickPrimary={() => setPlanInfoDrawer(false)}
            handleClose={() => setPlanInfoDrawer(false)}
          />

          <Drawer
            title={
              <p>
                Você gostaria de salvar essa solicitação
                <br />
                como novo modelo?
              </p>
            }
            label={
              <p>
                Ao salvar como modelo, as preferências sobre o procedimento e
                materiais poderão
                <br />
                ser usadas em solicitações futuras, facilitando o seu
                preenchimento no dia a dia.
              </p>
            }
            buttonSize={"lg"}
            isOpened={
              modelInfoDrawer && !model?.idModelo && !noRenderCreateModel
            }
            isDoubleAction={true}
            size="sm"
            buttonLabelPrimary={"Salvar como novo modelo"}
            buttonLabelSecondary={"Não, retornar à tela inicial"}
            handleButtonClickPrimary={() => {
              setNameModel(true);
              setModelInfoDrawer(false);
            }}
            handleButtonClickSecondary={() => {
              setPlanInfoDrawer(false);
              navigate("/");
            }}
          />
        </>
      )}
      <Drawer
        isOpened={idPatientNotReturnedDrawer}
        title="Falha ao identificar paciente"
        label={
          <div style={{ width: "65vw" }}>
            <p style={{ maxWidth: "702px" }}>
              Mas, não se preocupe que iremos resolver isso da melhor maneira
              possível. Para isso, pedimos a gentileza de entrar em contato no
              telefone <strong>(11) 3394-0990</strong>.
              <br /> Logo após esse contato, o status da solicitação de
              agendamento passará a ”Aguardando Atendimento”, onde atuaremos
              rapidamente na resolução do problema.
            </p>
          </div>
        }
        buttonSize={"sm"}
        isDoubleAction={false}
        buttonLabelPrimary={"Ok, entendi"}
        handleButtonClickPrimary={() => {
          navigate("/");
        }}
        handleClose={() => navigate("/")}
      />
      <Drawer
        isOpened={extraTeamsDrawer.render}
        title={
          <p>
            Os responsáveis pelas equipes serão
            <br /> notificados da sua indicação
          </p>
        }
        label={
          <div style={{ marginTop: "8px", width: "65vw" }}>
            <p style={{ maxWidth: "702px" }}>
              Ao finalizar o envio da solicitação, os(as) médicos(as)
              indicados(as) ou adicionados(as) serão notificados do seu pedido
              de inclusão na cirurgia.
            </p>
          </div>
        }
        buttonSize={"sm"}
        isDoubleAction={false}
        buttonLabelPrimary={"Ok, entendi"}
        handleButtonClickPrimary={(e) => {
          handleClickAdvance(e);
          setExtraTeamsDrawer({
            renderOnAdvanceStep: false,
            render: false,
          });
        }}
        handleClose={() => {}}
      />
      <Help selectedLocal={formData?.procedimento.local} />
    </S.Container>
  );
};

export default Scheduling;
