import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import SelectModel from "./pages/SelectModel";
import Scheduling from "./pages/Scheduling";
import MainLayout from "./components/MainLayout";
import Solicitations from "./pages/ListSolicitations";
import SystemStatus from "./pages/SystemStatus";
import AuthProvider from "./providers/AuthProvider";
import IdleTimeout from "./components/IdleTimeout";
import RevalidateSession from "./components/RevalidateSession";

const App = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/sistema" element={<SystemStatus />} />
          <Route element={<MainLayout />}>
            <Route exact path="/" element={<Solicitations />} />
            <Route path="/modelos" element={<SelectModel />} />
            <Route path="/agendamento" element={<Scheduling />} />
            <Route path="*" element={<Navigate to="/" replace={true} />} />
          </Route>
        </Routes>
        <RevalidateSession />
        <IdleTimeout />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
