import styled, { css } from "styled-components";

export const StyledAlert = styled.div`
  > div {
    top: 0;
    transform: translate(-50%, -110%);
    width: 312px;
    min-width: 312px;
    z-index: 1000;
    margin: 0 auto;
    background-color: ${(props) => props.backgroundColor};
    ${(props) =>
      props.isOpened &&
      css`
        top: 0;
        transform: translate(-50%, 90%);
      `}
    h3 {
      font-size: 16px;
    }
  }
`;
