export const procedures = {
  procedimentoPrincipal: "",
  cdProcedimentoPrincipal: "",
  ieLado: "",
  lateralidade: "",
  procedimentosExtras: [],
  regimeHospitalar: "",
  carater: "",
  local: "",
  diarias: "",
  cobranca: "",
  tipagemSanguinea: "",
  reservaHemocomponentes: "",
  anestesista: {
    tipo: "",
    id: "",
    nome: "",
    crm: "",
    telefone: "",
    codPessoaFisica: "",
  },

  reservaCritica: "",
  cdReservaCritica: "",

  equipeCirurgicaAdicional: "Não",
  equipe: [],
  biopsiaCongelacao: "",
  equipeResponsavel: "",
  biobanco: false,
  microscopio: false,
  roboDaVinci: false,
  roboDaOrtopedia: false,
  videolaparoscopia: false,
  tomografiaEstereotaxia: false,
  ecoTransesofagico: false,
  raiox: false,
  scopia: false,
  medicinaNuclear: false,
  ultrassonografia: false,
  ultrassonografiaLaparoscopio: false,
  ressonanciaMagneticaIntraperatoria: false,
  monitorizacaoNeurofisiologica: false,
  endoscopia: false,
  colonoscopia: false,
  pth: false,
  bancoTecidos: false,
  internacaoVespera: false,
  datasReservadas: false,
  cirurgiaSequencia: false,
  radioHsl: false,
  radioExt: false,
  data: "",
  hora: "",
  tempoCirurgia: "",
  agendarApos: "",
  observacoes: "",
  idProcedimento: null,
  cdEstabelecimento: "",
};
