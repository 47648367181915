export const fields = {
  local: [
    { name: "reservaCritica", initialValue: "", disabled: [] },
    { name: "cobranca", initialValue: "", disabled: [] },
    { name: "ultrassonografiaLaparoscopio", initialValue: false, disabled: ["321", "51"] },
    { name: "tomografiaEstereotaxia", initialValue: false, disabled: ["321", "51"] },
    { name: "monitorizacaoNeurofisiologica", initialValue: false, disabled: ["321", "51"] },
    { name: "medicinaNuclear", initialValue: false, disabled: ["321", "51"] },
    { name: "biopsiaCongelacao", initialValue: "", disabled: []  },
    { name: "biobanco", initialValue: false , disabled: []},
    { name: "microscopio", initialValue: false, disabled: ["1"] },
    { name: "roboDaVinci", initialValue: false, disabled: ["51"] },
    { name: "videolaparoscopia", initialValue: false, disabled: ["1", "51"] },
    { name: "ecoTransesofagico", initialValue: false, disabled: ["51"] },
    { name: "raiox", initialValue: false, disabled: []  },
    { name: "ultrassonografia", initialValue: false, disabled: []  },
    { name: "ressonanciaMagneticaIntraperatoria", initialValue: false, disabled: ["51"] },
    { name: "endoscopia", initialValue: false, disabled: ["51"] },
    { name: "colonoscopia", initialValue: false, disabled: ["51"] },
    { name: "pth", initialValue: false, disabled: ["51"] },
    { name: "bancoTecidos", initialValue: false, disabled: ["51"] },
    { name: "roboDaOrtopedia", initialValue: false, disabled: ["321", "51"] },
    { name: "radioHsl", initialValue: false, disabled: []  },
    { name: "radioExt", initialValue: false, disabled: []  },
    { name: "scopia", initialValue: false, disabled: []},
  ],

  services: [
    { name: "ultrassonografiaLaparoscopio", initialValue: false, disabled: ["321", "51"] },
    { name: "tomografiaEstereotaxia", initialValue: false, disabled: ["321", "51"] },
    { name: "monitorizacaoNeurofisiologica", initialValue: false, disabled: ["321", "51"] },
    { name: "medicinaNuclear", initialValue: false, disabled: ["321", "51"] },
    { name: "biopsiaCongelacao", initialValue: "", disabled: []  },
    { name: "biobanco", initialValue: false , disabled: []},
    { name: "microscopio", initialValue: false, disabled: ["1"] },
    { name: "roboDaVinci", initialValue: false, disabled: ["51"] },
    { name: "videolaparoscopia", initialValue: false, disabled: ["1", "51"] },
    { name: "ecoTransesofagico", initialValue: false, disabled: ["51"] },
    { name: "raiox", initialValue: false, disabled: []  },
    { name: "ultrassonografia", initialValue: false, disabled: []  },
    { name: "ressonanciaMagneticaIntraperatoria", initialValue: false, disabled: ["51"] },
    { name: "endoscopia", initialValue: false, disabled: ["51"] },
    { name: "colonoscopia", initialValue: false, disabled: ["51"] },
    { name: "pth", initialValue: false, disabled: ["51"] },
    { name: "bancoTecidos", initialValue: false, disabled: ["51"] },
    { name: "roboDaOrtopedia", initialValue: false, disabled: ["321", "51"] },
    { name: "radioHsl", initialValue: false, disabled: []  },
    { name: "radioExt", initialValue: false, disabled: []  },
    { name: "scopia", initialValue: false, disabled: []},
  ],
};
