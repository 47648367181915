import { useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";
import { useSelector } from "react-redux";
import { useAuthContext } from "../../hooks";
import { checkTokenExpiration } from "./utils";
import TimeoutModal from "../TimeoutModal";
import Alert from "../Alert";

const params = new URLSearchParams(window.location.search);
let hasRefreshToken = !!params.get("token");

const Content = () => {
  const { logout, tryToRevalidateAccessToken } = useAuthContext();

  const { tokenExpirationDate, sessionExpirationDate, timeToRevalidate } =
    useSelector((state) => state.auth);
  const [expiratedSession, setExpiratedSession] = useState(false);
  const [showLogoutAlert, setShowLogoutAlert] = useState(false);
  const expiryTimestamp = new Date(sessionExpirationDate);

  const timer = useTimer({
    expiryTimestamp,
    onExpire: () => _handleLogout(),
  });

  const _handleLogout = () => {
    if (!hasRefreshToken) {
      timer.pause();
      setShowLogoutAlert(false);
      setExpiratedSession(true);
      localStorage.clear();
    }
  };

  useEffect(() => {
    if (!hasRefreshToken) {
      checkTokenExpiration(
        tokenExpirationDate,
        sessionExpirationDate,
        timeToRevalidate,
        revalidateAccessToken,
        _handleShowLogoutAlert
      );
    }
  }, [timer.minutes]);

  const revalidateAccessToken = async () => {
    tryToRevalidateAccessToken(_handleShowLogoutAlert);
  };

  const _handleShowLogoutAlert = () => {
    if (timer.totalSeconds < 60 || timer.minutes === 15) {
      setShowLogoutAlert(true);
    }
  };

  return (
    <>
      {expiratedSession ? <TimeoutModal /> : null}

      {!expiratedSession && showLogoutAlert ? (
        <Alert
          title="Atenção!"
          message={
            <p>
              Sua sessão de login irá expirar
              <br />
              em{" "}
              {timer.minutes >= 1
                ? `${timer.minutes} ${
                    timer.minutes < 2
                      ? ` minuto e ${timer.seconds} segundos`
                      : " minutos"
                  }`
                : `${timer.seconds} ${
                    timer.seconds > 1 ? " segundos" : " segundo"
                  }`}
            </p>
          }
          isOpened
          linkText="Fazer login novamente"
          linkAction={() => {
            logout();
          }}
          handleClose={() => {
            setShowLogoutAlert(false);
          }}
          bgColor="#FFE3C2"
        />
      ) : null}
    </>
  );
};

const RevalidateSession = () => {
  const { sessionExpirationDate, token } = useSelector((state) => state.auth);

  if (!!token && !!sessionExpirationDate) {
    return <Content />;
  }

  return null;
};

export default RevalidateSession;
