import axios from "axios";
import { ApiAuth, ApiCore, ApiCoreSolicitations } from "./utilities/core";
import { removeAccents } from "../../utils";

export const apiSendLogs = (logs) => {
  return ApiCoreSolicitations({ url: "logs" }).post(logs, true);
};

export const apiSendFile = (file, idSolicitacao, onUploadProgress) => {
  const sendFileAPIFunctions = ApiCore({
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": `${file.type}`,
    },
    url: `envia-arquivo?nm_arquivo=${removeAccents(
      file.name
    )}&cd_solicitacao=${idSolicitacao}`,
    onUploadProgress: onUploadProgress,
  });

  return sendFileAPIFunctions;
};

export const apiModels = (idMedico) => {
  const modelFunctions = ApiCoreSolicitations({
    url: `modelo/${idMedico}`,
  });
  return modelFunctions;
};

export const apiListModels = (idMedico) => {
  const modelsFunctions = ApiCoreSolicitations({
    url: `lista-modelo/${idMedico}`,
  });
  return modelsFunctions;
};

export const apiConsentForm = () => {
  const getConsentForm = ApiCoreSolicitations({
    url: "termo-consentimento",
  });

  return getConsentForm;
};

export const apiCreateSolicitation = () => {
  const solicitationFunctions = ApiCoreSolicitations({
    url: "solicitacao",
    sendPlugPlayToken: true,
  });

  return solicitationFunctions;
};

export const apiPattientSolicitations = (idMedico, params) => {
  const solicitationFunctions = ApiCoreSolicitations({
    url: `lista-solicitacao/${idMedico}/summary?${params}`,
    sendPlugPlayToken: true,
  });

  return solicitationFunctions;
};

export const apiUpdateSolicitation = (idSolicitation) => {
  const solicitationFunctions = ApiCoreSolicitations({
    url: `update-solicitacao/${idSolicitation}`,
    sendPlugPlayToken: true,
  });

  return solicitationFunctions;
};

export const getAnamneseSolicitation = () => {
  const solicitationFunctions = ApiCoreSolicitations({
    url: "anamnese",
  });

  return solicitationFunctions;
};

export const apiCreateProcedure = (idSolicitation) => {
  const createProcedure = ApiCoreSolicitations({
    url: `procedimento/${idSolicitation}`,
  });

  return createProcedure;
};

export const apiSolicitationReports = (solicitationID) => {
  const solicitationFunctions = ApiCoreSolicitations({
    url: `documentos/${solicitationID}`,
  });

  return solicitationFunctions;
};

export const apiPatient = (doc) => {
  const patientFunctions = ApiCore({
    url: `paciente?${doc}`,
    sendPlugPlayToken: true,
  });

  return patientFunctions;
};

export const apiLogin = () => {
  const loginFunctions = ApiAuth({
    url: "refresh-token",
  });

  return loginFunctions;
};

export const apiCID = (str = "") => {
  const CIDFunctions = ApiCore({
    url: `cid${str ? `?descricao=${str}` : ""}`,
  });

  return CIDFunctions;
};

export const apiDomains = () => {
  const domainsFunctions = ApiCore({
    url: "dominios",
  });

  return domainsFunctions;
};

export const apiSpecialNeeds = () => {
  const specialNeedsFunctions = ApiCore({
    url: "necessidades",
  });

  return specialNeedsFunctions;
};

export const apiProcedure = (str = "", idSequencia = "") => {
  const mainProceduresFunctions = ApiCore({
    url: `procedimento?${idSequencia ? idSequencia : "descricao"}=${str}`,
  });

  return mainProceduresFunctions;
};

export const anamneseSolicitation = (idAnamnese) => {
  const solicitationFunctions = ApiCoreSolicitations({
    url: `anamnese/${idAnamnese}`,
  });

  return solicitationFunctions;
};

export const apiAnamneseExameFisico = (idAnamnese) => {
  const createExameFisico = ApiCoreSolicitations({
    url: `anamnese-exame-fisico/${idAnamnese}`,
  });

  return createExameFisico;
};

export const apiAnamneseResultado = (idAnamnese) => {
  const createResultados = ApiCoreSolicitations({
    url: `anamnese-resultado/${idAnamnese}`,
  });

  return createResultados;
};

export const apiFinalizeSolicitation = (idSolicitacao) => {
  const createResultados = ApiCoreSolicitations({
    url: `envia-solicitacao/${idSolicitacao}`,
  });

  return createResultados;
};

export const apiFinalizeAnamnese = (idSolicitacao) => {
  const sendAnamnese = ApiCoreSolicitations({
    url: `envia-anamnese/${idSolicitacao}`,
  });

  return sendAnamnese;
};

export const apiDoctor = () => {
  const doctorFunctions = ApiCore({
    url: "medico/login",
  });

  return doctorFunctions;
};

export const apiSurgeon = (str = "") => {
  const surgeonFunctions = ApiCore({
    url: `cirurgiao${str ? `?nome=${str}` : ""}`,
    sendPlugPlayToken: true,
  });

  return surgeonFunctions;
};

export const apiSurgeonCrm = (crm = "", uf = "") => {
  const surgeonFunctions = ApiCore({
    url: `cirurgiao${crm ? `?crm=${crm}&uf=${uf}` : ""}`,
    sendPlugPlayToken: true,
  });

  return surgeonFunctions;
};

export const apiAnesthetist = (str = "") => {
  const anesthetistFunctions = ApiCore({
    url: `anestesista${str ? `?nome=${str}` : ""}`,
    sendPlugPlayToken: true,
  });

  return anesthetistFunctions;
};

export const apiAnesthetistCrm = (crm = "", uf = "") => {
  const anesthetistFunctions = ApiCore({
    url: `anestesista${crm ? `?crm=${crm}&uf=${uf}` : ""}`,
    sendPlugPlayToken: true,
  });

  return anesthetistFunctions;
};

export const apiCodeStates = async () => {
  return await axios
    .get(
      "https://9873lqh5da.execute-api.sa-east-1.amazonaws.com/hml/healthcareStateCouncil"
    )
    .then((res) => {
      const { data } = res;
      if (!data.erro) {
        return data;
      }

      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const apiCEP = async (cep = "") => {
  return await axios
    .get(`https://viacep.com.br/ws/${cep}/json/`)
    .then((res) => {
      const { data } = res;
      if (!data.erro) {
        return data;
      }

      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const apiSelectedState = () => {
  const selectedStateFunctions = ApiCore({
    url: "estados",
  });
  return selectedStateFunctions;
};

export const apiHealthInsurance = () => {
  const healthInsuranceFunctions = ApiCore({
    url: "convenio",
  });
  return healthInsuranceFunctions;
};

export const apiSignature = () => {
  const signatureFunctions = ApiCore({
    url: "assinaturas",
  });
  return signatureFunctions;
};

export const apiCategory = (param = "") => {
  const categoryFunctions = ApiCore({
    url: `categoria/${param}`,
  });
  return categoryFunctions;
};

export const apiHealthPlan = (healthInsuranceCode = "") => {
  const healthPlanFunctions = ApiCore({
    url: `plano/${healthInsuranceCode}`,
  });
  return healthPlanFunctions;
};

export const apiPlanCategory = (code) => {
  const planCategoryFunctions = ApiCore({
    url: `categoria-plano/${code.convenio}?${
      code.plano ? `plano=${code.plano}` : `categoria=${code.categoria}`
    }`,
  });
  return planCategoryFunctions;
};

export const apiEstablishment = (code) => {
  const EstablishmentFunctions = ApiCore({
    url: `estabelecimento?idConvenio=${code.convenio}&idCategoriaConvenio=${code.categoria}&idPlanoConvenio=${code.plano}&uf=${code.uf}`,
  });
  return EstablishmentFunctions;
};

export const apiBookedDates = (idDoctor) => {
  const bookedDatesFunctions = ApiCore({
    url: `agenda-paciente/${idDoctor}`,
  });
  return bookedDatesFunctions;
};

export const apiSequenceSurgery = (idDoctor, date) => {
  const dateTimesFunctions = ApiCore({
    url: `cirurgia-sequencia/${idDoctor}?dt_dia=${date}`,
  });
  return dateTimesFunctions;
};

export const apiNationalities = () => {
  const nationalitiesFunctions = ApiCore({
    url: "nacionalidades",
  });

  return nationalitiesFunctions;
};

export const apiSendMaterials = (idSolicitation) => {
  const solicitationFunctions = ApiCoreSolicitations({
    url: `material/${idSolicitation}`,
  });

  return solicitationFunctions;
};

export const apiSolicitations = (idDoctor = "", params) => {
  const param = new URLSearchParams(params).toString();
  const solicitationFunctions = ApiCoreSolicitations({
    url: idDoctor ? `lista-solicitacao/${idDoctor}?${param}` : "solicitacao",
  });

  return solicitationFunctions;
};

export const apiAttachmentUrl = () => {
  const getFileAPIFunctions = ApiCore({
    url: "anexos",
    sendPlugPlayToken: true,
  });

  return getFileAPIFunctions;
};

export const apiSuggestedSchedules = (cd_medico = "", cd_procedimento = "") => {
  const getSuggestedSchedules = ApiCore({
    url: `tempo-cirurgico/${cd_medico}/${cd_procedimento}`,
    sendPlugPlayToken: true,
  });

  return getSuggestedSchedules;
};

export const apiGetHealthcareByPatient = (codPessoaFisica) => {
  const getPatient = ApiCore({
    url: `convenio-paciente/${codPessoaFisica}`,
  });

  return getPatient;
};

export const apiNotifications = (idMedico) => {
  const getNotifications = ApiCoreSolicitations({
    url: `notificacao/${idMedico}`,
  });

  return getNotifications;
};

export const apiUpdateNotifications = () => {
  const response = ApiCoreSolicitations({
    url: "update-equipe-unificada",
  });

  return response;
};

export const apiDuplicateModel = () => {
  const response = ApiCoreSolicitations({
    url: "copiar-modelo",
  });

  return response;
};

export const apiDeleteSolicitation = () => {
  const response = ApiCoreSolicitations({
    url: "desativar-solicitacao",
  });

  return response;
};

export const apiRequiredCid = (query) => {
  const getCidRequired = ApiCore({
    url: `check-cid-required-report?cd_doenca=${query}`,
  });

  return getCidRequired;
};

export const apiGetSuppliers = (query) => {
  const getSuppliers = ApiCore({
    url: `fornecedores-inpart?nmFantasia=${encodeURIComponent(query)}`,
  });

  return getSuppliers;
};

export const apiGetStatus = () => {
  const getStatus = ApiCore({
    url: "status-solicitacao",
  });

  return getStatus;
};
