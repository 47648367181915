import styled, { css } from "styled-components";

export const Wrapper = styled.span`
  display: inline-flex;
  position: relative;

  &:hover {
    > .tmp-tooltip__message {
      visibility: ${props => props.disabled ? "" : "visible"};
    }
  }

  ${(props) =>
    props.position == "top" &&
    css`
      justify-content: flex-start;
      > .tmp-tooltip__message {
        bottom: 100%;
      }
    `}
  ${(props) =>
    props.position == "top-start" &&
    css`
      justify-content: flex-start;
      > .tmp-tooltip__message {
        bottom: 100%;
        left: 0;
      }
    `}
  ${(props) =>
    props.position == "top-end" &&
    css`
      justify-content: flex-start;
      > .tmp-tooltip__message {
        bottom: 100%;
        right: 0;
      }
    `}

  ${(props) =>
    props.position == "right" &&
    css`
      align-items: center;
      > .tmp-tooltip__message {
        left: 100%;
      }
    `}
  ${(props) =>
    props.position == "right-start" &&
    css`
      align-items: flex-start;
      > .tmp-tooltip__message {
        left: 100%;
      }
    `}
  ${(props) =>
    props.position == "right-end" &&
    css`
      align-items: flex-flex-end;
      > .tmp-tooltip__message {
        left: 100%;
      }
    `}

  ${(props) =>
    props.position == "bottom" &&
    css`
      justify-content: flex-start;
      > .tmp-tooltip__message {
        top: 100%;
      }
    `}
  ${(props) =>
    props.position == "bottom-start" &&
    css`
      justify-content: flex-start;
      > .tmp-tooltip__message {
        top: 100%;
        left: 0;
      }
    `}
  ${(props) =>
    props.position == "bottom-end" &&
    css`
      justify-content: flex-start;
      > .tmp-tooltip__message {
        top: 100%;
        right: 0;
      }
    `}

  ${(props) =>
    props.position == "left" &&
    css`
      align-items: center;
      > .tmp-tooltip__message {
        right: 100%;
      }
    `}
  ${(props) =>
    props.position == "left-start" &&
    css`
      align-items: flex-start;
      > .tmp-tooltip__message {
        right: 100%;
      }
    `}
  ${(props) =>
    props.position == "left-end" &&
    css`
      align-items: flex-end;
      > .tmp-tooltip__message {
        right: 100%;
      }
    `}
`;

export const Tooltip = styled.span`
  display: inline-flex;
  box-sizing: border-box;
  position: absolute;
  width: max-content;
  visibility: hidden;
  z-index: 9;
  opacity: 1;
  text-align: left;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.48, 0, 0.48, 1);
  font-family: var(--font-family);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-133);
  color: var(--color-neutral-100) !important;
  background-color: var(--color-neutral-7) !important;
  border-radius: var(--border-radius-4);
  padding: var(--spacing-sizes-16);
`;
