import React from "react";
import { TmpDrawerContent } from "@tempo/tempo-design-system-core/dist";

import * as S from "./style";

const Drawer = (props) => {
  return (
    <S.WrapperDrawer>
      <TmpDrawerContent {...props} />
    </S.WrapperDrawer>
  );
};

export default Drawer;
