import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Inter';
    margin: 0;
    padding: 0;
    iframe{
      display: none;
    }
  }

  body { 
    box-sizing: border-box;
  }

  :root {
    // Colors
    --primary-button-color: #2C6EF2;
    --blue: #BADBFC54;
    --gray: #292929;

    // Font Sizes
    --title-big-size: 4rem;
    --small-size: 1.2rem;
  }

  body #root{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color:#fff;
  }

  a {
    text-decoration-line: underline ;
    color: #2C6EF2;
    cursor: pointer;
  }

  section {
    width: 100%;
  }

  input:disabled {
    pointer-events:none;
  }
`;

export default GlobalStyle;

export const ComponentLabel = styled.label`
  display: inline-block;
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: ${(props) => (props.error ? "#EA1F1F" : "#292929")};
  margin-bottom: ${(props) => props.marginBottom ?? "8px"};
  margin: ${(props) => props.margin};
`;

export const SectionTitle = styled.div`
  display: inline-block;
  width: 100%;
  margin-bottom: ${(props) => props.marginBottomDivider};
  margin-top: 32px;

  hr {
    height: 1px;
  }

  .title {
    margin: ${({ margin }) => margin};
    font-size: 1rem;
    font-weight: 500;
    color: #a3a3a3;
    margin-bottom: 15px;
  }
`;
