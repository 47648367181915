export const saveSignatureDataUrlOnRedux = async (base64, onSuccess) => {
  getSignatureDataUrl(base64, onSuccess);
};

export const getSignatureDataUrl = (base64, callback) => {
  const canvas = createTempCanvas();
  const ctx = canvas.getContext("2d");
  const image = new Image();
  image.onload = function () {
    canvas.width = image.width;
    canvas.height = image.height;
    ctx.drawImage(image, 0, 0);
    const url = canvas.toDataURL("image/png");
    callback(url);
  };
  image.src = `data:image/jpeg;base64, ${base64}`;
};

export const createTempCanvas = () => {
  let canvas = document.createElement("CANVAS");
  canvas.style.display = "none";
  return canvas;
};
