import styled from "styled-components";

export const WrapperDrawer = styled.div`
  aside {
    button {
      height: 48px;
    }
  }

  @media (max-width: 1000px) {
    aside {
      > div > div {
        margin-bottom: 0;
      }
      > div > div:nth-last-child(1) {
        flex-direction: column;
        margin: auto;
        gap: 16px;

        button {
          margin-right: 0;
          height: 48px;
        }
      }
    }
  }
`;
