import { StyleSheet, Font } from "@react-pdf/renderer";
import Inter from "../../../assets/font/Inter/static/Inter-Regular.ttf";
import InterSemiBold from "../../../assets/font/Inter/static/Inter-600.ttf";

Font.register({
  family: "Inter",
  fonts: [
    { src: Inter, format: "truetype" },
    { src: InterSemiBold, fontWeight: 600 },
  ],
});

const fontColor = "#454b52";
const orange = "#d47e4d";

export const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: "29px",
    fontSize: "20px",
  },
  header: {
    fontStyle: "normal",
  },
  h1: {
    color: fontColor,
    fontWeight: 600,
    fontSize: 20,
  },
  h2: {
    color: orange,
    fontWeight: "semibold",
    fontSize: 12,
  },
  p: {
    color: fontColor,
    fontSize: 9.3,
  },
  headerRow1: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1,
  },
  divider: {
    borderTop: "1px solid #E0E0E0",
  },
  section: {},
  signatureSection: {
    width: "100%",
    marginTop: 40,
    flexDirection: "column",
    alignItems: "center",
  },
  cellH1: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: 11,
  },
  cell: {
    width: "32%",
  },
  cell2: {
    width: "23%",
    marginRight: "2%",
    marginTop: "2x",
    borderBottom: "2px solid #000000",
  },
  borderBottom: {
    borderBottom: "2px solid #E0E0E0",
  },
});
