import styled, { css } from "styled-components";
import { TmpInput } from "@tempo/tempo-design-system-core/dist";

export const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  hr {
    height: 1px;
  }
`;
export const PatientDoesNotExist = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const RadioWrapper = styled.div`
  > :first-child {
    margin-right: 24px;
  }
`;

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "0%")};

  @media (min-width: 414px) and (max-width: 767px) {
    margin-right: 0px;
  }
`;

export const IconWrapper = styled.div`
  margin: 0 40px 0 30px;
`;

export const ReportsRow = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1365px) {
    flex-direction: row-reverse;
  }
`;

export const SugestionBox = styled.div`
  position: absolute;
  width: 100%;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);
  margin-top: 8px;
  border-radius: 8px;
  padding: 16px;
  border-color: #e0e0e0;
  border-width: 2px;
  z-index: 2;
  height: auto;
  background-color: #ffffff;
  display: block;
  top: 91%;
`;

export const SugestionItem = styled.li`
  border-bottom: 0;
  height: 56px;
  padding: 0 32px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.48, 0, 0.48, 1);
  transition-property: background, color;
  cursor: pointer;
  background-color: #ffffff;
  list-style: none;
  display: flex;
  align-items: center;

  &:hover {
    background-color: rgba(197, 215, 251, 0.32);
    color: #0a3a99;
  }

  &:focus {
    background-color: rgba(197, 215, 251, 0.32);
    color: #0a3a99;
    outline: 2px solid #ff8a00;
    outline-offset: 2px;
  }
`;

export const MaterialContainer = styled.section`
  display: grid;
  grid-template-rows: ${({ rows }) => rows ?? "1fr"};
  grid-row-gap: 40px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-top: ${({ borderTop }) => borderTop};
  padding: 33px 24px;
`;

export const Steps = styled.div`
  width: 100%;
  display: flex;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #666666 !important;
  margin-bottom: 37px;

  .step-label {
    color: #2c6ef2;
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    font-family: "Inter";
    margin-right: 6px;
  }

  .step {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    color: #666666;
    font-family: "Inter";
    margin-left: 6px;
    margin-right: 34px;
  }
`;

export const Error = styled.section`
  color: #ea1f1f;
  display: flex;
  align-items: center;
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
`;

export const MessageError = styled.div`
  position: absolute;
  color: #ea1f1f;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;

  svg {
    margin-right: 2.57px;
  }
`;

export const InputWrap = styled.div`
  max-width: ${(props) => props.maxWidth};
  max-height: ${(props) => props.maxHeight ?? "89px"};
  margin-right: ${(props) => (props.marginRight ? "0px" : "0px")};
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;

  @media (min-width: 1024px) {
    width: ${({ width }) => width};
    margin: ${({ margin }) => margin};
  }
  @media (max-width: 767px) {
    width: ${({ widthCel }) => widthCel};
    margin: ${({ marginCel }) => marginCel};
  }
  .input-wrapper {
    /* width: 100%; */
    /* min-width: 100px; */

    input {
      /* min-width: 100px; */
    }
  }
  > div > div > label {
    margin-top: ${(props) => props.inputMarginTop};
  }

  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  @media (max-width: 768px) {
    margin-top: 0px;
  }
`;
export const WrapperSignature = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: 1024px) {
    width: 100%;
    display: ${({ display }) => display};
  }
  @media (max-width: 767px) {
    width: 100%;
    display: ${({ displayTablet }) => displayTablet};
  }
`;

export const StyledDivider = styled.div`
  width: 100%;
  > hr {
    width: 100%;
    width: 100%;
  }
`;

export const RemoveBtn = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;

  margin: 0 40px 0 30px;

  > svg {
    color: #ea1f1f;
    width: 26.67px;
    height: 26.67px;
  }
`;

export const BtnArrow = styled.button`
  margin-right: 34px;
  background: transparent;
  box-shadow: none;
  border: none;
  color: #2c6ef2;
  cursor: pointer;
`;

export const StyledSaving = styled.div`
  width: 100%;

  > span {
    padding: 0;
  }
`;

export const SelectWrap = styled.div`
  /* max-width: ${(props) => props.maxWidth};
  min-width: ${(props) => (props.minWidth ? props.minWidth : "298px")};
  margin-right: ${({ marginRight }) => marginRight};
  width: ${({ width }) => width}; */

  /* height: 90px; */
  max-height: ${(props) => (props.maxHeight ? "88px" : "")};
  .label {
    color: ${(props) => (props.disabled ? "rgba(0,0,0,0.32)" : null)};
    margin-bottom: 24px;
  }
  .input-wrapper {
    overflow-y: hidden;
  }

  @media (min-width: 1366px) {
    > div {
      margin-right: ${(props) => props.marginRightLG};
    }
  }
  svg {
    color: #000;
  }
  .tmp-select__helper {
    div {
      overflow-y: hidden;
    }
  }

  > div > div > button {
    cursor: pointer;
    min-width: auto;
    width: 100%;
    background: ${(props) => (props.error ? "#FDC4C4" : "transparent")};
    color: ${(props) =>
      props.placeholderColor ? "#000" : "rgba(41,41,41,0.35)"};
    @media screen and (max-width: 1023px) and (min-width: 768px) {
      width: ${({ widthTablet }) => widthTablet};
      min-width: 100px;
    }
    @media (max-width: 767px) {
      width: ${({ widthCel }) => widthCel};
      min-width: 100px;
      max-width: 100%;
      margin: 0;
    }
  }

  > div > div > ol {
    /* max-width: ${(props) => props.maxWidth};
    min-width: ${(props) => (props.minWidth ? props.minWidth : "298px")}; */
    width: 100%;
    max-height: 270px;
    overflow-y: auto;
  }
`;

export const BoxTitle = styled.div`
  margin-bottom: ${({ marginBottom }) => marginBottom ?? "32px"};
  hr {
    height: 1px;
    background-color: ${(props) => (props.bgColor ? props.bgColor : "")};
  }
  @media (max-width: 767px) {
    width: 100%;
    margin-top: ${({ marginmediasm }) => marginmediasm};
  }

  @media (min-width: 768px) {
    width: 100%;
    margin-top: ${({ marginmediasm }) => marginmediasm};
  }
`;

export const Title = styled.h3`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ color }) => (color ? color : "#a3a3a3")};

  margin-bottom: 16px;
`;

export const ReportSubtitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  white-space: nowrap;
`;

export const AdditionalInformation = styled.div`
  ${(props) =>
    props.disabled &&
    `
    opacity: 0.3;
  `}
  top: 90%;
  left: 70%;
  font-family: "Inter";
  font-size: 16px;
  line-height: 150%;
  color: #292929;

  @media (max-width: 1365px) {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 40px;
  }

  > p {
    font-weight: 700;
    margin-bottom: 18.5px;
  }

  > ul {
    margin-left: 22px;
  }
`;

export const Counter = styled.span`
  position: absolute;
  right: 0;
  bottom: 0px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: ${(props) => (props.error ? "#EA1F1F" : "#292929")};
`;

export const Saving = styled.span`
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #2c6ef2;

  @media only screen and (min-width: 360px) {
    padding: 0px 24px;
  }

  @media only screen and (min-width: 768px) {
    padding: 0px 32px;
  }
`;

export const WrapperHr = styled.div`
  width: 90vw;
`;
export const Section = styled.section`
  width: 100%;
  hr {
    height: 1px;
    margin-top: 15px;
  }

  @media only screen and (min-width: 360px) {
    hr,
    h3 {
      margin: 0px 24px;
    }
    h3 {
      margin-bottom: 15px;
    }
  }

  @media only screen and (min-width: 768px) {
    hr,
    h3 {
      margin: 0px 32px;
    }
    h3 {
      margin-bottom: 15px;
    }
  }

  @media only screen and (min-width: 1366px) {
    hr,
    h3 {
      margin: 0px 88px;
    }
    h3 {
      margin-bottom: 15px;
    }
  }
`;

export const AddButtonWrap = styled.div`
  button {
    padding: 0px 24px 0px 24px;
    min-width: 0px;
    border: 1px solid #c4c9d4;
  }

  .tmp-icon {
    > svg {
      width: 21px;
      height: 21px;
    }
  }
`;

export const MaterialInfos = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #292929;
  position: relative;

  > div > div > label,
  div > div > div,
  div > div > span {
    color: #292929;
    border-color: #292929;
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }

  @media only screen and (min-width: 768px) {
    justify-content: left;
    flex-direction: ${({ direction }) => direction};
  }

  @media only screen and (min-width: 1024px) {
    flex-direction: ${({ directionlg }) => directionlg};
  }

  gap: 24px;
`;

export const ReportInfos = styled.div`
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #292929;
  position: relative;
  margin-bottom: ${(props) => props.marginBottom};

  .infos-report {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .subtitle-report,
  .alert-report {
    white-space: nowrap;
  }

  .subtitle-report {
    font-family: "Inter";
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #292929;

    @media (max-width: 768px) {
      white-space: normal;
    }
  }

  .alert-report {
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    line-height: 133%;
    color: #666666;
    display: flex;
    align-items: center;

    span {
      margin-right: 8.33px;
    }
  }
`;

export const MotiveInfos = styled.div`
  margin-right: ${(props) => (props.marginRight ? "24px" : "0px")};
  max-width: ${(props) => props.maxWidth};
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const StyledFile = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0px;
  margin-bottom: 56.3px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #292929;
`;

export const TextareaWrap = styled.div`
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  max-height: ${(props) => props.height};

  > div > div > div > textarea {
    height: ${(props) => props.height};
  }

  //ESTE CÓDIGO FAZ O CONTADOR NÃO APARECER
  /* ${(props) =>
    props.error
      ? css`
          > div > span:nth-child(2) > span: nth-child(3) {
            visibility: hidden !important;
            height: 0px;
            width: 0px;
            margin: 0px;
          }
        `
      : `> div > span:nth-child(2) > span: nth-child(1) {
    visibility: hidden !important;
    height: 0px;
    width: 0px;
    margin: 0px;
  }`} */

  /* > div > span:nth-child(2) > span:first-child {
    visibility: visible;
  } */
`;

export const CheckWrap = styled.div``;

export const StyledSection = styled.section`
  width: 90vw;
  margin-bottom: 64px;
`;

export const StyledSectionWrapper = styled.section`
  width: 100%;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? "64px"};
  display: grid;
  grid-template-rows: ${({ rows }) => rows ?? "1fr 1fr"};
  grid-row-gap: 40px;
`;

export const StyledContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ columns }) => columns};
  align-items: ${({ alignItems }) => alignItems ?? "center"};
  grid-gap: ${({ gap }) => gap ?? "24px"};
  max-height: ${(props) => props.maxHeight};

  span {
    line-height: normal;
  }

  .input-wrapper {
    padding: 4px 16px;
    height: 56px;
    /* min-width: 100%; */
    min-width: 0;

    @media (max-width: 414px) {
      /* min-width: 100%; */
    }
  }
  [data-testid="input_id"] {
    // Para evitar que a linha fique torta quando "abrir" um helper text
    max-height: 88px;
  }
  [data-testid="input"] {
    max-height: ${(props) => props.maxHeight};
  }
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}` : "0px")};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}` : "0px"};

  @media (max-width: 1365px) {
    ${({ columnsMd, gapMD }) =>
      css`
        grid-template-columns: ${columnsMd};
        grid-gap: ${gapMD};
      `}
  }
`;

export const MDrender = styled.div`
  display: none;
  @media (max-width: 1365px) {
    display: block;
  }

  max-height: ${({ maxHeight }) => maxHeight};
`;
export const LGrender = styled.div`
  display: none;
  @media (min-width: 1366px) {
    display: block;
  }

  max-height: ${({ maxHeight }) => maxHeight};
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: left;

  @media (max-width: 768px) {
    gap: ${({ gap }) => `${gap}px`};
  }
`;

export const WapperMedicalFee = styled.div`
  @media only screen and (max-width: 992px) {
    margin-left: 0;
  }
`;

export const SelectMedicalFee = styled.div`
  display: flex;
  gap: 8px;
  padding-bottom: 16px;
`;

export const Label = styled.p`
  color: ${(props) => props.disabled};
  font-weight: ${(props) => props.fontWeight};
`;

export const Radios = styled.div`
  display: flex;
  gap: 24px;
  padding: ${({ padding }) => padding ?? "24px 0"};

  span {
    line-height: normal;
    color: ${({ error }) => (error ? "#EA1F1F" : "")};
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const TextHelp = styled.span`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${(props) => props.disabled};
  gap: 5px;
`;

export const FixedRow = styled.div`
  display: flex;
  width: ${(props) => props.width};
  flex-wrap: nowrap;
  justify-content: left;

  @media (min-width: 414px) and (max-width: 743px) {
    flex-wrap: wrap;
    width: 100%;
    margin-right: ${(props) =>
      props.marginTablet ? props.marginTablet : "0px"};
  }

  @media (min-width: 743px) {
    margin-right: ${(props) =>
      props.marginTablet ? props.marginTablet : "0px"};
  }
`;

export const DisplayBox = styled.section`
  width: ${({ width }) => width};
  display: flex;
  align-items: ${({ align }) => align};
  justify-content: space-between;
  flex-direction: ${({ direction }) => direction};
  flex-wrap: wrap;

  margin: ${({ margin }) => margin};

  @media (max-width: 767px) {
    flex-direction: column;
    display: flex;
    align-items: center;
  }

  @media (min-width: 768px) {
    display: flex;
  }

  > section > div > span {
    > div {
      font-size: 0.8rem;
      font-weight: 700;
      > button {
        border: none;
        background-color: transparent;
        color: #2c6ef2;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  > section {
    div {
      div {
        min-width: 92%;

        @media (max-width: 767px) {
          width: ${({ widthmediasm }) => widthmediasm};
          margin-top: ${({ marginmediasm }) => marginmediasm};
          min-width: 100%;
        }

        @media screen and (max-width: 1024px) and (min-width: 768px) {
          width: ${({ widthmediamd }) => widthmediamd};
          min-width: 100%;
          margin: ${({ marginmediamd }) => marginmediamd};
        }

        @media (min-width: 1024px) {
          width: ${({ widthmedialg }) => widthmedialg};
          margin: ${({ marginmedialg }) => marginmedialg};
        }
      }
    }
  }
`;

export const Input = styled(TmpInput)`
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  min-width: ${({ minWidth }) => minWidth};

  label {
    margin-top: ${({ marginTop }) => marginTop};
  }

  > div > div > input {
    width: 100%;
  }

  @media (min-width: 414px) and (max-width: 767px) {
    width: 100%;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-top: ${({ marginmediasm }) => marginmediasm};
  }

  @media screen and (max-width: 1023px) and (min-width: 768px) {
    width: ${({ widthmediamd }) => widthmediamd};
    margin: ${({ marginmediamd }) => marginmediamd};
  }

  @media (min-width: 1024px) {
    width: ${({ widthmedialg }) => widthmedialg};
    margin: ${({ marginmedialg }) => marginmedialg};
  }
`;

export const StyledInput = styled(TmpInput)`
  width: ${({ width }) => width};
  min-width: ${(props) => (props.minWidth ? props.minWidth : "auto")};
  margin-right: ${({ marginRight }) => marginRight};
  flex: ${({ flex }) => flex};

  label {
    margin-top: ${(props) => (props.marginTop ? props.marginTop : "40px")};
  }

  > div > div > input {
    align-items: center;
    width: 100%;
    margin-right: 0px;
  }

  .input-wrapper {
    min-width: ${(props) => (props.minWidth ? props.minWidth : "298px")};
  }

  @media (min-width: 414px) and (max-width: 743px) {
    width: 100%;
    margin-right: 0px;

    ${(props) =>
      props.mobileMinWidth &&
      css`
        min-width: ${(props) => props.mobileMinWidth};
        .input-wrapper {
          min-width: ${(props) => props.mobileMinWidth};
        }
      `}
  }

  @media (min-width: 744px) and (max-width: 811px) {
    ${(props) =>
      props.tabletMinWidth &&
      css`
        min-width: ${(props) => props.tabletMinWidth};
        .input-wrapper {
          min-width: ${(props) => props.tabletMinWidth};
        }
      `}
    width: ${(props) => (props.widthTablet ? props.widthTablet : "100%")};
    margin-right: ${(props) =>
      props.marginTablet ? props.marginTablet : "0px"};
    flex: ${(props) => (props.widthTablet ? "none" : 1)};
  }

  @media (max-width: 1410px) {
    label {
      margin-top: ${(props) =>
        props.helperIcon && props.helperText ? "16px" : "0px"};
    }
  }
`;

export const BoxSelect = styled.section`
  > section {
    width: ${({ width }) => width};
    min-width: auto;
    margin: ${({ margin }) => margin};
  }

  svg {
    color: #000;
  }

  button {
    width: 100%;
    min-width: auto;
    color: ${(props) => (props.placeholderColor ? "#000" : "#a3a3a3")};
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-top: ${({ marginmediasm }) => marginmediasm};
    margin-left: none;
  }

  @media screen and (max-width: 1023px) and (min-width: 768px) {
    width: ${({ widthmediamd }) => widthmediamd};
    margin-top: ${({ marginmediamd }) => marginmediamd};
  }

  @media (min-width: 1024px) {
    width: ${({ widthmedialg }) => widthmedialg};
    margin: ${({ marginmedialg }) => marginmedialg};
  }
`;

export const Tooltip = styled.div`
  position: absolute;
  margin-left: 5vw;

  @media (max-width: 1015px) {
    margin-left: 8vw;
  }
  @media (max-width: 615px) {
    margin-left: 10vw;
  }
  @media (max-width: 425px) {
    margin-left: 14vw;
  }
  &:hover {
    > span {
      visibility: visible;
    }
  }
`;

export const TooltipeText = styled.span`
  visibility: hidden;
  width: 25vw;
  background-color: #000000;
  opacity: 0.8;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  text-align: justify;
  padding: 15px 15px 15px 15px;
  position: absolute;
  top: 3vh;
  z-index: 1;

  @media (max-width: 768px) {
    width: 50vw;
  }
`;

export const AutocompleteRow = styled.div`
  position: relative;
  background-color: transparent;
  max-height: 88px;

  > div:last-child {
    width: -webkit-fill-available !important;
  }

  > div {
    div {
      min-width: auto;
      position: relative;
    }
  }

  input {
    width: 100%;
  }

  @media (max-width: 1410px) {
    label {
      margin-top: 0px;
    }
  }
`;

export const HelpText = styled.section`
  display: flex;
  align-items: center;
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
`;

export const WrapperPatienrCare = styled.div`
  @media (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

export const StyledLoading = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #f5f5f5;
  z-index: 10;
  opacity: 80%;
`;
