import axios from "axios";
import { handleResponse, handleError } from "./response";

const BASE_URL = process.env.REACT_APP_INTEGRATION_API;

const getList = async (resource, config) => {
  return await axios
    .get(`${BASE_URL}/${resource}`, config)
    .then(handleResponse)
    .catch(handleError);
};

const getSingle = async (resource, id, config) => {
  return await axios
    .get(`${BASE_URL}/${resource}/${id}`, config)
    .then(handleResponse)
    .catch(handleError);
};

const deleteSingle = async (resource, id, config) => {
  return await axios
    .delete(`${BASE_URL}/${resource}/${id}`, config)
    .then(handleResponse)
    .catch(handleError);
};

const post = async (resource, model, config) => {
  return await axios
    .post(`${BASE_URL}/${resource}`, model, config)
    .then(handleResponse)
    .catch(handleError);
};

const put = async (resource, model, config) => {
  return await axios
    .put(`${BASE_URL}/${resource}`, model, config)
    .then(handleResponse)
    .catch(handleError);
};

export const apiProvider = {
  getList,
  getSingle,
  deleteSingle,
  post,
  put,
};
