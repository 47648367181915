import React from "react";
import * as Styled from "./styled";
const IluminationIcon = ({ color = "#3D3D3D", size = 16, disabled }) => {
  return (
    <Styled.StyledIcon disabled={disabled}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
      >
        <rect x="10.8003" width="2.4" height="4.8" rx="1.2" fill={color} />
        <rect
          y="13.1997"
          width="2.4"
          height="4.8"
          rx="1.2"
          transform="rotate(-90 0 13.1997)"
          fill={color}
        />
        <rect
          x="19.1997"
          y="13.1997"
          width="2.4"
          height="4.8"
          rx="1.2"
          transform="rotate(-90 19.1997 13.1997)"
          fill={color}
        />
        <rect
          x="1.6626"
          y="4.56885"
          width="2.4"
          height="4.8"
          rx="1.2"
          transform="rotate(-45 1.6626 4.56885)"
          fill={color}
        />
        <rect
          x="19.0972"
          y="8.0918"
          width="2.4"
          height="4.8"
          rx="1.2"
          transform="rotate(-135 19.0972 8.0918)"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12V16.7998C19.988 16.8001 21.5994 18.4118 21.5994 20.3998C21.5994 22.388 19.9876 23.9998 17.9994 23.9998H5.99941C4.01119 23.9998 2.39941 22.388 2.39941 20.3998C2.39941 18.4116 4.01119 16.7998 5.99941 16.7998H6V12ZM6 19.2V19.1992H5.99883C5.33609 19.1992 4.79883 19.7365 4.79883 20.3992C4.79883 21.062 5.33609 21.5992 5.99883 21.5992H17.9988C18.6616 21.5992 19.1988 21.062 19.1988 20.3992C19.1988 19.7369 18.6622 19.1999 18 19.1992V19.2H6ZM11.9994 8.39941C10.0112 8.39941 8.39941 10.0112 8.39941 11.9994V16.7994H15.5994V11.9994C15.5994 10.0112 13.9876 8.39941 11.9994 8.39941Z"
          fill={color}
        />
      </svg>
    </Styled.StyledIcon>
  );
};

export default IluminationIcon;
