import styled from "styled-components";

export const SugestionBox = styled.div`
  position: absolute;
  width: 100%;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);
  margin-top: 8px;
  border-radius: 8px;
  padding: 16px;
  border-color: #e0e0e0;
  border-width: 2px;
  z-index: 2;
  height: auto;
  background-color: #ffffff;
  display: block;
  top: 80px;
  max-height: 250px;
  overflow-y: auto;
`;

export const SugestionItem = styled.li`
  border-bottom: 0;
  height: 56px;
  padding: 0 32px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.48, 0, 0.48, 1);
  transition-property: background, color;
  cursor: pointer;
  background-color: #ffffff;
  list-style: none;
  display: flex;
  align-items: center;

  &:hover {
    background-color: rgba(197, 215, 251, 0.32);
    color: #0a3a99;
  }

  &:focus {
    background-color: rgba(197, 215, 251, 0.32);
    color: #0a3a99;
    outline: 2px solid #ff8a00;
    outline-offset: 2px;
  }

  &:first-of-type {
    ${(props) =>
      props?.showTopBorder
        ? `
        border-top: 2px solid #E0E0E0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      `
        : ""};
  }
`;

export const SugestionTitle = styled.p`
  font-size: 12px;
  line-height: 12px;
  color: #a3a3a3;
`;
