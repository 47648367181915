import * as D from "@tempo/tempo-design-system-core/dist";
import { retornar as BackSVG } from "@tempo/tempo-assets/dist";
import * as S from "./style";

const RequestFailedAction = ({
  startIcon = <BackSVG />,
  handleClick,
  titleButton,
  loading,
}) => {
  return (
    <S.WrapperButton>
      <D.TmpButton
        startIcon={startIcon}
        handleClick={handleClick}
        loading={loading}
      >
        {titleButton}
      </D.TmpButton>
    </S.WrapperButton>
  );
};

export default RequestFailedAction;
