import React from "react";

const WarningIcon = ({ color = "#3D3D3D", size = 16 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M7.99995 4.80005C8.44178 4.80005 8.79995 5.15822 8.79995 5.60005V10C8.79995 10.4419 8.44178 10.8 7.99995 10.8C7.55812 10.8 7.19995 10.4419 7.19995 10V5.60005C7.19995 5.15822 7.55812 4.80005 7.99995 4.80005Z"
        fill={color}
      />
      <path
        d="M7.99995 13.2C8.44178 13.2 8.79995 12.8419 8.79995 12.4C8.79995 11.9582 8.44178 11.6 7.99995 11.6C7.55812 11.6 7.19995 11.9582 7.19995 12.4C7.19995 12.8419 7.55812 13.2 7.99995 13.2Z"
        fill={color}
      />
      <path
        d="M15.4364 12.3946L9.74502 1.51403C9.30678 0.675902 8.6706 0.195122 7.99987 0.195122C7.32915 0.195122 6.69297 0.675608 6.25447 1.51403L0.563644 12.3946C0.114561 13.2531 0.0442101 14.095 0.365957 14.7652C0.687703 15.4357 1.33927 15.8049 2.20037 15.8049H13.7994C14.6605 15.8049 15.312 15.4358 15.6338 14.7655C15.9555 14.0953 15.8852 13.2531 15.4364 12.3946ZM14.754 14.189C14.6206 14.4671 14.2727 14.6265 13.7994 14.6265H2.20037C1.72708 14.6265 1.37911 14.4671 1.24572 14.189C1.11233 13.9112 1.17738 13.4811 1.42424 13.0092L7.11507 2.12826C7.36999 1.64159 7.68418 1.37351 7.99987 1.37351C8.31557 1.37351 8.62975 1.64159 8.88442 2.12856L14.5758 13.0092C14.8226 13.4811 14.8874 13.9112 14.754 14.189Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.11208 1.41229C6.57208 0.53276 7.25594 0 7.99999 0C8.74407 0 9.42792 0.533073 9.88765 1.41228L15.579 12.2929C16.048 13.19 16.1401 14.11 15.7796 14.861C15.4191 15.6119 14.6995 16 13.7995 16H2.20049C1.30052 16 0.580928 15.612 0.220417 14.8607C-0.140113 14.1097 -0.0480731 13.1901 0.421258 12.2929L6.11208 1.41229ZM7.99999 0.390244C7.4026 0.390244 6.8141 0.818456 6.39711 1.61576L0.706277 12.4964C0.277447 13.3161 0.228782 14.0804 0.511742 14.6698C0.794729 15.2595 1.37827 15.6098 2.20049 15.6098H13.7995C14.6217 15.6098 15.2053 15.2595 15.4882 14.6701C15.7712 14.0807 15.7225 13.3161 15.294 12.4963L9.60263 1.61578C9.18588 0.818748 8.59737 0.390244 7.99999 0.390244ZM7.2577 2.23C7.49991 1.76767 7.76972 1.56863 7.99999 1.56863L7.2577 2.23ZM7.2577 2.23L1.56687 13.1109C1.32777 13.568 1.30469 13.9128 1.3915 14.0936C1.47833 14.2746 1.7421 14.4314 2.20049 14.4314H13.7995C14.2579 14.4314 14.5216 14.2747 14.6085 14.0936C14.6953 13.9128 14.6724 13.5679 14.4334 13.1109L8.74204 2.2303C8.50009 1.76768 8.23026 1.56863 7.99999 1.56863M6.97274 2.02641C7.24037 1.51548 7.5989 1.17838 7.99999 1.17838C8.40112 1.17838 8.75965 1.51551 9.02706 2.02682L14.7184 12.9074C14.9731 13.3943 15.0797 13.9097 14.8998 14.2845C14.7198 14.6596 14.2877 14.8216 13.7995 14.8216H2.20049C1.71233 14.8216 1.28015 14.6596 1.10018 14.2845C0.920208 13.9097 1.02724 13.3942 1.28185 12.9074L6.97274 2.02641Z"
        fill={color}
      />
    </svg>
  );
};

export default WarningIcon;
