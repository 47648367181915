import styled from "styled-components";
import { TmpButtonIcon } from "@tempo/tempo-design-system-core";

export const HelpButton = styled(TmpButtonIcon)`
  background-color: #2c6ef2;
  position: fixed;
  right: 24px;
  bottom: 24px;
  border: none;
  color: #fff;
  z-index: 31;
  box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

export const Container = styled.div`
  div {
    position: fixed;
    right: 42px;
    bottom: 40px;
    width: 414px;
    background-color: #f5f5f5;
    padding: 12px 24px;
    z-index: 30;
    border-radius: 8px;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.13);

    @media screen and (max-height: 800px) {
      overflow-y: scroll;
      max-height: 70vh;
    }

    section:last-child {
      border-bottom: none;
    }
  }

  h1,
  h2 {
    color: #292929;
    font-size: 1rem;
    margin-bottom: 4px;
  }

  h1 {
    margin-top: 20px;
    font-weight: 700;
  }
  h2 {
    font-weight: 500;
    font-size: 0.875rem;
  }

  p {
    color: #666666;
    font-weight: 400;
    line-height: 160%;

    .text {
      font-weight: 600;
    }
    .number {
      font-weight: 700;
    }
  }
`;
export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
`;

export const HelpSection = styled.section`
  padding: 20px 0;
  border-bottom: 1px solid #e0e0e0;
`;
