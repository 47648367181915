export const solicitation = {
  solicitacao: {
    idSolicitacao: null,
    anteciparAgendamento: 0,
    usuarioSolicitante: null,
    status: "Pendente",
    idMedico: null,
    statusAnamnese: "Pendente",
    statusRelatorio: "Pendente",
    wasOpened: true,
  },
  paciente: {
    idPaciente: null,
    documento: "",
    tipoDocumento: "CPF",
    nome: "",
  },
  procedimento: {
    idProcedimento: null,
  },
  material: {
    idMaterial: null,
  },
  relatorio: {
    idRelatorio: null,
    termoConsentimento: null,
  },
  anamnese: {
    idAnamnese: null,
    status_anamnse: "Pendente",
  },
};
