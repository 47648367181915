import { setClearAuth } from "../../../features/Auth/authSlice";
import { store } from "../../../redux/store";

export function handleResponse(response) {
  if (response.results) {
    return response.results;
  }

  if (response.data) {
    return response.data;
  }

  return response;
}

export function handleError(response) {
  if (response?.response?.status === 401) {
    store.dispatch(setClearAuth());
  } else {
    return response;
  }
}
