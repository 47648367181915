import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1366px;
  width: 100%;
  padding: 2.5rem 1.5rem;
  box-sizing: border-box;
`;
