import styled, { css } from "styled-components";

export const MainCointainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 767px) {
    display: none;
  }

  // 58px = altura da footer
  // 89px = altura da header
  // 82px = altura da nav
  min-height: calc(100vh - 58px - 89px - 82px);

  @media (min-width: 360px) {
    // Fluid SM
    padding: 0 24px;
  }

  @media (min-width: 768px) {
    // Fluid MD
    padding: 0 32px;
  }

  @media (min-width: 1366px) {
    // Fluid LG
    padding: 0 88px;
    max-width: 1440px;
  }

  @media (min-width: 1440px) {
    // Fixed XL
  }
`;

export const Nav = styled.nav`
  display: grid;
  grid-template-columns: 1fr max(500px) 1fr;
  flex-direction: row;
  align-items: center;
`;

export const TextWrapper = styled.div`
  position: relative;
  max-width: ${(props) => props.maxWidth};
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.div`
  font-family: Inter;
  font-weight: 700;
  color: #292929;
  font-size: 1.5rem;

  ${(props) =>
    props.limitWidth &&
    css`
      max-width: 100%;

      @media (max-width: 950px) {
        max-width: 57%;
      }
    `}

  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const HoveredButton = styled.button`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 31px 24px;
  font-size: 1rem;
  background-color: transparent;
  font-weight: 400;
  border: none;
  color: #707070;
  z-index: 1;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  p {
    margin-left: 8px;
    @media (max-width: 768px) {
      display: none;
    }
  }
  &:hover {
    text-decoration: underline;
  }
`;
export const HeaderText = styled.p`
  position: relative;
  right: 100px;
  float: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: #292929;
  margin-top: 31px;
  @media (max-width: 650px) {
    right: 24px;
  }
`;

export const NavWrapper = styled.div`
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin-top: 88px;
  width: 100%;

  @media (max-width: 767px) {
    display: none;
  }

  @media (min-width: 1024px) {
    width: 100%;
    max-width: 90vw;
    > nav > button {
      padding-left: 0;
    }
  }

  @media (max-width: 576px) {
    max-width: 100%;
  }
`;

export const TooltipContainer = styled.div`
  &:hover {
    > span {
      visibility: visible;
    }
  }
`;
export const Tooltip = styled.span`
  visibility: hidden;
  /* width: 200px; */
  background-color: #000000;
  opacity: 0.8;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  text-align: justify;
  padding: 15px 15px 15px 15px;
  position: absolute;
  z-index: 1;
  margin: 10px 0 0 20%;
`;
