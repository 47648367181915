import { TmpIcon } from "@tempo/tempo-design-system-core/dist";
import {
  seta_direcional_esquerda as LeftDirectionalArrowSVG,
  seta_direcional_direita as RightDirectionalArrowSVG,
} from "@tempo/tempo-assets/dist";

import * as S from "./style";

const Pagination = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
  className,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const maxPages = 5;
  let startPage = 1;
  let endPage = 1;
  let firstPage = 1;

  if (totalPages <= maxPages) {
    startPage = 1;
    endPage = totalPages;
  } else {
    const middlePage = Math.floor(maxPages / 2);
    if (currentPage <= middlePage) {
      startPage = 1;
      endPage = maxPages;
    } else if (currentPage + middlePage >= totalPages) {
      startPage = totalPages - maxPages + 1;
      endPage = totalPages;
    } else {
      startPage = currentPage - middlePage;
      endPage = currentPage + middlePage;
    }
  }

  const pages = Array.from(
    { length: endPage - startPage + 1 },
    (_, i) => startPage + i
  );

  return (
    <S.Container className={className}>
      {totalItems > 0 && (
        <>
          <S.Arrow
            onClick={() => onPageChange?.(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <TmpIcon icon={<LeftDirectionalArrowSVG />} />
          </S.Arrow>

          {startPage > 1 && (
            <>
              <S.Pagination onClick={() => onPageChange?.(firstPage)}>
                {firstPage}
              </S.Pagination>
              {startPage > 2 && <S.Divide>...</S.Divide>}
            </>
          )}

          {pages.map((page) => (
            <S.Pagination
              key={page}
              onClick={() => onPageChange?.(page)}
              disabled={currentPage === page}
              active={currentPage === page}
            >
              {page}
            </S.Pagination>
          ))}

          {endPage < totalPages && (
            <>
              {endPage < totalPages - 1 && <S.Divide>...</S.Divide>}
              <S.Pagination onClick={() => onPageChange?.(totalPages)}>
                {totalPages}
              </S.Pagination>
            </>
          )}

          <S.Arrow
            onClick={() => onPageChange?.(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <TmpIcon icon={<RightDirectionalArrowSVG />} />
          </S.Arrow>
        </>
      )}
    </S.Container>
  );
};

export default Pagination;
