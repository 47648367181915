import Modal from "../NewModal";
import { TmpButtonGroup, TmpInput } from "@tempo/tempo-design-system-core/dist";
import * as S from "./style";
import { useState } from "react";

const ModalNameModel = ({
  title,
  subtitle,
  labelPrimary,
  isEditingModel,
  initialName,
  onSave,
  onCancel,
  onChangeName,
  loading,
  minPrimaryWidth,
  minSecondaryWidth,
}) => {
  const [name, setName] = useState(initialName || "");

  const _handleChangeName = (e) => {
    const value = e.target.value;
    setName(value);
    if (typeof onChangeName === "function") onChangeName(value);
  };

  const _handleSave = () => {
    if (name.trim().length) onSave(name.trim());
  };

  return (
    <Modal
      minHeight="min-content"
      maxHeight="555px"
      isOpened={true}
      labelPrimary={"Sucesso"}
      removeBtn
    >
      <>
        <S.Container>
          <S.Title>{title}</S.Title>
          <S.Text>{subtitle}</S.Text>
          <S.WrapperInput>
            <TmpInput
              value={name}
              handleChange={_handleChangeName}
              marginTop
              label="Nome do Modelo"
              placeholder="Inserir nome do modelo"
            />
          </S.WrapperInput>
          <S.BtnSendWrap
            edtModel={isEditingModel}
            pointer={loading}
            loading={loading}
            minWidthPrimary={minPrimaryWidth}
            minWidthSecondary={minSecondaryWidth}
          >
            <TmpButtonGroup
              icon={false}
              labelPrimary={labelPrimary}
              labelSecondary="Cancelar"
              handleClickPrimary={_handleSave}
              loadingButtonPrimary={loading}
              disabledPrimary={!name.trim().length}
              handleClickSecondary={onCancel}
            />
          </S.BtnSendWrap>
        </S.Container>
      </>
    </Modal>
  );
};

export default ModalNameModel;
