import { useState } from "react";
import Modal from "../NewModal";
import * as S from "./style";
import {
  TmpButton,
  TmpRadio,
  TmpIcon,
} from "@tempo/tempo-design-system-core/dist";
import { alerta as Alert, perfil as Perfil } from "@tempo/tempo-assets/dist";
import { transformDateEndianness, valueToWord } from "../../utils";
import { apiSolicitations } from "../../services/api";
import { ensureArray } from "../../utils/Validation";
import { useDispatch } from "react-redux";
import { patient } from "../../features/patient/patient";
import { procedures } from "../../features/procedures/procedures";
import { materials } from "../../features/materials/materials";
import { reports } from "../../features/reports/reports";
import { solicitation } from "../../features/solicitation/solicitation";
import { setSolicitationObject } from "../../features/solicitation/solicitationSlice";
import moment from "moment";

const renderSolcitationsQuantity = (solicitationsLength) =>
  solicitationsLength > 10
    ? "mais de 10 (dez)"
    : `${solicitationsLength} (${valueToWord[solicitationsLength]})`;

const dayPeriod = (hour) => {
  return Number.parseInt(`${hour?.split("")[0]}${hour?.split("")[1]}`) < 12
    ? "am"
    : "";
};

const InfoModal = ({
  open,
  solicitations,
  doctorName,
  loading,
  onClickReturnToHome,
  onAdvance,
}) => {
  const patientDate = transformDateEndianness(
    solicitations[0].dataNascimento.split("T")[0].replaceAll("-", "/")
  );

  return (
    <Modal minHeight="min-content" isOpened={open} removeBtn width="624px">
      <S.BoxModal textAlign={"start"} alignItems={"flex-start"}>
        <h1 style={{ fontSize: "1.2rem", marginBottom: "16px" }}>
          Paciente com solicitações de agendamento
        </h1>

        <p
          style={{
            fontSize: "1rem",
            lineHeight: "24px",
          }}
        >
          O(A) paciente{" "}
          <strong>
            {solicitations[0].nomePaciente}
            {" - "}
            {patientDate}
          </strong>{" "}
          já possui{" "}
          <strong>{renderSolcitationsQuantity(solicitations.length)}</strong>{" "}
          solicitações de cirurgia com o(a) <strong>Dr. {doctorName}.</strong>
        </p>

        {solicitations.length === 1 ? (
          <S.Card>
            <div>
              {solicitations[0].data ? (
                <p>
                  <strong>Data da Cirurgia:</strong> {solicitations[0].data} |{" "}
                  <strong>Horário:</strong> {solicitations[0].hora}{" "}
                  {dayPeriod(solicitations[0].hora)} <br />
                </p>
              ) : (
                <p>
                  <strong>Data da Cirurgia: </strong> |{" "}
                  <strong>Horário:</strong> <i>Pendente preenchimento</i> <br />
                </p>
              )}
              <p>
                <strong>Procedimento Principal:</strong>{" "}
                {solicitations[0].procedimentoPrincipal ? (
                  solicitations[0].procedimentoPrincipal
                ) : (
                  <i>Pendente preenchimento</i>
                )}
              </p>
              <p>
                <strong>Status:</strong> {solicitations[0]?.status}
              </p>
            </div>
          </S.Card>
        ) : (
          <p style={{ margin: "24px 0" }}>
            Caso deseje editar, escolha uma solicitação já existente.
          </p>
        )}

        <div
          style={{
            width: "100%",
            fontSize: ".75rem",
            lineHeight: "18px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <S.Alert>
            <TmpIcon size="sm" icon={<Alert />} />
          </S.Alert>
          <p>
            Para fazer uma nova solicitacão, para esse mesmo paciente, entre em
            contato no <br />{" "}
            <strong>(11) 3394-0381 / agendamento.cirurgias@hsl.org.br</strong>{" "}
            ou{" "}
            <strong>
              (61) 3044-8888 / <br /> agendamento.cirurgiasbsb@hsl.org.br
            </strong>
          </p>
        </div>
      </S.BoxModal>
      <S.BtnSendWrap alingItems={"center"} margin={"0 24px 0 0"}>
        <TmpButton type="secondary" size="sm" handleClick={onClickReturnToHome}>
          Retornar para tela inicial
        </TmpButton>
        <TmpButton
          type="primary"
          size="sm"
          loading={loading}
          handleClick={() => {
            onAdvance(
              solicitations.length === 1 ? solicitations[0].idSolicitacao : null
            );
          }}
        >
          {solicitations.length === 1
            ? "Editar essa solicitação"
            : "Escolher solicitação"}
        </TmpButton>
      </S.BtnSendWrap>
    </Modal>
  );
};

const ChoiceModal = ({
  open,
  solicitations,
  loading,
  getSolicitationData,
  onBack,
}) => {
  const [selectedSolicitationIndex, setSelectedSolicitation] = useState(null);
  const patientDate = transformDateEndianness(
    solicitations[0].dataNascimento.split("T")[0].replaceAll("-", "/")
  );

  return (
    <Modal minHeight="min-content" isOpened={open} removeBtn width="624px">
      <S.BoxModal textAlign={"start"} alignItems={"flex-start"}>
        <h1 style={{ fontSize: "1.2rem", marginBottom: "16px" }}>
          Seguir com uma das solicitações abaixo
        </h1>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            margin: "8px 0 16px",
          }}
        >
          <TmpIcon style={{ marginRight: "8px" }} size="sm" icon={<Perfil />} />

          <p>
            <strong>Nome do Paciente:</strong> {solicitations[0].nomePaciente} -{" "}
            {patientDate}
          </p>
        </div>

        <S.List>
          {solicitations.map((solicitation, index) => {
            const { data, hora, procedimentoPrincipal, tuss } = solicitation;
            return (
              <S.ListItem
                selected={index == selectedSolicitationIndex}
                key={index}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedSolicitation(index);
                }}
              >
                <TmpRadio
                  id={index}
                  checked={index == selectedSolicitationIndex}
                />
                {data ? (
                  <p style={{ minWidth: "191px" }}>{`${transformDateEndianness(
                    data.split(" ")[0].replaceAll("-", "/")
                  )} - ${hora} ${dayPeriod(solicitation.hora)}`}</p>
                ) : (
                  <p className="italic">Pendente preenchimento</p>
                )}

                {procedimentoPrincipal ? (
                  <p
                    style={{
                      marginLeft: "30px",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >{`${tuss ? tuss + " - " : ""}  ${procedimentoPrincipal}`}</p>
                ) : (
                  <p className="italic" style={{ marginLeft: "30px" }}>
                    Pendente preenchimento
                  </p>
                )}
              </S.ListItem>
            );
          })}
        </S.List>
      </S.BoxModal>

      <S.BtnSendWrap alingItems={"center"} margin={"0 24px 0 0"}>
        <TmpButton type="secondary" size="sm" handleClick={onBack}>
          Voltar
        </TmpButton>
        <TmpButton
          type="primary"
          size="sm"
          disabled={selectedSolicitationIndex === null}
          loading={loading}
          handleClick={async () => {
            const id = solicitations[selectedSolicitationIndex].idSolicitacao;
            if (id) {
              getSolicitationData(id);
            }
          }}
        >
          Seguir com essa
        </TmpButton>
      </S.BtnSendWrap>
    </Modal>
  );
};

export const ChoiceSolicitationModal = ({
  doctorName,
  solicitations,
  clearSolicitations,
  onClickReturnToHome,
}) => {
  const [open, setOpen] = useState(true);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  function validarCampos(objeto) {
    const camposValidos = {};
    for (const campo in objeto) {
      const valor = objeto[campo];
      if (valor !== null && valor !== "") {
        camposValidos[campo] = valor;
      }
    }
    return camposValidos;
  }

  const getSolicitationData = async (id) => {
    let newSolicitation;

    setLoading(true);
    await apiSolicitations()
      .getSingle(id)
      .then((res) => {
        newSolicitation = {
          solicitacao: solicitation.solicitacao,
          paciente: patient,
          procedimento: procedures,
          material: materials,
          relatorio: reports,
        };
        newSolicitation = {
          ...newSolicitation,
          solicitacao: res?.solicitacao,
          anamnese: {
            idAnamnese:
              res?.solicitacao?.id_anamnese ?? solicitation?.id_anamnese,
            status_anamnse:
              res?.solicitacao?.status_anamnese ?? solicitation?.status_anamnse,
          },
        };
        const cdConvenio = res?.paciente?.cdConvenio;
        if (cdConvenio === "N/A" || cdConvenio === "" || !cdConvenio) {
          res.paciente.cdConvenio = "";
          res.paciente.convenio = "";
        }

        const cdCategoria = res?.paciente?.cdCategoria;
        if (cdCategoria === "N/A" || cdCategoria === "" || !cdCategoria) {
          res.paciente.cdCategoria = "";
          res.paciente.categoria = "";
        }

        const cdPlano = res?.paciente?.cdPlano;
        if (cdPlano === "N/A" || cdPlano === "" || !cdPlano) {
          res.paciente.cdPlano = "";
          res.paciente.plano = "";
        }

        const cdProcedimentoPrincipal =
          res?.procedimento?.cdProcedimentoPrincipal;
        if (
          cdProcedimentoPrincipal === "N/A" ||
          cdProcedimentoPrincipal === "" ||
          !cdProcedimentoPrincipal
        ) {
          res.procedimento.cdProcedimentoPrincipal = "";
          res.procedimento.procedimentoPrincipal = "";
        }

        if (res?.paciente?.idPaciente)
          newSolicitation = {
            ...newSolicitation,
            paciente: {
              ...res?.paciente,
              sexo: res?.paciente?.sexo === "F" ? "Feminino" : "Masculino",
              dataNascimento: transformDateEndianness(
                moment(res?.paciente?.dataNascimento)
                  .tz("Europe/London")
                  .format("YYYY-MM-DD")
              ),
              documento:
                res?.paciente?.cpf === "" ? null : res?.paciente?.paciente?.cpf,
              nacionalidade: "Brasileira",
              tipoDocumento: res?.paciente?.tipoDocumento,
              cpf: res?.paciente?.cpf === "" ? null : res?.paciente?.cpf,
              convenio: res?.paciente?.convenio ?? "",
              categoria: res?.paciente?.categoria ?? "",
              plano: res?.paciente?.plano ?? "",
            },
          };
        if (res?.procedimento?.idProcedimento) {
          newSolicitation = {
            ...newSolicitation,
            procedimento: {
              ...newSolicitation.procedimento,
              idProcedimento: res?.procedimento?.idProcedimento,
              cobranca: res?.procedimento?.cobranca,
              local: res?.procedimento?.local,
              cdEstabelecimento: res?.procedimento?.cdEstabelecimento,
              diarias: res?.procedimento?.diarias ?? "",
            },
          };

          newSolicitation = {
            ...newSolicitation,
            procedimento: {
              ...newSolicitation.procedimento,
              ...validarCampos(res.procedimento),
              diarias: res?.procedimento?.diarias ?? "",
            },
          };

          if (res?.procedimento?.procedimentoPrincipal) {
            newSolicitation = {
              ...newSolicitation,
              procedimento: {
                ...newSolicitation.procedimento,
                ...res?.procedimento,
                radioHsl: false,
                radioExt: false,
                equipeCirurgicaAdicional:
                  !!res?.procedimento?.equipe.length &&
                  res?.procedimento?.equipe?.nomeCirurgiao !== ""
                    ? "Sim"
                    : "Não",
                procedimentosExtras: ensureArray(
                  res.procedimento.procedimentosExtras
                ),
                diarias: res?.procedimento?.diarias ?? "",
              },
            };
          }
        }
        newSolicitation = {
          ...newSolicitation,
          material: {
            ...res?.material,
            materialEquipamentos: ensureArray(
              res.material.materialEquipamentos
            ),
            materialInstrumentos: ensureArray(
              res.material.materialInstrumentos
            ),
            materialMedicacao: ensureArray(res.material.materialMedicacao),
            materialOPME: ensureArray(res.material.materialOPME),
          },
        };
        if (res?.relatorio?.idRelatorio)
          newSolicitation = {
            ...newSolicitation,
            relatorio: {
              ...res?.relatorio,
              relatorioDocumento: res?.relatorioDocumento,
            },
          };
        dispatch(setSolicitationObject(newSolicitation));
        clearSolicitations(newSolicitation);
        setLoading(false);
        setOpen(false);
        return res;
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        return {};
      });
  };

  const [selectedModal, setModal] = useState("info");

  return !open ? null : selectedModal === "info" ? (
    <InfoModal
      {...{
        open,
        doctorName,
        loading,
        onAdvance: (id) => {
          if (id) {
            getSolicitationData(id);
          } else {
            setModal("choice");
          }
        },
        solicitations,
        onClickReturnToHome,
      }}
    />
  ) : (
    <ChoiceModal
      {...{
        open,
        solicitations,
        loading,
        getSolicitationData,
        onBack: () => setModal("info"),
      }}
    />
  );
};
