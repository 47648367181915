export const reports = {
  idRelatorio: null,
  observacoes: "",
  carteirinha: "",
  carteirinhaUUID: "",
  documentoPaciente: "",
  documentoPacienteUUID: "",
  laudo: "",
  laudoUUID: "",
  termoConsentimento: "",
  termoConsentimentoUUID: "",
  solicitacaoInternacaoUUID: "",
  relatorioDocumento: [],
  idRelatorioDocumento: null,
};
