import styled from "styled-components";

export const Container = styled.div`
  width: calc(100% - 16px);
  height: 16px;
  background-color: rgba(204, 204, 204, 1);
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin: 9px 8px;
`;
export const Loading = styled.div`
  width: 50px;
  height: 20px;
  background: rgba(204, 204, 204, 1);
  background: linear-gradient(
    90deg,
    rgba(204, 204, 204, 1) 0%,
    rgba(235, 235, 235, 1) 48%,
    rgba(204, 204, 204, 1) 100%
  );
  position: absolute;
  border-radius: 2px;

  animation: 1s linear infinite alternate loading;

  @keyframes loading {
    0% {
      left: 0;
    }
    50% {
      left: 50%;
    }
    100% {
      left: 90%;
    }
  }
`;
