import React from "react";
import * as S from "./style";
import ImgMobile from "../../assets/images/mobile-msg.png";

const MsgMobile = () => {
  return (
    <S.MsgMobile>
      <img
        className="ilustration"
        src={ImgMobile}
        alt="Mulher olhando por um binóculo"
      />
      <h2 className="subtitle">
        Detectamos que você está acessando pelo celular...
      </h2>
      <p className="text">
        Acesse o portal de agendamento em computador ou tablet.
      </p>
    </S.MsgMobile>
  );
};

export default MsgMobile;
