import React, { useEffect, useReducer, useRef, useState } from "react";
import * as S from "./style";
import { useNavigate } from "react-router-dom";
import {
  setClearSolicitation,
  setSolicitationObject,
  setSolicitationProcedureProperty,
  setSolicitationReportProperty,
} from "../../features/solicitation/solicitationSlice";
import { patient } from "../../features/patient/patient";
import { procedures } from "../../features/procedures/procedures";
import { materials } from "../../features/materials/materials";
import { reports } from "../../features/reports/reports";
import { solicitation } from "../../features/solicitation/solicitation";
import { setClearPatient } from "../../features/patient/patientSlice";
import {
  setBasicInfoError,
  setEditingModel,
  setInfoPatient,
  setInvalidPhone,
  setIsNewPatient,
  setOpenGoToHomeModal,
  setRegisterSuccess,
} from "../../features/settings/settingsSlice";
import { setClearProcedures } from "../../features/procedures/proceduresSlice";
import { setClearMaterials } from "../../features/materials/materialsSlice";
import { setClearReports } from "../../features/reports/reportsSlice";
import { isError, setFreeFields } from "../../features/settings/settingsSlice";
import { useSelector, useDispatch } from "react-redux";
import NewModal from "../../components/NewModal";
import { StyledLoadingTermo } from "./style";
import {
  TmpInputSearch,
  TmpButton,
  TmpAlert,
  TmpMenuOverflow,
  TmpLoading,
  TmpButtonIcon,
  TmpIcon,
} from "@tempo/tempo-design-system-core/dist";
import Anamnese from "../../components/Anamnese";
import DropdownRadio from "../../components/DropdownRadio";
import Paginator from "../../components/Pagination";
import ConsentForm from "../../components/ConsentForm";
import {
  isSuccess,
  isSuccessAnam,
  isSuccessConsent,
  setCurrentStep,
  setOpenAnamnese,
} from "../../features/settings/settingsSlice";
import {
  mais as IconMais,
  baixar as IconBaixar,
  termos_de_uso as TermosSVG,
  prescricao_guia as PrescricaoSVG,
  dependentes as DependenteeSVG,
  excluir as IconExcluir,
  cancelar as IconCancelar,
} from "@tempo/tempo-assets/dist";
import {
  apiSolicitations,
  apiProcedure,
  apiNotifications,
  apiUpdateNotifications,
  apiDeleteSolicitation,
  apiGetStatus,
} from "../../services/api";
import { transformDateEndianness, getDaysToProcedure } from "../../utils";
import moment from "moment-timezone";
import SolicitationPDF from "../../features/Documents/Solicitation";
import { pdf } from "@react-pdf/renderer";
import { setClearModel } from "../../features/models/modelsSlice";
import { RequestFailed } from "../../components/RequestFailed";
import TableList from "./tableList";
import { TABLE_WIDTH } from "./constants";
import { ensureArray } from "../../utils/Validation";
import { sendGAPageView } from "../../utils/ga";
import DrawerUnification from "../../components/DrawerConfirmUnification";
import ModalUnifiedSolicitation from "../../components/ModalUnifiedSolicitation";
import Alert from "../../components/Alert";
import { H1 } from "../../components/ConsentForm/style";
import NotHaveSolicitations from "../../components/NotHaveSolicitations";
import NotFound from "../../components/NotFound";
import Filter from "../../components/Filter";
import Status from "../../components/Status";
import debounce from "lodash.debounce";

const notificationReducer = (state, action) => {
  switch (action.type) {
    case "set-notifications":
      var openModal = [];
      action.payload.map((not, index) => {
        openModal.push({
          isOpen: index === 0,
          idNotificacao: not.id_notificacao,
        });
      });
      return {
        ...state,
        notifications: action.payload,
        openModal: [...openModal],
      };
    case "update-modal":
      var newOpenModal = state.openModal.filter(
        (a) => a.idNotificacao !== action.payload
      );
      if (newOpenModal.length) {
        newOpenModal[0].isOpen = true;
      }
      return { ...state, openModal: [...newOpenModal] };
    case "add-answer":
      return { ...state, answer: action.payload };
    case "conclude-answer":
      return { ...state, answer: null };
    case "set-confirm":
      return { ...state, confirm: action.payload };
    case "set-loading":
      return { ...state, loading: action.payload };
    case "reset-loading":
      return { ...state, loading: { primary: false, secondary: false } };
    default:
      return state;
  }
};

const orderingReducer = (state, action) => {
  switch (action.type) {
    case "set-ordering":
      return {
        property: action.payload.property,
        asc: action.payload.asc,
      };
    default:
      return state;
  }
};

let listStatus = [];
const Solicitations = () => {
  const { cdPessoaFisica } = useSelector((state) => state.auth.userInfos);
  const {
    openAnamnese,
    success,
    successAnam,
    successConsent,
    registerSuccess,
  } = useSelector((state) => state.settings);
  const isSecretary = useSelector((state) => state.auth.isSecretary);
  const [failedListRequest, setFailedListRequest] = useState(false);
  const currentSolicitation = useSelector((state) => state.solicitation);
  const [consentFormOpen, setConsentFormOpen] = useState(false);
  const [solicitationList, setSolicitationList] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [reload, setReload] = useState(false);
  const dispatch = useDispatch();
  const [loadingTermo, setLoadingTermo] = useState(false);
  const [errorLoadTermo, setErrorLoadTermo] = useState(false);
  const [currentTermoStatus, setCurrentTermoStatus] = useState("");
  const [excludeSolicitation, setExcludeSolicitation] = useState({
    isOpen: false,
    idSolicitation: null,
    sucess: false,
    loading: false,
  });
  const [listFilter, setListFilter] = useState([]);

  const [pagination, setPagination] = useState({
    offset: 1,
    limit: 30,
    totalItems: 1,
    totalPages: 1,
  });

  const navigate = useNavigate();

  const [order, dispatchOrder] = useReducer(orderingReducer, {
    property: "data",
    asc: "",
  });

  const [notifications, dispatchNotifications] = useReducer(
    notificationReducer,
    {
      notifications: [],
      confirm: [],
      answer: null,
      openModal: [],
      loading: { primary: false, secondary: false },
    }
  );

  const filterResponse = (array) => {
    const notificationsList = array.filter(
      (objeto) => objeto.tipo_notificacao === "Convite"
    );
    const confirmList = array.filter(
      (objeto) =>
        objeto.tipo_notificacao === "Resposta" ||
        objeto.tipo_notificacao === "Cancelamento"
    );
    dispatchNotifications({
      type: "set-notifications",
      payload: notificationsList,
    });
    dispatchNotifications({
      type: "set-confirm",
      payload: confirmList,
    });
  };

  const handleAnswerNotification = async (idNotificacao, answer) => {
    dispatchNotifications({
      type: "set-loading",
      payload: {
        primary: answer,
        secondary: !answer,
      },
    });

    const notification = notifications.notifications.filter(
      (notification) => notification.id_notificacao === idNotificacao
    )[0];

    const response = await apiUpdateNotifications().put({
      idSolicitacao: notification.id_solicitacao_destinatario,
      idSolicitacaoPrincipal: notification.id_solicitacao_remetente,
      idMedico: Number(cdPessoaFisica),
      idNotificacao: notification.id_notificacao,
      tipoNotificacao: notification.tipo_notificacao,
      equipeConfirmada: answer,
    });

    if (response.status === 200) {
      dispatchNotifications({
        type: "update-modal",
        payload: idNotificacao,
      });

      dispatchNotifications({
        type: "add-answer",
        payload: answer,
      });

      if (answer) {
        SolicitationListRequest(
          true,
          search,
          order,
          listFilter.filter((item) => item.selected).map((item) => item.label),
          pagination.limit,
          pagination.offset
        );
      }
    }

    dispatchNotifications({
      type: "reset-loading",
    });
  };

  const handlePdfDownload = async (solicitation) => {
    setLoading(false);
    const newPdf = <SolicitationPDF solicitation={solicitation} />;
    const blob = await pdf(newPdf).toBlob();
    const link = document.createElement("a");

    link.download = "Solicitação";
    link.href = URL.createObjectURL(blob);
    link.click();
    link.remove();
    return null;
  };

  useEffect(() => {
    dispatch(setIsNewPatient(false));
    dispatch(setClearPatient());
    dispatch(setClearModel());
    dispatch(setEditingModel(false));
    dispatch(setBasicInfoError(false));
    dispatch(setOpenGoToHomeModal({ value: false }));
    dispatch(setInvalidPhone(false));

    sendGAPageView("Minhas Solicitacoes");
  }, []);

  const SolicitationListRequest = async (
    loadingList,
    search,
    order,
    filters,
    limit,
    offset
  ) => {
    const filter = filters.join(",");
    const ascendete = order.asc === "" || order.asc === "desc" ? false : true;

    let params = {
      name: search,
      status: filter,
      asc: ascendete,
      sortBy: order.property,
      offset: offset,
      limit: limit,
    };

    if (cdPessoaFisica) {
      try {
        setLoadingList(loadingList);

        const fetchSolicitations = async () => {
          try {
            const res = await apiSolicitations(
              cdPessoaFisica,
              params
            ).getList();

            setSolicitationList(res.items);
            setPagination({
              ...pagination,
              totalItems: res?.totalItems,
              offset: res?.currentPage,
              totalPages: res?.totalPages,
              limit: res?.perPage,
            });
            if (Array.isArray(res?.items)) {
              const responseNotifications = await apiNotifications(
                cdPessoaFisica
              ).getList();
              if (responseNotifications.length > 0) {
                filterResponse(responseNotifications);
              }
            } else {
              setFailedListRequest(true);
            }
          } catch (err) {
            console.log(err);
            setFailedListRequest(true);
          } finally {
            setLoadingList(false);
          }
        };

        await fetchSolicitations();
      } catch (err) {
        console.log(err);
        setLoadingList(false);
        setFailedListRequest(true);
      }
    }
  };
  useEffect(() => {
    const initialOffset = 1;
    SolicitationListRequest(
      true,
      search,
      order,
      listFilter.filter((item) => item.selected).map((item) => item.label),
      pagination.limit,
      initialOffset
    );
    const intervalId = setInterval(
      () => SolicitationListRequest(false),
      180000
    );

    return () => clearInterval(intervalId);
  }, [cdPessoaFisica, order]);

  useEffect(() => {
    const fetchStatus = async () => {
      const Status = await apiGetStatus().getList();
      const formattedStatusList = Status.map((item) => ({
        label: item,
        selected: false,
      }));

      formattedStatusList.sort((a, b) => a.label.localeCompare(b.label));
      setListFilter(formattedStatusList);
      listStatus = formattedStatusList;
    };

    fetchStatus();
  }, []);

  const handleCheckBox = (value) => {
    const initialOffset = 1;
    const selectedFilters = value
      .filter((item) => item.selected)
      .map((item) => item.label);
    setListFilter(value);
    SolicitationListRequest(
      true,
      search,
      order,
      selectedFilters,
      pagination.limit,
      initialOffset
    );
  };

  const searchDebounce = useRef(
    debounce((search, order, listFilter, pagination) => {
      SolicitationListRequest(
        true,
        search,
        order,
        listFilter.filter((item) => item.selected).map((item) => item.label),
        pagination.limit,
        pagination.offset
      );
    }, 1000)
  ).current;

  useEffect(() => {
    searchDebounce(search, order, listFilter, pagination);
  }, [search]);

  const handleSearch = (e) => {
    const search = e.target.value;
    setLoadingList(true);
    setSearch(search);
    searchDebounce(search);
  };

  const handleCleanSearch = () => {
    const cleanSearch = "";
    setLoadingList(true);
    setSearch("");
    SolicitationListRequest(
      true,
      cleanSearch,
      order,
      listFilter.filter((item) => item.selected).map((item) => item.label),
      pagination.limit,
      pagination.offset
    );
  };

  const handleClick = () => {
    dispatch(isError(false));
    dispatch(setFreeFields(false));
    dispatch(setClearPatient());
    dispatch(setClearProcedures());
    dispatch(setClearMaterials());
    dispatch(setClearReports());
    dispatch(setClearSolicitation());
    dispatch(setCurrentStep(1));
    dispatch(setInfoPatient(null));
    handleResetFilter();
    const path = window.location.pathname;
    navigate("/modelos", {
      state: { from: path, fromScreen: "Inicial" },
    });
  };

  const handleResetFilter = () => {
    const initialOffset = 1;
    const selectedFilters = [];
    listFilter.forEach((item) => (item.selected = false));
    setListFilter(listStatus);
    if (listFilter.length > 0) {
      SolicitationListRequest(
        true,
        search,
        order,
        selectedFilters,
        pagination.limit,
        initialOffset
      );
    }
  };

  const handleReturnNew = () => {
    return <S.StyledNew>novo!</S.StyledNew>;
  };

  const getAnamneseStatus = (status) => {
    if (status === "Indisponível") return null;
    if (status === "Pendente")
      return <S.Status className="ballStatus" bg="#FF8A00"></S.Status>;
    return <S.Status className="ballStatus" bg="#78CE7E"></S.Status>;
  };

  const removeEmptyFilter = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([, v]) => v != null && v !== "")
    );
  };

  function validarCampos(objeto) {
    const camposValidos = {};
    for (const campo in objeto) {
      const valor = objeto[campo];
      if (valor !== null && valor !== "") {
        camposValidos[campo] = valor;
      }
    }
    return camposValidos;
  }

  const getSolicitationData = async (id, anamnese, Nextfunction, isPDF) => {
    let newSolicitation;

    let getPdf = isPDF ? `${id}?isPDF=${isPDF}` : id;
    const data = await apiSolicitations()
      .getSingle(getPdf)
      .then((res) => {
        newSolicitation = {
          solicitacao: solicitation.solicitacao,
          paciente: patient,
          procedimento: procedures,
          material: materials,
          relatorio: reports,
        };
        newSolicitation = {
          ...newSolicitation,
          solicitacao: res?.solicitacao,
          anamnese: { ...anamnese },
        };
        const cdConvenio = res?.paciente?.cdConvenio;
        if (cdConvenio === "N/A" || cdConvenio === "" || !cdConvenio) {
          res.paciente.cdConvenio = "";
          res.paciente.convenio = "";
        }

        const cdCategoria = res?.paciente?.cdCategoria;
        if (cdCategoria === "N/A" || cdCategoria === "" || !cdCategoria) {
          res.paciente.cdCategoria = "";
          res.paciente.categoria = "";
        }

        const cdPlano = res?.paciente?.cdPlano;
        if (cdPlano === "N/A" || cdPlano === "" || !cdPlano) {
          res.paciente.cdPlano = "";
          res.paciente.plano = "";
        }

        const cdProcedimentoPrincipal =
          res?.procedimento?.cdProcedimentoPrincipal;
        if (
          cdProcedimentoPrincipal === "N/A" ||
          cdProcedimentoPrincipal === "" ||
          !cdProcedimentoPrincipal
        ) {
          res.procedimento.cdProcedimentoPrincipal = "";
          res.procedimento.procedimentoPrincipal = "";
        }

        if (res?.paciente?.idPaciente)
          newSolicitation = {
            ...newSolicitation,
            paciente: {
              ...res?.paciente,
              sexo: res?.paciente?.sexo === "F" ? "Feminino" : "Masculino",
              dataNascimento: transformDateEndianness(
                moment(res?.paciente?.dataNascimento)
                  .tz("Europe/London")
                  .format("YYYY-MM-DD")
              ),
              documento:
                res?.paciente?.cpf === "" ? null : res?.paciente?.paciente?.cpf,
              nacionalidade: "Brasileira",
              tipoDocumento: res?.paciente?.tipoDocumento,
              cpf: res?.paciente?.cpf === "" ? null : res?.paciente?.cpf,
              convenio: res?.paciente?.convenio ?? "",
              categoria: res?.paciente?.categoria ?? "",
              plano: res?.paciente?.plano ?? "",
            },
          };
        if (res?.procedimento?.idProcedimento) {
          newSolicitation = {
            ...newSolicitation,
            procedimento: {
              ...newSolicitation.procedimento,
              idProcedimento: res?.procedimento?.idProcedimento,
              cobranca: res?.procedimento?.cobranca,
              local: res?.procedimento?.local,
              cdEstabelecimento: res?.procedimento?.cdEstabelecimento,
              diarias: res?.procedimento?.diarias ?? "",
              tempoCirurgia: res?.procedimento?.tempoCirurgia ?? "",
              observacoes: res?.procedimento?.observacoes ?? "",
            },
          };

          newSolicitation = {
            ...newSolicitation,
            procedimento: {
              ...newSolicitation.procedimento,
              ...validarCampos(res.procedimento),
            },
          };

          if (res?.procedimento?.procedimentoPrincipal) {
            newSolicitation = {
              ...newSolicitation,
              procedimento: {
                ...newSolicitation.procedimento,
                ...res?.procedimento,
                radioHsl: false,
                radioExt: false,
                equipeCirurgicaAdicional:
                  !!res?.procedimento?.equipe.length &&
                  res?.procedimento?.equipe?.nomeCirurgiao !== ""
                    ? "Sim"
                    : "Não",
                procedimentosExtras: ensureArray(
                  res.procedimento.procedimentosExtras
                ),
              },
            };
          }
        }
        newSolicitation = {
          ...newSolicitation,
          procedimento: {
            ...newSolicitation.procedimento,
            diarias: newSolicitation.procedimento?.diarias ?? "",
            tempoCirurgia: newSolicitation?.procedimento?.tempoCirurgia ?? "",
            observacoes: newSolicitation?.procedimento?.observacoes ?? "",
          },
          material: {
            ...res?.material,
            materialEquipamentos: ensureArray(
              res.material.materialEquipamentos
            ),
            materialInstrumentos: ensureArray(
              res.material.materialInstrumentos
            ),
            materialMedicacao: ensureArray(res.material.materialMedicacao),
            materialOPME: ensureArray(res.material.materialOPME),
          },
        };
        if (res?.relatorio?.idRelatorio)
          newSolicitation = {
            ...newSolicitation,
            relatorio: {
              ...res?.relatorio,
              relatorioDocumento: res?.relatorioDocumento,
            },
          };
        dispatch(setSolicitationObject(newSolicitation));
        setLoading(false);
        if (!Nextfunction) {
          handleNavigate(newSolicitation, res?.solicitacao?.wasOpened);
        }
        return res;
      })
      .catch((err) => {
        console.log(err);
        if (Nextfunction === "consent") {
          setErrorLoadTermo(true);
        }
        setLoading(false);
        return {};
      })
      .finally(() => setLoading(false));

    if (
      data?.procedimento?.idProcedimento &&
      data?.procedimento?.procedimentoPrincipal
    ) {
      setLoading(true);
      try {
        await apiProcedure(data?.procedimento?.idSequencia, "nr_seq")
          .getList()
          .then((response) => {
            dispatch(
              setSolicitationProcedureProperty({
                value: removeEmptyFilter(response),
                prop: "procedimentoPrincipal",
              })
            );

            newSolicitation = {
              ...newSolicitation,
              procedimento: {
                ...newSolicitation.procedimento,
                diarias: newSolicitation.procedimento?.diarias ?? "",
                tempoCirurgia:
                  newSolicitation?.procedimento?.tempoCirurgia ?? "",
                observacoes: newSolicitation?.procedimento?.observacoes ?? "",
                procedimentoPrincipal: removeEmptyFilter(response),
              },
            };
          });
      } catch (err) {
        console.log(err);
        if (Nextfunction === "consent") {
          setErrorLoadTermo(true);
        }
        setLoading(false);
      }

      if (data?.procedimento?.procedimentosExtras.length) {
        const arr = await data.procedimento?.procedimentosExtras.map(
          async (item) => {
            return await apiProcedure(item.idSequencia, "nr_seq")
              .getList()
              .then((resProc) => {
                return { ...item, ...removeEmptyFilter(resProc) };
              })
              .finally(() => setLoading(false));
          }
        );

        await Promise.all(arr).then((values) => {
          dispatch(
            setSolicitationProcedureProperty({
              value: values,
              prop: "procedimentosExtras",
            })
          );

          newSolicitation = {
            ...newSolicitation,
            procedimento: {
              ...newSolicitation.procedimento,
              diarias: newSolicitation.procedimento?.diarias ?? "",
              tempoCirurgia: newSolicitation?.procedimento?.tempoCirurgia ?? "",
              observacoes: newSolicitation?.procedimento?.observacoes ?? "",
              procedimentosExtras: values,
            },
          };
        });
      }
    }

    if (Nextfunction === "anamnese") {
      dispatch(setOpenAnamnese(true));
      setLoading(false);
    } else if (Nextfunction === "consent") {
      setConsentFormOpen(true);
      setLoadingTermo(false);
    } else if (Nextfunction === "download") handlePdfDownload(newSolicitation);
  };

  const handleOpenAnamnese = (e, solicitation) => {
    e.stopPropagation();
    const anamnese = {
      idAnamnese: solicitation?.id_anamnese,
      status_anamnese: solicitation?.status_anamnese,
    };
    dispatch(
      setSolicitationObject({
        anamnese: {
          ...currentSolicitation?.anamnese,
          ...anamnese,
        },
      })
    );
    dispatch(
      setSolicitationObject({
        solicitacao: {
          ...currentSolicitation?.solicitacao,
          idSolicitacao: solicitation?.id_solicitacao,
        },
      })
    );
    setLoading(true);
    getSolicitationData(solicitation.id_solicitacao, anamnese, "anamnese");
  };

  const handleOpenTerm = (e, solicitation) => {
    e.stopPropagation();
    const anamnese = {
      idAnamnese: solicitation?.id_anamnese,
      status_anamnese: solicitation?.status_anamnese,
    };
    setLoading(true);
    getSolicitationData(solicitation.id_solicitacao, anamnese, "consent");
  };

  const handleDownload = (e, solicitation, isPDF) => {
    e.stopPropagation();
    const anamnese = {
      idAnamnese: solicitation?.id_anamnese,
      status_anamnese: solicitation?.status_anamnese,
    };
    setLoading(true);
    getSolicitationData(
      solicitation.id_solicitacao,
      anamnese,
      "download",
      isPDF
    );
    return;
  };

  const handleInformations = (e, solicitation) => {
    e.stopPropagation();
    setCurrentTermoStatus(solicitation?.status_termo_consentimento);
    if (
      e.target.localName === "span" ||
      e.target.id === "tmp-button-icon" ||
      e.target.localName === "ul" ||
      e.target.localName === "li"
    )
      return null;

    const anamnese = {
      idAnamnese: solicitation?.id_anamnese,
      status_anamnese: solicitation?.status_anamnese,
    };

    if (
      e.target.className === "anamnese" &&
      solicitation.status_anamnese !== "Indisponível"
    ) {
      dispatch(
        setSolicitationObject({
          anamnese: {
            ...currentSolicitation?.anamnese,
            ...anamnese,
          },
        })
      );
      dispatch(
        setSolicitationObject({
          solicitacao: {
            ...currentSolicitation?.solicitacao,
            idSolicitacao: solicitation?.id_solicitacao,
          },
        })
      );

      getSolicitationData(solicitation.id_solicitacao, anamnese, "anamnese");
    }
    if (e.target.className?.baseVal === "download") {
      getSolicitationData(solicitation.id_solicitacao, anamnese, "download");
      return;
    }
    getSolicitationData(solicitation.id_solicitacao, anamnese, null);
    setLoading(true);
  };

  const sendSolicitationToDelete = async (id) => {
    try {
      await apiDeleteSolicitation().patch({
        idSolicitacao: id,
      });
    } catch (error) {
      console.log("Deletar Solicitação", error);
    } finally {
      setExcludeSolicitation({
        sucess: true,
        isOpen: false,
        idSolicitation: null,
        loading: false,
      });
      const initialOffset = 1;
      SolicitationListRequest(
        true,
        search,
        order,
        listFilter.filter((item) => item.selected).map((item) => item.label),
        pagination.limit,
        initialOffset
      );
    }
  };

  const handleNavigate = (newSolicitation, wasOpened) => {
    dispatch(setCurrentStep(1));
    const path = window.location.pathname;
    handleResetFilter();
    navigate(!wasOpened ? "/modelos" : "/agendamento", {
      state: {
        from: path,
        fromScreen: "Inicial",
        noRenderCreateModel: true,
        formData: newSolicitation,
      },
    });
  };

  useEffect(() => {
    setSolicitationList((prevList) => [...prevList, order]);
  }, [order]);

  const FeedBackTableSolicitations = () => {
    if (loadingList === true) {
      return (
        <S.StyledLoadingTable>
          <TmpLoading label="Carregando..." />
        </S.StyledLoadingTable>
      );
    } else {
      if (solicitationList?.length === 0) {
        if (search.length === 0) {
          return <NotHaveSolicitations />;
        }
        if (search.length !== 0) {
          return <NotFound />;
        }
        if (failedListRequest === true) {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                minHeight: "49vh",
              }}
            >
              <RequestFailed.Root>
                <RequestFailed.Image />
                <RequestFailed.Content
                  title="Falha ao carregar"
                  content={`Pedimos desculpas pelo ocorrido.
              Estamos trabalhando para que ele retorne o mais breve possível.`}
                />
                <RequestFailed.Action
                  handleClick={() => {
                    setReload(true);
                    SolicitationListRequest(false);
                  }}
                  titleButton="Recarregar"
                  loading={reload}
                />
              </RequestFailed.Root>
            </div>
          );
        }
      }
      return <></>;
    }
  };

  const setOrder = (property) => {
    dispatchOrder({
      type: "set-ordering",
      payload: {
        property,
        asc: order.asc === "cresc" ? "desc" : "cresc",
      },
    });
  };

  const handlePagination = (page = 1, limit = 30) => {
    const offset = limit != pagination.limit ? 1 : page;
    setPagination({
      ...pagination,
      offset: offset,
      limit: limit,
    });
    SolicitationListRequest(
      true,
      search,
      order,
      listFilter.filter((item) => item.selected).map((item) => item.label),
      limit,
      offset
    );
  };

  useEffect(() => {
    handleResetFilter();
  }, []);

  return (
    <S.Container>
      {loadingTermo && errorLoadTermo === false ? (
        <NewModal isOpened={true} labelPrimary={""} removeBtn>
          <StyledLoadingTermo height={"450px"} top={"0vh"}>
            <TmpLoading label="Carregando..." />
          </StyledLoadingTermo>
        </NewModal>
      ) : loading ? (
        <S.StyledLoading>
          <TmpLoading label="Carregando..." />
        </S.StyledLoading>
      ) : null}
      <div
        style={{
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <h2 style={{ fontWeight: "800", padding: "20px 0" }}>
          Minhas solicitações
        </h2>
        {success === true ? (
          <S.StyledAlert widthP="210px" isOpened={success}>
            <TmpAlert
              id="form-alert"
              isOpened={success}
              type="success"
              title="Sucesso!"
              message={
                isSecretary
                  ? "Solicitação preenchida, pendente de envio por parte do médico."
                  : "Sua solicitação de agendamento foi enviada."
              }
              handleClose={() => {
                dispatch(isSuccess(false));
              }}
            />
          </S.StyledAlert>
        ) : null}

        {excludeSolicitation.sucess ? (
          <S.StyledAlert widthP="210px" isOpened={excludeSolicitation.sucess}>
            <TmpAlert
              id="form-alert"
              isOpened={excludeSolicitation.sucess}
              type="success"
              title="Sucesso!"
              message={
                "A solicitação com preenchimento pendente foi apagada com sucesso!"
              }
              handleClose={() => {
                setExcludeSolicitation({
                  ...excludeSolicitation,
                  sucess: false,
                  loading: false,
                });
              }}
            />
          </S.StyledAlert>
        ) : null}

        {registerSuccess ? (
          <S.StyledAlert widthP="210px" isOpened={true}>
            <TmpAlert
              id="form-alert"
              isOpened={true}
              type="success"
              title="Sucesso!"
              message={
                isSecretary
                  ? "Seu pedido de alterações foi registrado. Pendente de envio por parte do médico."
                  : "Suas alterações foram registradas."
              }
              handleClose={() => {
                dispatch(setRegisterSuccess(false));
              }}
            />
          </S.StyledAlert>
        ) : null}
        {successAnam === true ? (
          <S.StyledAlert isOpened={successAnam}>
            <TmpAlert
              id="form-alert"
              isOpened={successAnam}
              type="success"
              title="Sucesso!"
              message="A anamnese foi anexada à solicitação."
              handleClose={() => {
                dispatch(isSuccessAnam(false));
              }}
            />
          </S.StyledAlert>
        ) : null}

        {successConsent === true ? (
          <S.StyledAlert isOpened={successConsent}>
            <TmpAlert
              id="form-alert"
              isOpened={successConsent}
              type="success"
              title="Sucesso!"
              message="O Termo de Consentimento foi anexadado à solicitação."
              handleClose={() => {
                dispatch(isSuccessConsent(false));
              }}
            />
          </S.StyledAlert>
        ) : null}
        {errorLoadTermo === true ? (
          <S.StyledAlert widthTitle="200px" widthP="210px" isOpened={true}>
            <TmpAlert
              id="form-alert"
              isOpened={true}
              type="error"
              title="Falha ao abrir o Termo de Consentimento"
              message="Tente novamente"
              handleClose={() => {
                setErrorLoadTermo(false);
                setConsentFormOpen(false);
              }}
            />
          </S.StyledAlert>
        ) : null}
      </div>
      <S.ContainerFilter>
        <S.BoxSearch>
          <S.WapperSearch>
            <TmpInputSearch
              placeholder="Buscar pelo nome, CPF ou outro documento do paciente"
              value={search}
              handleChange={(e) => handleSearch(e)}
              handleClear={() => handleCleanSearch()}
            />
          </S.WapperSearch>
          <S.NewAdd>
            <S.WapperFilter>
              {listStatus.length ? (
                <Filter
                  label="Filtrar"
                  type="checkbox"
                  disabled={false}
                  buttonGroupLabelPrimary="Aplicar filtro"
                  buttonGroupLabelSecondary="Limpar filtro"
                  handleClickPrimary={(value) => handleCheckBox(value)}
                  handleClickSecondary={handleResetFilter}
                  filterItems={[...listFilter]}
                />
              ) : (
                <Filter label="Filtrar" disabled={true} />
              )}
            </S.WapperFilter>
            <S.WrapperBtn renderSize={"all"}>
              <TmpButton
                startIcon={<IconMais />}
                handleClick={handleClick}
                size="lg"
                disabled={!cdPessoaFisica}
              >
                Novo&nbsp;Agendamento
              </TmpButton>
            </S.WrapperBtn>
          </S.NewAdd>
        </S.BoxSearch>
      </S.ContainerFilter>

      <S.ContainerQuantityPerPage>
        <div>
          {!loadingList && (
            <p
              style={{
                color: "#666666",
                fontSize: "14px",
              }}
            >
              Exibindo{" "}
              <b>
                {pagination.totalItems >= pagination.limit
                  ? pagination.limit
                  : pagination.totalItems}
              </b>{" "}
              de <b>{pagination.totalItems}</b>
            </p>
          )}
        </div>
        <div>
          <DropdownRadio
            borde
            className="mr-4"
            label={
              <S.ShowLimit>
                Conteúdo por página: <span>{pagination.limit}</span>
              </S.ShowLimit>
            }
            valueSelect={pagination.limit}
            totalItems={pagination.totalItems}
            listItems={[
              {
                label: "30 itens por página",
                value: 30,
                handleClick: () => handlePagination(pagination.offset, 30),
              },
              {
                label: "50 itens por página",
                value: 50,
                handleClick: () => handlePagination(pagination.offset, 50),
              },
              {
                label: "100 itens por página",
                value: 100,
                handleClick: () => handlePagination(pagination.offset, 100),
              },
            ]}
          />
        </div>
      </S.ContainerQuantityPerPage>

      <S.Table id="table">
        <TableList
          order={order?.asc}
          onClickOrderByAnamnese={() => setOrder("status_anamnese")}
          orderedByAnamnese={order?.property === "status_anamnese"}
          onClickOrderByName={() => setOrder("nome")}
          orderedByName={order?.property === "nome"}
          onClickOrderByDate={() => setOrder("data")}
          orderedByDate={order?.property === "data"}
          onClickOrderByStatus={() => setOrder("status_solicitacao")}
          orderedByStatus={order?.property === "status_solicitacao"}
          onClickOrderByTermo={() => setOrder("status_termo_consentimento")}
          orderedByTermo={order?.property === "status_termo_consentimento"}
        />
        {solicitationList.length > 0 && loadingList === false ? (
          <>
            <S.BoxCondition id={"table-cont"}>
              {solicitationList.map((item, index) => {
                const isDisabled = [
                  "Cirurgia realizada",
                  "Solicitação cancelada",
                ].includes(item.status_solicitacao);
                const solicitationDate =
                  item?.data !== null
                    ? new Date(
                        `${item?.data?.substring(0, 10)} ${item?.hora}:00`
                      )
                    : "";

                return (
                  <S.Div
                    id={"table-cont2"}
                    key={index}
                    background={
                      item.status_solicitacao === "Pendente Preenchimento"
                    }
                  >
                    <S.BoxMap
                      id={"cell"}
                      style={{ position: "relative" }}
                      key={index}
                      isDisabled={isDisabled}
                      onClick={
                        !isDisabled
                          ? (e) => handleInformations(e, item)
                          : undefined
                      }
                    >
                      <S.BoxInfoMap
                        width={TABLE_WIDTH.name.desktop}
                        widthRes="46%"
                        widthCel="46%"
                        displayRes={"flex"}
                        flexDirectionRes={"column"}
                      >
                        <div className="name-container">
                          <S.Pname>{item?.nome}</S.Pname>
                          {item.solicitacao_unificada && (
                            <S.IconContainer>
                              <TmpIcon
                                icon={
                                  <DependenteeSVG
                                    style={{ opacity: 0.32, height: "24px" }}
                                    color="#000000"
                                  />
                                }
                              />
                              <S.IconTooltip className={"tooltip"}>
                                Cirurgia com mais de uma equipe
                                <div className="background"></div>
                              </S.IconTooltip>
                            </S.IconContainer>
                          )}
                          {item?.status_solicitacao ===
                          "Aguardando preenchimento"
                            ? handleReturnNew()
                            : null}
                        </div>
                        <S.StyledDate>
                          {solicitationDate ? (
                            <>
                              <p>
                                {item?.data !== null
                                  ? solicitationDate.toLocaleDateString("pt-br")
                                  : null}
                              </p>
                              <p>
                                {item?.hora !== null
                                  ? `${solicitationDate.toLocaleTimeString(
                                      "pt-BR",
                                      {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                      }
                                    )}`
                                  : null}
                              </p>
                            </>
                          ) : (
                            <p style={{ color: "#666666" }}>
                              Sem data selecionada
                            </p>
                          )}
                        </S.StyledDate>
                      </S.BoxInfoMap>
                      <S.BoxDateHourData
                        flexDirection={"column"}
                        alignItems={"flex-start"}
                        width={TABLE_WIDTH.date.desktop}
                        disabled={isDisabled}
                      >
                        {solicitationDate ? (
                          <>
                            <p>
                              {item?.data !== null
                                ? solicitationDate.toLocaleDateString("pt-br")
                                : null}
                            </p>
                            <p>
                              {item?.hora !== null
                                ? `${solicitationDate.toLocaleTimeString(
                                    "pt-BR",
                                    {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    }
                                  )}`
                                : null}
                            </p>
                          </>
                        ) : (
                          <p style={{ color: "#666666" }}>
                            Sem data selecionada
                          </p>
                        )}
                      </S.BoxDateHourData>
                      <S.BoxInfoMap width={"20%"}>
                        <S.StyledStatus>
                          <Status urgente={item?.urgencia}>
                            {item?.status_solicitacao}
                          </Status>
                        </S.StyledStatus>
                      </S.BoxInfoMap>
                      <S.BoxInfoMap
                        width={TABLE_WIDTH.anamnese.desktop}
                        margin="0 5px 0 0"
                        displayRes="none"
                        disabled={
                          item?.data === null ||
                          getDaysToProcedure(item?.data) >= 60 ||
                          isDisabled
                        }
                      >
                        {item?.data === null ||
                        getDaysToProcedure(item?.data) >= 60 ? (
                          <PrescricaoSVG
                            style={{
                              width: "22px",
                              height: "22px",
                              paddingRight: "5px",
                              color: "rgba(163, 163, 163, 1)",
                            }}
                          />
                        ) : (
                          <PrescricaoSVG
                            style={{
                              width: "22px",
                              height: "22px",
                              paddingRight: "5px",
                            }}
                          />
                        )}
                        {getAnamneseStatus(
                          getDaysToProcedure(item?.data) >= 60 ||
                            item.status_anamnese
                        )}
                        <p
                          onClick={
                            !isDisabled &&
                            item.status_anamnese !== "Indisponível"
                              ? (e) => {
                                  e.stopPropagation();
                                  handleOpenAnamnese(e, item);
                                }
                              : undefined
                          }
                          style={{ cursor: isDisabled ? "default" : "pointer" }}
                          className="anamnese"
                        >
                          {item.status_anamnese}
                        </p>
                      </S.BoxInfoMap>
                      <S.BoxInfoMap
                        width={TABLE_WIDTH.consentForm.desktop}
                        displayRes="none"
                        disabled={
                          item?.data === null ||
                          getDaysToProcedure(item?.data) >= 60 ||
                          isDisabled
                        }
                      >
                        {item?.data === null ||
                        getDaysToProcedure(item?.data) >= 60 ? (
                          <TermosSVG
                            style={{
                              width: "22px",
                              height: "22px",
                              paddingRight: "5px",
                              position: "relative",
                              color: "rgba(163, 163, 163, 1)",
                            }}
                          />
                        ) : (
                          <TermosSVG
                            style={{
                              width: "22px",
                              height: "22px",
                              paddingRight: "5px",
                              position: "relative",
                            }}
                          />
                        )}
                        {getAnamneseStatus(
                          getDaysToProcedure(item?.data) >= 60 ||
                            item.status_termo_consentimento
                        )}
                        <p
                          onClick={
                            !isDisabled &&
                            item.status_termo_consentimento !== "Indisponível"
                              ? (e) => {
                                  e.stopPropagation();
                                  setLoadingTermo(true);
                                  handleOpenTerm(e, item);
                                }
                              : undefined
                          }
                          style={{ cursor: isDisabled ? "default" : "pointer" }}
                          className="anamnese"
                        >
                          {item.status_termo_consentimento || "Pendente"}
                        </p>
                      </S.BoxInfoMap>
                      <S.StyledDonwload
                        width={TABLE_WIDTH.download.desktop}
                        className="download"
                        disabled={isDisabled}
                        disabledListItems={
                          typeof item?.id_relatorio !== "number"
                        }
                      >
                        <TmpMenuOverflow
                          title="D"
                          closeOnClickOutside={true}
                          className="download"
                          listItems={
                            item?.status_solicitacao === "Pendente"
                              ? [
                                  {
                                    handleClick:
                                      typeof item?.id_relatorio !== "number" ||
                                      isDisabled
                                        ? (e) => {
                                            e.stopPropagation();
                                          }
                                        : (e) => handleDownload(e, item, true),
                                    label: "Baixar solicitação",
                                    leadingIcon: (
                                      <IconBaixar
                                        className="download"
                                        style={{
                                          width: "22px",
                                          height: "22px",
                                          paddingRight: "5px",
                                        }}
                                      />
                                    ),
                                  },
                                  {
                                    handleClick: (e) => {
                                      e.stopPropagation();
                                      setExcludeSolicitation({
                                        isOpen: true,
                                        idSolicitation: item?.id_solicitacao,
                                        sucess: false,
                                      });
                                    },
                                    label: "Apagar solicitação pendente",
                                    leadingIcon: (
                                      <IconExcluir
                                        className="download"
                                        style={{
                                          width: "22px",
                                          height: "22px",
                                          paddingRight: "5px",
                                          position: "relative",
                                        }}
                                      />
                                    ),
                                  },
                                ]
                              : [
                                  {
                                    handleClick:
                                      typeof item?.id_relatorio !== "number" ||
                                      isDisabled
                                        ? () => {}
                                        : (e) => handleDownload(e, item, true),
                                    label: "Baixar solicitação",
                                    leadingIcon: (
                                      <IconBaixar
                                        className="download"
                                        style={{
                                          width: "22px",
                                          height: "22px",
                                          paddingRight: "5px",
                                        }}
                                      />
                                    ),
                                  },
                                ]
                          }
                        />
                      </S.StyledDonwload>
                    </S.BoxMap>
                    <S.MenuOverFlow
                      className="overflow"
                      disabled={isDisabled}
                      disabledListItems={typeof item?.id_relatorio !== "number"}
                      disableAnamnese={
                        isDisabled || item.status_anamnese === "Indisponível"
                      }
                      disableTermoConsentimento={
                        isDisabled ||
                        item.status_termo_consentimento === "Indisponível"
                      }
                    >
                      <TmpMenuOverflow
                        title="Documentos"
                        closeOnClickOutside={true}
                        listItems={
                          item?.status_solicitacao === "Pendente"
                            ? [
                                {
                                  handleClick:
                                    !isDisabled ||
                                    item.status_anamnese !== "Indisponível"
                                      ? (e) => handleOpenAnamnese(e, item)
                                      : () => {},
                                  label: "Anamnese Exame Físico",
                                  leadingIcon: (
                                    <PrescricaoSVG
                                      style={{
                                        width: "22px",
                                        height: "22px",
                                        paddingRight: "5px",
                                      }}
                                    />
                                  ),
                                },
                                {
                                  handleClick:
                                    !isDisabled ||
                                    item.status_solicitacao !== "Indisponível"
                                      ? (e) => handleOpenTerm(e, item)
                                      : () => {},
                                  label: "Termo de Consentimento",
                                  leadingIcon: (
                                    <TermosSVG
                                      style={{
                                        width: "22px",
                                        height: "22px",
                                        paddingRight: "5px",
                                        position: "relative",
                                      }}
                                    />
                                  ),
                                },
                                {
                                  handleClick: !isDisabled
                                    ? (e) => handleDownload(e, item, true)
                                    : () => {},
                                  label: "Baixar Solicitação",
                                  leadingIcon: (
                                    <IconBaixar
                                      className="download"
                                      style={{
                                        width: "25px",
                                        height: "25px",
                                        cursor: "pointer",
                                        margin: "0 auto",
                                      }}
                                    />
                                  ),
                                },
                                {
                                  handleClick: (e) => {
                                    e.stopPropagation();
                                    setExcludeSolicitation({
                                      isOpen: true,
                                      idSolicitation: item?.id_solicitacao,
                                      sucess: false,
                                    });
                                  },
                                  label: "Apagar solicitação pendente",
                                  leadingIcon: (
                                    <IconExcluir
                                      className="download"
                                      style={{
                                        width: "22px",
                                        height: "22px",
                                        paddingRight: "5px",
                                        position: "relative",
                                      }}
                                    />
                                  ),
                                },
                              ]
                            : [
                                {
                                  handleClick:
                                    !isDisabled &&
                                    item.status_anamnese !== "Indisponível"
                                      ? (e) => handleOpenAnamnese(e, item)
                                      : () => {},
                                  label: "Anamnese Exame Físico",
                                  leadingIcon: (
                                    <PrescricaoSVG
                                      style={{
                                        width: "22px",
                                        height: "22px",
                                        paddingRight: "5px",
                                      }}
                                    />
                                  ),
                                },
                                {
                                  handleClick: !isDisabled
                                    ? (e) => handleOpenTerm(e, item)
                                    : () => {},
                                  label: "Termo de Consentimento",
                                  leadingIcon: (
                                    <TermosSVG
                                      style={{
                                        width: "22px",
                                        height: "22px",
                                        paddingRight: "5px",
                                        position: "relative",
                                      }}
                                    />
                                  ),
                                },
                                {
                                  handleClick: !isDisabled
                                    ? (e) => handleDownload(e, item, true)
                                    : () => {},
                                  label: "Baixar Solicitação",
                                  leadingIcon: (
                                    <IconBaixar
                                      className="download"
                                      style={{
                                        width: "25px",
                                        height: "25px",
                                        cursor: "pointer",
                                        margin: "0 auto",
                                      }}
                                    />
                                  ),
                                },
                              ]
                        }
                      />
                    </S.MenuOverFlow>
                  </S.Div>
                );
              })}
              {pagination.totalPages > 1 && (
                <div
                  style={{
                    width: "100%",
                    height: "60px",
                    padding: "8px 0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderTop: "1px solid #00000020",
                  }}
                >
                  <Paginator
                    className="mt-40"
                    currentPage={pagination.offset}
                    totalItems={pagination.totalItems}
                    itemsPerPage={pagination.limit}
                    onPageChange={(offset) =>
                      handlePagination(offset, pagination.limit)
                    }
                  />
                </div>
              )}
            </S.BoxCondition>
            <div
              style={{
                height: "1px",
                backgroundColor: "rgba(0, 0, 0, 0.08)",
                width: "100%",
              }}
            ></div>
          </>
        ) : (
          <FeedBackTableSolicitations />
        )}
      </S.Table>
      {openAnamnese ? <Anamnese /> : null}
      {consentFormOpen && errorLoadTermo === false ? (
        <ConsentForm
          handleClose={() => {
            setLoading(false);
            setConsentFormOpen(false);
            if (
              currentSolicitation?.relatorio?.termoConsentimento !== "" &&
              currentTermoStatus === "Pendente"
            )
              dispatch(isSuccessConsent(true));
          }}
          reports={reports}
          errorLoad={errorLoadTermo}
          dataChange={(val) =>
            dispatch(
              setSolicitationReportProperty({
                prop: val.target.name,
                value: val.target.value,
              })
            )
          }
        />
      ) : null}

      {excludeSolicitation.isOpen ? (
        <NewModal
          isOpened={true}
          labelPrimary={""}
          width={"499px"}
          maxHeight={"350px"}
          height={"350px"}
          minHeight="350px"
          removeBtn
        >
          <S.ContainerModal>
            <TmpButtonIcon
              icon={<IconCancelar />}
              size="lg"
              handleClick={() =>
                setExcludeSolicitation({
                  isOpen: false,
                  idSolicitation: null,
                  sucess: false,
                  loading: false,
                })
              }
            />
            <S.BoxTitle>
              <H1 size="24px" align="center" marginLeft="0">
                Tem certeza que deseja apagar a solicitação com preenchimento
                pendente?
              </H1>
            </S.BoxTitle>
            <S.TextModal>Essa ação não poderá ser desfeita.</S.TextModal>
            <S.BoxButtons width={excludeSolicitation.loading}>
              <TmpButton
                size={"lg"}
                handleClick={() =>
                  setExcludeSolicitation({
                    isOpen: false,
                    idSolicitation: null,
                    sucess: false,
                    loading: false,
                  })
                }
                loading={loading}
                type="secondary"
              >
                Não, pensarei melhor
              </TmpButton>
              <TmpButton
                size={"lg"}
                handleClick={() => {
                  sendSolicitationToDelete(excludeSolicitation.idSolicitation);
                  setExcludeSolicitation({
                    ...excludeSolicitation,
                    loading: true,
                  });
                }}
                loading={excludeSolicitation.loading}
              >
                Sim, tenho certeza
              </TmpButton>
            </S.BoxButtons>
          </S.ContainerModal>
        </NewModal>
      ) : null}

      {notifications.openModal.length === 0 &&
        notifications?.confirm.length > 0 &&
        notifications?.confirm.map((notification) => {
          const notificationInfo = notification;

          const handleAnswer = async () => {
            await apiUpdateNotifications().put({
              idSolicitacao: notification?.id_solicitacao_destinatario,
              idSolicitacaoPrincipal: notification?.id_solicitacao_remetente,
              idMedico: Number(cdPessoaFisica),
              idNotificacao: notification?.id_notificacao,
              equipeConfirmada: notification?.dados_notificacao.resposta
                ? notification?.dados_notificacao?.resposta === "Recusado"
                  ? false
                  : true
                : false,
              tipoNotificacao: notification?.tipo_notificacao,
            });

            const confirmList = notifications.confirm.filter(
              (c) => c.id_notificacao !== notificationInfo.id_notificacao
            );
            dispatchNotifications({
              type: "set-confirm",
              payload: confirmList,
            });
          };

          return (
            <DrawerUnification
              key={notificationInfo?.id_notificacao}
              open={notificationInfo?.status_notificacao === "Não Visualizado"}
              obj={notificationInfo?.dados_notificacao}
              confirmed={notificationInfo?.dados_notificacao?.resposta}
              tipoNotificacao={notificationInfo?.tipo_notificacao}
              handleButtonClickPrimary={() => handleAnswer()}
            />
          );
        })}

      {notifications.notifications.length > 0 &&
        notifications.openModal.map((modalInfo) => {
          const notification = notifications.notifications.find(
            (a) => a.id_notificacao === modalInfo.idNotificacao
          );
          const notificationInfo = notification.dados_notificacao;

          const onConfirm = () => {
            handleAnswerNotification(
              notifications.openModal.find((a) => a?.isOpen)?.idNotificacao,
              true
            );
          };

          const onDecline = () => {
            handleAnswerNotification(
              notifications.openModal.find((a) => a?.isOpen)?.idNotificacao,
              false
            );
          };

          return (
            <ModalUnifiedSolicitation
              key={modalInfo.idNotificacao}
              mainDoctor={notificationInfo.nome_medico}
              patientName={notificationInfo.nome_paciente}
              patientBirthday={notificationInfo.data_nascimento}
              surgeryDate={notificationInfo.data_cirurgia}
              surgeryTime={notificationInfo.hora_cirurgia}
              mainProcedure={notificationInfo.procedimento_principal}
              onConfirm={onConfirm}
              onDecline={onDecline}
              isOpened={notifications?.answer === null && modalInfo?.isOpen}
              loading={notifications.loading}
            />
          );
        })}

      {notifications?.answer !== null && (
        <S.ContainerAlert declined={notifications?.answer}>
          <Alert
            type={notifications?.answer ? "success" : "error"}
            title={
              notifications?.answer
                ? "Sua equipe foi adicionada à cirurgia"
                : "Convite declinado"
            }
            message={
              <p>
                {notifications?.answer
                  ? "Selecione o agendamento na lista e finalize o seu preenchimento"
                  : "Caso tenha recusado por engano, verifique com a equipe titular ou agende pela Central"}
              </p>
            }
            isOpened
            bgColor={notifications?.answer ? "#D2EFD4" : "#FFE3C2"}
            handleClose={() =>
              dispatchNotifications({
                type: "conclude-answer",
              })
            }
          />
        </S.ContainerAlert>
      )}
    </S.Container>
  );
};

export default Solicitations;
