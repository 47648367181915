import styled, { css } from "styled-components";

export const StyledUploader = styled.div`
  width: 100%;
  max-width: 724.43px;

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.3;
    pointer-events: none;
  `}

  > div > div {
    border: 1px solid ${(props) => (props.error ? "#EA1F1F" : "#292929")};
    border-radius: 8px;
    margin-top: 8px 0px;
  }

  > div > div > div {
    width: 0px;
    height: 0px;
    visibility: hidden;
  }

  > div,
  div > div {
    padding: 0px;

    .tmp-icon {
      width: 0px;
      visibility: hidden;
      margin: 0px;
    }
  }

  > div > div > label {
    border: none;
    height: 56px;
    display: flex;
    flex-direction: row-reverse;
    padding: 0px 9px;
    justify-content: flex-end;
    align-items: center;
    background: ${(props) => (props.error ? "#FDC4C4" : "white")};

    .tmp-typography,
    .tmp-icon,
    .tmp-uploader__labelOr {
      padding-bottom: 0px;
    }

    .tmp-uploader__labelOr {
      width: 0px;
      height: 0px;
      visibility: hidden;
    }

    .tmp-typography {
      font-family: "Inter";
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #292929;
      height: min-content;
      text-transform: lowercase;
    }

    .tmp-link {
      font-family: "Inter";
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      text-decoration-line: underline;
      color: #2c6ef2;
      margin: 0px;
      height: min-content;
      margin-right: 8px;

      &:after {
        visibility: hidden;
      }
    }

    >span: first-child {
      width: 0px;
      visibility: hidden;
    }

    > a {
      background: #f5f5f5;
      padding: 12px 29px;
      border: 1px dashed #666666;
      border-radius: 8px;
    }

    > input:hover:after {
      width: 0px;
    }
  }

  @media (max-width: 1200px) {
    max-width: 100%;
  }
`;

export const StyledFile = styled.div`
  width: 100%;
  max-width: 724.43px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 9px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #292929;
  position: relative;
  border: 1px solid #cccccc;
  border-radius: 8px;
  box-sizing: border-box;

  ${({ processing }) =>
    processing &&
    css`
      padding: 0;
      border: none;
    `}

  @media (max-width:1200px) {
    max-width: 100%;
  }
`;

export const LabelAndButton = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: calc(100% - ${({ size }) => size});

  > p {
    width: 100%;
    margin-left: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tmp-button-icon {
    position: relative !important;
  }
`;

export const WrapperProgress = styled.div`
  position: absolute;
  top: 1vh;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #ffff;

  @media (max-width: 767px) {
    width: 90vw;
    height: 10vh;
  }
`;

export const DownlaodBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  /* width: 70px; */
  z-index: 3;
  border: none;
  background-color: transparent;
`;

export const RemoveBtn = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  position: relative;

  span {
    display: block;
    position: absolute;
    width: 48px;
    height: 48px;
    left: 50%;
    top: 50%;
    transform: translate(-49%, -53%);
    border-radius: 50%;
    background: #c5d7fba3;
    transition: all 0.125s linear;
    opacity: 0;
  }

  :hover {
    span {
      opacity: 1;
    }
  }

  > svg {
    color: #ea1f1f;
    width: 26.67px;
    height: 26.67px;
    margin: auto 18px;

    /* @media screen and (max-width: 1365px) and (min-width: 768px) {
    margin:32px 32px 0 0px;
    }
    @media (max-width:767px) {
      margin:32px 24px 0 0px;
    } */
  }
`;
