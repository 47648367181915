import styled from "styled-components";

export const BoxTextareaNotCount = styled.div`
  div {
    min-height: auto;
    min-width: auto;
  }

  div > div {
    min-height: auto;
    min-width: auto;
  }

  div > div > textarea {
    min-height: auto;
    height: 100%;
    min-width: auto;
    resize: none;
  }

  span {
    visibility: hidden;
  }
`;

export const BoxTextarea = styled.div`
  div {
    min-height: auto;
    min-width: auto;
  }

  div > div {
    min-height: auto;
    min-width: auto;
  }

  div > div > textarea {
    min-height: auto;
    height: 100%;
    min-width: auto;
    resize: none;
  }
`;
