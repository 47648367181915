import styled from "styled-components";

import { TmpInput } from "@tempo/tempo-design-system-core";

export const Container = styled.section`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 1200px) and (min-width: 1024px) {
    label {
      font-size: 0.85rem;
    }
    div {
      font-size: 0.85rem;
    }
    input {
      font-size: 0.85rem;
    }
    button {
      font-size: 0.85rem;
    }
  }
`;

export const BoxProcedure = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > section {
    div {
      div {
        min-width: 100%;

        @media (max-width: 767px) {
          width: ${({ widthmediasm }) => widthmediasm};
          margin-top: ${({ marginmediasm }) => marginmediasm};
          min-width: 100%;
        }

        @media screen and (max-width: 1023px) and (min-width: 768px) {
          width: ${({ widthmediamd }) => widthmediamd};
          min-width: auto;
          margin: ${({ marginmediamd }) => marginmediamd};
        }

        @media screen and (max-width: 1365px) and (min-width: 1024px) {
          width: ${({ widthmedialg }) => widthmedialg};
          margin: ${({ marginmedialg }) => marginmedialg};
        }

        @media (min-width: 1365px) {
          width: ${({ widthmedia }) => widthmedia};
          margin: ${({ marginmedia }) => marginmedia};
        }
      }
    }
  }
`;

export const OthersProcedures = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /*  */
`;

export const BoxMap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: 1023px) and (min-width: 768px) {
    justify-content: left;
  }
`;

export const IconWrapper = styled.div`
  margin: 0 40px 0 30px;
  display: flex;
  align-items: center;
`;

export const BoxMapAddNewExtraProcedure = styled.div`
  display: grid;
  gap: 24px;
  width: 100%;
  grid-template-columns: 2fr 1fr;

  @media (max-width: 1365px) {
    grid-template-columns: 1fr;
    grid-gap: 40px;
    padding-right: 24px;
  }

  > div {
    display: flex;
    flex: 1;

    @media (max-width: 576px) {
      margin: 0px;
    }
  }

  .tabFocus {
    flex: 1;
    width: 100%;
  }
`;

export const DisplayBox = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  flex-direction: row;
  margin: ${({ margin }) => margin};

  .checkboxes {
    display: flex;
    justify-content: center;
    label {
      /* margin-left: 30px; */
    }

    @media (max-width: 767px) {
      /* margin: 10px 0;
      justify-content: left; */
    }
  }
`;

export const WrapperAgend = styled.div`
  display: flex;
  width: 90vw;

  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }
`;

export const InputWrapper = styled.div`
  max-height: 88px;

  @media (min-width: 1366px) {
    > div {
      margin-right: ${(props) => props.marginRightLG};
    }
  }

  @media (max-width: 1365px) {
    padding: ${({ paddingMd }) => paddingMd};
  }
`;

export const InputText = styled(TmpInput)`
  width: ${({ width }) => width};
  min-width: auto;
  position: relative;
  box-sizing: border-box;

  > div {
    div {
      min-width: auto;
      position: relative;
      background-color: #fff;
    }
  }
  input {
    width: 100%;
  }

  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
`;

export const BoxCRM = styled.div`
  max-height: 88px;

  > div {
    margin-right: 24px;
  }
`;

export const BoxSelect = styled.div`
  box-sizing: border-box;
  @media (max-width: 1365px) {
    padding: ${({ paddingMd }) => paddingMd};
  }

  > div {
    max-height: 88px;
    width: ${({ width }) => width};
    min-width: auto;
    margin: ${({ margin }) => margin};
  }

  @media (min-width: 1366px) {
    > div {
      margin-right: ${(props) => props.marginRightLG};
    }
  }

  svg {
    color: #000;
  }

  button {
    width: 100%;
    min-width: auto;
    color: ${(props) => (props.placeholderColor ? "#000" : "#a3a3a3")};
  }

  @media (max-width: 767px) {
    width: ${({ widthmediasm }) => widthmediasm};
    margin: ${({ marginmediasm }) => marginmediasm};
    min-width: auto;
  }

  @media screen and (max-width: 1023px) and (min-width: 768px) {
    width: ${({ widthmediamd }) => widthmediamd};
    min-width: auto;
    margin: ${({ marginmediamd }) => marginmediamd};
  }

  @media screen and (max-width: 1365px) and (min-width: 1024px) {
    width: ${({ widthmedialg }) => widthmedialg};
    margin: ${({ marginmedialg }) => marginmedialg};
  }

  @media (min-width: 1365px) {
    width: ${({ widthmedia }) => widthmedia};
    margin: ${({ marginmedia }) => marginmedia};
  }

  width: 100%;
`;
export const BoxTeam = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  > section {
    div {
      div {
        min-width: 92%;

        @media (max-width: 767px) {
          width: ${({ widthmediasm }) => widthmediasm};
          margin-top: ${({ marginmediasm }) => marginmediasm};
          min-width: 100%;
        }

        @media screen and (max-width: 1023px) and (min-width: 768px) {
          width: ${({ widthmediamd }) => widthmediamd};
          min-width: 100%;
          margin: ${({ marginmediamd }) => marginmediamd};
        }

        @media screen and (max-width: 1365px) and (min-width: 1024px) {
          width: ${({ widthmedialg }) => widthmedialg};
          margin: ${({ marginmedialg }) => marginmedialg};
        }

        @media (min-width: 1365px) {
          width: ${({ widthmedia }) => widthmedia};
          margin: ${({ marginmedia }) => marginmedia};
        }
      }
    }
  }
`;
export const BoxServices = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  > div {
    width: ${({ width }) => width};
    min-width: auto;
    margin: ${({ margin }) => margin};
  }

  button {
    width: 100%;
    min-width: auto;
    color: red;
  }

  @media (max-width: 767px) {
    width: ${({ widthmediasm }) => widthmediasm};
    margin: ${({ marginmediasm }) => marginmediasm};
    min-width: auto;
  }

  @media screen and (max-width: 1023px) and (min-width: 768px) {
    width: ${({ widthmediamd }) => widthmediamd};
    min-width: auto;
    margin: ${({ marginmediamd }) => marginmediamd};
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 24px;
  border-bottom: 1px solid #e0e0e0;
  height: 100%;

  span {
    line-height: normal;
  }
  .checkboxes {
    @media (min-width: 1366px) and (max-width: 1435px) {
      margin-top: 30px;
    }
    margin-left: 30px;
    > label:first-child {
      margin-right: 15px;
    }
  }
`;

export const WrapperCheckbox = styled.div`
  width: 100%;
  > label {
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    flex-wrap: wrap;
    width: auto;
  }

  span {
    height: 5vh;
    font-size: 15px;
  }
`;
export const P = styled.p`
  color: #292929;
  margin: 32px 0 22px 0;
`;

export const WrapperInput = styled.div`
  width: 30vw;
`;

export const Helper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 15px;
  gap: 4px;
  color: #3d3d3d;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 133%;
`;

export const BoxData = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  > div {
    width: ${({ width }) => width};
    min-width: auto;
    margin: ${({ margin }) => margin};
  }

  button {
    width: 100%;
    min-width: auto;
  }

  @media (max-width: 767px) {
    width: ${({ widthmediasm }) => widthmediasm};
    margin: ${({ marginmediasm }) => marginmediasm};
    min-width: auto;
  }
`;
export const BoxCheckbox = styled.div`
  display: flex;
  margin: 56px 0px 0px 0;
  @media (max-width: 1365px) {
    margin: 0;
  }

  span {
    line-height: normal;
  }
  > label {
    @media (min-width: 1024px) {
    }
  }
  @media (max-width: 767px) {
  }
`;
export const BoxTextarea = styled.div`
  position: relative;
  /* display: grid;
  grid-template-columns: 25px 1fr; */

  ${(props) =>
    props.editingModel &&
    `
    opacity: 0.3;
    pointer-events: none;
  `}
  > div {
    width: 100%;
  }
`;
export const InputTextData = styled(TmpInput)`
  width: ${({ width }) => width};
  min-width: auto;
  position: relative;

  input {
    padding: ${({ padding }) => padding};
  }

  ::-webkit-clear-button {
    display: none;
  }

  @media (max-width: 767px) {
    width: ${({ widthmediasm }) => widthmediasm};
    margin: ${({ marginmediasm }) => marginmediasm};
    min-width: auto;
  }

  @media screen and (max-width: 1023px) and (min-width: 768px) {
    width: ${({ widthmediamd }) => widthmediamd};
    min-width: auto;
    margin: ${({ marginmediamd }) => marginmediamd};
  }

  @media screen and (max-width: 1365px) and (min-width: 1024px) {
    width: ${({ widthmedialg }) => widthmedialg};
    margin: ${({ marginmedialg }) => marginmedialg};
  }

  @media (min-width: 1365px) {
    width: ${({ widthmedia }) => widthmedia};
    margin: ${({ marginmedia }) => marginmedia};
  }

  > div {
    div {
      min-width: auto;
      position: relative;
    }
  }
  input {
    width: 100%;
  }
  > span {
    span {
      span {
        svg {
          width: 24px;
          height: 24px;
          position: absolute;
          right: 16px;
          bottom: 44px;
        }
      }
    }
  }
`;
export const BoxIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};

  @media screen and (max-width: 1365px) and (min-width: 1024px) {
    width: ${({ widthmedialg }) => widthmedialg};
    margin: ${({ marginmedialg }) => marginmedialg};
  }
`;
export const AlertBox = styled.div`
  width: 90vw;
  display: flex;
  align-items: center;
  margin: 30px 0 64px 0;
`;
export const textAlert = styled.p`
  padding-left: 5px;
`;

export const TimeList = styled.div`
  border: 1px solid rgba(224, 224, 224, 1);
  overflow-y: ${(props) => (props.editingModel ? "hidden" : "scroll")};
  height: 443px;
  border-radius: 8px;
  width: 100%;
  text-align: center;
  opacity: ${(props) => (props.editingModel ? "0.3" : "1")};

  p {
    margin: 0px;
  }

  .time-row {
    height: 126px;
    display: flex;
    text-align: center;
    position: relative;
  }

  .hour {
    width: 130px;
    padding-top: 24px;
    font-weight: 500;
    font-size: 16px;
    color: #292929;
  }

  .no-hour-response {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-weight: 400;
      font-size: 16px;
      color: #707070;
    }

    .tmp-button-group {
      margin: 0 auto;
    }

    .surgery-time-info-box {
      display: flex;
      justify-content: center;
      margin-bottom: 56px;
      gap: 8px;
      line-height: 16px;

      > a {
        /* margin-left: 8px; */
      }
    }

    .surgery-time-info {
      font-size: 16px;
      color: #292929;
    }

    .surgery-time-info-error {
      font-size: 16px;
      color: #e93535;
    }
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    width: 8px;
    background: #cecece;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;

export const TimeDescriptionRow = styled.div`
  height: 100%;
  width: 100%;
  position: relative;

  > div {
    height: 50%;
    border: 1px solid rgba(224, 224, 224, 1);
    border-top: none;
    border-right: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.backgroundColor};
  }

  .selected-hour {
    position: absolute;
    display: inline-block;
    max-height: 100%;
    right: 51.3%;
    transform: translate(50%, 9%);
    width: 95%;
    z-index: 5;
    background: transparent;
    border: 0px;
  }

  .selected-float-infos {
    padding: 16px;
    background: #0054a4;
    z-index: 200;
    width: 98%;
    color: #0a3a99;
    font-weight: 700;
    font-size: 16px;
    text-align: left;

    p {
      margin-bottom: 5px;
    }

    span {
      font-weight: 400;
    }
  }

  .available-hour {
    &:hover {
      cursor: pointer;
      text-decoration-line: underline;
      color: #2c6ef2;
      background-color: #d9d9d9;
    }
  }

  .unavailable-hour {
    font-style: italic;
    color: #666666;
    background-color: #e0e0e0;
    border-top: 1px solid #cccccc;
  }

  .change-time-btn {
    cursor: pointer;
    zindex: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    background: #ffffff;
    border: 1px solid #2c6ef2;
    border-radius: 4px;
    width: 129px;
    height: 32px;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: #2c6ef2;
  }
`;
export const SelectedFloatInfos = styled.div`
  padding: 16px;
  background: #0054a4;
  z-index: 200;
  width: 95%;
  height: 28.5px;
  color: #0a3a99;
  font-weight: 700;
  font-size: 16px;
  text-align: left;

  p {
    margin-bottom: 5px;

    @media (max-width: 767px) {
      font-size: 15px;
      margin-bottom: 10px;
    }
  }

  span {
    font-weight: 400;
  }
`;

export const ScheduleDateInfos = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #0a3a99;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-self: flex-end;
  align-items: center;

  p {
    font-weight: 700;
    font-size: 1rem;
    align-items: center;
    display: flex;
    margin-bottom: 16px;

    span {
      font-weight: 400;
    }
  }
`;

export const Schedule = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  > div {
    margin-right: 24px;
    @media (max-width: 1365px) {
      width: 100%;
      margin-right: 0;
    }
  }

  > div > p {
    font-weight: 500;
    font-size: 16px;
    color: #292929;
  }

  .margin-bottom-16 {
    margin-bottom: 16px;
  }

  .wrapperTooltip {
    display: flex;
    flex-direction: row;
    font-weight: 500;
    font-size: 16px;
    color: #292929;
    column-gap: 5px;
    position: relative;

    @media screen and (max-width: 767px) {
      flex-direction: row;
      row-gap: 5px;
    }
  }

  @media screen and (max-width: 1365px) {
    flex-direction: column;
  }
`;

export const WrapperSurgeryCalendar = styled.div`
  width: 400px;
  height: 425px;
  display: flex;
  background-color: #f5f5f5;
  align-items: center;
  border-radius: 8px;
  > div > div:nth-child(2) {
    width: ${({ reload }) => reload && "40px"};
  }
`;

export const WrapperSwitch = styled.div`
  display: ${(props) => props.display};
  align-items: flex-start;
  /* width: 90vw; */

  margin: ${({ margin }) => margin};

  @media (min-width: 1366px) {
    display: ${({ display }) => display};
  }

  @media (max-width: 1365px) {
    display: ${({ displaymd }) => displaymd};
  }
`;

export const StyledDatePicker = styled.div`
  @media screen and (max-width: 1023px) and (min-width: 768px) {
    /* width: 90vw; */
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .react-datepicker__input-container {
    height: 0px;
    visibility: hidden;
    font-size: 0px;
  }

  .react-datepicker-wrapper {
    height: 0px;
    visibility: hidden;
    font-size: 0px;
  }

  .react-datepicker-wrapper {
    display: block;
    min-width: 200px;
  }

  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    color: #cccccc !important;
  }

  .react-datepicker__day[aria-disabled="false"] {
    color: ${(props) => props.colorEnabled};
  }

  .react-datepicker__day--selected {
    color: white !important;
    background-color: #2c6ef2 !important;
    border-radius: 50% !important;
  }

  .react-datepicker__day--highlighted {
    background-color: transparent;
    border-bottom: 2px solid #2c6ef2;
    border-radius: 0px;
  }

  .react-datepicker {
    border: 1px solid rgba(224, 224, 224, 1);
    box-shadow: none;
    min-height: 445px;

    ${(props) =>
      props.editingModel &&
      `
    opacity: 0.3;
    pointer-events: none;
    `}
  }

  .react-datepicker-popper {
    transform: none !important;
    position: relative !important;
    padding-top: 0px;
    inset: 0 !important;
  }

  .react-datepicker__tab-loop {
    margin: 0 0 16px;
    display: flex;
    justify-content: center;

    @media (max-width: 767px) {
      max-width: 90vw;
    }
    .tmp-button-icon {
      width: 50px;
      height: 50px;
    }

    .react-datepicker__current-month {
      h6 {
        font-size: 18px;
      }
    }
    .tmp-button-group {
      visibility: hidden;
      height: 0;
    }
    .react-datepicker-popper {
      @media (max-width: 400px) {
        max-width: 90vw;
      }
    }

    .react-datepicker__week {
      div {
        height: 35px;
        width: 20px;
      }
    }
  }
`;

export const Saving = styled.span`
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #2c6ef2;

  @media only screen and (min-width: 360px) {
    margin: 0px 24px;
  }
`;

export const Tooltip = styled.div`
  position: absolute;
  margin-left: 5vw;

  @media (max-width: 1015px) {
    margin-left: 8vw;
  }
  @media (max-width: 615px) {
    margin-left: 10vw;
  }
  @media (max-width: 425px) {
    margin-left: 14vw;
  }
  &:hover {
    > span {
      visibility: visible;
    }
  }
`;
export const TooltipeText = styled.span`
  visibility: hidden;
  width: 25vw;
  background-color: #000000;
  opacity: 0.8;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  text-align: justify;
  padding: 15px 15px 15px 15px;
  position: absolute;
  top: 3vh;
  z-index: 1;

  @media (max-width: 768px) {
    width: 50vw;
  }
`;

export const TooltipArea = styled.section`
  width: 2vw;
  position: absolute;
  left: 31vw;

  &:hover {
    > span {
      visibility: visible;
    }
  }
`;
export const TooltipTextArea = styled.div`
  margin-left: 5px;
  max-width: 100%;

  &:hover {
    > span {
      visibility: visible;
    }
  }
`;
export const TooltipeTextArea = styled.span`
  visibility: hidden;
  width: 200px;
  background-color: #000000;
  opacity: 0.8;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  text-align: justify;
  padding: 15px 15px 15px 15px;
  position: absolute;
  z-index: 30;
`;

export const OptionalFields = styled.div`
  width: 90vw;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
`;
