import { useEffect, useState } from "react";
import {
  seta_direcional_direita as ArrowToRight,
  seta_direcional_baixo as ArrowToDown,
  termos_de_uso as Terms,
  copiar as Copy,
  editar as Edit,
  excluir as Trash,
  termos_de_uso as PrescricaoSVG,
  alerta as Alerta,
} from "@tempo/tempo-assets/dist";
import {
  TmpList,
  TmpShape,
  TmpMenuOverflow,
  TmpIcon,
  TmpButton,
  TmpAlert,
  TmpLoading,
  TmpSpinner,
} from "@tempo/tempo-design-system-core/dist";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setClearPatient } from "../../features/patient/patientSlice";
import { setClearProcedures } from "../../features/procedures/proceduresSlice";
import { setClearMaterials } from "../../features/materials/materialsSlice";
import { setClearReports } from "../../features/reports/reportsSlice";
import {
  isError,
  setFreeFields,
  setEditingModel,
  setModelSave,
  setBasicInfoError,
  setSaveSolicitationInfoBeforeEditing,
  setCurrentStep,
} from "../../features/settings/settingsSlice";
import Modal from "../../components/Modal";
import * as S from "./style";
import {
  apiDuplicateModel,
  apiListModels,
  apiModels,
} from "../../services/api";

import { setModelObject } from "../../features/models/modelsSlice";
import { StyledAlert } from "./style";
import { model } from "../../features/models/model";
import { sortModels } from "../../utils";
import { ensureArray } from "../../utils/Validation";
import {
  setClearSolicitation,
  setSolicitationObject,
} from "../../features/solicitation/solicitationSlice";
import { sendGAPageView } from "../../utils/ga";
import ModalNameModel from "../../components/ModalNameModel";

const initialDuplicateModelState = {
  openModal: false,
  sourceModelName: "",
  idModel: null,
  loading: false,
};

const SelectModel = () => {
  const INTIALALERT = { status: false, type: "", message: "" };
  const [modelToDelete, setModelToDelete] = useState(undefined);
  const [alert, setAlert] = useState(INTIALALERT);
  const [selectDropdownIsOpen, setSelectDropdownIsOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modelsLoad, setModelsLoad] = useState(false);
  const [deletedLoad, setDeletedLoad] = useState(false);
  const { cdPessoaFisica } = useSelector((state) => state.auth.userInfos);
  const models = useSelector((state) => state.model);
  const { modelSave, saveSolicitationInfoBeforeEditing } = useSelector(
    (state) => state.settings
  );
  const currentSolicitation = useSelector((state) => state.solicitation);

  const [duplicateModel, setDuplicateModel] = useState(
    initialDuplicateModelState
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    sendGAPageView("Novo Agendamento");
  }, []);

  const renderArrowIcon = () => (
    <S.SVGWrapper>
      {modelsLoad ? (
        <TmpSpinner />
      ) : selectDropdownIsOpen ? (
        <ArrowToDown />
      ) : (
        <ArrowToRight />
      )}
    </S.SVGWrapper>
  );

  const clearInheritedStates = () => {
    dispatch(isError(false));
    dispatch(setFreeFields(false));
    dispatch(setClearPatient());
    dispatch(setClearProcedures());
    dispatch(setClearMaterials());
    dispatch(setClearReports());
    dispatch(setClearSolicitation());
    dispatch(setModelSave(false));
  };

  const handleClickNewScheduling = () => {
    if (
      currentSolicitation.solicitacao.wasOpened &&
      currentSolicitation.solicitacao.idSolicitacao === null
    ) {
      clearInheritedStates();
    } else {
      dispatch(isError(false));
      dispatch(setFreeFields(false));
    }

    const path = window.location.pathname;
    navigate("/agendamento", {
      state: { from: path, fromScreen: "Modelos" },
    });
  };

  const loadModelList = () => {
    dispatch(
      // limpar o id de modelo selecionado sempre que estiver nessa tela
      setModelObject({
        ...model,
        listModels: models.listModels,
      })
    );
    dispatch(setEditingModel(false));
    dispatch(setBasicInfoError(false));
    if (cdPessoaFisica) {
      setModelsLoad(true);
      apiListModels(cdPessoaFisica)
        .getList()
        .then((response) => {
          if (response?.length) {
            dispatch(
              setModelObject({
                listModels: response.sort((a, b) =>
                  sortModels(
                    a,
                    b,
                    currentSolicitation?.solicitacao?.origem === "Tasy",
                    currentSolicitation
                  )
                ),
              })
            );
          }
          setModelsLoad(false);
        })
        .catch((err) => {
          console.log(err);
          setModelsLoad(false);
        });
    }
  };

  useEffect(() => {
    loadModelList();
  }, []);

  const Alert = ({ alertOBJ, onClickClose }) => {
    const { type, message, title } = alertOBJ;
    switch (type) {
      case "error":
        return (
          <StyledAlert isOpened>
            <TmpAlert
              id="form-alert"
              isOpened
              type="error"
              title={title}
              message={message}
              handleClose={() => onClickClose()}
            />
          </StyledAlert>
        );

      case "success":
        return (
          <StyledAlert isOpened>
            <TmpAlert
              id="form-alert"
              isOpened
              type="success"
              title={title}
              message={message}
              handleClose={() => onClickClose()}
            />
          </StyledAlert>
        );
    }
  };

  const handleDeleteModel = async (idModelo) => {
    setDeletedLoad(true);
    if (cdPessoaFisica) {
      apiModels(cdPessoaFisica)
        .deleteSingle(idModelo)
        .then(() => {
          setDeletedLoad(false);
          const newModelArray = models.listModels.filter(
            (item) => item.idModelo !== idModelo
          );
          setAlert({
            status: true,
            type: "success",
            title: "Sucesso",
            message: "Modelo excluído",
          });
          dispatch(setModelObject({ listModels: newModelArray }));
          setShowDeleteModal(false);
          setModelToDelete(undefined);
        })
        .catch((err) => {
          console.log(err);
          setDeletedLoad(false);
          setAlert({
            status: true,
            type: "error",
            title: "Falha ao apagar modelo",
            message: "Tente novamente",
          });
          setShowDeleteModal(false);
          setModelToDelete(undefined);
        });
    }
  };

  const handleSelectModel = (idModel, isEditing = false) => {
    if (
      currentSolicitation.solicitacao.wasOpened &&
      currentSolicitation.solicitacao.idSolicitacao === null
    ) {
      clearInheritedStates();
    } else {
      dispatch(isError(false));
      dispatch(setFreeFields(false));
    }
    setLoading(true);
    apiModels(cdPessoaFisica)
      .getSingle(idModel)
      .then((response) => {
        setLoading(false);
        if (response?.idModelo && !response.length) {
          if (isEditing) {
            dispatch(setClearSolicitation());
            dispatch(setEditingModel(true));
            dispatch(setSaveSolicitationInfoBeforeEditing(currentSolicitation));
          }
          // tratando dados vindo fora do padrão esperado pelo front
          response.procedimento.procedimentosExtras = ensureArray(
            response.procedimento.procedimentosExtras
          );
          response.material.materialEquipamentos = ensureArray(
            response.material.materialEquipamentos
          );
          response.material.materialInstrumentos = ensureArray(
            response.material.materialInstrumentos
          );
          response.material.materialMedicacao = ensureArray(
            response.material.materialMedicacao
          );
          response.material.materialOPME = ensureArray(
            response.material.materialOPME
          );

          const cdCidPrincipal = response?.paciente?.cdCidPrincipal;
          if (
            cdCidPrincipal === "N/A" ||
            cdCidPrincipal === "" ||
            !cdCidPrincipal
          ) {
            response.paciente.cdCidPrincipal = "";
            response.paciente.cidPrincipal = "";
          }

          dispatch(setModelObject(response));
          const path = window.location.pathname;
          navigate("/agendamento", {
            state: { from: path, fromScreen: "Modelos" },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        navigate("/sistema", {
          state: {
            error: true,
            title: "Falha ao carregar",
            content: `Pedimos desculpas pelo ocorrido.
              Estamos trabalhando para que ele retorne o mais breve possível.`,
            enabledButton: true,
            titleButton: "Voltar",
            page: "/modelos",
          },
        });
      })
      .finally(setLoading(false));
  };

  const handleClickOpen = () => {
    setSelectDropdownIsOpen((prevState) => !prevState);
  };

  const getDuplicateName = () => {
    const counter = models.listModels.reduce((acc, current) => {
      if (current.nomeModelo.includes(duplicateModel.sourceModelName)) {
        return acc + 1;
      }

      return acc;
    }, 0);

    return `${duplicateModel.sourceModelName} - Cópia ${
      counter > 0 ? counter : ""
    }`;
  };

  useEffect(() => {
    if (modelSave && saveSolicitationInfoBeforeEditing) {
      dispatch(setSolicitationObject(saveSolicitationInfoBeforeEditing));
      dispatch(setSaveSolicitationInfoBeforeEditing(null));
      dispatch(setCurrentStep(1));
    }
  }, [modelSave, saveSolicitationInfoBeforeEditing]);

  return (
    <S.Container>
      {modelSave ? (
        <S.StyledAlert isOpened={modelSave}>
          <TmpAlert
            id="form-alert"
            isOpened={modelSave}
            type="success"
            title="Sucesso!"
            message="Modelo atualizado"
            handleClose={() => {
              dispatch(setModelSave(false));
            }}
          />
        </S.StyledAlert>
      ) : null}
      {loading && (
        <S.StyledLoading>
          <TmpLoading label="Carregando..." />
        </S.StyledLoading>
      )}
      <S.Subtitle>Como você gostaria de continuar esse agendamento?</S.Subtitle>
      <S.Paragraph>Selecione uma das opções abaixo:</S.Paragraph>
      <S.CardWrap
        paddingTop="0px"
        paddingBottom={selectDropdownIsOpen ? null : "0px"}
      >
        <TmpShape>
          <div
            style={{
              width: "100%",
              maxWidth: "100vw",
            }}
          >
            <TmpList>
              <S.ListItem
                style={{
                  marginBottom: selectDropdownIsOpen ? "25px" : null,
                  borderRadius: "16px",
                }}
                label="Utilizar um modelo salvo"
                trailingIcon={renderArrowIcon()}
                handleClick={handleClickOpen}
                disabled={models.listModels?.length === 0}
                leadingIcon={
                  <S.SVGWrapper>
                    <Copy color={"#292929"} />
                  </S.SVGWrapper>
                }
              />
              {selectDropdownIsOpen ? (
                <>
                  {models.listModels.map((item, index) => (
                    <S.Label key={item?.idModel || index}>
                      <S.SVGWrapper>
                        <PrescricaoSVG />
                      </S.SVGWrapper>
                      <div
                        onClick={() => handleSelectModel(item.idModelo)}
                        className="boxLabel"
                      >
                        <p className="label">{item.nomeModelo}</p>
                        <p className="text">{item.cidPrincipal}</p>
                      </div>
                      <S.MenuOverFlow className="overflow">
                        <TmpMenuOverflow
                          closeOnClickOutside
                          listItems={[
                            {
                              handleClick: () => {
                                handleSelectModel(item.idModelo, true);
                              },
                              label: "Editar",
                              leadingIcon: <Edit />,
                            },
                            // {
                            //   handleClick: () => {
                            //     setDuplicateModel((prev) => ({
                            //       ...prev,
                            //       openModal: true,
                            //       idModel: item.idModelo,
                            //       sourceModelName: item.nomeModelo,
                            //     }));
                            //   },
                            //   label: "Duplicar",
                            //   leadingIcon: <Copy />,
                            // },
                            {
                              handleClick: () => {
                                setShowDeleteModal(true);
                                setModelToDelete(item);
                              },
                              label: "Apagar",
                              leadingIcon: <Trash />,
                            },
                          ]}
                        />
                      </S.MenuOverFlow>
                    </S.Label>
                  ))}
                  <div style={{ height: "30px" }}></div>
                </>
              ) : null}
            </TmpList>
          </div>
        </TmpShape>
      </S.CardWrap>
      <S.Subtitle style={{ margin: "0px" }}>ou</S.Subtitle>
      <S.CardWrap paddingTop="0px" paddingBottom="0px">
        <TmpShape>
          <div
            style={{
              width: "100%",
              maxWidth: "100vw",
              cursor: "pointer",
            }}
          >
            <TmpList>
              <S.ListItem
                label="Seguir sem utilizar modelo"
                trailingIcon={
                  <S.SVGWrapper id="t">
                    <ArrowToRight color={"#292929"} />
                  </S.SVGWrapper>
                }
                handleClick={() => {
                  handleClickNewScheduling();
                }}
                leadingIcon={
                  <S.SVGWrapper id="t">
                    <Terms color={"#292929"} />
                  </S.SVGWrapper>
                }
              />
            </TmpList>
          </div>
        </TmpShape>
      </S.CardWrap>
      {showDeleteModal ? (
        <Modal
          minHeight="min-content"
          maxHeight="555px"
          isOpened={true}
          labelPrimary={"Atenção!"}
          removeBtn
        >
          <>
            <TmpIcon
              size="lg"
              icon={<Alerta />}
              style={{
                marginTop: "74px",
                marginBottom: "38px",
                color: "#000000",
              }}
            />
            <div
              style={{
                textAlign: "center",
                marginBottom: "56px",
              }}
            >
              <p className="modal-title" style={{ marginBottom: "16px" }}>
                Atenção!
              </p>
              <p className="modal-body" style={{ margin: "0 auto" }}>
                Tem certeza que deseja apagar o Modelo?
              </p>
              <p style={{ margin: "20px 0", fontSize: "16px" }}>
                <b>Nome do Modelo:</b> {modelToDelete?.nomeModelo}
              </p>
              <p style={{ fontSize: "16px" }}>
                <b>CID:</b> {modelToDelete?.cidPrincipal}
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "48px",
                }}
              >
                <S.WrapperActionModal>
                  <TmpButton
                    size="lg"
                    type="secondary"
                    handleClick={() => {
                      setShowDeleteModal(false);
                      setModelToDelete(undefined);
                    }}
                  >
                    Voltar
                  </TmpButton>

                  <TmpButton
                    size="lg"
                    handleClick={() => {
                      handleDeleteModel(modelToDelete.idModelo);
                    }}
                    loading={deletedLoad}
                  >
                    Sim, apagar
                  </TmpButton>
                </S.WrapperActionModal>
              </div>
            </div>
          </>
        </Modal>
      ) : null}
      {alert.status && (
        <Alert alertOBJ={alert} onClickClose={() => setAlert(INTIALALERT)} />
      )}

      {duplicateModel.openModal && (
        <ModalNameModel
          title="Deseja renomear o modelo duplicado?"
          subtitle=""
          labelPrimary="Salvar"
          isEditingModel={false}
          initialName={getDuplicateName()}
          onCancel={() => {
            setDuplicateModel({ ...initialDuplicateModelState });
          }}
          onSave={async (newModelName) => {
            setDuplicateModel((prev) => ({ ...prev, loading: true }));

            await apiDuplicateModel().post({
              idMedico: cdPessoaFisica,
              idModelo: duplicateModel.idModel,
              nome: newModelName,
            });

            setDuplicateModel({ ...initialDuplicateModelState });
            loadModelList();
          }}
          loading={duplicateModel.loading}
          minPrimaryWidth="96px"
          minSecondaryWidth="117px"
        />
      )}
    </S.Container>
  );
};

export default SelectModel;
