import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    cursor: pointer;
  }
`;

export const Pagination = styled.button`
  display: flex;
  cursor: pointer;
  border: none;
  align-items: center;
  border-radius: 3rem;
  justify-content: center;
  margin: 0px 5.5px;
  padding: 8px;
  height: 32px;
  width: 32px;
  font-size: 16px;
  color: ${({ active }) => (active ? "white" : "#2C6EF2")};
  background-color: ${({ active }) => (active ? "#2C6EF2" : "transparent")};

  :hover {
    background-color: ${({ active }) => (active ? "#2C6EF2" : "#C5D7FB64")};
  }
`;

export const Arrow = styled(Pagination)`
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  color: ${({ disabled }) => (disabled ? "#A3A3A3" : "#2C6EF2")};

  :hover {
    background-color: transparent;
  }
`;

export const Divide = styled.span`
  color: #2c6ef2;
`;
