import { otherTeams } from "../types";

export const unifiedProcedures = (procedureData) => {
  const addTeam = (type) => {
    return {
      ...procedureData,
      equipe: [...procedureData[type], otherTeams],
    };
  };

  const removeAllTeams = () => {
    return {
      ...procedureData,
      equipe: [],
    };
  };

  return {
    addTeam,
    removeAllTeams,
  };
};
