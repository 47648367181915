export const anamneseExameFisico = {
  fields: {
    estadoGeral: [
      { label: "", code: "" },
      { label: "Bom", code: "B" },
      { label: "Mal", code: "M" },
      { label: "Regular", code: "R" },
    ],
    vivacidade: [
      { label: "", code: "" },
      { label: "Agitado", code: "A" },
      { label: "Apático", code: "AP" },
      { label: "Palidez cutâneo-mucosa", code: "P" },
    ],
    sintomas: [
      { label: "", code: "" },
      { label: "Cianótico", code: "C" },
      { label: "Ictérico", code: "I" },
      { label: "Desidratado", code: "DS" },
    ],
    transpiracao: [
      { label: "", code: "" },
      { label: "Sudoreico", code: "S" },
      { label: "Dispneico", code: "D" },
      { label: "Edema", code: "E" },
    ],
  },
};
