import React from "react";
import { useSelector } from "react-redux";
import * as S from "../style";
import { addListItem, removeListItem } from "../../../../utils";
import {
  TmpDivider,
  TmpButton,
  TmpIcon,
} from "@tempo/tempo-design-system-core/dist";
import { mais as Plus, alerta as Alert } from "@tempo/tempo-assets/dist";
import Uploader from "../../../../components/Uploader";
import TextArea from "../../../../components/TextArea";
import { ComponentLabel } from "../../../../globalStyle";

const Reports = ({
  data,
  dataChange,
  local,
  healthInsurance,
  requiredLaudo,
}) => {
  const error = useSelector((state) => state.settings.error);
  const { editingModel } = useSelector((state) => state.settings);

  const addNewItem = (type) => {
    dataChange({
      target: {
        name: type,
        value: addListItem(data[type], type, {
          idRelatorioDocumento: null,
          documento: "",
          documentoUUID: "",
        }).value,
      },
    });
  };

  const removeItem = (type, listIndex = null) => {
    if (listIndex !== null)
      dataChange({
        target: {
          name: type,
          value: removeListItem(data[type], type, listIndex).value,
        },
      });
    else
      dataChange({
        target: {
          name: type,
          value: "",
        },
      });
  };

  const handleChangeInput = (e, prop) => {
    dataChange({
      target: {
        name: prop,
        value: e.target.value.trim(),
      },
    });
  };

  return (
    <S.Container>
      <S.BoxTitle margin="0 0 16px 0">
        <S.Title id="step-reports">Observações e Orientações</S.Title>
        <TmpDivider />
      </S.BoxTitle>
      <S.StyledSectionWrapper rows={"1fr"}>
        <S.ReportInfos>
          <S.TextareaWrap
            width="100%"
            height="335px"
            marginBottom="40px"
            marginTop="32px"
          >
            <TextArea
              value={data?.observacoes}
              id="observacoes"
              placeholder="Escreva aqui..."
              handleChange={(e) => handleChangeInput(e, "observacoes")}
              label="Informe outras observações relevantes e/ou orientações gerais"
              maxLength={2000}
              message="Você atingiu o limite de caractere."
            />
          </S.TextareaWrap>
        </S.ReportInfos>
      </S.StyledSectionWrapper>

      <S.BoxTitle margin="0 0 16px 0">
        <S.Title id="step-reports">Anexos</S.Title>
        <TmpDivider />
      </S.BoxTitle>
      <S.StyledSectionWrapper rows={"1fr"}>
        <S.ReportInfos>
          <div>
            <div
              style={{ opacity: editingModel ? "0.3" : "1" }}
              className="infos-report"
            >
              <span className="subtitle-report">
                Faça o upload de todos os Relatórios, Laudos e Documentos
                médicos do paciente.
              </span>
              <span className="alert-report">
                <TmpIcon size="sm" icon={<Alert />} />
                Formatos aceitos: doc, docx, pdf, jpg e png
              </span>
            </div>
          </div>
        </S.ReportInfos>

        <S.ReportsRow>
          <S.AdditionalInformation disabled={editingModel}>
            <p>Alguns documentos necessários:</p>
            <ul>
              <li>Foto da Carteirinha do convênio do paciente</li>
              <li>Documentos de Identificação do paciente</li>
              <li>Laudo do Paciente com dados do procedimento</li>
            </ul>
          </S.AdditionalInformation>
          <S.StyledSectionWrapper marginBottom={"0"} rows={"1fr"}>
            <S.ReportInfos>
              <Uploader
                disabled={editingModel}
                dataChange={dataChange}
                label={"Carteirinha "}
                prop="carteirinha"
                propUUID="carteirinhaUUID"
                value={data?.carteirinha}
                removeFile={() => {
                  removeItem("carteirinha");
                  removeItem("carteirinhaUUID");
                }}
                downloadId={data?.carteirinhaUUID}
              />
            </S.ReportInfos>
            <S.ReportInfos>
              <Uploader
                disabled={editingModel}
                dataChange={dataChange}
                label={
                  local !== ""
                    ? "Documentos do paciente"
                    : "Documentos do paciente *"
                }
                error={
                  local !== "" ? false : data?.documentoPaciente === "" && error
                }
                prop="documentoPaciente"
                propUUID="documentoPacienteUUID"
                value={data?.documentoPaciente}
                removeFile={() => {
                  removeItem("documentoPaciente");
                  removeItem("documentoPacienteUUID");
                }}
                downloadId={data?.documentoPacienteUUID}
              />
            </S.ReportInfos>
            <S.ReportInfos>
              <Uploader
                disabled={editingModel}
                dataChange={dataChange}
                label={
                  requiredLaudo && healthInsurance !== "Particular"
                    ? "Laudo do Paciente *"
                    : "Laudo do Paciente"
                }
                prop="laudo"
                propUUID="laudoUUID"
                value={data?.laudo}
                removeFile={() => {
                  removeItem("laudo");
                  removeItem("laudoUUID");
                }}
                downloadId={data?.laudoUUID}
                error={
                  data?.laudo === "" && requiredLaudo && error ? true : false
                }
              />
            </S.ReportInfos>
            <S.ReportInfos>
              <Uploader
                disabled={editingModel}
                dataChange={dataChange}
                label={
                  <>
                    Termo de Consentimento{" "}
                    <span style={{ fontWeight: "400", fontSize: "14px" }}>
                      (pode ser enviado posteriormente)
                    </span>
                  </>
                }
                prop="termoConsentimento"
                propUUID="termoConsentimentoUUID"
                value={data?.termoConsentimento}
                removeFile={() => {
                  removeItem("termoConsentimento");
                  removeItem("termoConsentimentoUUID");
                }}
                downloadId={data?.termoConsentimentoUUID}
              />
            </S.ReportInfos>

            <S.StyledDivider>
              <TmpDivider />
            </S.StyledDivider>
            <ComponentLabel marginBottom={"0"}>
              Exames complementares, Relatório Médico, entre outros
            </ComponentLabel>

            {data?.relatorioDocumento?.map((item, index) => (
              <S.ReportInfos key={index}>
                <Uploader
                  disabled={editingModel}
                  dataChange={dataChange}
                  key={index}
                  prop="documento"
                  listId="relatorioDocumento"
                  list={data.relatorioDocumento}
                  index={index}
                  value={item.documento}
                  removeFile={() => removeItem("relatorioDocumento", index)}
                  downloadId={item.documentoUUID}
                />
              </S.ReportInfos>
            ))}

            <S.AddButtonWrap>
              <TmpButton
                disabled={editingModel}
                type="secondary"
                size="sm"
                startIcon={<Plus />}
                handleClick={() => addNewItem("relatorioDocumento")}
              >
                Adicionar outros documentos
              </TmpButton>
            </S.AddButtonWrap>
          </S.StyledSectionWrapper>
        </S.ReportsRow>
      </S.StyledSectionWrapper>
    </S.Container>
  );
};

export default Reports;
