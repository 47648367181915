import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import {
  transformDate,
  getSchedulingLocal,
  formatQuantity,
} from "../../../utils";
import { store } from "../../../redux/store";
import {
  getPatientData,
  getSupportServices,
  getObservation,
} from "./Controller";

import logoHSL from "../../../assets/images/logo-hsl.png";

const fontColor = "#454b52";
const orange = "#d47e4d";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: "29px",
    fontSize: "20px",
  },
  header: {
    fontStyle: "normal",
  },
  h1: {
    color: fontColor,
    fontWeight: 600,
    fontSize: 14,
  },
  h2: {
    color: orange,
    fontWeight: "semibold",
    fontSize: 12,
  },
  p: {
    color: fontColor,
    fontSize: 8,
  },
  h3: {
    color: fontColor,
    fontSize: 10,
    marginRight: "8x",
  },
  sectionMaterial: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    padding: "0 24px 0 0",
  },
  headerRow1: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1,
  },
  divider: {
    borderTop: "1px solid #E0E0E0",
  },
  section: {},
  boxTitle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  h3Title: {
    color: fontColor,
    fontSize: 10,
  },
  signatureSection: {
    width: "100%",
    marginTop: 40,
    flexDirection: "column",
    alignItems: "center",
  },
  pMaterial: {
    fontSize: 8,
    width: "100%",
    margin: 10,
    textAlign: "justify",
  },
  cellH1: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: 10,
  },
  cell: {
    width: "32%",
  },
  cell2: {
    width: "23%",
    marginRight: "2%",
    marginTop: "2x",
    borderBottom: "2px solid #000000",
  },
  cell3: {
    width: "100%",
    marginTop: "4x",
    marginBottom: "4x",
    borderBottom: "1px solid #000000",
  },
  borderBottom: {
    borderBottom: "2px solid #E0E0E0",
  },
});

const SolicitationPDF = ({ solicitation }) => {
  const { crm, nome, urlAssinatura, telefone } =
    store.getState()?.auth?.userInfos;
  const pacienteTable = getPatientData(
    solicitation?.paciente,
    solicitation?.procedimento
  );

  const proceduresDescription = [
    [
      {
        label: "local",
        name: "Local",
        value: solicitation.procedimento.local || "---",
      },
      {
        label:"Honorários Médicos",
        name:"Honorários Médicos",
        value:solicitation.procedimento.cobranca || "---",
      },
    ],
    [
      {
        label: "dataInicio",
        name: "Data de início da cirurgia",
        value: solicitation?.procedimento?.data
          ? transformDate(solicitation.procedimento.data)
          : "-",
      },
      {
        label: "horaInicio",
        name: "Hora de início:",
        value: solicitation.procedimento.hora,
      },
      {
        label: "tempoDeCirurgia",
        name: "Tempo de Cirurgia",
        value: solicitation.procedimento.tempoCirurgia + "min",
      },
    ],
  ];

  const proceduresDescription2 = [
    [
      {
        label: "tipagem",
        name: "Tipagem sanguínea",
        value: solicitation.procedimento?.tipagemSanguinea || "---",
      },
      {
        label: "Reserva de Hemocomponentes",
        name: "Reserva de Hemocomponentes",
        value: solicitation?.procedimento.reservaHemocomponentes,
      },
      {
        label: "leito",
        name: "Reserva de Unidade Crítica ou Semi- crítica",
        value: solicitation?.procedimento.reservaCritica,
      },
    ],
  ];

  const proceduresObservation = getObservation(solicitation?.procedimento);
  const reportObservation = getObservation(solicitation?.relatorio);

  const procedures = [
    {
      tipo: "Principal",
      codigoAMB:
        solicitation.procedimento?.cdProcedimentoPrincipal ||
        solicitation.procedimento?.procedimentoPrincipal?.cdProcedimento ||
        "-",
      codigoTUSS:
        solicitation.procedimento?.procedimentoPrincipal?.tuss != 0
          ? solicitation.procedimento?.procedimentoPrincipal?.tuss ||
            solicitation.procedimento?.tuss
          : "-",
      name:
        solicitation.procedimento?.procedimentoPrincipal?.nomeProcedimento ||
        solicitation.procedimento?.procedimentoPrincipal?.procedimento ||
        solicitation.procedimento?.procedimentoPrincipal ||
        "-",
      lateralidade: solicitation.procedimento?.lateralidade || "-",
    },
    ...solicitation.procedimento?.procedimentosExtras.map((procedimento) => ({
      tipo: "Adicional",
      codigoAMB: procedimento?.cdProcedimento || "-",
      codigoTUSS: procedimento?.tuss != 0 ? procedimento?.tuss : "-",
      name: procedimento?.nomeProcedimento || procedimento?.procedimento || "-",
      lateralidade: procedimento?.lateralidade || "-",
    })),
  ];

  const supportServices = getSupportServices(solicitation?.procedimento);

  const procedureTable = [
    { label: "tipo", name: "Tipo", values: ["Adicional", "Principal"] },
    {
      label: "codigoAMB",
      name: "Código AMB",
      values: ["41120035", "44040059"],
    },
    {
      label: "codigoTUSS",
      name: "Código TUSS",
      values: ["30212057", "30213053"],
    },
    {
      label: "procedimento",
      name: "Procedimento",
      values: [
        "Esvaziamento parcial cervical ou do pescoço (unilateral)",
        "Tireoidectomia total",
      ],
    },
    {
      label: "lateralidade",
      name: "Lateralidade",
      values: ["Lado Direito", "Não se aplica"],
    },
  ];

  const nomeMedico =
    solicitation?.solicitacao?.medicoTitular !== ""
      ? solicitation?.solicitacao?.medicoTitular
      : nome;

  const team = [
    [
      { name: "Responsável pela cirurgia", value: nomeMedico },
      { name: "CRM/CRO", value: crm === "" ? "Não informado" : crm },
      {
        name: "Telefone",
        value: telefone === "None" ? "Não informado" : telefone,
      },
    ],
    [
      {
        name: "Anestesista",
        value:
          solicitation.procedimento?.anestesista.tipo ===
          "Anestesista Particular"
            ? solicitation.procedimento?.anestesista.nome
            : solicitation.procedimento?.anestesista.tipo,
      },
      {
        name: "CRM/CRO",
        value:
          solicitation.procedimento?.anestesista.tipo ===
          "Anestesista Particular"
            ? solicitation.procedimento?.anestesista.crm
            : "Não informado",
      },
      {
        name: "Telefone",
        value:
          solicitation.procedimento?.anestesista.tipo ===
          "Anestesista Particular"
            ? solicitation.procedimento?.anestesista.telefone
            : "Não informado",
      },
    ],
    ...solicitation.procedimento.equipe.map((cirurgiao) => [
      {
        name: "Responsável por outras equipes",
        value: cirurgiao.nomeCirurgiao,
      },
      { name: "CRM/CRO", value: cirurgiao.crmCirurgiao },
      {
        name: "Telefone",
        value: cirurgiao.telefoneCirurgiao
          ? cirurgiao.telefoneCirurgiao
          : "Não informado",
      },
    ]),
  ];

  const handleFalsy = (value) => {
    if (value === 0 || !!value) {
      return value;
    }
    return "-";
  };

  const internacao = [
    [
      {
        name: "Caráter da Internação",
        value: solicitation.procedimento?.carater || "-",
      },
      {
        name: "Regime de Internação",
        value: solicitation.procedimento?.regimeHospitalar || "-",
      },
      {
        name: "Internação véspera",
        value: solicitation.procedimento?.internacaoVespera ? "Sim" : "Não",
      },
    ],
    [
      {
        name: "Tipo de Internação",
        value: "Cirúrgica",
      } /* Da onde vem essa info? */,
      {
        name: "Qtd. diárias solicitadas",
        value: handleFalsy(solicitation.procedimento?.diarias),
      },
      { name: "CID 10 principal", value: solicitation.paciente?.cidPrincipal },
    ],
    [
      {
        name: "Necessidades Especiais",
        value: solicitation?.paciente?.necessidadesEspeciais || "-",
      },
      {
        name: "Peso do paciente",
        value: solicitation?.paciente?.peso || "-",
      },
      {
        name: "Início da Doença",
        value: solicitation?.paciente?.tempoDoenca || "-",
      },
    ],
    [
      {
        name: "Indicação Clínica/Justificativa do Procedimento",
        value: solicitation?.paciente?.indicacaoClinica || "-",
      },
    ],
  ];

  const materialsOPMEDescritionTable = [
    { label: "material", name: "Material" },
    { label: "fabricante 1", name: "Fabricante1" },
    { label: "quantidade", name: "Quantidade" },
    { label: "justificativa", name: "Justificativa" },
  ];

  const materialsDescritionTable = [
    { label: "material", name: "Material" },
    { label: "quantidade", name: "Quantidade" },
  ];

  return (
    <Document>
      <Page wrap size="A4" style={styles.page}>
        <View style={styles.headerRow1}>
          <Image style={{ width: "150px", height: "auto" }} src={logoHSL} />
          <View style={styles.boxTitle}>
            <Text style={styles.h1}>SOLICITAÇÃO INTERNAÇÃO CIRÚRGICA</Text>
            {solicitation?.solicitacao?.solicitacaoUnificada ? (
              <Text style={styles.h3Title}>
                CIRURGIA COM MAIS DE UMA EQUIPE
              </Text>
            ) : null}
          </View>
        </View>
        <View style={styles.section}>
          <View style={styles.header}>
            <View style={{ marginTop: 20 }}>
              <Text style={styles.h2}>Informações Básicas do Paciente</Text>
            </View>

            <View style={{ width: "100%" }}>
              {pacienteTable.map((row, i) => {
                return (
                  <View
                    key={i}
                    style={{ flexDirection: "row", marginTop: "8px" }}
                  >
                    {row.map((cell, j) => (
                      <View key={j} style={styles.cell}>
                        <Text style={styles.cellH1}>{cell.name}</Text>
                        <Text style={styles.p}>{cell.value}</Text>
                      </View>
                    ))}
                  </View>
                );
              })}
            </View>

            <View style={{ marginTop: 20 }}>
              <Text style={styles.h2}>Resumo de internação</Text>
            </View>
            <View style={{ width: "100%" }}>
              {internacao.map((row, i) => {
                return (
                  <View
                    key={i}
                    style={{ flexDirection: "row", marginTop: "8px" }}
                  >
                    {row.map((cell, j) => (
                      <View key={j} style={styles.cell}>
                        <Text style={styles.cellH1}>{cell.name}</Text>
                        <Text style={styles.p}>{cell.value}</Text>
                      </View>
                    ))}
                  </View>
                );
              })}
            </View>

            <View style={{ marginTop: 20 }}>
              <Text style={styles.h2}>Identificação do(s) Procedimento(s)</Text>
            </View>
            {/* To-do: Esses maps aninhados não são muito legais pra perfomance =( */}
            <View style={{ width: "100%" }}>
              {proceduresDescription.map((row, i) => {
                return (
                  <View
                    key={i}
                    style={{
                      flexDirection: "row",
                      marginTop: "8px",
                      marginBottom: "10px",
                    }}
                  >
                    {row.map((cell, j) => (
                      <View key={j} style={styles.cell}>
                        <Text style={styles.cellH1}>{cell.name}</Text>
                        <Text style={styles.p}>{cell.value}</Text>
                      </View>
                    ))}
                  </View>
                );
              })}
            </View>

            <Text style={styles.cellH1}>Procedimentos</Text>
            <View style={{ width: "100%", flexDirection: "row" }}>
              {procedureTable.map((column, i) => (
                <View
                  style={{
                    marginTop: "8px",
                    width: "23%",
                    marginRight: "2%",
                    flexDirection: "column",
                  }}
                  key={i}
                >
                  <View style={styles.borderBottom}>
                    <Text style={styles.cellH1}>{column.name}</Text>
                  </View>
                </View>
              ))}
            </View>
            <View>
              {procedures.map((row, i) => (
                <View
                  key={i}
                  style={{
                    flexDirection: "row",
                    backgroundColor: "#c0c0c024",
                    marginBottom: "2px",
                  }}
                >
                  <View style={styles.cell2}>
                    <Text style={styles.p}>{row.tipo}</Text>
                  </View>
                  <View style={styles.cell2}>
                    <Text style={styles.p}>{row.codigoAMB}</Text>
                  </View>
                  <View style={styles.cell2}>
                    <Text style={styles.p}>{row.codigoTUSS}</Text>
                  </View>
                  <View style={styles.cell2}>
                    <Text style={styles.p}>{row.name}</Text>
                  </View>
                  <View style={styles.cell2}>
                    <Text style={styles.p}>{row.lateralidade}</Text>
                  </View>
                </View>
              ))}

              <View style={{ width: "100%" }}>
                {proceduresDescription2.map((row, i) => {
                  return (
                    <View
                      key={i}
                      style={{ flexDirection: "row", marginTop: "8px" }}
                    >
                      {row.map((cell, j) => (
                        <View key={j} style={styles.cell}>
                          <Text style={styles.cellH1}>{cell.name}</Text>
                          <Text style={styles.p}>{cell.value}</Text>
                        </View>
                      ))}
                    </View>
                  );
                })}
              </View>
            </View>

            {!!supportServices && (
              <View style={{ width: "100%" }}>
                {supportServices.map((row, i) => {
                  return (
                    <View
                      key={i}
                      style={{ flexDirection: "row", marginTop: "8px" }}
                    >
                      {row.map((cell, j) => (
                        <View key={j} style={styles.cell}>
                          <Text style={styles.cellH1}>{cell.name}</Text>
                          <Text style={styles.p}>{cell.value}</Text>
                        </View>
                      ))}
                    </View>
                  );
                })}
              </View>
            )}

            {proceduresObservation && (
              <View style={{ width: "100%" }}>
                {proceduresObservation.map((row, i) => {
                  return (
                    <View
                      key={i}
                      style={{ flexDirection: "row", marginTop: "8px" }}
                    >
                      {row.map((cell, j) => (
                        <View key={j} style={styles.cell}>
                          <Text style={styles.cellH1}>{cell.name}</Text>
                          <Text style={styles.p}>{cell.value}</Text>
                        </View>
                      ))}
                    </View>
                  );
                })}
              </View>
            )}

            <View style={{ marginTop: 20, marginBottom: "8px" }}>
              <Text style={styles.h2}>Equipe(s) Médica(s) Envolvida(s)</Text>
            </View>
            {solicitation?.solicitacao?.solicitacaoUnificada ? (
              <View
                style={{
                  marginBottom: "8px",
                }}
              >
                <Text style={styles.cellH1}>
                  Essa solicitação é da equipe titular (responsável pela
                  cirurgia)?
                </Text>
                <Text style={styles.p}>
                  {solicitation?.solicitacao?.idSolicitacao ===
                  solicitation?.solicitacao?.idSolicitacaoPrincipal
                    ? "Sim"
                    : "Não"}
                </Text>
              </View>
            ) : null}
            <View style={{ width: "100%" }}>
              {team.map((row, i) => {
                return (
                  <View
                    key={i}
                    style={{
                      flexDirection: "row",
                      marginTop: "2px",
                      paddingTop: "5px",
                      paddingLeft: "5px",
                      backgroundColor: "#c0c0c024",
                    }}
                  >
                    {row.map((cell, j) => (
                      <View key={j} style={styles.cell}>
                        <Text style={styles.cellH1}>{cell.name}</Text>
                        <Text style={styles.p}>{cell.value}</Text>
                      </View>
                    ))}
                  </View>
                );
              })}
            </View>

            <View style={{ marginTop: 20 }}>
              <Text style={styles.h2}>Equipamentos e Materiais</Text>
            </View>

            {!!solicitation.material?.materialOPME && (
              <View>
                <Text style={{ ...styles.p, marginTop: "10px" }}>
                  Materiais OPME (Órteses, Próteses e Materiais Especiais)
                </Text>
                <View style={{ width: "100%", flexDirection: "row" }}>
                  {materialsOPMEDescritionTable.map((column, i) => (
                    <View
                      style={{
                        marginTop: "8px",
                        width: "23%",
                        marginRight: "2%",
                        flexDirection: "column",
                      }}
                      key={i}
                    >
                      <View style={styles.borderBottom}>
                        <Text style={styles.cellH1}>{column.name}</Text>
                      </View>
                    </View>
                  ))}
                </View>
              </View>
            )}

            <View>
              {solicitation.material?.materialOPME.map((material, i) => (
                <View
                  key={i}
                  style={{
                    flexWrap: "wrap",
                    flexDirection: "row",
                    backgroundColor: "#c0c0c024",
                    marginBottom: "2px",
                  }}
                >
                  <View style={styles.cell2}>
                    <Text style={styles.p}>{material.material}</Text>
                  </View>
                  <View style={styles.cell2}>
                    <Text style={styles.p}>
                      {`1° ${material.fabricante1};
                          ${
                            material?.fabricante2 &&
                            "2° " + material.fabricante2 + ";"
                          }
                          ${
                            material?.fabricante3 &&
                            "3° " + material.fabricante3 + ";"
                          }`}
                    </Text>
                  </View>
                  <View style={styles.cell2}>
                    <Text style={styles.p}>
                      {formatQuantity(material.quantidade)}
                    </Text>
                  </View>
                  <View style={styles.cell2}>
                    <Text style={styles.p}>
                      {material?.naoIndicarFabricanteMotivo}
                    </Text>
                  </View>
                  {material?.naoIndicarFabricanteJustificativa ? (
                    <View style={styles.sectionMaterial} key={i}>
                      <Text style={styles.cellH1}>Carta de Justificativa</Text>
                      <Text style={styles.divider}></Text>
                      <Text style={styles.pMaterial}>
                        {material?.naoIndicarFabricanteJustificativa}
                      </Text>

                      <Text style={styles.divider}></Text>
                      <View
                        style={{
                          width: "100%",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          alignItems: "center",
                          paddingLeft: 10,
                        }}
                      >
                        <Text style={styles.h3}>
                          {material?.naoIndicarFabricanteMotivoData !== ""
                            ? `${new Date(
                                material?.naoIndicarFabricanteMotivoData
                              ).toLocaleDateString()} - ${
                                material?.naoIndicarFabricanteMotivoHora
                                  ? material?.naoIndicarFabricanteMotivoHora
                                  : ""
                              }`
                            : null}
                        </Text>
                        <Image
                          style={{
                            width: "100px",
                            height: "auto",
                            border: "solid",
                          }}
                          src={{ uri: urlAssinatura, method: "GET" }}
                        />
                      </View>
                    </View>
                  ) : null}
                </View>
              ))}
            </View>

            {/* INSTRUMENTAIS */}
            {solicitation.material?.materialInstrumentos && (
              <View>
                <View>
                  <Text style={{ ...styles.p, marginTop: "10px" }}>
                    Instrumentais
                  </Text>
                </View>
                <View style={{ width: "100%", flexDirection: "row" }}>
                  {materialsDescritionTable.map((column, i) => (
                    <View
                      style={{
                        marginTop: "8px",
                        width: "23%",
                        marginRight: "2%",
                        flexDirection: "column",
                      }}
                      key={i}
                    >
                      <View style={styles.borderBottom}>
                        <Text style={styles.cellH1}>{column.name}</Text>
                      </View>
                    </View>
                  ))}
                </View>
              </View>
            )}

            <View>
              {solicitation.material?.materialInstrumentos.map(
                (material, i) => (
                  <View
                    key={i}
                    style={{
                      flexDirection: "row",
                      backgroundColor: "#c0c0c024",
                      marginBottom: "2px",
                    }}
                  >
                    <View style={styles.cell2}>
                      <Text style={styles.p}>{material.material}</Text>
                    </View>
                    <View style={styles.cell2}>
                      <Text style={styles.p}>
                        {formatQuantity(material.quantidade)}
                      </Text>
                    </View>
                  </View>
                )
              )}
            </View>

            {/* EQUIPAMENTOS */}
            {!!solicitation.material?.materialEquipamentos && (
              <View>
                <View>
                  <Text style={{ ...styles.p, marginTop: "10px" }}>
                    Equipamentos
                  </Text>
                </View>
                <View style={{ width: "100%", flexDirection: "row" }}>
                  {materialsDescritionTable.map((column, i) => (
                    <View
                      style={{
                        marginTop: "8px",
                        width: "23%",
                        marginRight: "2%",
                        flexDirection: "column",
                      }}
                      key={i}
                    >
                      <View style={styles.borderBottom}>
                        <Text style={styles.cellH1}>{column.name}</Text>
                      </View>
                    </View>
                  ))}
                </View>
              </View>
            )}
            <View>
              {solicitation.material?.materialEquipamentos.map(
                (material, i) => (
                  <View
                    key={i}
                    style={{
                      flexDirection: "row",
                      backgroundColor: "#c0c0c024",
                      marginBottom: "2px",
                    }}
                  >
                    <View style={styles.cell2}>
                      <Text style={styles.p}>{material.material}</Text>
                    </View>
                    <View style={styles.cell2}>
                      <Text style={styles.p}>
                        {formatQuantity(material.quantidade)}
                      </Text>
                    </View>
                  </View>
                )
              )}
            </View>

            {/* Medicação Intra-operatória */}
            {!!solicitation.material?.materialMedicacao && (
              <View>
                <View>
                  <Text style={{ ...styles.p, marginTop: "10px" }}>
                    Medicação Intra-operatória
                  </Text>
                </View>
                <View style={{ width: "100%", flexDirection: "row" }}>
                  {materialsDescritionTable.map((column, i) => (
                    <View
                      style={{
                        marginTop: "8px",
                        width: "23%",
                        marginRight: "2%",
                        flexDirection: "column",
                      }}
                      key={i}
                    >
                      <View style={styles.borderBottom}>
                        <Text style={styles.cellH1}>{column.name}</Text>
                      </View>
                    </View>
                  ))}
                </View>
              </View>
            )}

            <View>
              {solicitation.material?.materialMedicacao.map((material, i) => (
                <View
                  key={i}
                  style={{
                    flexDirection: "row",
                    backgroundColor: "#c0c0c024",
                    marginBottom: "2px",
                  }}
                >
                  <View style={styles.cell2}>
                    <Text style={styles.p}>{material.material}</Text>
                  </View>
                  <View style={styles.cell2}>
                    <Text style={styles.p}>
                      {formatQuantity(material.quantidade)}
                    </Text>
                  </View>
                </View>
              ))}
            </View>

            {reportObservation && (
              <View style={{ width: "100%" }}>
                {reportObservation.map((row, i) => {
                  return (
                    <View
                      key={i}
                      style={{ flexDirection: "row", marginTop: "8px" }}
                    >
                      {row.map((cell, j) => (
                        <View key={j} style={styles.cell}>
                          <Text style={styles.cellH1}>{cell.name}</Text>
                          <Text style={styles.p}>{cell.value}</Text>
                        </View>
                      ))}
                    </View>
                  );
                })}
              </View>
            )}
          </View>

          <View
            style={{
              marginTop: "20px",
              marginBottom: "10px",
              width: "100%",
              height: "1px",
              backgroundColor: "silver",
            }}
          />
          <Text style={{ ...styles.p, marginTop: 10, textAlign: "center" }}>
            {`Assinatura do médico responsável/CRM. - ${getSchedulingLocal(
              solicitation?.procedimento?.local
            )}, ${new Date().toLocaleDateString()}.`}
          </Text>

          <View
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            <Image
              style={{ width: "150px", height: "auto" }}
              src={{ uri: urlAssinatura, method: "GET" }}
            />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default SolicitationPDF;
