import React, { useEffect, useState, useCallback, useReducer } from "react";
import ptBR from "date-fns/locale/pt-BR";
import {
  addDays,
  addMinutes,
  format,
  isSameDay,
  toDate,
  isAfter,
  parseISO,
  subMinutes,
  differenceInCalendarDays,
} from "date-fns";
import { useSelector } from "react-redux";
import {
  apiAnesthetist,
  apiProcedure,
  apiSurgeon,
  apiBookedDates,
  apiSequenceSurgery,
  apiSuggestedSchedules,
} from "../../../../services/api";
import {
  updatePropInList,
  removeListItem,
  addListItem,
  updateIndexList,
  getSelectObjValue,
  minutesToHourString,
  getMinutes,
  removeSpecialCharacters,
  checkQuantity,
} from "../../../../utils";
import * as S from "./style";
import * as SC from "../style";
import { Error, AutocompleteRow } from "../../Steps/style";
import {
  TmpDivider,
  TmpRadio,
  TmpIcon,
  TmpCheckbox,
  TmpButton,
  TmpSelect,
  TmpDatepicker,
  TmpSwitch,
  TmpButtonGroup,
  TmpInput,
  TmpLink,
  TmpSpinner,
} from "@tempo/tempo-design-system-core/dist";
import {
  mais as IconMais,
  ajuda as IconAjuda,
  excluir as IconExcluir,
  erro as ErrorIcon,
  alerta as IconAlert,
  relogio as IconRelogio,
  calendario as IconCalendar,
  info as IconInfo,
  check as CheckIcon,
  erro as IconErro,
} from "@tempo/tempo-assets/dist";
import {
  objectProcedure,
  otherTeams,
  times,
  timesBsb,
  getItaimHours,
} from "../types";
import TextArea from "../../../../components/TextArea";
import Autocomplete from "../../../../components/Autocomplete";
import { RequestFailed } from "../../../../components/RequestFailed";
import {
  criticalUnits,
  criticalUnitsbyBSB,
  criticalUnitsbyItaim,
} from "../../../../utils/models";
import debounce from "lodash.debounce";

import { verifyValue } from "../../../../utils";
import Reload from "../../../../components/ReloadHelper";
import { searchListWithPriorityOrder } from "../../../../utils";
import { unifiedProcedures } from "./unifiedProcedures";
import Drawer from "../../../../components/Drawer";
import { fields } from "./comboboxes";

const Procedure = ({
  data,
  dataChange,
  dataChangeObj,
  domains,
  dataPatient,
  surgicalTimeLoading,
  setExtraTeamsDrawer,
  useLoadingFields,
  populateFormData,
  isAditionalTeam,
  isUnifiedSolicitation,
  editingSolicitation,
  onClickFilledFieldByMainTeam,
}) => {
  const [sugestions, setSugestions] = useState([]);
  const [currentSugestionType, setCurrentSugestionType] = useState();
  const [disableDates, setDisableDates] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [errorRequest, setErrorRequest] = useState(false);
  const [bookedDates, setBookedDates] = useState([]);
  const [dates, setDates] = useState(false);
  const [currentTime] = useState(format(new Date(), "yyyy-MM-dd HH:mm:ss"));
  const [listSearchError, setListSearchError] = useState([]);
  const [surgicalTimeSuggestion, setSurgicalTimeSuggestion] = useState([]);
  const [scheduleOverlay, setScheduleOverlay] = useState(false);
  const [idTeamExtra, setIdTeamExtra] = useState(null);

  const [hours, setHours] = useState(() => {
    if (data.cdEstabelecimento !== 51) {
      return timesBsb;
    } else if (data.cdEstabelecimento === 51) {
      return getItaimHours(data.data, data.regimeHospitalar);
    } else return times;
  });

  const notFoundDoctorReducer = (state, action) => {
    switch (action.type) {
      case "add_list":
        return {
          ...state,
          notFoundDoctorInList: [...state.notFoundDoctorInList, action.payload],
        };
      case "error":
        return { ...state, isError: action.payload };
      case "remove_doctor":
        return {
          ...state,
          notFoundDoctorInList: state.notFoundDoctorInList.filter(
            (doctor) => doctor !== action.payload
          ),
        };
      default:
        return state;
    }
  };

  const [notFoundDoctor] = useReducer(notFoundDoctorReducer, {
    notFoundDoctorInList: [],
    isError: false,
  });

  const isEditingAditional = editingSolicitation && isAditionalTeam;

  const newDateCheck = isEditingAditional
    ? data?.data && data?.tempoCirurgiaTotal
    : data?.data && data?.tempoCirurgia;

  const [newDate, setNewDate] = useState(
    newDateCheck
      ? {
          hr_inicio: data?.hora,
          nm_paciente: dataPatient.nome,
          nr_minuto_duracao: isEditingAditional
            ? data?.tempoCirurgiaTotal
            : typeof data?.tempoCirurgia !== "number"
            ? getMinutes(data?.tempoCirurgia)
            : data?.tempoCirurgia,
          data: data?.data,
        }
      : {
          hr_inicio: "",
          nm_paciente: "",
          nr_minuto_duracao: "",
          data: "",
        }
  );

  useEffect(() => {
    if (data.cdEstabelecimento === 51) {
      setHours(getItaimHours(data.data, data.regimeHospitalar));
    }
  }, [data]);
  const [sequenceSurgery, setSequenceSurgery] = useState([]);
  const error = useSelector((state) => state.settings.error);
  const { cdPessoaFisica } = useSelector((state) => state.auth.userInfos);
  const { editingModel } = useSelector((state) => state.settings);
  const [modalOpen, setModalOpen] = useState(false);

  const collator = new Intl.Collator("pt-BR", { sensitivity: "base" });
  const surgeryDateRequest = () => {
    if (data?.data)
      try {
        apiSequenceSurgery(cdPessoaFisica, data?.data?.substring(0, 10))
          .getList()
          .then((res) => {
            setReload(false);
            setErrorRequest(false);
            var dates = [];
            res?.forEach((item) => {
              dates.push(item);
            });
            setSequenceSurgery(dates);
          })
          .catch((err) => {
            console.log(err);
            setReload(false);
            setErrorRequest(true);
          });
      } catch (err) {
        console.log(err);
      }
  };

  // Verificação para o calendario exibir certo os horarios disponiveis
  useEffect(() => {
    if (data.data === "") {
      populateFormData({
        target: {
          name: "tempoCirurgia",
          value: "",
        },
      });
      populateFormData({
        target: {
          name: "hora",
          value: "",
        },
      });
      setNewDate({
        hr_inicio: "",
        nm_paciente: "",
        nr_minuto_duracao: "",
        data: data?.data,
      });
      populateFormData({
        target: {
          name: "data",
          value: format(subMinutes(new Date(), 1), "yyyy-MM-dd HH:mm:ss"),
        },
      });
    }
  }, [data.data]);

  useEffect(() => {
    const date1 = data?.data ? new Date(data?.data) : new Date();
    const date2 = new Date(currentTime);

    setDates(isSameDay(date1, date2));
  }, [data?.data, currentTime]);

  const teamConfirmed = (confirmed, type) => {
    if (confirmed === "Confirmada" && type === "text") {
      return <span style={{ color: "#35973C" }}>Equipe confirmada</span>;
    } else if (confirmed === "Confirmada" && type === "icon") {
      return <CheckIcon style={{ color: "#35973C" }} />;
    } else if (confirmed === "Recusada" && type === "icon") {
      return <ErrorIcon style={{ color: "red" }} />;
    } else if (confirmed === "Recusada" && type === "text") {
      return <span style={{ color: "red" }}>Campo Obrigatório</span>;
    }
  };

  useEffect(() => {
    if (!editingModel) {
      try {
        apiBookedDates(cdPessoaFisica)
          .getList()
          .then((res) => {
            var dates = [];
            if (!res) return;
            res?.forEach((item) => {
              dates.push(new Date(item.dt_agenda));
            });
            setBookedDates(dates);
          });
      } catch (err) {
        console.log(err);
      }
    }
  }, []);

  useEffect(() => {
    if (!editingModel && data?.procedimentoPrincipal?.idSequencia) {
      useLoadingFields("surgicalTimeSuggestion", true);

      try {
        apiSuggestedSchedules(
          cdPessoaFisica,
          data.procedimentoPrincipal.idSequencia
        )
          .getList()
          .then((res) => {
            if (res?.qt_min_cirurgia) {
              var sugestionsHours = [];
              sugestionsHours.push(
                minutesToHourString(Number(res.qt_min_cirurgia)).substring(0, 5)
              );
              sugestionsHours.push(
                minutesToHourString(Number(res.qt_min_cirurgia) + 30).substring(
                  0,
                  5
                )
              );
              setSurgicalTimeSuggestion(sugestionsHours);
            } else {
              setSurgicalTimeSuggestion([]);
            }
          });
      } catch (err) {
        console.log(err);
      }
      useLoadingFields("surgicalTimeSuggestion", false);
    }
  }, [data.procedimentoPrincipal]);

  useEffect(() => {
    setSugestions([]);
  }, [currentSugestionType]);

  const clearSchedule = () => {
    dataChange({
      target: {
        name: "tempoCirurgia",
        value: "",
      },
    });
    dataChange({
      target: {
        name: "hora",
        value: "",
      },
    });
    setNewDate({
      hr_inicio: "",
      nm_paciente: "",
      nr_minuto_duracao: "",
      data: data?.data,
    });
  };

  useEffect(() => {
    surgeryDateRequest();
  }, [data?.data]);

  const updateData = (id, value) => {
    dataChange({
      target: {
        name: id,
        value,
      },
    });
  };

  const handleChangeRadio = (id) => {
    if (id === "radioHsl") {
      updateData("radioHsl", true);
      updateData("radioExt", false);
      updateData("biopsiaCongelacao", "radioHsl");
    } else {
      updateData("radioHsl", false);
      updateData("radioExt", true);
      updateData("biopsiaCongelacao", "radioExt");
    }
  };

  const handleCheckboxChange = (id, value) => {
    if (id === "biopsiaCongelacao") {
      if (!value) updateData("biopsiaCongelacao", "");
      else updateData("biopsiaCongelacao", "radioHsl");
    } else updateData(id, value);
  };

  const addNewItem = (type) => {
    dataChange({
      target: {
        name: type,
        value: addListItem(
          data[type],
          type,
          type === "procedimentosExtras" ? objectProcedure : otherTeams
        ).value,
      },
    });
  };

  const addTeam = (type) => {
    const procedureData = unifiedProcedures(data).addTeam(type);
    setExtraTeamsDrawer((prevState) => ({
      ...prevState,
      renderOnAdvanceStep: true,
    }));
    dataChangeObj({
      target: {
        name: "procedimento",
        value: procedureData,
      },
    });
  };

  const removeItem = (type, listIndex) => {
    dataChange({
      target: {
        name: type,
        value: removeListItem(data[type], type, listIndex).value,
      },
    });
  };

  const handleChangeSelect = (args, prop, index = null, listId = null) => {
    if (index !== null) {
      dataChange({
        target: {
          name: listId,
          value: updatePropInList(
            data[listId],
            { id: prop, value: args },
            index,
            listId
          ).value,
        },
      });
    } else
      dataChange({
        target: {
          name: prop,
          value: args,
        },
      });
  };

  const getProcedure = (str, i, type) => {
    const strCleaned = removeSpecialCharacters(str);
    if (strCleaned.length > 1) {
      apiProcedure(strCleaned)
        .getList()
        .then((res) => {
          setSugestions(res);
          setLoading(false);
          setListSearchError((prevState) =>
            prevState.filter((id) => id !== `procedimento${i}`)
          );
          setListSearchError((prevState) =>
            prevState.filter((i) => i !== "procedimento")
          );
        })
        .catch((err) => {
          console.log(err);
          if (type === "procedimentosExtras")
            setListSearchError((prevState) => [
              ...prevState,
              `procedimento${i}`,
            ]);
          else
            setListSearchError((prevState) => [...prevState, "procedimento"]);
          setSugestions([]);
          setLoading(false);
        });
    }
  };

  const getProcedureDebounce = useCallback(
    debounce((str, i, type) => getProcedure(str, i, type), 1000),
    []
  );

  const getSurgeon = (str, i, type) => {
    const surgeonCleaned = removeSpecialCharacters(str);
    apiSurgeon(surgeonCleaned)
      .getList()
      .then((res) => {
        setSugestions(res);
        setLoading(false);
        setListSearchError((prevState) =>
          prevState.filter((id) => id !== `cirurgiao${i}`)
        );
        setListSearchError((prevState) =>
          prevState.filter((i) => i !== "cirurgiao0")
        );
      })
      .catch((err) => {
        console.log(err);
        if (type === "cirurgiaoAdicional")
          setListSearchError((prevState) => [...prevState, `cirurgiao${i}`]);
        else setListSearchError((prevState) => [...prevState, "cirurgiao0"]);
        setSugestions([]);
        setLoading(false);
      });
  };

  const getSurgeonDebounce = useCallback(
    debounce((str, i, type) => getSurgeon(str, i, type), 1000),
    []
  );

  const getAnesthetist = (str) => {
    const anesthetistCleaned = removeSpecialCharacters(str);
    apiAnesthetist(anesthetistCleaned)
      .getList()
      .then((res) => {
        setSugestions(res);
        setLoading(false);
        setListSearchError((prevState) =>
          prevState.filter((i) => i !== "anestesista")
        );
      })
      .catch((err) => {
        console.log(err);
        setListSearchError((prevState) => [...prevState, "anestesista"]);
        setSugestions([]);
        setLoading(false);
      });
  };

  const getAnesthetistDebounce = useCallback(
    debounce((str) => getAnesthetist(str), 1000),
    []
  );

  const applyTimeMask = (value) => {
    // Remove caracteres não numéricos
    value = value.replace(/\D/g, "");

    if (value.length === 1) {
      // Se o primeiro dígito é maior que 2, substitua por 0 e o dígito
      if (parseInt(value, 10) > 2) {
        value = "0" + value;
      }
    } else if (value.length === 2) {
      // Se os dois primeiros dígitos formam uma hora maior que 23, ajuste
      const hours = parseInt(value.slice(0, 2), 10);
      if (hours > 23) {
        value = "23";
      }
    } else if (value.length === 3) {
      // Se o terceiro dígito é maior que 5, substitua por 0 e o dígito
      if (parseInt(value[2], 10) > 5) {
        value = value.slice(0, 2) + "0" + value[2];
      }
    } else if (value.length > 4) {
      // Se os minutos formam um valor maior que 59, ajuste
      const minutes = parseInt(value.slice(2, 4), 10);
      if (minutes > 59) {
        value = value.slice(0, 2) + "59";
      }
    }

    // Adiciona os dois pontos entre horas e minutos
    if (value.length > 2) {
      value = value.slice(0, 2) + ":" + value.slice(2, 4);
    }
    // Limita o comprimento para 5 caracteres (HH:MM)
    if (value.length > 5) {
      value = value.slice(0, 5);
    }
    return value;
  };

  const handleChangeInput = (value, prop, index = null, listId = null) => {
    if (index !== null)
      dataChange({
        target: {
          name: listId,
          value: updatePropInList(
            data[listId],
            { id: prop, value },
            index,
            listId
          ).value,
        },
      });
    else
      dataChange({
        target: {
          name: prop,
          value,
        },
      });
  };

  const updateSugestion = (type, str, i) => {
    if (str.length > 2)
      switch (type) {
        case "cirurgiao":
        case "cirurgiaoAdicional":
          getSurgeonDebounce(str, i, type);
          break;
        case "procedimentoAdicional":
        case "procedimentosExtras":
          getProcedureDebounce(str, i, type);
          break;
        case "nomeAnestesista":
          getAnesthetistDebounce(str);
          break;
        default:
          return;
      }
  };

  const handleAutocompleteField = (
    type,
    value,
    prop,
    sugestionProp = "",
    index = null,
    listId = null,
    field
  ) => {
    if (value?.length < 3) {
      setCurrentSugestionType(undefined);
      handleChangeInput(value, prop, index, listId);
      setLoading(false);
      return;
    }
    setCurrentSugestionType(type);
    handleChangeInput(value, prop, index, listId);
    setLoading(true);
    if (index === null) {
      if (sugestionProp) updateSugestion(type, value[sugestionProp]);
      else updateSugestion(type, value);
    } else {
      if (field === "nomeCirurgiao") {
        handleChangeInput("", "crmCirurgiao", index, "equipe");
      }
      updateSugestion(type.replace(/[0-9]/g, ""), value, index);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
    }
  };

  const handleReturnLabel = (label, rule) => {
    return `${label} ${rule ? "(indisponível na Unidade)" : ""}`;
  };

  const removeEmptyFilter = (obj) => {
    return Object.fromEntries(
      // eslint-disable-next-line no-unused-vars
      Object.entries(obj).filter(([_, v]) => v != null && v !== "")
    );
  };
  const reservaCriticaOptions = () => {
    if (data?.cdEstabelecimento === 321) {
      return criticalUnitsbyBSB;
    } else if (data?.cdEstabelecimento === 51) {
      return criticalUnitsbyItaim;
    } else {
      return criticalUnits;
    }
  };

  const criticalUnitsList = Object.keys(reservaCriticaOptions())?.map(
    (item) => ({
      label: item,
    })
  );

  const handleUpdateListItem = (oldValue, prop, index, newValue) => {
    const filteredValue = newValue;
    const oldObj = { ...oldValue };
    delete oldObj.status;

    const newObj = { ...oldObj, ...filteredValue };

    dataChange({
      target: {
        name: prop,
        value: updateIndexList(data[prop], index, newObj),
      },
    });
  };

  const verifyCdProcedureExtra = (i, cd) => {
    if (cd === "N/A" || cd === "" || !cd) {
      dataChange({
        target: {
          name: data.procedimentosExtras[i]?.procedimento,
          value: "",
        },
      });
      dataChange({
        target: {
          name: data.procedimentosExtras[i]?.cdProcedimento,
          value: "",
        },
      });
    }
  };

  const listTimes = (dt, index, max) => {
    const minutes = parseInt(dt.nr_minuto_duracao) / 30;
    const integer = Math.trunc(minutes);
    const float = minutes - integer;
    return (
      <>
        {integer &&
          Array(integer)
            .fill(0)
            ?.map((_item, it) => (
              <div key={it}>
                {it === 0 ? (
                  getFirstHour(
                    dt,
                    null,
                    dt.hr_inicio.substring(15, 16) === "00",
                    {
                      borderColor: "rgba(44, 110, 242, 1)",
                      borderRadius:
                        index === 0 || index === max
                          ? "6px 6px 0px 0px"
                          : "0px",
                      color: "rgba(255, 255, 255, 1)",
                      backgroundColor: "rgba(0, 84, 164, 1)",
                    },
                    ""
                  )
                ) : (
                  <S.SelectedFloatInfos
                    key={it}
                    height={float <= 5 && float !== 0 ? "30px" : null}
                    style={{
                      borderLeft: "1px solid",
                      borderColor: "rgba(44, 110, 242, 1)",
                      borderRight: "1px solid",
                      borderBottom:
                        it + 1 === integer && float === 0 ? "1px solid" : "0px",
                      borderRadius:
                        it === integer - 1 && float === 0
                          ? "0px 0px 6px 6px"
                          : "0px",
                      backgroundColor: "rgba(0, 84, 164, 1)",
                    }}
                    className="selected-float-infos "
                  >
                    {/* {it + 1 === integer && float === 0 ? <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}><button onClick={clearSchedule} className="change-time-btn">Alterar horário</button></div> : null} */}
                  </S.SelectedFloatInfos>
                )}
              </div>
            ))}
        {float ? (
          integer < 0 ? (
            getFirstHour(
              dt,
              float <= 5 && float !== 0 ? "30px" : null,
              dt.hr_inicio.substring(15, 16) === "00",
              {
                borderColor: "rgba(44, 110, 242, 1)",
                color: "rgba(255, 255, 255, 1)",
                borderRadius:
                  index === 0 || index === max ? "6px 6px 0px 0px" : "0px",
                backgroundColor: "rgba(0, 84, 164, 1)",
              },
              ""
            )
          ) : (
            <S.SelectedFloatInfos
              height={float <= 5 && float !== 0 ? "30px" : null}
              style={{
                borderLeft: "1px solid",
                borderColor: "rgba(44, 110, 242, 1)",
                borderRight: "1px solid",
                borderBottom: "1px solid",
                borderRadius: "0px 0px 6px 6px",
                backgroundColor: "rgba(0, 84, 164, 1)",
              }}
              className="selected-float-infos "
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              ></div>
            </S.SelectedFloatInfos>
          )
        ) : null}
      </>
    );
  };

  const getModal = (medico, id) => {
    if (modalOpen && idTeamExtra === id) {
      return (
        <Drawer
          title={<p>Atenção!</p>}
          label={
            <p>
              A solicitação do(a) <b>{medico}</b> já foi enviada. Você deseja
              mesmo removê-lo(a) da <br />
              cirurgia?
            </p>
          }
          buttonSize={"sm"}
          isOpened={true}
          isDoubleAction={true}
          buttonLabelPrimary={"Sim"}
          buttonLabelSecondary={"Voltar"}
          handleButtonClickPrimary={() => {
            if (data.equipe.length === 1) {
              dataChange({
                target: {
                  name: "equipeCirurgicaAdicional",
                  value: "Não",
                },
              });
            }
            removeItem("equipe", id);
            setModalOpen(false);
          }}
          handleButtonClickSecondary={() => setModalOpen(false)}
        />
      );
    }
  };
  const handleSelectSurgeryDate = (hour) => {
    dataChange({
      target: {
        name: "hora",
        value: hour,
      },
    });
  };

  const getFirstHour = (
    dt,
    height = null,
    haveMinutes,
    style = null,
    isNewSchedule = false,
    procedureInfos
  ) => {
    return haveMinutes ? (
      <S.SelectedFloatInfos
        height={height}
        style={{
          borderRadius: "6px 6px 0px 0px",
          borderLeft: "1px solid",
          color: style?.color ? style?.color : null,
          borderColor: style?.borderColor
            ? style?.borderColor
            : "rgba(44, 110, 242, 1)",
          borderRight: "1px solid",
          borderTop: "1px solid",
          backgroundColor: style?.backgroundColor
            ? style?.backgroundColor
            : "rgba(197, 215, 251, 1)",
        }}
        className="selected-float-infos "
      >
        <p>
          Paciente:{" "}
          <span>
            {dt.nm_paciente} {procedureInfos}
          </span>
        </p>
        <p>
          Duração:{" "}
          <span>{minutesToHourString(parseInt(dt.nr_minuto_duracao))}</span>
        </p>
        {parseInt(dt.nr_minuto_duracao) <= 30 && isNewSchedule ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          ></div>
        ) : null}
      </S.SelectedFloatInfos>
    ) : (
      <>
        <S.SelectedFloatInfos
          height={height}
          style={{
            color: style?.color ? style?.color : null,
            borderRadius:
              parseInt(dt.nr_minuto_duracao) < 60
                ? "6px 6px 6px 6px"
                : "6px 6px 0px 0px",
            backgroundColor: style?.backgroundColor
              ? style?.backgroundColor
              : "rgba(197, 215, 251, 1)",
            borderLeft: "1px solid",
            borderRight: "1px solid",
            borderBottom:
              parseInt(dt.nr_minuto_duracao) < 60 ? "1px solid" : null,
            borderColor: style?.borderColor
              ? style?.borderColor
              : "rgba(44, 110, 242, 1)",
            borderTop: "1px solid",
          }}
          className="selected-float-infos "
        >
          <p>
            Paciente:{" "}
            <span>
              {dt.nm_paciente} {procedureInfos}
            </span>
          </p>
          <p>
            Duração:{" "}
            <span>{minutesToHourString(parseInt(dt.nr_minuto_duracao))}</span>
          </p>
          {parseInt(dt.nr_minuto_duracao) < 60 && isNewSchedule ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <button onClick={clearSchedule} className="change-time-btn">
                Alterar horário
              </button>
            </div>
          ) : null}
        </S.SelectedFloatInfos>
      </>
    );
  };

  const listTimeNewDate = (dt) => {
    const minutes =
      parseInt(
        typeof dt.nr_minuto_duracao === "string"
          ? getMinutes(dt.nr_minuto_duracao)
          : dt.nr_minuto_duracao
      ) / 30;
    const integer = Math.trunc(minutes);
    const float = minutes - integer;
    const proceduresInfos = `(${dataPatient?.sexo}) - ${
      data?.procedimentoPrincipal?.procedimento ?? data?.procedimentoPrincipal
    }`;
    return (
      <>
        {integer !== 0
          ? Array(integer)
              .fill(0)
              ?.map((_item, it) => (
                <div key={it}>
                  {it === 0 ? (
                    getFirstHour(
                      dt,
                      null,
                      dt.hr_inicio.substring(3, 5) === "00",
                      null,
                      true,
                      proceduresInfos
                    )
                  ) : (
                    <S.SelectedFloatInfos
                      key={it}
                      height={float <= 5 && float !== 0 ? "30px" : null}
                      style={{
                        borderLeft: "1px solid",
                        borderColor: "rgba(44, 110, 242, 1)",
                        borderRight: "1px solid",
                        borderBottom:
                          it === integer - 1 && float === 0
                            ? "1px solid"
                            : "0px",
                        borderRadius:
                          it === integer - 1 && float === 0
                            ? "0px 0px 6px 6px"
                            : "0px",
                        backgroundColor: "rgba(197, 215, 251, 1)",
                      }}
                      className="selected-float-infos "
                    >
                      {it === integer - 1 && float === 0 ? (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <button
                            onClick={clearSchedule}
                            className="change-time-btn"
                          >
                            Alterar horário
                          </button>
                        </div>
                      ) : null}
                    </S.SelectedFloatInfos>
                  )}
                </div>
              ))
          : null}
        {float ? (
          integer < 0 ? (
            getFirstHour(
              dt,
              float <= 5 && float !== 0 ? "30px" : null,
              dt.hr_inicio.substring(3, 5) === "00",
              null,
              true,
              proceduresInfos
            )
          ) : (
            <S.SelectedFloatInfos
              height={float <= 5 && float !== 0 ? "51.5px" : null}
              style={{
                borderLeft: "1px solid",
                borderColor: "rgba(44, 110, 242, 1)",
                borderRight: "1px solid",
                borderBottom: "1px solid",
                borderRadius: "0px 0px 6px 6px",
                backgroundColor: "rgba(197, 215, 251, 1)",
              }}
              className="selected-float-infos "
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <button onClick={clearSchedule} className="change-time-btn">
                  Alterar horário
                </button>
              </div>
            </S.SelectedFloatInfos>
          )
        ) : null}
      </>
    );
  };

  const handleSetHour = () => {
    const solicitationData = data.data.split(" ")[0];

    const solicitationsWithSameDate = sequenceSurgery.filter(
      (i) => i.hr_inicio.split(" ")[0] === solicitationData
    );

    if (solicitationsWithSameDate.length > 0) {
      // O usuário não deve conseguir agendar um horário que se sobreponha a outro horário.

      const initHour = new Date(solicitationData + " " + data.hora);
      const durationInMinutes = getMinutes(data.tempoCirurgia);
      const endHour = new Date(
        initHour.getTime() + 1000 * 60 * durationInMinutes
      );

      const concurrencyHours = solicitationsWithSameDate.filter(
        (i) =>
          new Date(i.hr_inicio) > initHour && new Date(i.hr_inicio) < endHour
      );

      if (concurrencyHours.length > 0) {
        setScheduleOverlay(true);
        return;
      }
    }

    setNewDate({
      hr_inicio: data?.hora,
      nm_paciente: dataPatient.nome,
      nr_minuto_duracao: getMinutes(data?.tempoCirurgia),
      data: data?.data,
    });
  };

  const getDate = () => {
    const surgeryDate = new Date(
      `${newDate?.data?.substring(0, 10)} ${newDate?.hr_inicio}:00`
    );

    const getDuration = () => {
      let tempoCirurgia =
        typeof data?.tempoCirurgia !== "number"
          ? getMinutes(data?.tempoCirurgia)
          : data?.tempoCirurgia;

      if (Number.isNaN(tempoCirurgia)) {
        tempoCirurgia = 0;
      }

      if (isUnifiedSolicitation) {
        if (isAditionalTeam) {
          return editingSolicitation
            ? data?.tempoCirurgiaTotal
            : tempoCirurgia + data?.tempoCirurgiaTotal;
        }

        return data?.tempoCirurgiaTotal + tempoCirurgia;
      }

      return newDate?.nr_minuto_duracao;
    };

    const newDateWithMinutesAdded = addMinutes(surgeryDate, getDuration());

    const difference = differenceInCalendarDays(
      surgeryDate,
      newDateWithMinutesAdded
    );

    return `${format(surgeryDate, "dd/MM/yyyy", {
      locale: ptBR,
    })}${
      difference
        ? ` - ${format(newDateWithMinutesAdded, "dd/MM/yyyy", {
            locale: ptBR,
          })}`
        : ""
    } | ${format(surgeryDate, "HH:mm aaa", {
      locale: ptBR,
    })} - ${format(newDateWithMinutesAdded, "HH:mm aaa", {
      locale: ptBR,
    })}`;
  };

  const clearServicesDisableds  =() => {
    fields.services.forEach((field) => {
    if (field.disabled.includes(data.cdEstabelecimento.toString())) {
      populateFormData({
        target: {
          name: field.name,
          value: field.initialValue,
        },
      });
    }

    if(field.name === "biopsiaCongelacao" && data.cdEstabelecimento === "321" && data.biopsiaCongelacao === "radioExt"){
      populateFormData({
        target: {
          name: field.name,
          value: field.initialValue,
        },
      });
    }
  })
  }
  useEffect(() => {
    clearServicesDisableds()
  }, [])

  return (
    <SC.Container>
      <SC.BoxTitle>
        <SC.Title>Seu(s) procedimento(s)</SC.Title>
        <TmpDivider />
      </SC.BoxTitle>
      <SC.StyledSectionWrapper rows={"1fr fit-content"}>
        <SC.StyledContent columns={"2fr 1fr"} columnsMd={"1fr"} gapMD={"40px"}>
          <AutocompleteRow>
            <S.InputText
              autoComplete="off"
              width="100%"
              label="Procedimento principal/TUSS *"
              name="Procedimento principal"
              type="text"
              placeholder="Informe o procedimento desejado"
              error={
                (data?.procedimentoPrincipal?.procedimento === "" ||
                  !data?.cdProcedimentoPrincipal) &&
                error
              }
              value={
                typeof data?.procedimentoPrincipal?.procedimento === "string"
                  ? data.procedimentoPrincipal?.procedimento
                  : typeof data?.procedimentoPrincipal === "string"
                  ? data.procedimentoPrincipal
                  : ""
              }
              handleChange={(e) => {
                if (e.target.value.trim().length > 2) {
                  handleAutocompleteField(
                    "procedimentoAdicional",
                    { procedimento: e.target.value.trim() },
                    "procedimentoPrincipal",
                    "procedimento"
                  );
                } else {
                  setCurrentSugestionType(undefined);
                  dataChange({
                    target: {
                      name: "cdProcedimentoPrincipal",
                      value: "",
                    },
                  });
                  dataChange({
                    target: {
                      name: "tuss",
                      value: "",
                    },
                  });
                  handleChangeInput(
                    { procedimento: e.target.value.trim() },
                    "procedimentoPrincipal"
                  );
                  setLoading(false);
                }
              }}
              helperText={
                listSearchError.includes("procedimento") ? (
                  <Reload
                    handleClick={() => {
                      setListSearchError((prevState) =>
                        prevState.filter((i) => i !== "procedimento")
                      );
                      getProcedure(data?.procedimentoPrincipal?.procedimento);
                    }}
                  />
                ) : data?.procedimentoPrincipal?.procedimento === "" &&
                  error ? (
                  <Error>Campo obrigatório</Error>
                ) : null
              }
              helperIcon={
                data?.procedimentoPrincipal?.procedimento === "" && error ? (
                  <ErrorIcon />
                ) : null
              }
            />
            {data?.procedimentoPrincipal?.procedimento &&
            currentSugestionType === "procedimentoAdicional" ? (
              <Autocomplete
                isCurrentInput={
                  currentSugestionType === "procedimentoAdicional"
                }
                list={searchListWithPriorityOrder(
                  sugestions,
                  typeof data?.procedimentoPrincipal?.procedimento === "string"
                    ? data.procedimentoPrincipal?.procedimento
                    : typeof data?.procedimentoPrincipal === "string"
                    ? data.procedimentoPrincipal
                    : "",
                  "procedimento",
                  false
                )}
                isLoading={loading}
                propLabel="procedimento"
                inputName="procedimentoPrincipal"
                onClick={(value) => {
                  dataChange({
                    target: {
                      name: "cdProcedimentoPrincipal",
                      value: value.cdProcedimento,
                    },
                  });
                  const filteredValue = removeEmptyFilter(value);
                  handleChangeInput(
                    {
                      ...filteredValue,
                      procedimento: `${value.tuss} - ${value.procedimento}`,
                      nomeProcedimento: value.procedimento,
                    },
                    "procedimentoPrincipal"
                  );
                  dataChange({
                    target: {
                      name: "tuss",
                      value: value?.tuss,
                    },
                  });
                }}
              />
            ) : null}
          </AutocompleteRow>
          {data?.procedimentoPrincipal?.exige_lado === "S" ? (
            <SC.SelectWrap
              maxHeight
              placeholderColor={data?.lateralidade !== ""}
            >
              <TmpSelect
                label="Lateralidade *"
                name="Lateralidade"
                placeholder="Selecione a opção de Lateralidade"
                listItems={domains?.lateralidade.sort((a, b) =>
                  collator.compare(a.label, b.label)
                )}
                value={getSelectObjValue(data?.lateralidade)}
                error={data?.lateralidade === "" && error}
                handleChange={(args) => {
                  dataChange({
                    target: {
                      name: "ieLado",
                      value: domains.ieLado.filter(
                        (item) => item?.ds_valor_dominio === args.label
                      )[0].vl_dominio,
                    },
                  });
                  handleChangeSelect(args.label, "lateralidade");
                }}
                helperText={
                  data?.lateralidade === "" && error ? (
                    <Error>Campo obrigatório</Error>
                  ) : (
                    ""
                  )
                }
                helperIcon={
                  data?.lateralidade === "" && error ? (
                    <ErrorIcon style={{ color: "#EA1F1F" }} />
                  ) : (
                    <ErrorIcon style={{ visibility: "hidden" }} />
                  )
                }
              />
            </SC.SelectWrap>
          ) : (
            <SC.SelectWrap
              maxHeight
              placeholderColor={data?.lateralidade !== ""}
            >
              <TmpSelect
                label="Lateralidade"
                name="Lateralidade"
                placeholder="Selecione a opção de Lateralidade"
                listItems={domains?.lateralidade.sort((a, b) =>
                  collator.compare(a.label, b.label)
                )}
                value={getSelectObjValue(data?.lateralidade)}
                onClick={
                  isAditionalTeam ? onClickFilledFieldByMainTeam : undefined
                }
                handleChange={(args) => {
                  dataChange({
                    target: {
                      name: "ieLado",
                      value: domains.ieLado.filter(
                        (item) => item?.ds_valor_dominio === args.label
                      )[0].vl_dominio,
                    },
                  });
                  handleChangeSelect(args.label, "lateralidade");
                }}
              />
            </SC.SelectWrap>
          )}
        </SC.StyledContent>
        {data?.procedimentosExtras?.length > 0 && (
          <div>
            {data?.procedimentosExtras?.map((item, index) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "33px 0",
                  backgroundColor: (index + 1) % 2 ? "#f5f5f5" : "#ffffff",
                  borderTop:
                    (index + 1) % 2
                      ? "none"
                      : "1px solid rgba(224, 224, 224, 1)",
                  borderBottom:
                    (index + 1) % 2
                      ? "none"
                      : "1px solid rgba(224, 224, 224, 1)",
                }}
                key={index}
              >
                <S.BoxMap>
                  <S.BoxMapAddNewExtraProcedure>
                    <div>
                      <S.IconWrapper>
                        <IconExcluir
                          width="30px"
                          height="30px"
                          cursor="pointer"
                          color="red"
                          onClick={() => {
                            removeItem("procedimentosExtras", index);
                          }}
                        />
                      </S.IconWrapper>
                      <AutocompleteRow style={{ flex: 1 }}>
                        <S.InputText
                          autoComplete="off"
                          label="Procedimento adicional *"
                          placeholder="Informe o Procedimento adicional"
                          name="procedure_aditional"
                          width="100%"
                          error={
                            (item.procedimento === "" ||
                              !item.cdProcedimento) &&
                            error
                          }
                          value={item.procedimento}
                          handleChange={(e) => {
                            handleAutocompleteField(
                              `procedimentosExtras${index}`,
                              e.target.value.trim(),
                              "procedimento",
                              null,
                              index,
                              "procedimentosExtras"
                            );
                            verifyCdProcedureExtra(item.cdProcedimento, index);
                            handleUpdateListItem(
                              item,
                              "procedimentosExtras",
                              index,
                              {
                                ...item,
                                procedimento: e.target.value.trim(),
                                cdProcedimento: "",
                                tuss: "",
                              }
                            );
                          }}
                          helperText={
                            listSearchError.includes(`procedimento${index}`) ? (
                              <Reload
                                handleClick={() => {
                                  setListSearchError((prevState) =>
                                    prevState.filter(
                                      (i) => i !== `procedimento${index}`
                                    )
                                  );
                                  getProcedure(
                                    item.procedimento,
                                    index,
                                    "procedimentosExtras"
                                  );
                                }}
                              />
                            ) : item.procedimento === "" && error ? (
                              <Error>Campo obrigatório</Error>
                            ) : (
                              ""
                            )
                          }
                          helperIcon={
                            item.procedimento === "" && error ? (
                              <ErrorIcon />
                            ) : (
                              <ErrorIcon style={{ visibility: "hidden" }} />
                            )
                          }
                        />
                        {item.procedimento &&
                        currentSugestionType ===
                          `procedimentosExtras${index}` ? (
                          <Autocomplete
                            isCurrentInput={
                              currentSugestionType ===
                              `procedimentosExtras${index}`
                            }
                            isLoading={loading}
                            list={searchListWithPriorityOrder(
                              sugestions,
                              item?.procedimento,
                              "procedimento",
                              false
                            )}
                            propLabel="procedimento"
                            inputName="procedimentosExtras"
                            onClick={(value) => {
                              handleUpdateListItem(
                                item,
                                "procedimentosExtras",
                                index,
                                {
                                  ...value,
                                  procedimento: `${value.tuss} - ${value.procedimento}`,
                                  nomeProcedimento: value.procedimento,
                                }
                              );
                            }}
                          />
                        ) : null}
                      </AutocompleteRow>
                    </div>
                    <SC.SelectWrap
                      style={{ flex: 1 }}
                      paddingMd={"0 0 0 40px"}
                      placeholderColor={item.lateralidade !== ""}
                      marginRightLG={"24px"}
                    >
                      <TmpSelect
                        label={`Lateralidade ${
                          item.exige_lado === "S" ? "*" : ""
                        }`}
                        placeholder="Selecione a opção de Lateralidade"
                        listItems={domains?.lateralidade.sort((a, b) =>
                          collator.compare(a.label, b.label)
                        )}
                        name="lateralidade"
                        value={getSelectObjValue(item.lateralidade)}
                        error={
                          item.lateralidade === "" &&
                          error &&
                          data?.procedimentosExtras[index].exige_lado === "S"
                        }
                        handleChange={(args) => {
                          const updatedIeList = updatePropInList(
                            data["procedimentosExtras"],
                            {
                              id: "ieLado",
                              value: domains.ieLado.filter(
                                (item) => item?.ds_valor_dominio === args.label
                              )[0].vl_dominio,
                            },
                            index,
                            "procedimentosExtras"
                          ).value;

                          const updatedList = updatePropInList(
                            updatedIeList,
                            { id: "lateralidade", value: args?.label },
                            index,
                            "procedimentosExtras"
                          ).value;

                          dataChange({
                            target: {
                              name: "procedimentosExtras",
                              value: updatedList,
                            },
                          });
                        }}
                        helperText={
                          item.lateralidade === "" &&
                          error &&
                          data?.procedimentosExtras[index].exige_lado ===
                            "S" ? (
                            <Error>Campo obrigatório</Error>
                          ) : (
                            ""
                          )
                        }
                        helperIcon={
                          item.lateralidade === "" &&
                          error &&
                          data?.procedimentosExtras[index].exige_lado ===
                            "S" ? (
                            <ErrorIcon style={{ color: "#EA1F1F" }} />
                          ) : (
                            <ErrorIcon style={{ visibility: "hidden" }} />
                          )
                        }
                      />
                    </SC.SelectWrap>
                  </S.BoxMapAddNewExtraProcedure>
                </S.BoxMap>
              </div>
            ))}
          </div>
        )}

        <div style={{ width: "100%", height: "fit-content" }}>
          <TmpButton
            handleClick={() => addNewItem("procedimentosExtras")}
            startIcon={<IconMais />}
            type="secondary"
          >
            Adicionar mais Procedimentos
          </TmpButton>
        </div>

        <SC.StyledContent
          columns={"repeat(3, 1fr)"}
          columnsMd={"repeat(2, 2fr)"}
          gap={"40px 24px"}
          gapMD={"40px 16px"}
        >
          <SC.SelectWrap
            maxHeight
            placeholderColor={data?.regimeHospitalar !== ""}
            onClick={isAditionalTeam ? onClickFilledFieldByMainTeam : undefined}
          >
            <TmpSelect
              label="Regime Hospitalar *"
              name="Regime Hospitalar"
              placeholder="Selecione Regime Hospitalar"
              listItems={domains?.regime_atend}
              value={getSelectObjValue(data?.regimeHospitalar)}
              error={data?.regimeHospitalar === "" && error}
              handleChange={(args) => {
                handleChangeSelect(args.label, "regimeHospitalar");
                handleChangeInput("", "diarias");
              }}
              onClick={
                isAditionalTeam ? onClickFilledFieldByMainTeam : undefined
              }
              disabled={isAditionalTeam}
              helperText={
                data?.regimeHospitalar === "" && error ? (
                  <Error>Campo obrigatório</Error>
                ) : (
                  ""
                )
              }
              helperIcon={
                data?.regimeHospitalar === "" && error ? (
                  <ErrorIcon style={{ color: "#EA1F1F" }} />
                ) : (
                  <ErrorIcon style={{ visibility: "hidden" }} />
                )
              }
            />
          </SC.SelectWrap>
          {editingModel === false && data?.regimeHospitalar === "Internado" ? (
            <SC.SelectWrap maxHeight>
              <S.InputText
                autoComplete="off"
                widthmedia="100%"
                label="Diárias *"
                name="Diárias"
                type="number"
                onKeyDown={(e) => handleKeyDown(e)}
                onWheel={(e) => e.target.blur()}
                min="0"
                placeholder="Informe a quantidade de diárias"
                error={checkQuantity(String(data?.diarias), "diaria") && error}
                value={data?.diarias}
                onClick={
                  isAditionalTeam ? onClickFilledFieldByMainTeam : undefined
                }
                disabled={isAditionalTeam}
                handleChange={(e) =>
                  handleChangeInput(e.target.value.trim(), "diarias")
                }
                helperText={
                  data?.diarias === "" && error ? (
                    <Error>Campo obrigatório</Error>
                  ) : error &&
                    checkQuantity(String(data?.diarias), "diaria") ? (
                    "Quantidade inválida. O valor deve ser maior ou igual a 1."
                  ) : (
                    ""
                  )
                }
                helperIcon={
                  data?.diarias === "" && error ? (
                    <ErrorIcon style={{ color: "#EA1F1F" }} />
                  ) : error && checkQuantity(String(data?.diarias)) ? (
                    <ErrorIcon style={{ color: "#EA1F1F" }} />
                  ) : null
                }
              />
            </SC.SelectWrap>
          ) : null}
          <SC.SelectWrap
            maxHeight
            placeholderColor={data?.carater !== ""}
            onClick={isAditionalTeam ? onClickFilledFieldByMainTeam : undefined}
          >
            <TmpSelect
              label="Caráter *"
              name="Carater"
              placeholder="Selecionar Caráter"
              listItems={domains?.carater_atend.sort((a, b) =>
                collator.compare(a.label, b.label)
              )}
              value={getSelectObjValue(data?.carater)}
              onClick={
                isAditionalTeam ? onClickFilledFieldByMainTeam : undefined
              }
              disabled={isAditionalTeam}
              error={data?.carater === "" && error}
              handleChange={(args) => handleChangeSelect(args.label, "carater")}
              helperText={
                data?.carater === "" && error ? (
                  <Error>Campo obrigatório</Error>
                ) : (
                  ""
                )
              }
              helperIcon={
                data?.carater === "" && error ? (
                  <ErrorIcon style={{ color: "#EA1F1F" }} />
                ) : (
                  <ErrorIcon style={{ visibility: "hidden" }} />
                )
              }
            />
          </SC.SelectWrap>

          <SC.SelectWrap
            maxHeight
            placeholderColor={data?.tipagemSanguinea !== ""}
            onClick={isAditionalTeam ? onClickFilledFieldByMainTeam : undefined}
          >
            <TmpSelect
              label="Tipagem Sanguínea *"
              name="Tipagem Sanguinea"
              placeholder="Selecionar opção de tipagem sanguínea "
              listItems={[
                {
                  label: "Sim",
                },
                {
                  label: "Não",
                },
              ]}
              value={getSelectObjValue(data?.tipagemSanguinea)}
              onClick={
                isAditionalTeam ? onClickFilledFieldByMainTeam : undefined
              }
              disabled={isAditionalTeam}
              error={data?.tipagemSanguinea === "" && error}
              handleChange={(args) =>
                handleChangeSelect(args.label, "tipagemSanguinea")
              }
              helperText={
                data?.tipagemSanguinea === "" && error ? (
                  <Error>Campo obrigatório</Error>
                ) : (
                  ""
                )
              }
              helperIcon={
                data?.tipagemSanguinea === "" && error ? (
                  <ErrorIcon style={{ color: "#EA1F1F" }} />
                ) : (
                  <ErrorIcon style={{ visibility: "hidden" }} />
                )
              }
            />
          </SC.SelectWrap>
          <SC.SelectWrap
            maxHeight
            placeholderColor={data?.reservaHemocomponentes !== ""}
            onClick={isAditionalTeam ? onClickFilledFieldByMainTeam : undefined}
          >
            <TmpSelect
              label="Reserva de Hemocomponentes *"
              name="Reserva de Hemocomponentes"
              placeholder="Selecionar opção de Reserva de Hemocomponentes"
              listItems={[
                {
                  label: "Sim",
                },
                {
                  label: "Não",
                },
              ]}
              value={getSelectObjValue(data?.reservaHemocomponentes)}
              onClick={
                isAditionalTeam ? onClickFilledFieldByMainTeam : undefined
              }
              disabled={isAditionalTeam}
              error={data?.reservaHemocomponentes === "" && error}
              handleChange={(args) =>
                handleChangeSelect(args.label, "reservaHemocomponentes")
              }
              helperText={
                data?.reservaHemocomponentes === "" && error ? (
                  <Error>Campo obrigatório</Error>
                ) : (
                  ""
                )
              }
              helperIcon={
                data?.reservaHemocomponentes === "" && error ? (
                  <ErrorIcon style={{ color: "#EA1F1F" }} />
                ) : (
                  <ErrorIcon style={{ visibility: "hidden" }} />
                )
              }
            />
          </SC.SelectWrap>
          <SC.SelectWrap
            maxHeight
            placeholderColor={data?.reservaCritica !== ""}
            onClick={isAditionalTeam ? onClickFilledFieldByMainTeam : undefined}
          >
            <TmpSelect
              label="Reserva de Unidade Crítica ou Semi-crítica *"
              name="Reserva de Unidade Crítica ou Semi-crítica"
              placeholder="Selecionar opção de Reserva de unidade "
              listItems={criticalUnitsList?.sort((a, b) =>
                collator.compare(a.label, b.label)
              )}
              error={data?.reservaCritica === "" && error}
              value={getSelectObjValue(data?.reservaCritica)}
              onClick={
                isAditionalTeam ? onClickFilledFieldByMainTeam : undefined
              }
              disabled={isAditionalTeam}
              handleChange={(args) => {
                handleChangeSelect(args.label, "reservaCritica");
                dataChange({
                  target: {
                    name: "cdReservaCritica",
                    value: criticalUnits[args.label],
                  },
                });
              }}
              helperText={
                data?.reservaCritica === "" && error ? (
                  <Error>Campo obrigatório</Error>
                ) : (
                  ""
                )
              }
              helperIcon={
                data?.reservaCritica === "" && error ? (
                  <ErrorIcon style={{ color: "#EA1F1F" }} />
                ) : (
                  <ErrorIcon style={{ visibility: "hidden" }} />
                )
              }
            />
          </SC.SelectWrap>
        </SC.StyledContent>
      </SC.StyledSectionWrapper>

      <SC.BoxTitle>
        <SC.Title>Equipes Envolvidas</SC.Title>
        <TmpDivider />
      </SC.BoxTitle>
      <SC.StyledSectionWrapper rows={"1fr fit-content"}>
        <SC.StyledContent
          gapMD={"40px 24px"}
          columns={"repeat(3, 1fr)"}
          columnsMd={"1fr"}
          gap={"40px 24px"}
        >
          <SC.SelectWrap
            maxHeight
            placeholderColor={data?.anestesista.tipo !== ""}
            onClick={isAditionalTeam ? onClickFilledFieldByMainTeam : undefined}
          >
            <TmpSelect
              label="Anestesista *"
              name="tipoAnestesista"
              placeholder="Selecione a opção de Anestesia"
              listItems={
                data?.cdEstabelecimento === 321
                  ? [
                      {
                        label: "Sem Anestesista",
                      },
                      {
                        label: "Anestesista da Casa SANI",
                      },
                    ]
                  : [
                      {
                        label: "Sem Anestesista",
                      },
                      {
                        label: "Anestesista da Casa (SMA/SANI)",
                      },
                      {
                        label: "Anestesista Particular",
                      },
                    ]
              }
              value={getSelectObjValue(data?.anestesista.tipo)}
              onClick={
                isAditionalTeam ? onClickFilledFieldByMainTeam : undefined
              }
              disabled={editingModel || isAditionalTeam}
              error={data?.anestesista.tipo === "" && error}
              handleChange={(args) => {
                dataChange({
                  target: {
                    name: "anestesista",
                    value: {
                      tipo: args.label,
                      id: "",
                      nome: "",
                      crm: "",
                      telefone: "",
                      codPessoaFisica: "",
                    },
                  },
                });
              }}
              helperText={
                data?.anestesista.tipo === "" && error ? (
                  <Error>Campo obrigatório</Error>
                ) : (
                  ""
                )
              }
              helperIcon={
                data?.anestesista.tipo === "" && error ? (
                  <ErrorIcon style={{ color: "#EA1F1F" }} />
                ) : (
                  <ErrorIcon style={{ visibility: "hidden" }} />
                )
              }
            />
          </SC.SelectWrap>
          <AutocompleteRow>
            <TmpInput
              autoComplete="off"
              widthmediasm="100%"
              // marginmediasm="40px 0 0px 0"
              label={`Nome do(a) anestesista ${
                data?.anestesista.tipo === "Anestesista Particular" ? "*" : ""
              }`}
              name="Nome do(a) anestesistal"
              type="text"
              placeholder="Informe o nome do anestesista"
              onClick={
                isAditionalTeam ? onClickFilledFieldByMainTeam : undefined
              }
              disabled={
                editingModel ||
                isAditionalTeam ||
                data?.anestesista.tipo !== "Anestesista Particular"
              }
              error={
                data?.anestesista.tipo === "Anestesista Particular" &&
                data?.anestesista.nome === "" &&
                error
              }
              value={data?.anestesista.nome}
              handleChange={(e) => {
                handleAutocompleteField(
                  "nomeAnestesista",
                  e.target.value.trim(),
                  "nomeAnestesista"
                );

                dataChange({
                  target: {
                    name: "anestesista",
                    value: {
                      ...data.anestesista,
                      nome: e.target.value,
                    },
                  },
                });
              }}
              helperText={
                listSearchError.includes("anestesista") ? (
                  <Reload
                    handleClick={() => {
                      getAnesthetist(data?.anestesista.nome);
                      setListSearchError((prevState) =>
                        prevState.filter((i) => i !== "anestesista")
                      );
                    }}
                  />
                ) : data?.anestesista.nome === "" &&
                  error &&
                  data?.anestesista.tipo === "Anestesista Particular" ? (
                  <Error>Campo obrigatório</Error>
                ) : null
              }
              helperIcon={
                data?.anestesista.nome === "" &&
                error &&
                data?.anestesista.tipo === "Anestesista Particular" ? (
                  <ErrorIcon />
                ) : null
              }
            />
            {data?.anestesista.nome &&
            currentSugestionType === "nomeAnestesista" ? (
              <Autocomplete
                isCurrentInput={currentSugestionType === "nomeAnestesista"}
                list={sugestions}
                isLoading={loading}
                propLabel="nomeAnestesista"
                inputName="nomeAnestesista"
                disabled={isAditionalTeam}
                onClick={
                  isAditionalTeam
                    ? onClickFilledFieldByMainTeam
                    : (value) => {
                        dataChange({
                          target: {
                            name: "anestesista",
                            value: {
                              ...data.anestesista,
                              id: value?.id || "",
                              codPessoaFisica:
                                value?.codPessoaFisicaAnestesista || "",
                              telefone: value?.telefoneAnestesista || "",
                              crm: value?.crmAnestesista || "",
                              nome: value?.nomeAnestesista || "",
                            },
                          },
                        });
                      }
                }
              />
            ) : null}
          </AutocompleteRow>
          <div style={{ maxHeight: "88px" }}>
            <TmpInput
              autoComplete="off"
              label={`CRM ${
                data?.anestesista.tipo === "Anestesista Particular" ? "*" : ""
              }`}
              name="CRM"
              type="text"
              placeholder="Informe o CRM"
              error={
                data?.anestesista.crm === "" &&
                error &&
                data?.anestesista.tipo === "Anestesista Particular"
              }
              value={data?.anestesista.crm}
              onClick={
                isAditionalTeam ? onClickFilledFieldByMainTeam : undefined
              }
              disabled={
                editingModel ||
                isAditionalTeam ||
                data?.anestesista.tipo !== "Anestesista Particular"
              }
              handleChange={(e) => {
                dataChange({
                  target: {
                    name: "anestesista",
                    value: {
                      ...data.anestesista,
                      crm: e.target.value.trim(),
                    },
                  },
                });
              }}
              helperText={
                notFoundDoctor.isError ? (
                  "Anestesista não encontrado(a)."
                ) : data?.anestesista.crm === "" &&
                  error &&
                  data?.anestesista.tipo === "Anestesista Particular" ? (
                  <Error>Campo obrigatório</Error>
                ) : (
                  ""
                )
              }
              helperIcon={
                notFoundDoctor.isError ? (
                  <IconAlert />
                ) : data?.anestesista.crm === "" &&
                  error &&
                  data?.anestesista.tipo === "Anestesista Particular" ? (
                  <ErrorIcon />
                ) : null
              }
            />
          </div>

          <SC.SelectWrap>
            <p className="label">Haverá outra equipe cirúrgica? *</p>
            <SC.RadioWrapper>
              <TmpRadio
                label="Sim"
                value="Sim"
                name="Equipe Adicional"
                checked={data.equipeCirurgicaAdicional === "Sim"}
                onClick={
                  isAditionalTeam ? onClickFilledFieldByMainTeam : undefined
                }
                disabled={isAditionalTeam}
                handleChange={() => {
                  const newProcedureData =
                    unifiedProcedures(data).addTeam("equipe");
                  setExtraTeamsDrawer((prevState) => ({
                    ...prevState,
                    renderOnAdvanceStep: true,
                  }));

                  dataChangeObj({
                    target: {
                      name: "procedimento",
                      value: {
                        ...newProcedureData,
                        equipeCirurgicaAdicional: "Sim",
                        equipeResponsavel: true,
                      },
                    },
                  });

                  dataChange({
                    target: {
                      name: "equipeCirurgicaAdicional",
                      value: "Sim",
                    },
                  });
                }}
              />
              <TmpRadio
                label="Não"
                value="Não"
                name="Equipe Adicional"
                onClick={
                  isAditionalTeam ? onClickFilledFieldByMainTeam : undefined
                }
                disabled={isAditionalTeam}
                checked={data.equipeCirurgicaAdicional === "Não"}
                handleChange={() => {
                  const newProcedureData =
                    unifiedProcedures(data).removeAllTeams();

                  setExtraTeamsDrawer((prevState) => ({
                    ...prevState,
                    renderOnAdvanceStep: false,
                    render: false,
                  }));

                  dataChangeObj({
                    target: {
                      name: "procedimento",
                      value: {
                        ...newProcedureData,
                        equipeCirurgicaAdicional: "Não",
                        equipeResponsavel: "",
                      },
                    },
                  });
                }}
              />
            </SC.RadioWrapper>
          </SC.SelectWrap>
          <SC.SelectWrap disabled={data.equipeCirurgicaAdicional === "Não"}>
            <p className="label">
              Sua equipe é a titular (responsável pelo paciente)?*
            </p>

            <SC.RadioWrapper>
              <TmpRadio
                label="Sim"
                value="Sim"
                name="Equipe Titular"
                onClick={
                  isAditionalTeam ? onClickFilledFieldByMainTeam : undefined
                }
                disabled={
                  data.equipeCirurgicaAdicional === "Não" || isAditionalTeam
                }
                checked={data.equipeResponsavel}
                handleChange={() =>
                  dataChange({
                    target: {
                      name: "equipeResponsavel",
                      value: true,
                    },
                  })
                }
              />
              <TmpRadio
                label="Não"
                value="Não"
                name="Equipe Titular"
                onClick={
                  isAditionalTeam ? onClickFilledFieldByMainTeam : undefined
                }
                disabled={true}
                checked={data.equipeResponsavel === false}
                handleChange={() =>
                  dataChange({
                    target: {
                      name: "equipeResponsavel",
                      value: false,
                    },
                  })
                }
              />
            </SC.RadioWrapper>
          </SC.SelectWrap>
        </SC.StyledContent>
        {data?.equipe.length > 0 && data.equipeCirurgicaAdicional === "Sim" ? (
          <div>
            <>Equipe adicional</>
            {data?.equipe?.map((item, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: (index + 1) % 2 ? "#ffffff" : "#f5f5f5",
                    borderTop:
                      (index + 1) % 2
                        ? "none"
                        : "1px solid rgba(224, 224, 224, 1)",
                    borderBottom:
                      (index + 1) % 2
                        ? "none"
                        : "1px solid rgba(224, 224, 224, 1)",
                    padding: "33px 0",
                  }}
                  key={index}
                >
                  {modalOpen ? getModal(item.nomeCirurgiao, index) : null}
                  <S.BoxMap>
                    <S.BoxMapAddNewExtraProcedure>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <S.IconWrapper>
                          <IconExcluir
                            width="30px"
                            height="30px"
                            cursor="pointer"
                            color="red"
                            onClick={
                              isAditionalTeam
                                ? onClickFilledFieldByMainTeam
                                : () => {
                                    if (data?.equipe.length) {
                                      if (item?.status === "Confirmada") {
                                        setModalOpen(true);
                                        setIdTeamExtra(index);
                                      } else {
                                        if (data.equipe.length === 1) {
                                          dataChange({
                                            target: {
                                              name: "equipeCirurgicaAdicional",
                                              value: "Não",
                                            },
                                          });
                                        }
                                        removeItem("equipe", index);
                                      }
                                      setExtraTeamsDrawer((prevState) => ({
                                        ...prevState,
                                        renderOnAdvanceStep: false,
                                        render: false,
                                      }));
                                    }
                                  }
                            }
                            disabled={isAditionalTeam}
                          />
                        </S.IconWrapper>
                        <AutocompleteRow style={{ flex: 1 }}>
                          <S.InputText
                            autoComplete="off"
                            label="Nome do(a) cirugião(ã) responsável *"
                            placeholder="Informe o Nome do(a) cirurgião(ã)"
                            name="name_cirurgiao"
                            width="100%"
                            error={
                              item.status === "Recusada" ||
                              (item.nomeCirurgiao === "" && error)
                            }
                            value={item.nomeCirurgiao}
                            onClick={
                              isAditionalTeam
                                ? onClickFilledFieldByMainTeam
                                : undefined
                            }
                            disabled={isAditionalTeam}
                            handleChange={(e) =>
                              handleAutocompleteField(
                                `cirurgiaoAdicional${index}`,
                                e.target.value.trim(),
                                "nomeCirurgiao",
                                null,
                                index,
                                "equipe",
                                "nomeCirurgiao"
                              )
                            }
                            helperText={
                              item?.status ? (
                                teamConfirmed(item?.status, "text")
                              ) : listSearchError.includes(
                                  `cirurgiao${index}`
                                ) &&
                                data?.equipeCirurgicaAdicional === "Sim" ? (
                                <Reload
                                  handleClick={() => {
                                    setListSearchError((prevState) =>
                                      prevState.filter(
                                        (i) => i !== `cirurgiao${index}`
                                      )
                                    );
                                    getSurgeon(
                                      item.nomeCirurgiao,
                                      index,
                                      "cirurgiaoAdicional"
                                    );
                                  }}
                                />
                              ) : item.nomeCirurgiao === "" && error ? (
                                <Error>Campo obrigatório</Error>
                              ) : (
                                ""
                              )
                            }
                            helperIcon={
                              item?.status ? (
                                teamConfirmed(item?.status, "icon")
                              ) : item.nomeCirurgiao === "" && error ? (
                                <ErrorIcon />
                              ) : (
                                <ErrorIcon style={{ visibility: "hidden" }} />
                              )
                            }
                          />
                          {item.nomeCirurgiao &&
                          currentSugestionType ===
                            `cirurgiaoAdicional${index}` ? (
                            <Autocomplete
                              isCurrentInput={
                                currentSugestionType ===
                                `cirurgiaoAdicional${index}`
                              }
                              isLoading={loading}
                              list={sugestions}
                              propLabel="nomeCirurgiao"
                              inputName="nomeCirurgiao"
                              disabled={isAditionalTeam}
                              onClick={
                                isAditionalTeam
                                  ? onClickFilledFieldByMainTeam
                                  : (value) => {
                                      let obj = {
                                        nomeCirurgiao: value.nomeCirurgiao,
                                        crmCirurgiao: verifyValue(
                                          value.crmCirurgiao
                                        ),
                                        idMedico: value.idMedico,
                                        telefoneCirurgiao:
                                          value.telefoneCirurgiao,
                                      };
                                      handleUpdateListItem(
                                        item,
                                        "equipe",
                                        index,
                                        obj
                                      );
                                    }
                              }
                            />
                          ) : null}
                        </AutocompleteRow>
                      </div>

                      <div style={{ maxHeight: "88px" }}>
                        <S.InputText
                          autoComplete="off"
                          label="CRM *"
                          placeholder="Informe o CRM"
                          name="crm"
                          type="text"
                          error={
                            item?.status === "Recusada" ||
                            (item.crmCirurgiao === "" && error)
                          }
                          value={verifyValue(item.crmCirurgiao)}
                          onClick={
                            isAditionalTeam
                              ? onClickFilledFieldByMainTeam
                              : undefined
                          }
                          disabled={isAditionalTeam}
                          handleChange={(e) =>
                            handleAutocompleteField(
                              `crmCirurgiao${index}`,
                              e.target.value.trim(),
                              "crmCirurgiao",
                              null,
                              index,
                              "equipe"
                            )
                          }
                          helperText={
                            item?.status === "Recusada" ? (
                              teamConfirmed(item?.status, "text")
                            ) : notFoundDoctor.notFoundDoctorInList.includes(
                                `surgeon${index}`
                              ) ? (
                              "Cirurgião(ã) não encontrado(a)."
                            ) : item.crmCirurgiao === "" && error ? (
                              <Error>Campo obrigatório</Error>
                            ) : (
                              ""
                            )
                          }
                          helperIcon={
                            item?.status === "Recusada" ? (
                              teamConfirmed(item?.status, "icon")
                            ) : notFoundDoctor.notFoundDoctorInList.includes(
                                `surgeon${index}`
                              ) ? (
                              <IconAlert />
                            ) : item.crmCirurgiao === "" && error ? (
                              <ErrorIcon />
                            ) : (
                              <ErrorIcon style={{ display: "none" }} />
                            )
                          }
                        />
                      </div>
                    </S.BoxMapAddNewExtraProcedure>
                  </S.BoxMap>
                </div>
              );
            })}
          </div>
        ) : null}
        <div style={{ width: "100%", height: "fit-content" }}>
          <TmpButton
            handleClick={
              isAditionalTeam
                ? onClickFilledFieldByMainTeam
                : () => addTeam("equipe")
            }
            disabled={
              editingModel ||
              isAditionalTeam ||
              data?.equipeCirurgicaAdicional === "Não"
            }
            startIcon={<IconMais />}
            type="secondary"
          >
            Adicionar mais equipe
          </TmpButton>
        </div>
      </SC.StyledSectionWrapper>

      <SC.BoxTitle>
        <SC.Title>Serviços de Apoio ao Centro Cirúrgico</SC.Title>
        <TmpDivider />
        <S.P>Selecione as opções da qual deseja apoio do Centro Cirúrgico</S.P>
        <S.Helper>
          <IconInfo
            style={{
              color: "#000",
            }}
          />
          <p>As opções ficam disponíveis de acordo com o Local selecionado.</p>
        </S.Helper>
      </SC.BoxTitle>
      <SC.BoxTitle bgColor={"#a3a3a3"}>
        <SC.Title color={"#292929"}>Serviços</SC.Title>
        <TmpDivider />
      </SC.BoxTitle>
      <S.DisplayBox>
        <SC.StyledContent
          columns={"repeat(2, 1fr)"}
          columnsMd={"1fr"}
          gapMD={"0"}
          gap={"0 24px"}
          marginBottom={"64px"}
        >
          <S.CheckboxWrapper>
            <TmpCheckbox
              label="Biópsia de congelação"
              name="services"
              checked={!!data?.biopsiaCongelacao}
              handleChange={(value) => {
                handleCheckboxChange("biopsiaCongelacao", value);
              }}
            />
            <div className="checkboxes">
              <TmpRadio
                label="Laboratório HSL"
                value="Laboratório HSL"
                name="biópsia"
                disabled={
                  data?.biopsiaCongelacao === "" ||
                  data?.biopsiaCongelacao === false
                }
                checked={
                  data.biopsiaCongelacao && data.cdEstabelecimento === 321
                    ? true
                    : data?.biopsiaCongelacao === "radioHsl"
                }
                handleChange={() => handleChangeRadio("radioHsl")}
              />
              <TmpRadio
                label="Laboratório Externo"
                value="Laboratório Externo"
                name="biópsia"
                disabled={
                  data?.cdEstabelecimento === 321 ||
                  data?.biopsiaCongelacao === ""
                }
                checked={
                  data?.cdEstabelecimento !== 321 &&
                  data?.biopsiaCongelacao === "radioExt"
                }
                handleChange={() => handleChangeRadio("radioExt")}
              />
            </div>
          </S.CheckboxWrapper>

          <S.CheckboxWrapper>
            <TmpCheckbox
              label="Biobanco"
              name="services"
              value="Biobanco"
              checked={data["biobanco"]}
              handleChange={(value) => {
                handleCheckboxChange("biobanco", value);
              }}
            />
          </S.CheckboxWrapper>

          <S.CheckboxWrapper>
            <TmpCheckbox
              label={handleReturnLabel(
                "Banco de tecidos",
                data.cdEstabelecimento === 51
              )}
              name="services"
              value="Banco de tecidos"
              disabled={data.cdEstabelecimento === 51}
              checked={data.cdEstabelecimento !== 51 && data["bancoTecidos"]}
              handleChange={(value) => {
                handleCheckboxChange("bancoTecidos", value);
              }}
            />
          </S.CheckboxWrapper>
        </SC.StyledContent>
      </S.DisplayBox>
      <SC.BoxTitle bgColor={"#a3a3a3"}>
        <SC.Title color={"#292929"}>Equipamentos</SC.Title>
        <TmpDivider />
      </SC.BoxTitle>
      <S.DisplayBox>
        <SC.StyledContent
          columns={"repeat(2, 1fr)"}
          columnsMd={"1fr"}
          gapMD={"0"}
          gap={"0 24px"}
          marginBottom={"64px"}
        >
          <div>
            <S.CheckboxWrapper>
              <TmpCheckbox
                label={handleReturnLabel(
                  "Colonoscopia",
                  data.cdEstabelecimento === 51
                )}
                name="services"
                value="Colonoscopia"
                disabled={data.cdEstabelecimento === 51}
                checked={
                  data.cdEstabelecimento !== 51 &&
                  data.cdEstabelecimento !== 51 &&
                  data["colonoscopia"]
                }
                handleChange={(value) => {
                  handleCheckboxChange("colonoscopia", value);
                }}
              />
            </S.CheckboxWrapper>
            <S.CheckboxWrapper>
              <TmpCheckbox
                label={handleReturnLabel(
                  "Eco transesofágico",
                  data.cdEstabelecimento === 51
                )}
                name="services"
                value="Eco transesofágico"
                disabled={data.cdEstabelecimento === 51}
                checked={
                  data.cdEstabelecimento !== 51 && data["ecoTransesofagico"]
                }
                handleChange={(value) => {
                  handleCheckboxChange("ecoTransesofagico", value);
                }}
              />
            </S.CheckboxWrapper>
            <S.CheckboxWrapper>
              <TmpCheckbox
                label={handleReturnLabel(
                  "Endoscopia",
                  data.cdEstabelecimento === 51
                )}
                name="services"
                value="Endoscopia"
                disabled={data.cdEstabelecimento === 51}
                checked={data.cdEstabelecimento !== 51 && data["endoscopia"]}
                handleChange={(value) => {
                  handleCheckboxChange("endoscopia", value);
                }}
              />
            </S.CheckboxWrapper>
            <S.CheckboxWrapper>
              <TmpCheckbox
                label={handleReturnLabel(
                  "Medicina Nuclear (Roll)",
                  data.cdEstabelecimento === 321 ||
                    data.cdEstabelecimento === 51
                )}
                name="services"
                value="Medicina Nuclear (Roll)"
                disabled={
                  data.cdEstabelecimento === 321 ||
                  data.cdEstabelecimento === 51
                }
                checked={
                  data.cdEstabelecimento !== 321 &&
                  data.cdEstabelecimento !== 51 &&
                  data["medicinaNuclear"]
                }
                handleChange={(value) => {
                  handleCheckboxChange("medicinaNuclear", value);
                }}
              />
            </S.CheckboxWrapper>
            <S.CheckboxWrapper>
              <TmpCheckbox
                label={handleReturnLabel(
                  "Microscópio",
                  data.cdEstabelecimento === 1
                )}
                name="services"
                value="Microscópio"
                checked={data.cdEstabelecimento !== 1 && data["microscopio"]}
                disabled={data.cdEstabelecimento === 1}
                handleChange={(value) => {
                  handleCheckboxChange("microscopio", value);
                }}
              />
            </S.CheckboxWrapper>
            <S.CheckboxWrapper>
              <TmpCheckbox
                label={handleReturnLabel(
                  "Monitoração neurofisiológica (Coluna)",
                  data.cdEstabelecimento === 321 ||
                    data.cdEstabelecimento === 51
                )}
                name="services"
                value="Monitoração neurofisiológica"
                disabled={
                  data.cdEstabelecimento === 321 ||
                  data.cdEstabelecimento === 51
                }
                checked={
                  data.cdEstabelecimento !== 321 &&
                  data.cdEstabelecimento !== 51 &&
                  data["monitorizacaoNeurofisiologica"]
                }
                handleChange={(value) => {
                  handleCheckboxChange("monitorizacaoNeurofisiologica", value);
                }}
              />
            </S.CheckboxWrapper>
            <S.CheckboxWrapper>
              <TmpCheckbox
                label={handleReturnLabel("PTH", data.cdEstabelecimento === 51)}
                name="services"
                value="PTH"
                checked={data.cdEstabelecimento !== 51 && data["pth"]}
                disabled={data.cdEstabelecimento === 51}
                handleChange={(value) => {
                  handleCheckboxChange("pth", value);
                }}
              />
            </S.CheckboxWrapper>
            <S.CheckboxWrapper>
              <TmpCheckbox
                label="Raio x"
                name="services"
                checked={data["raiox"]}
                handleChange={(value) => {
                  handleCheckboxChange("raiox", value);
                }}
              />
            </S.CheckboxWrapper>
          </div>
          <div>
            <S.CheckboxWrapper>
              <TmpCheckbox
                label={handleReturnLabel(
                  "Ressonância magnética intraoperatória",

                  data.cdEstabelecimento === 51
                )}
                name="services"
                value="Ressonância magnética intraoperatória"
                disabled={data.cdEstabelecimento === 51}
                checked={
                  data.cdEstabelecimento !== 51 &&
                  data["ressonanciaMagneticaIntraperatoria"]
                }
                handleChange={(value) => {
                  handleCheckboxChange(
                    "ressonanciaMagneticaIntraperatoria",
                    value
                  );
                }}
              />
            </S.CheckboxWrapper>
            <S.CheckboxWrapper>
              <TmpCheckbox
                label={handleReturnLabel(
                  "Robô Da Vinci",

                  data?.cdEstabelecimento === 51
                )}
                name="services"
                value="Robô Da Vinci"
                checked={data.cdEstabelecimento !== 51 && data["roboDaVinci"]}
                disabled={data?.cdEstabelecimento === 51}
                handleChange={(value) => {
                  handleCheckboxChange("roboDaVinci", value);
                }}
              />
            </S.CheckboxWrapper>
            <S.CheckboxWrapper>
              <TmpCheckbox
                label={handleReturnLabel(
                  "Robô da Ortopedia",
                  data.cdEstabelecimento === 321 ||
                    data.cdEstabelecimento === 51
                )}
                name="services"
                value="Robô da Ortopedia"
                checked={
                  data.cdEstabelecimento !== 321 &&
                  data.cdEstabelecimento !== 51 &&
                  data["roboDaOrtopedia"]
                }
                disabled={
                  data?.cdEstabelecimento === 321 ||
                  data?.cdEstabelecimento === 51
                }
                handleChange={(value) => {
                  handleCheckboxChange("roboDaOrtopedia", value);
                }}
              />
            </S.CheckboxWrapper>
            <S.CheckboxWrapper>
              <TmpCheckbox
                label="Scopia"
                name="services"
                checked={data["scopia"]}
                handleChange={(value) => {
                  handleCheckboxChange("scopia", value);
                }}
              />
            </S.CheckboxWrapper>
            <S.CheckboxWrapper>
              <TmpCheckbox
                label={handleReturnLabel(
                  "Tomografia para estereotaxia",
                  data.cdEstabelecimento === 321 ||
                    data.cdEstabelecimento === 51
                )}
                name="services"
                value="Tomografia para estereotaxia"
                disabled={
                  data.cdEstabelecimento === 321 ||
                  data.cdEstabelecimento === 51
                }
                checked={
                  data.cdEstabelecimento !== 321 &&
                  data.cdEstabelecimento !== 51 &&
                  data["tomografiaEstereotaxia"]
                }
                handleChange={(value) => {
                  handleCheckboxChange("tomografiaEstereotaxia", value);
                }}
              />
            </S.CheckboxWrapper>
            <S.CheckboxWrapper>
              <TmpCheckbox
                label="Ultrassonografia"
                name="services"
                value="Ultrassonografia"
                checked={data["ultrassonografia"]}
                handleChange={(value) => {
                  handleCheckboxChange("ultrassonografia", value);
                }}
              />
            </S.CheckboxWrapper>
            <S.CheckboxWrapper>
              <TmpCheckbox
                label={handleReturnLabel(
                  "Ultrassonografia laparoscópio",
                  data.cdEstabelecimento === 321 ||
                    data.cdEstabelecimento === 51
                )}
                name="services"
                value="Ultrassonografia laparoscópio"
                disabled={
                  data.cdEstabelecimento === 321 ||
                  data.cdEstabelecimento === 51
                }
                checked={
                  data.cdEstabelecimento !== 321 &&
                  data.cdEstabelecimento !== 51 &&
                  data["ultrassonografiaLaparoscopio"]
                }
                handleChange={(value) => {
                  handleCheckboxChange("ultrassonografiaLaparoscopio", value);
                }}
              />
            </S.CheckboxWrapper>
            <S.CheckboxWrapper>
              <TmpCheckbox
                label={handleReturnLabel(
                  "Videolaparoscopia",
                  data?.cdEstabelecimento === 1 ||
                    data?.cdEstabelecimento === 51
                )}
                name="services"
                value="Videolaparoscopia"
                checked={
                  data.cdEstabelecimento !== 1 &&
                  data.cdEstabelecimento !== 51 &&
                  data["videolaparoscopia"]
                }
                disabled={
                  data?.cdEstabelecimento === 1 ||
                  data?.cdEstabelecimento === 51
                }
                handleChange={(value) => {
                  handleCheckboxChange("videolaparoscopia", value);
                }}
              />
            </S.CheckboxWrapper>
          </div>
        </SC.StyledContent>
      </S.DisplayBox>
      <SC.BoxTitle bgColor={"#a3a3a3"}>
        <SC.Title>Dados do agendamento</SC.Title>
        <TmpDivider />
      </SC.BoxTitle>
      <SC.StyledSectionWrapper rows={"1fr auto"}>
        <SC.StyledContent columns={"100%"}>
          {isAditionalTeam && (
            <S.InputTextData
              autoComplete="off"
              padding="15px 0px"
              widthmedia="406px"
              widthmedialg="406px"
              name="Tempo de duração total da cirurgia"
              type="time"
              label="Informe a duração dos seus procedimentos *"
              placeholder="hh:mm"
              error={data?.tempoCirurgia === "" && error}
              disabled={editingSolicitation}
              value={
                typeof data?.tempoCirurgia === "number"
                  ? minutesToHourString(data?.tempoCirurgia).substring(0, 5)
                  : data?.tempoCirurgia
              }
              handleChange={(e) => {
                handleChangeInput(e.target.value.trim(), "tempoCirurgia");
              }}
              helperText={
                data?.tempoCirurgia === "" && error ? (
                  <Error>
                    <ErrorIcon
                      style={{
                        width: "17px",
                        height: "17px",
                        marginRight: "5px",
                      }}
                    />
                    Campo obrigatório
                  </Error>
                ) : null
              }
              helperIcon={<IconRelogio />}
            />
          )}
          <S.Schedule>
            <div>
              <p className="margin-bottom-16">Selecione a data da cirurgia *</p>
              <S.StyledDatePicker
                onClick={
                  isAditionalTeam ? onClickFilledFieldByMainTeam : undefined
                }
                editingModel={editingModel || isAditionalTeam}
                colorEnabled={disableDates ? "#2C6EF2" : "#292929"}
              >
                {errorRequest ? (
                  <S.WrapperSurgeryCalendar reload={reload}>
                    <RequestFailed.Root>
                      <RequestFailed.Content content="Falha no carregamento" />
                      <RequestFailed.Action
                        handleClick={() => {
                          setReload(true);
                          surgeryDateRequest();
                        }}
                        titleButton="Recarregar"
                        loading={reload}
                      />
                    </RequestFailed.Root>
                  </S.WrapperSurgeryCalendar>
                ) : (
                  <TmpDatepicker
                    open={true}
                    locale={ptBR}
                    minDate={toDate(new Date())}
                    maxDate={toDate(addDays(new Date(), 60))}
                    selected={data?.data ? new Date(data?.data) : new Date()}
                    // highlightDates={bookedDates}
                    includeDates={disableDates && [...bookedDates]}
                    onClick={
                      isAditionalTeam ? onClickFilledFieldByMainTeam : undefined
                    }
                    disabled={isAditionalTeam}
                    handleChange={(value) => {
                      clearSchedule();
                      dataChange({
                        target: {
                          name: "data",
                          value: format(value, "yyyy-MM-dd HH:mm:ss"),
                        },
                      });
                    }}
                  />
                )}
              </S.StyledDatePicker>
            </div>
            <S.WrapperSwitch
              margin={"0 0 40px"}
              displaymd="block"
              display="none"
            >
              <TmpSwitch
                label="Exibir somente dias com reserva de sala"
                id="disableDates"
                onClick={
                  isAditionalTeam ? onClickFilledFieldByMainTeam : undefined
                }
                disabled={
                  editingModel || isAditionalTeam || bookedDates?.length === 0
                }
                checked={disableDates}
                handleChange={() => {
                  setDisableDates((prevState) => !prevState);
                }}
              ></TmpSwitch>
            </S.WrapperSwitch>
            <div style={{ width: "100%", marginRight: "0px" }}>
              <div className="wrapperTooltip margin-bottom-16">
                <p
                  style={{
                    opacity: editingModel ? "0.3" : "1",
                    position: "relative",
                  }}
                >
                  Selecione o horário abaixo na sua agenda *
                </p>
                <S.TooltipTextArea>
                  <S.TooltipeTextArea>
                    Esses horários refletem somente a disponibilidade de agenda
                    do(a) médico(a) selecionado(a).
                  </S.TooltipeTextArea>
                  <IconAjuda />
                </S.TooltipTextArea>
              </div>
              <S.TimeList
                onClick={
                  isAditionalTeam ? onClickFilledFieldByMainTeam : undefined
                }
                editingModel={editingModel || isAditionalTeam}
              >
                {hours.length === 0 ? (
                  <div className="no-hour-response">
                    <span>
                      Esta unidade não possui horários disponíveis para o dia
                      selecionado
                    </span>
                  </div>
                ) : null}
                {(!!data?.data && data?.hora === "") || !!newDate.hr_inicio ? (
                  hours?.map((hour, index, array) => (
                    <div
                      style={
                        hour.substring(0, 2) ===
                        newDate.hr_inicio.substring(0, 2)
                          ? { zIndex: array.length - index }
                          : null
                      }
                      key={index}
                      className="time-row"
                    >
                      <p className="hour">{hour}</p>
                      <S.TimeDescriptionRow
                        backgroundColor={
                          (index + 1) % 2 ? "transparent" : "#F5F5F5"
                        }
                      >
                        {sequenceSurgery?.map((dt, it) => {
                          return dt.nm_paciente !== dataPatient.nome &&
                            parseInt(dt.nr_minuto_duracao) !==
                              data.tempoCirurgia &&
                            dt.hr_inicio &&
                            hour.substring(0, 2) ===
                              dt.hr_inicio.substring(11, 13) ? (
                            <div
                              key={it}
                              className="selected-hour sequency-schedulings"
                            >
                              {listTimes(dt, it, sequenceSurgery.length - 1)}
                            </div>
                          ) : null;
                        })}
                        {newDate.hr_inicio !== "" &&
                        data?.data === newDate.data &&
                        hour.substring(0, 2) ===
                          newDate.hr_inicio.substring(0, 2) ? (
                          <div className="selected-hour new-date">
                            {listTimeNewDate(newDate)}
                          </div>
                        ) : null}
                        {(!!data?.data &&
                          isAfter(
                            parseISO(data.data),
                            parseISO(currentTime)
                          )) ||
                        currentTime.split(" ")[1].split(":")[0] <
                          hour.split(":")[0] ? (
                          <>
                            {index === 0 &&
                            hour.substring(0, 2) === "05" &&
                            data.regimeHospitalar === "Hospital Dia" &&
                            data.cdEstabelecimento === 51 ? (
                              <div className="unavailable-hour">
                                indisponível
                              </div>
                            ) : (
                              <div
                                className="available-hour"
                                onClick={() =>
                                  handleSelectSurgeryDate(
                                    hour.substring(0, 2) + ":00"
                                  )
                                }
                              >
                                disponível
                              </div>
                            )}
                            <div
                              className="available-hour"
                              onClick={() =>
                                handleSelectSurgeryDate(
                                  hour.substring(0, 2) + ":30"
                                )
                              }
                            >
                              disponível
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="unavailable-hour">indisponível</div>
                            <div className="unavailable-hour">indisponível</div>
                          </>
                        )}
                      </S.TimeDescriptionRow>
                    </div>
                  ))
                ) : (
                  <div className="no-hour-response">
                    {data?.hora !== "" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <S.InputTextData
                          autoComplete="off"
                          padding="15px 0px"
                          lang={ptBR}
                          widthmedia="406px"
                          widthmedialg="406px"
                          name="Tempo de duração total da cirurgia"
                          type="text"
                          maxLength={5}
                          label="Duração total do(s) seus(s) procedimento(s) *"
                          placeholder="hh:mm"
                          onClick={
                            isAditionalTeam
                              ? onClickFilledFieldByMainTeam
                              : undefined
                          }
                          disabled={isAditionalTeam}
                          error={
                            (data?.tempoCirurgia === "" && error) ||
                            scheduleOverlay
                          }
                          value={
                            typeof data?.tempoCirurgia === "number"
                              ? minutesToHourString(
                                  data?.tempoCirurgia
                                ).substring(0, 5)
                              : data?.tempoCirurgia
                          }
                          //Aqui é utilizado o oninput para retirar as letras antes de serem renderizadas
                          onInput={(e) => {
                            setScheduleOverlay(false);
                            handleChangeInput(
                              applyTimeMask(e.target.value.trim()),
                              "tempoCirurgia"
                            );
                          }}
                          helperText={
                            data?.tempoCirurgia === "" && error ? (
                              <Error>
                                <ErrorIcon
                                  style={{
                                    width: "17px",
                                    height: "17px",
                                    marginRight: "5px",
                                  }}
                                />
                                Campo obrigatório
                              </Error>
                            ) : null
                          }
                          helperIcon={<IconRelogio />}
                        />
                        {scheduleOverlay ? (
                          <div className="surgery-time-info-box">
                            <IconErro style={{ color: "#E93535" }} />
                            <p className="surgery-time-info-error">
                              Sobreposição na sua agenda. Verifique os seus
                              horários.
                            </p>
                          </div>
                        ) : (
                          <div
                            style={{ margin: "32px 0" }}
                            className="surgery-time-info-box"
                          >
                            <S.TooltipTextArea>
                              <S.TooltipeTextArea>
                                Essa sugestão de duração é baseada no seu
                                histórico cirúrgico de procedimentos
                                iguais/similares.
                              </S.TooltipeTextArea>
                              <IconAjuda />
                            </S.TooltipTextArea>
                            <p className="surgery-time-info">
                              Sugestão de tempo cirúrgico:
                            </p>
                            {surgicalTimeLoading ? (
                              <TmpSpinner />
                            ) : surgicalTimeSuggestion.length > 0 ? (
                              surgicalTimeSuggestion.map((i, key) => (
                                <TmpLink
                                  key={key}
                                  onClick={() =>
                                    handleChangeInput(i, "tempoCirurgia")
                                  }
                                >
                                  {i}
                                </TmpLink>
                              ))
                            ) : (
                              <p className="surgery-time-info">
                                não há nenhuma disponível
                              </p>
                            )}
                          </div>
                        )}

                        <TmpButtonGroup
                          size="lg"
                          icon={false}
                          labelPrimary="Aplicar"
                          labelSecondary="Voltar"
                          onClick={
                            isAditionalTeam
                              ? onClickFilledFieldByMainTeam
                              : undefined
                          }
                          disabledPrimary={
                            data?.tempoCirurgia === "" ||
                            isAditionalTeam ||
                            scheduleOverlay
                          }
                          disabledSecondary={isAditionalTeam}
                          handleClickPrimary={() => {
                            handleSetHour();
                          }}
                          handleClickSecondary={() => {
                            setScheduleOverlay(false);
                            dataChange({
                              target: {
                                name: "tempoCirurgia",
                                value: "",
                              },
                            });
                            dataChange({
                              target: {
                                name: "hora",
                                value: "",
                              },
                            });
                          }}
                        />
                      </div>
                    ) : (
                      <span>Selecione um dia ao lado para ver os horários</span>
                    )}
                  </div>
                )}
              </S.TimeList>
            </div>
          </S.Schedule>
        </SC.StyledContent>

        <SC.StyledContent columns={"382px auto"} columnsMd={"1fr"} gapMD="40px">
          <div>
            <S.WrapperSwitch
              display={bookedDates?.length === 0 ? "none" : "flex"}
              displaymd="none"
            >
              <TmpSwitch
                label="Exibir somente dias com reserva de sala"
                id="disableDates"
                onClick={
                  isAditionalTeam ? onClickFilledFieldByMainTeam : undefined
                }
                disabled={
                  editingModel || isAditionalTeam || bookedDates?.length === 0
                }
                checked={disableDates}
                handleChange={() => {
                  setDisableDates((prevState) => !prevState);
                }}
              ></TmpSwitch>
            </S.WrapperSwitch>

            <S.BoxCheckbox>
              <TmpCheckbox
                label="Necessita internação na véspera"
                name="options"
                value="Necessita internação na véspera"
                checked={
                  data?.cdEstabelecimento !== 51 &&
                  !dates &&
                  data["internacaoVespera"]
                }
                onClick={
                  isAditionalTeam ? onClickFilledFieldByMainTeam : undefined
                }
                disabled={
                  editingModel ||
                  isAditionalTeam ||
                  data?.cdEstabelecimento === 51 ||
                  dates
                }
                handleChange={(value) => {
                  handleCheckboxChange("internacaoVespera", value);
                }}
              />
            </S.BoxCheckbox>
          </div>
          {newDate?.nr_minuto_duracao ? (
            <S.ScheduleDateInfos>
              <p>
                <TmpIcon
                  style={{ marginRight: "10px" }}
                  icon={<IconCalendar />}
                />
                {"Agendamento selecionado: "}
                <span style={{ marginLeft: "5px" }}>{getDate()}</span>
              </p>
              <div>
                <IconAlert /> A Central de Agendamentos entrará em contato em
                caso de indisponibilidade da data selecionada.
              </div>
            </S.ScheduleDateInfos>
          ) : null}
        </SC.StyledContent>
      </SC.StyledSectionWrapper>

      <SC.StyledSectionWrapper rows={"1fr"}>
        <SC.StyledContent>
          <S.BoxTextarea editingModel={editingModel}>
            <TextArea
              label={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "fit-content",
                  }}
                >
                  Alguma observação em relação à escolha da data e horário?
                  <S.TooltipTextArea>
                    <S.TooltipeTextArea>
                      Esse campo deverá ser usado <b>somente</b> para
                      informações relacionadas ao agendamento.
                    </S.TooltipeTextArea>
                    <IconAjuda />
                  </S.TooltipTextArea>
                </div>
              }
              placeholder="Escreva aqui..."
              prop={"observacoes"}
              maxLength={300}
              id="inputText"
              value={data?.observacoes}
              handleChange={dataChange}
              object={data}
              message="Você atingiu o limite de caractere."
            />
          </S.BoxTextarea>
        </SC.StyledContent>
      </SC.StyledSectionWrapper>
    </SC.Container>
  );
};

export default Procedure;
