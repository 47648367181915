import React from "react";
import * as S from "./style";
import { brand_sirio as SvgBrandSirio } from "@tempo/tempo-assets/dist";
import {
  DropdownProfile,
  TmpHeaderBase,
} from "@tempo/tempo-design-system-core/dist";
import { useDispatch, useSelector } from "react-redux";
import { useAuthContext } from "../../hooks";
import { apiDoctorMock } from "../../utils/Mocks/apiDoctorMock";
import { setUserInfos } from "../../features/Auth/authSlice";
import { setSolicitationProperty } from "../../features/solicitation/solicitationSlice";
import { saveSignatureDataUrlOnRedux } from "../../utils/Auth";

const Header = ({ text }) => {
  const { nome, user } = useSelector((state) => state.auth.userInfos) || "";
  const doctor = useSelector((state) => state.auth.userInfos.nome);
  const userName = nome || user;
  const { logout } = useAuthContext();
  const dispatch = useDispatch();

  const _handleSignout = () => logout();

  const _handleLogMock = () => {
    const {
      crm_usuario,
      nm_usuario,
      id_pessoa_fisica_gssk,
      cd_pessoa_fisica_pk,
      im_assinatura,
    } = apiDoctorMock?.data;

    dispatch(
      setUserInfos({
        crm: crm_usuario,
        nome: nm_usuario,
        cdPessoaFisica: cd_pessoa_fisica_pk || id_pessoa_fisica_gssk,
        assinatura: im_assinatura,
      })
    );

    dispatch(
      setSolicitationProperty({
        idMedico: cd_pessoa_fisica_pk || id_pessoa_fisica_gssk,
      })
    );

    saveSignatureDataUrlOnRedux(im_assinatura, (urlAssinatura) =>
      dispatch(
        setUserInfos({
          urlAssinatura,
        })
      )
    );
  };

  const _handleChange = () => {};

  const getName = (name, title = "Dr(a)") => {
    if (doctor !== null) return `${title} ${doctor}`;
    return `${title} ${name}`;
  };

  return (
    <S.Container>
      <TmpHeaderBase
        style={{ width: "100%" }}
        logo={<SvgBrandSirio />}
        // eslint-disable-next-line react/no-children-prop
        children={
          <>
            <S.HeaderContent>
              <p>{text}</p>
            </S.HeaderContent>
            <S.Profile>
              <DropdownProfile
                title={nome}
                name={getName(userName)}
                label="profile"
                closeOnClickOutside={true}
                listItems={
                  process.env.REACT_APP_ENVIRONMENT !== "production"
                    ? [
                        {
                          label: "Perfil de TI",
                          handleClick: _handleLogMock,
                        },
                        {
                          label: "Sair",
                          handleClick: _handleSignout,
                        },
                      ]
                    : [
                        {
                          label: "Sair",
                          handleClick: _handleSignout,
                        },
                      ]
                }
                handleChange={_handleChange}
              ></DropdownProfile>
            </S.Profile>
          </>
        }
        handleClickLogo={() => {}}
      />
    </S.Container>
  );
};

export default Header;
