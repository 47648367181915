import { useState } from "react";
import * as S from "./style";
import { call as CallSVG } from "@tempo/tempo-assets/dist";

import { getSchedulingLocal } from "../../utils";

export const Help = ({ selectedLocal }) => {
  const [isOpenedModal, setOpenModal] = useState(false);

  const renderContacts = () => {
    const local = getSchedulingLocal(selectedLocal);

    switch (local) {
      case "Brasília":
        return (
          <p>
            Em casos de dúvidas ou informações referente a um agendamento
            realizado. Sendo:{" "}
            <strong className="text">Cirurgias eletivas ou de urgência</strong>:{" "}
            <strong className="number">(61) 3044-8888</strong> - Opção 4
          </p>
        );

      case "São Paulo":
        return (
          <p>
            Dúvidas ou informações sobre um agendamento{" "}
            <strong className="text">Cirurgias eletivas:</strong>{" "}
            <strong className="number">(11) 3394-0381</strong>. Opção 2 e em
            seguida 3, de segunda a sexta, das 8h às 21h. <br /> Após às 21h e
            fins de semana: (11) 3394-6070
            <br />
            <strong className="text">Cirurgias de urgência</strong>:{" "}
            <strong className="number">(11) 3394-6070</strong>
          </p>
        );
    }
  };

  return (
    <>
      {!!selectedLocal && (
        <>
          {isOpenedModal ? (
            <S.Overlay onClick={() => setOpenModal(false)}></S.Overlay>
          ) : null}
          <S.Container>
            {isOpenedModal ? (
              <div>
                <h1>Precisando de ajuda?</h1>
                <S.HelpSection>
                  <h2>Fale com a Central de Agendamento</h2>
                  {renderContacts()}
                </S.HelpSection>
                <S.HelpSection>
                  <h2>Fale com o Service Desk</h2>
                  <p>
                    <strong className="number">(11) 3394-0990</strong> para
                    problemas no preenchimento ou erros, 24h, 7 dias por semana
                  </p>
                </S.HelpSection>
                <S.HelpSection>
                  <h2>Fale com a Informática Clínica</h2>
                  <p>
                    <strong className="number">(11) 96195-4520</strong> para
                    dúvidas sobre o preenchimento dessa solicitação de
                    agendamento ou funcionalidades do site de Agendamento do
                    Centro Cirúrgico,
                    <br />
                    24h, 7 dias por semana
                  </p>
                </S.HelpSection>
                <S.HelpSection>
                  <h2>Fale com o SAM</h2>
                  <p>
                    <strong className="number">(11) 3394-1000</strong> para
                    dúvidas sobre cadastro médico ou secretária, acessos aos
                    sistemas do Sírio-Libanês ou autorizações especiais para
                    cirurgias
                  </p>
                </S.HelpSection>
              </div>
            ) : null}
            <S.HelpButton
              size="lg"
              icon={<CallSVG />}
              onClick={() => setOpenModal((prevState) => !prevState)}
            ></S.HelpButton>
          </S.Container>
        </>
      )}
    </>
  );
};
