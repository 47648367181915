import styled, { css } from "styled-components";

export const AnamneseFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 11px;
  width: 100%;
  padding-bottom: 32px;

  ${(props) =>
    props.mobileColumn
      ? css`
          @media (max-width: 768px) {
            flex-direction: column;
          }
        `
      : null}
`;

export const BoxTitle = styled.div`
  display: flex;
  background-color: #fff;
  width: 740px;
  padding: 20px 20px;
  border-radius: 8px 0 0 0;
  z-index: 10;

  .tmp-button-icon {
    margin: 12px 12px 0 0;

    span,
    svg {
      pointer-events: none;
    }
  }

  @media (max-width: 1365px) {
    max-width: 564px;
  }
`;

export const AnamneseTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 133%;
  letter-spacing: -0.01em;
  color: #292929;
`;

export const AnamneseRow = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: ${(props) => (props.alignSelf ? props.alignSelf : "center")};
  align-items: center;
  margin-bottom: 40px;
  gap: 24px;
  @media (max-width: 760px) {
    display: block;
    width: 100%;
  }

  @media (min-width: 1060px) {
    > div:first-child {
      margin-right: ${(props) =>
        props.marginRight ? props.marginRight : "0px"};
    }
  }

  > div > div > div {
    min-width: 100%;
  }

  > div > div > div > div > div {
    min-width: 100% !important;
  }
`;

export const BtnArrow = styled.button`
  margin-right: 34px;
  background: transparent;
  box-shadow: none;
  border: none;
  color: #2c6ef2;
  cursor: pointer;
`;

export const Legend = styled.div`
  font-weight: 500;
  display: flex;
  width: 100%;
  font-size: 14px;
  line-height: 100%;
  color: #666666;
  margin-bottom: 28px;
  justify-content: flex-start;
`;

export const Progress = styled.div`
  height: 4px;
  background: #c5d7fb;
  width: 100%;
  margin: 32px 0px;

  div {
    width: ${(props) => props.width};
    background: #2c6ef2;
    animation: 0.3s;
    height: 4px;
    border-radius: 0px 4px 4px 0px;
  }
`;

export const Steps = styled.div`
  width: 100%;
  display: flex;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #666666 !important;
  margin-bottom: 24px;

  .step-label {
    color: #2c6ef2;
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    font-family: "Inter";
    margin-right: 6px;
  }

  .step {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    color: #666666;
    font-family: "Inter";
    margin-left: 6px;
    margin-right: 34px;
  }

  @media (max-width: 760px) {
    align-items: center;
    flex-direction: column;
    > div:last-child {
      margin-top: 22px;
    }
  }
`;

export const RadioTable = styled.div`
  display: ${(props) => props.display};
  margin-top: ${(props) => props.marginTop};
  max-width: 100%;
  width: 100%;
  hr {
    margin: 24px 0px;
    height: 1px;
  }

  ${(props) =>
    props.mobileColumn
      ? css`
          @media (max-width: 760px) {
            flex-direction: column;
            flex-wrap: wrap;
          }
        `
      : null}
`;

export const Helper = styled.span`
  display: ${(props) => (props.display ? "flex" : "none")};
  gap: 5px;
  color: "#EA1F1F";
  font-size: 14px;
`;

export const StyledRadioColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  max-width: 950px;
  gap: 12px;
  margin: ${(props) => props.margin};

  span {
    line-height: normal;
  }
  .sc-brePNt {
    margin: 0 0 0 10px;
  }
  ${(props) =>
    props.mobileColumn
      ? css`
          @media (max-width: 760px) {
            width: 100%;
            justify-content: space-around;
            margin-top: 10px;
          }
        `
      : null}
`;

export const StyledRadioGroup = styled.div`
  display: flex;
  margin-top: 16px;
  max-width: 950px;

  span {
    line-height: normal;
  }
  > label {
    margin-right: ${(props) =>
      props.marginRight ? props.marginRight : "39px"};
    width: ${(props) => props.width};
  }

  @media (max-width: 576px) {
    > label {
      margin-right: ${(props) => props.marginRight ?? "30px"};
      width: ${(props) => props.width};
    }
  }

  ${(props) =>
    props.mobileColumn
      ? css`
          @media (max-width: 760px) {
            flex-direction: column;
            height: 140px;
            justify-content: space-around;
            margin-top: 0;
            margin-left: 10px;
          }
        `
      : null}
`;

export const InputWrap = styled.div`
  width: 100%;

  @media (max-width: 760px) {
    width: 100%;
  }
`;

export const StyledRadio = styled.div`
  max-width: ${(props) => props.maxWidth};
`;

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  overflow-y: hidden;
  max-height: 600px;
  max-width: 800px;

  @media (max-width: 1365px) {
    max-height: 450px;
    max-width: 624px;
  }
`;

export const ModalContent = styled.div`
  margin: 8px 0 96px 24px;
  padding-right: 24px;
  position: relative;
  overflow-y: auto;
  max-height: calc(600px - 72px - 96px);

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 8px;
  }
`;

export const BtnWrap = styled.div`
  width: calc(100% - 48px);
  border-radius: 0 0 10px 10px;
  background-color: #f5f5f5;
  position: absolute;
  bottom: 0;
  padding: 0 24px;
  height: 96px;
  display: flex;
  align-items: center;

  @media (max-width: 1365px) {
    max-width: 574px;
  }

  @media (max-width: 768px) {
    bottom: 0vh;
  }

  button:nth-child(1) {
    width: 117px;
    height: 48px;
  }
  button {
    width: ${(props) => (props.SaveClose ? "163px" : "110px")};
    height: 48px;
    white-space: nowrap;
  }
`;
