import React from "react";
import ReactDOM from "react-dom";

import { ThemeProvider } from "@tempo/tempo-design-system-core/dist/theme";
import GlobalStyle from "./globalStyle";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import Hotjar from "@hotjar/browser";
import { SystemFeedback } from "./hooks";
import "./styles/root.css";
export function gtag() {
  window.dataLayer.push(arguments);
}

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  const siteId = process.env.REACT_APP_HOTJAR_SITE_ID_PROD;
  const hotjarVersion = process.env.REACT_APP_HOTJAR_VERSION;
  const gaTrackId = process.env.REACT_APP_GA4_TRACKID;

  window.dataLayer = window.dataLayer || [];

  gtag("js", new Date());

  gtag("config", gaTrackId);

  Hotjar.init(siteId, hotjarVersion);

  console.log = () => {};
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider brand="hsl" theme="default">
      <SystemFeedback>
        <Provider store={store}>
          <GlobalStyle />
          <App />
        </Provider>
      </SystemFeedback>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}
