import styled, { css } from "styled-components";

export const StyledModal = styled.div`
  > div > div > div {
    /* modal-content */
    height: auto;
    @media (max-width: 760px) {
      width: 100%;
    }
  }
  > div > div {
    /* modal-md */
    box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.24);
    min-height: ${(props) => props.minHeight};
    height: min-content;
    max-width: ${(props) => (props?.maxWidth ? props.maxWidth : "1026px")}
    justify-content: ${(props) =>
      props.justifyContent ? props.justifyContent : "center"};
    align-items: center;
    max-height: 90vh;
    overflow-y: auto;
    margin: 40px;

    @media (max-width: 760px) {
      height: 100%;
      max-height: 100vh;
      margin: 0;
    }

    > div:first-child {
      visibility: hidden;
      overflow: revert-layer;
      width: 100%;
    }

    > div {
      background: transparent;
      justify-content: center;
      align-items: center;
    }

    >div: nth-child(2) > div {
      > button {
        min-width: min-content;
      }
    }
    @media (max-width: 1388px) {
    }
    @media (max-width: 760px) {
      div {
        max-height: none;
      }
    }
  }

  ${(props) =>
    props.showBtn &&
    css`
      > div > div > div:nth-child(2) {
        width: 0px;
        height: 0px;
        visibility: hidden;
        padding: 0px;
        margin: 0px;
      }
    `}

  .modal-title {
    max-width: 700px;
    font-family: "Inter";
    font-weight: 700;
    font-size: 32px;
    line-height: 124%;
    letter-spacing: -0.01em;
    color: #292929;
    display: contents;
  }

  .modal-body {
    font-family: "Inter";
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    line-height: 150%;
    color: #292929;
    max-width: 715px;
  }

  .modal-subtitle {
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
  }

  .modal-text {
    margin-bottom: 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #292929;
  }

  .modal-signature {
    display: flex;
    align-items: center;
    margin: 0px 0px 52px 0px;
    justify-content: center;
  }
`;

export const ModalInfos = styled.div`
  visibility: visible;
  display: flex;
  flex-direction: column;

  > span > svg {
    width: 75px;
    height: 75px;
  }

  > div {
    color: #292929;
    font-family: "Inter";
    > span {
      font-weight: 700;
      font-size: 32px;
      line-height: 124%;
      letter-spacing: -0.01em;
      color: #292929;
    }
    > p {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
    }
  }
`;
