import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import { getPatientAge, transformDate } from "../../../utils";
import { store } from "../../../redux/store";
import logoHSL from "../../../assets/images/logo-hsl.png";

import { styles } from "./style";

const fontColor = "#454b52";

const MyDocument = ({ data }) => {
  const { crm, nome, urlAssinatura } = store.getState().auth.userInfos;

  const patientTable = [
    [
      {
        label: "paciente",
        name: "Paciente",
        value: data.paciente?.nome || "-",
      },
      {
        label: "idade",
        name: "Idade",
        value: data.paciente?.dataNascimento
          ? getPatientAge(data.paciente?.dataNascimento)
          : "-",
      },
      {
        label: "prontuario",
        name: "Prontuário",
        value: data.paciente?.prontuario || "-",
      },
    ],
    [
      { label: "internação", name: "Internação", value: "Cirúrgica" },
      {
        label: "dataInternacao",
        name: "Data internação",
        value: data.procedimento?.data
          ? transformDate(data.procedimento?.data)
          : "-",
      },
      {
        label: "dataSolicitacao",
        name: "Solicitação criada em",
        value: data.solicitacao?.dataSolicitacao
          ? transformDate(data.dataSolicitacao)
          : "-",
      },
    ],
    [
      { label: "medico", name: "Médico", value: `Dr(a). ${nome}` || "-" },
      { label: "crm", name: "CRM/CRB", value: crm || "-" },
    ],
  ];

  const procedureTable = [
    {
      label: "tipo",
      name: "Tipo",
      values: ["Adicional", "Principal"],
    },
    {
      label: "codigoAMB",
      name: "Código AMB",
      values: ["41120035", "44040059"],
    },
    {
      label: "codigoTUSS",
      name: "Código TUSS",
      values: ["30212057", "30213053"],
    },
    {
      label: "procedimento",
      name: "Procedimento",
      values: [
        "Esvaziamento parcial cervical ou do pescoço (unilateral)",
        "Tireoidectomia total",
      ],
    },
    {
      label: "lateralidade",
      name: "Lateralidade",
      values: ["Lado Direito", "Não se aplica"],
    },
  ];

  const procedures = [
    {
      tipo: "Principal",
      codigoAMB:
        data.procedimento?.cdProcedimentoPrincipal ||
        data.procedimento?.procedimentoPrincipal?.cdProcedimento ||
        "-",
      codigoTUSS:
        data.procedimento?.tuss ||
        data.procedimento?.procedimentoPrincipal?.tuss ||
        "-",
      name:
        data.procedimento?.procedimentoPrincipal?.nomeProcedimento ||
        data.procedimento?.procedimentoPrincipal?.procedimento ||
        data.procedimento?.procedimentoPrincipal ||
        "-",
      lateralidade:
        data.procedimento?.procedimentoPrincipal?.lateralidade ||
        data.procedimento?.lateralidade ||
        "-",
    },
    ...data.procedimento?.procedimentosExtras.map((procedimento) => ({
      tipo: "Adicional",
      codigoAMB: procedimento?.cdProcedimento || "-",
      codigoTUSS: procedimento?.tuss || "-",
      name: procedimento?.nomeProcedimento || procedimento?.procedimento || "-",
      lateralidade: procedimento?.lateralidade || "-",
    })),
  ];

  return (
    <Document>
      <Page wrap size="A4" style={styles.page}>
        <View
          style={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Image style={{ width: "150px", height: "auto" }} src={logoHSL} />
          <Text style={styles.h1}>Termo de Consentimento</Text>
        </View>
        <View style={styles.section}>
          <View style={styles.header}>
            <View style={{ marginTop: 20 }}>
              <Text style={styles.h2}>
                Termo de Consentimento para procedimentos Cirúrgicos, Invasivos,
                Terapêuticos e Diagnósticos
              </Text>
            </View>

            {/* TABELA */}
            <View style={{ width: "100%" }}>
              {patientTable.map((row, i) => {
                return (
                  <View
                    key={i}
                    style={{ flexDirection: "row", marginTop: "8px" }}
                  >
                    {row.map((cell, j) => (
                      <View key={j} style={styles.cell}>
                        <Text style={styles.cellH1}>{cell.name}</Text>
                        <Text style={styles.p}>{cell.value}</Text>
                      </View>
                    ))}
                  </View>
                );
              })}
            </View>

            <View style={{ ...styles.divider, marginTop: 16 }}>
              <Text style={styles.p}>
                O presente Termo de Consentimento Esclarecido tem o objetivo de
                cumprir o dever ético de informar ao paciente e/ou <br />
                responsável os principais aspectos relacionados com o(s)
                Tratamento(s), Assistência Clínica, Medicamento(s) ou <br />
                Procedimento(s) (exames,cirurgias) ao(s) qual(is) será
                submetido, complementando as informações prestadas pelo seu{" "}
                <br />
                médico e pela equipe de funcionários e prestadores de serviços
                do Hospital Sírio ­Libanês. <br />
              </Text>
              <Text style={{ ...styles.p, marginTop: 10 }}>
                Autorizo o Dr(a). {nome} pertencente ao corpo clínico do
                Hospital Sírio ­Libanês a realização do(s) <br />
                seguinte(s) Tratamento(s), Assistência Clínica ou
                Procedimento(s): <br />
              </Text>

              <View style={{ width: "100%", flexDirection: "row" }}>
                {procedureTable.map((column, i) => (
                  <View
                    style={{
                      marginTop: "8px",
                      width: "23%",
                      marginRight: "2%",
                      flexDirection: "column",
                    }}
                    key={i}
                  >
                    <View style={styles.borderBottom}>
                      <Text style={styles.cellH1}>{column.name}</Text>
                    </View>
                  </View>
                ))}
              </View>
              <View>
                {procedures.map((row, i) => (
                  <View
                    key={i}
                    style={{
                      flexDirection: "row",
                      backgroundColor: "#c0c0c024",
                      marginBottom: "2px",
                    }}
                  >
                    <View style={styles.cell2}>
                      <Text style={styles.p}>{row.tipo}</Text>
                    </View>
                    <View style={styles.cell2}>
                      <Text style={styles.p}>{row.codigoAMB}</Text>
                    </View>
                    <View style={styles.cell2}>
                      <Text style={styles.p}>{row.codigoTUSS}</Text>
                    </View>
                    <View style={styles.cell2}>
                      <Text style={styles.p}>{row.name}</Text>
                    </View>
                    <View style={styles.cell2}>
                      <Text style={styles.p}>{row.lateralidade}</Text>
                    </View>
                  </View>
                ))}
              </View>

              <Text style={{ ...styles.p, marginTop: 10 }}>
                A proposta do(s) Tratamento(s) Assitência Clínica ou
                Procedimento(s) a que serei submetido(a), seus benefícios,{" "}
                <br />
                riscos, complicações potenciais e alternativas me foram
                explicados claramente pelo médico. Tive a oportunidade de <br />
                fazer perguntas, que foram respondidas satisfatoriamente.
                Entendo que não existe garantia absoluta sobre os <br />
                resultados a serem obtidos, mas que serão utilizados todos os
                recursos, medicamentos e equipamentos disponíveis no <br />
                Hospital para ser alcançado/obtido o melhor resultado. Também
                estou ciente de que podem ocorrer complicações <br />
                durante o(s) Tratamento(s), Assistência Clínica ou Procedimento,
                assim como pode ser necessária a modificação da <br />
                proposta inicial em virtude de situações imprevistas, bem como
                aumento no tempo de internação. Infecções <br />
                relacionadas não podem ser descartadas, mesmo com a adoção de
                todas as medidas preventivas preconizada para a <br />
                assistência à saúde. Confirmo que me foi esclarecido os pontos
                necessários e me foi ofertada a possibilidade de <br />
                questionamento para sanar toda e qualquer dúvida que eu poderia
                ter diante do que me foi exposto. <br />
              </Text>

              <Text style={{ ...styles.p, marginTop: 10 }}>
                Apenas para procedimentos cirúrgicos: Declaro que fui
                devidamente informado que, nas cirurgias em que for <br />
                possível e cuja função de gravação esteja disponível em
                equipamento, poderá haver gravação do procedimento pelo <br />
                médico cirurgião. As imagens gravadas pelo médico ficarão
                armazenadas no sistema do Hospital pelo prazo de 30 dias. <br />
                Após esse prazo as imagens serão apagadas do sistema do
                Hospital, não sendo possível a sua recuperação. <br />
              </Text>

              <Text style={{ ...styles.p, marginTop: 10 }}>
                Declaro que fui devidamente esclarecido pelo médico responsável
                que tenho indicação de realizar o procedimento <br />
                cirúrgico acima citado neste momento, pois minha doença pode ser
                agravada caso seja postergado (doença tempo <br />
                sensível). Estou ciente que mesmo com todos os cuidados de
                segurança instituídos por esta unidade, um resultado <br />
                negativo do teste PCR não exclui que eu possa estar em um
                período de incubação do COVID­19 (que pode ser de até <br />
                14 dias), e compreendo que poderei manifestar sintomas
                posteriores ao meu período de permanência por uma <br />
                contaminação anterior ou posterior ao mesmo. <br />
              </Text>

              <View style={styles.signatureSection}>
                <View
                  style={{
                    width: "320px",
                    height: "1px",
                    backgroundColor: fontColor,
                  }}
                />
                <Text style={{ ...styles.p, marginTop: 2 }}>
                  Assinatura Responsável
                </Text>
              </View>
            </View>

            <Text style={{ ...styles.p, marginTop: 10 }}>
              Expliquei todo(s) o(s) Tratamento(s), Assistência Clínica ou
              Procedimento(s) ao paciente acima identificado e/ou seu <br />
              responsável, sobre os benefícios, riscos e alternativas, tendo
              respondido às perguntas formuladas pelos mesmos. De <br />
              acordo com o meu entendimento, o paciente e/ou seu responsável
              está em condições de compreender o que lhes foi <br />
              informado. <br />
            </Text>

            <Text style={{ ...styles.p, marginTop: 10 }}>
              Assinatura do médico responsável/CRM.
            </Text>

            <View
              style={{
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Image
                style={{ width: "140px", height: "auto" }}
                src={{ uri: urlAssinatura, method: "GET" }}
              />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
