export const settings = {
  error: false,
  success: false,
  successAnam: false,
  registerSuccess: false,
  successConsent: false,
  openAnamnese: false,
  consentFormOpen: false,
  confirmGoToHomeOpen: {
    value: false,
    toScreen: "",
  },
  freeFields: false,
  isNewPatient: false,
  currentStep: 1,
  infoPatient: null,
  timeNeedError: false,
  editingModel: false,
  downloadError: false,
  loadingTermo: false,
  modelSave: false,
  saveSolicitationInfoBeforeEditing: null,
  basicInfoError: false,
  invalidPhone: false,
  requiredLaudo: false,
};
