import styled, { css } from "styled-components";
import { TmpInput } from "@tempo/tempo-design-system-core/dist";

export const StyledInput = styled(TmpInput)`
  width: ${({ width }) => width};
  min-width: ${(props) => (props.minWidth ? props.minWidth : "298px")};
  margin-right: ${({ marginRight }) => marginRight};
  flex: ${({ flex }) => flex};

  label {
    margin-top: ${(props) => (props.marginTop ? props.marginTop : "40px")};
  }

  > div > div > input {
    align-items: center;
    width: 100%;
    margin-right: 0px;
  }

  .input-wrapper {
    min-width: ${(props) => (props.minWidth ? props.minWidth : "298px")};
  }

  @media (min-width: 414px) and (max-width: 743px) {
    width: 100%;
    margin-right: 0px;

    ${(props) =>
      props.mobileMinWidth &&
      css`
        min-width: ${(props) => props.mobileMinWidth};
        .input-wrapper {
          min-width: ${(props) => props.mobileMinWidth};
        }
      `}
  }

  @media (min-width: 744px) and (max-width: 811px) {
    ${(props) =>
      props.tabletMinWidth &&
      css`
        min-width: ${(props) => props.tabletMinWidth};
        .input-wrapper {
          min-width: ${(props) => props.tabletMinWidth};
        }
      `}
    width: ${(props) => (props.widthTablet ? props.widthTablet : "100%")};
    margin-right: ${(props) =>
      props.marginTablet ? props.marginTablet : "0px"};
    flex: ${(props) => (props.widthTablet ? "none" : 1)};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Title = styled.h2`
  color: #292929;
  font-size: 20px;
  font-style: normal;
  align-self: flex-start;
  margin-left: 42px;
  font-weight: 700;
  line-height: 133%;
  letter-spacing: -0.2px;
  margin-top: 42px;
`;
export const Text = styled.p`
  color: ${(props) => (props.color ? props.color : "#666")};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  line-height: ${(props) => (props.lineHeigth ? props.lineHeigth : "")};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "auto")};
  white-space: ${(props) => (props.whiteSpace ? props.whiteSpace : "")};
  width: ${(props) => (props.width ? props.width : "")};
`;

export const Procedure = styled.p`
  color: ${(props) => (props.color ? props.color : "#666")};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "auto")};
  max-width: ${(props) => (props.width ? props.width : "")};
`;

export const Name = styled.p`
  color: ${(props) => (props.color ? props.color : "#666")};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  white-space: normal;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "auto")};
  max-width: ${(props) => (props.width ? props.width : "")};
`;
export const WrapperInput = styled.div`
  margin: 40px 0 82px 0;
  .input-wrapper {
    width: 542px;
  }
`;
export const BtnSendWrap = styled.div`
  width: 624px;
  height: 96px;
  display: flex;
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) =>
    props.justContent ? "space-between" : "flex-end"};
  background-color: #f5f5f5;
  margin-top: 64px;

  button {
    margin: ${(props) => props.margin};
  }
  > div {
    width: auto;
    height: 48px;
    margin: 24px 24px 0 0;

    > button {
      min-width: ${(props) => (props.minWidth ? props.minWidth : "")};
      height: ${(props) => (props.loading ? "" : "48px")};
      padding: 0;
      margin: 0;
    }
    > button:nth-last-child(1) {
      min-width: ${(props) => (props.minWidth ? props.minWidth : "")};
      padding: ${(props) => (props.paddingButton ? props.paddingButton : "")};
      height: ${(props) => (props.loading ? "" : "48px")};
    }
  }
  button {
    pointer-events: ${(props) => (props.pointer ? "none" : "")};
  }
`;

export const PatientInfoRow = styled.div`
  display: flex;
  color: #666;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  white-space: pre;
`;

export const PatientInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f5f6fd;
  width: 510px;
  border-radius: 4px;
  padding: 24px 16px;
  margin: 8px;
  gap: 8px;
`;

export const QuestionTextContainer = styled.div`
  display: flex;
  align-self: flex-start;
  margin-left: 42px;
`;
