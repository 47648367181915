import styled from "styled-components";

export const WrapperTooltip = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  .tmp-tooltip-text {
    padding: 4px 12px;
    border-radius: 16px;
    background-color: var(${(props) => `--${props.backGround}`});
    color: var(${(props) => `--${props.color}`});
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .status {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
  }

  .tmp-tooltip__message {
    max-width: 350px;
    z-index: 4;
    p {
      font-size: 14px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;
