import { supportServicesFields, supportServicesExhibition } from "./Models";
import { getPatientAge } from "../../../utils";

export const getServicesDisplayData = (key, value) => {
  if (key === "biopsiaCongelacao") {
    let laboratorio;

    if (value === "radioHsl") laboratorio = "HSL";
    if (value === "radioExt") laboratorio = "Externo";

    return `Laboratório ${laboratorio}`;
  }
  return "Sim";
};

export const getSupportServices = (procedure) => {
  if (!procedure) {
    return undefined;
  }

  let supportServices = [];
  supportServicesFields.forEach((key) => {
    if (procedure[key]) {
      supportServices.push({
        name: supportServicesExhibition[key],
        value: getServicesDisplayData(key, procedure[key]),
      });
    }
  });

  /* 
        Na implementação do pdf é usado um array com multiplos arrays de 3 items cada para exibição
        Por isso, esse passo adicional abaixo:
     */

  let exibicaoServicosDeApoio = [];
  if (supportServices.length) {
    let auxArray = [];
    for (let i = 0; i < supportServices.length; i++) {
      if (auxArray.length < 3) {
        // 0, 1, 2
        auxArray.push(supportServices[i]);
      }
      if (auxArray.length === 3) {
        exibicaoServicosDeApoio.push(auxArray);
        auxArray = [];
      }
    }

    if (auxArray.length) {
      exibicaoServicosDeApoio.push(auxArray);
    }
    return exibicaoServicosDeApoio;
  }
  return undefined;
};

export const getObservation = (context) => {
  if (!context || !context?.observacoes) {
    return undefined;
  }
  return [
    [
      {
        label: "obsProcedure",
        name: "Observações",
        value: context?.observacoes,
      },
    ],
  ];
};

export const getPatientData = (patient, procedure) => {
  if (!patient) {
    return undefined;
  }

  const patientAge = getPatientAge(patient?.dataNascimento);
  const minorAgePatient = patientAge < 18;

  let patientData = [
    [
      { label: "nome", name: "Nome", value: patient?.nome },
      {
        label: "nascimento",
        name: "Nascimento",
        value: patient?.dataNascimento || "---",
      },
      {
        label: "idade",
        name: "Idade",
        value: patient?.dataNascimento ? patientAge : "-",
      },
    ],
    [
      {
        label: "sexo",
        name: "Sexo",
        value:
          patient?.sexo.length === 1
            ? patient?.sexo === "M"
              ? "Masculino"
              : "Feminino"
            : patient?.sexo,
      },
      {
        label: "telefone",
        name: "Telefone",
        value:
          `${patient?.telefone?.ddi}${patient?.telefone?.ddd}${patient?.telefone?.phone}` ||
          "Não Informado",
      },
      { label: "cpf", name: "CPF", value: patient?.cpf || "---" },
    ],
    [
      { label: "unidade", name: "Unidade", value: procedure?.local || "-" },
      { label: "convenio", name: "Convênio", value: patient?.convenio },
      { label: "categoria", name: "Categoria", value: patient?.categoria },
    ],
    [
      { label: "plano", name: "Plano", value: patient?.plano },
      {
        label: "carteirinha",
        name: "Carteirinha",
        value: patient?.carteirinha || "Não informado",
      },
    ],
  ];

  if (minorAgePatient) {
    patientData.push([
      {
        label: "nomeResponsavel",
        name: "Nome do responsável legal",
        value: patient?.nomeResponsavel,
      },
      {
        label: "documentoResponsavel",
        name: "CPF do responsável",
        value: patient?.documentoResponsavel || "-",
      },
    ]);
  }

  return patientData;
};
